import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Vigilancia' : 'Monitoring'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="font-extrabold text-sm mb-3">
        {isSpanish
          ? '¿Cómo monitorear cuando no está allí?'
          : 'How to monitor when you’re not there'}
      </p>
      <p>
        {isSpanish
          ? `Cuando los niños crecen, es normal que pasan más tiempo solos o fuera de 
            casa. Cuanto más tiempo sin supervisión tienen los niños, más posibilidades 
            tienen de meterse en problemas. ¡Esto significa que monitorear a su hijo/a  
            sigue siendo una prioridad!`
          : `As children grow up, it is normal for them to spend time on their own or
            away from home. The more unsupervised time children have, the more chances
            they have to get in trouble. This means monitoring your school-aged child
            is still a top priority!`}
      </p>
    </SlideContainer>
  );
};
