import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting5Slide: FC = () => {
  const { isEnglish } = useProductLanguage();

  const list = isEnglish ? englishListItems : spanishListItems;
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Encouragement & praise' : 'Ánimo y elogio'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            When you use encouragement and praise you promote a strong sense of
            self in your child by sending them these messages:
          </>
        ) : (
          <>
            Cuando usa el ánimo o el elogio, Ud. está promoviendo un fuerte
            sentido de autoestima en su niño/a cuando manda estos mensajes:
          </>
        )}
      </p>

      <YellowCircleList className="my-6 font-bold">
        {list.map((listItem, i) => {
          return <li key={i}>{listItem}</li>;
        })}
      </YellowCircleList>
    </SlideContainer>
  );
};

const englishListItems = [
  'You can do it!',
  'You have good ideas!',
  'You are important!',
  'I like what you are doing right now.',
];

const spanishListItems = [
  '¡Puedes hacerlo!',
  '¡Tienes buenas ideas!',
  '¡Eres importante!',
  '¡Me gusta lo que estás haciendo ahorita!',
];
