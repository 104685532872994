import { FC } from 'react';
import { Com_Com_a } from './Com_Com_a';
import { Com_Listening_a } from './Com_Listening_a';
import { Com_Restate_a } from './Com_Restate_a';
import { Com_Restate_b } from './Com_Restate_b';
import { Com_Restate_c } from './Com_Restate_c';
import { Com_AskQuestions_a } from './Com_AskQuestions_a';
import { Com_AskQuestions_b } from './Com_AskQuestions_b';
import { Com_AskQuestions_c } from './Com_AskQuestions_c';
import { Com_AskQuestions_d } from './Com_AskQuestions_d';
import { Com_ProblemSolving_a } from './Com_ProblemSolving_a';
import { Com_ProblemSolving_b } from './Com_ProblemSolving_b';
import { Com_ProblemSolving_c } from './Com_ProblemSolving_c';

export const comunicationSlides: Record<string, FC> = {
  'Communication A': Com_Com_a,
  'Communication Listening_a': Com_Listening_a,
  'Communication Restate_a': Com_Restate_a,
  'Communication Restate_b': Com_Restate_b,
  'Communication Restate_c': Com_Restate_c,
  'Communication AskQuestions_a': Com_AskQuestions_a,
  'Communication AskQuestions_b': Com_AskQuestions_b,
  'Communication AskQuestions_c': Com_AskQuestions_c,
  'Communication AskQuestions_d': Com_AskQuestions_d,
  'Communication ProblemSolving_a': Com_ProblemSolving_a,
  'Communication ProblemSolving_b': Com_ProblemSolving_b,
  'Communication ProblemSolving_c': Com_ProblemSolving_c,
};
