import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Choose_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Escoger Rutinas' : 'Choose Routines'}
      className="px-14 justify-center text-white font-light"
    >
      <p>
        {isSpanish
          ? `Tener rutinas consistentes y saludables apoya al trabajo escolar 
            de su hijo/a, y le prepara para el éxito. Escoja 3 rutinas que a Ud. 
            le gustaría hablar con su hijo/a. Juntos pueden planear en cuál 
            rutina quieren enfocarse primero.`
          : `Having consistent healthy routines supports your child’s schoolwork, and
            sets them up for success. Choose up to 3 routines that you’d like to
            talk with your child about. Together, you can plan which routine you
            want to focus on first.`}
      </p>
    </SlideContainer>
  );
};
