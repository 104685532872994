import { ComponentPropsWithoutRef, FC } from 'react';
import fcuLogoSrc from '../../images/fcu_logo.png';
import fcuCompactLogoSrc from '../../images/fcu_logo_compact.png';
import { twMerge } from 'tailwind-merge';

interface LogoProps extends Omit<ComponentPropsWithoutRef<'img'>, 'src'> {
  /**
   * Use the "compact" version of the logo (doesn't include the house).
   * Default: false
   */
  compact?: boolean;
}

export const Logo: FC<LogoProps> = ({ className, compact, ...rest }) => (
  <img
    src={compact ? fcuCompactLogoSrc : fcuLogoSrc}
    alt="Family Check-up"
    className={twMerge('w-38 h-43', compact && 'w-18 h-10', className)}
    {...rest}
  />
);
