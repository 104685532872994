import { FC, ReactNode } from 'react';
import { PillButton } from '../PillButton/PillButton';
import { Modal } from '../Modal/Modal';
import './overlay.css';
import { useProductLanguage } from '../../hooks/useProductLanguage';

interface Option {
  value: string;
  label: string;
}

/**
 * @param {boolean} [selectionsDisabled=false] - Defaults to false
 */

export const SelectOverlay: FC<{
  title?: ReactNode;
  options: Option[];
  initValues?: string[];
  value?: string;
  openPortal(value: boolean): void;
  onValueChange(value: string): void;
  selectionsDisabled?: boolean;
}> = ({
  title,
  options,
  initValues = [],
  value = '',
  openPortal,
  onValueChange,
  selectionsDisabled = false,
  ...rest
}) => {
  const selected = (option: Option): boolean =>
    value === option.value || initValues.includes(option.value);

  const disabled = (option: Option): boolean =>
    selectionsDisabled && !initValues.includes(option.value);

  const { isSpanish } = useProductLanguage();

  return (
    <Modal>
      <div
        className="overlay absolute top-0 left-0 z-40 bg-white flex flex-col w-screen min-h-screen flex-1 pt-5 px-8"
        {...rest}
      >
        <div className="flex flex-col">
          {title && (
            <h1 className="font-medium text-center w-75 max-w-full m-auto mb-5 text-blue-400">
              {title}
            </h1>
          )}
          {/* Button List */}
          <div className="flex flex-col flex-grow-1 flex-shrink-0 overflow-auto px-1">
            {options.map((option) => (
              // Option Button
              <button
                key={option.value}
                className={`relative flex justify-start items-center
              text-left font-medium rounded-lg py-2 px-3 mb-3
              shadow-lg transition-all duration-200 flex-shrink-0
              ${
                selected(option)
                  ? 'bg-blue-900 text-white'
                  : 'bg-white text-gray-600 can-hover:hover:bg-gray-200'
              }
              ${disabled(option) && !selected(option) ? 'text-gray-800' : ''}`}
                disabled={disabled(option)}
                onClick={() => onValueChange(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <PillButton
            className="w-52 self-center my-12"
            onClick={() => openPortal(false)}
          >
            {isSpanish ? 'Terminado' : 'Done'}
          </PillButton>
        </div>
      </div>
    </Modal>
  );
};
