import { useEffect } from 'react';

const siteName = 'Family Check-Up';

export function useDocumentTitle(title: string) {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title === '' ? siteName : `${siteName}: ${title}`;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}
