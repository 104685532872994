import { Language } from 'shared/lib/types/Language';
import { useParticipantProductContextOptional } from '../contexts/participantProductContext';

/**
 * Get the currently selected product's language.
 */
export function useProductLanguage(): {
  language: Language;
  isEnglish: boolean;
  isSpanish: boolean;
} {
  const participantContext = useParticipantProductContextOptional();
  const language = participantContext
    ? participantContext.productSession.product.language
    : 'english';

  return {
    language,
    isEnglish: language === 'english',
    isSpanish: language === 'spanish',
  };
}
