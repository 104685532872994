import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Ignoring_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      bearCharacter="BOY_WINKING"
      videoKey={isSpanish ? 'ani_5_spanish' : 'ani_5'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Veamos cómo la familia de los osos usa la estrategia de 
              ignorar para manejar las reacciones negativas.`
            : `Let’s watch the bear family use ignoring to manage negative reactions.`}
        </p>
      }
    />
  );
};
