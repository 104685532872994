import { FC } from 'react';
import { MoodInput } from '../MoodInput/MoodInput';
import { Mood } from 'shared/lib/types/Mood';

export const MoodQuestionInput: FC<{
  value: Mood | null;
  onChange(value: Mood): unknown;
  className?: string;
  question: string;
}> = ({ value, onChange, className = '', question, ...rest }) => (
  <div
    className={`flex flex-col justify-center items-center mb-5 ${className}`}
    {...rest}
  >
    <h3 className="font-bold text-white mb-4 text-center">{question}</h3>
    <MoodInput value={value} onChange={onChange} transparentBg />
  </div>
);
