import { FC } from 'react';
import { PostTestFeedback } from 'shared/lib/types/PostTestFeedback';
import { ReactComponent as Check } from '../../images/feather/check.svg';
import { ReactComponent as Close } from '../../images/feather/x.svg';

interface PostTestFeedbackDrawerProps {
  postTestFeedback: Pick<PostTestFeedback, 'feedback'> | null;
  show: boolean;
  onClose(): void;
  className?: string;
}

export const PostTestFeedbackDrawer: FC<PostTestFeedbackDrawerProps> = ({
  postTestFeedback,
  show,
  onClose,
  className = '',
  ...rest
}) => (
  <div
    className={`flex flex-col w-full bg-blue-200 pt-3 px-5 pb-14 absolute transition-all transform ${
      show
        ? 'translate-y-8 md:translate-y-8 opacity-100'
        : 'translate-y-64 opacity-0'
    } ${className}`}
    {...rest}
  >
    <div className="flex flex-row w-full justify-end">
      <button onClick={onClose} className="pb-1">
        <Close stroke="#439BAC" strokeWidth="4" className="w-5 h-5" />
      </button>
    </div>
    <div className="flex flex-col w-full items-center bg-blue-350 rounded-lg pb-6">
      <div className="flex flex-col items-center justify-center rounded-full bg-red-600 h-10 w-10 transform -translate-y-5">
        <Check stroke="#FFFFFF" className="h-8 w-8" strokeWidth="3" />
      </div>
      <p className="text-center px-5 font-bold text-blue-800 text-15px">
        {postTestFeedback?.feedback}
      </p>
    </div>
  </div>
);
