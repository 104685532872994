import { FC } from 'react';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import { SurveyTakeWithDraftResponses } from 'shared/lib/types/SurveyTake';
import { DraftSurveyResponse } from 'shared/lib/types/SurveyResponse';
import { Redirect, Route, Switch } from 'react-router-dom';
import { calculatePostTestProgress } from 'shared/lib/utils/postTestUtils';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { usePostTestScreen } from '../../hooks/product/usePostTestScreen';
import { POST_TEST_RESULTS } from '../../constants/routes/postTestRoutes';
import { PostTestQuestionScreen } from './PostTestQuestionScreen';

interface PostTestScreenProps {
  basePath?: string;
  survey: SurveyWithQuestions;
  postTestTake: SurveyTakeWithDraftResponses;
  onResponseChange(response: DraftSurveyResponse): unknown;
  onUnitSurveyComplete(): void;
  goToPreviousUnitSurvey(): void;
  postTestSurveys: readonly SurveyWithQuestions[];
}

export const PostTestScreen: FC<PostTestScreenProps> = ({
  basePath = '',
  postTestTake,
  survey,
  onResponseChange,
  onUnitSurveyComplete,
  goToPreviousUnitSurvey,
  postTestSurveys,
}) => {
  const { isSpanish } = useProductLanguage();

  const { postTestComplete, onNext, onBack } = usePostTestScreen({
    basePath,
    survey,
    goToPreviousUnitSurvey,
    onUnitSurveyComplete,
  });

  const surveyIndex = postTestSurveys.findIndex(({ id }) => id === survey.id);

  return (
    <Switch>
      <Route path={`${basePath}/question/:questionNumber`} exact>
        {({ match }) => {
          const questionNumber = match?.params.questionNumber
            ? +match.params.questionNumber
            : null;
          if (questionNumber === null || !Number.isFinite(questionNumber)) {
            return null;
          }

          const question = survey.questions.find(
            (question) => question.index === questionNumber - 1,
          );

          if (!question) {
            // Invalid question number. Redirect to the welcome screen.
            return <Redirect to={`${basePath}/`} />;
          }

          return (
            <PostTestQuestionScreen
              survey={survey}
              question={question}
              value={
                postTestTake.responses.find(
                  ({ questionId }) => questionId === question.id,
                )?.value ?? null
              }
              onChange={(value) =>
                onResponseChange({ questionId: question.id, value })
              }
              onNext={() => onNext(question.index)}
              onBack={() => onBack(question.index)}
              isSpanish={isSpanish}
              postTestProgress={calculatePostTestProgress(
                survey,
                question,
                postTestSurveys,
              )}
              hideBack={surveyIndex <= 0 && questionNumber <= 1}
            />
          );
        }}
      </Route>
      <Route path={`${basePath}/`}>
        {({ match }) => {
          if (!match) {
            return;
          }

          if (postTestComplete) {
            return <Redirect to={POST_TEST_RESULTS} />;
          } else if (postTestTake.responses.length > 0) {
            const firstUnansweredQuestion = survey.questions.find(
              (question) =>
                !postTestTake.responses.some(
                  (response) => response.questionId === question.id,
                ),
            );

            return (
              <Redirect
                to={`${basePath}/question/${
                  (firstUnansweredQuestion?.index ?? 0) + 1
                }`}
              />
            );
          } else {
            return <Redirect to={`${basePath}/question/1`} />;
          }
        }}
      </Route>
    </Switch>
  );
};
