import { FC } from 'react';
import {
  ParticipantProductContext,
  ParticipantProductContextValue,
} from './participantProductContext';
import {
  ParticipantCoachContext,
  ParticipantCoachContextValues,
} from './participantCoachContext';
import {
  OrgDocumentContext,
  OrgDocumentContextValues,
} from './orgDocumentContext';

type ParticipantAppContextProps = ParticipantProductContextValue &
  ParticipantCoachContextValues &
  OrgDocumentContextValues;

export const ParticipantAppContext: FC<ParticipantAppContextProps> = ({
  coach,
  visibleCoachFields,
  productSession,
  setProductSession,
  nextAppointmentDate,
  orgDocument,
  children,
}) => (
  <ParticipantProductContext.Provider
    value={{
      productSession,
      setProductSession,
    }}
  >
    <ParticipantCoachContext.Provider
      value={{ coach, visibleCoachFields, nextAppointmentDate }}
    >
      <OrgDocumentContext.Provider value={{ orgDocument }}>
        {children}
      </OrgDocumentContext.Provider>
    </ParticipantCoachContext.Provider>
  </ParticipantProductContext.Provider>
);
