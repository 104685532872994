import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { SuggestionTextArea } from '../../../components/SuggestionTextArea/SuggestionTextArea';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSuggestions = [
  'Take a walk after dinner or on weekends.',
  'Wash the car together.',
  'Walk or bike to school together.',
  'Take the dog on longer walks.',
  'Do yard work with together.',
  'Do housework together.',
  'Have a sit-up competition with your kids.',
  'Have a dance party with your child’s favorite music.',
];

const spanishSuggestions = [
  'Salir a caminar después de cenar o los fines de semana.',
  'Lavar el auto juntos.',
  'Caminar o andar en bicicleta juntos a la escuela. ',
  'Llevar al perro a paseos más largos.',
  'Trabajar juntos en el jardín.',
  'Limpiar la casa juntos.',
  'Tener una competencia de abdominales con sus hijo/a.',
  'Tener una fiesta de baile con la música favorita de su hijo/a.',
];

export const HBST_Physicalactivity_c: FC = () => {
  const { next } = useSlideContext();
  const {
    value: answers,
    setValue: setAnswers,
    isReview,
    hasInteracted,
    activity,
  } = useActivityState<string[]>([]);
  const [openPortal, setOpenPortal] = useState<null | number>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const maxSelections = 3;

  const handleAnswer =
    (key: number) => (type: 'text' | 'select') => (value: string) => {
      let newAnswers = [...answers];

      if (type === 'text') {
        newAnswers[key] = value;
      } else if (type === 'select') {
        if (newAnswers.includes(value)) {
          // if value already in array, get rid of it
          newAnswers = newAnswers.filter((item) => item !== value);
        }
        // if max selections reached, replace current field with new selection
        else if (newAnswers.filter(Boolean).length === maxSelections) {
          newAnswers[key] = value;
        }
        // if current index has no value, fill it
        else if (!newAnswers[key]) {
          newAnswers[key] = value;
        }
        // find any empty indexes and fill selection
        else if (newAnswers.indexOf('') !== -1) {
          newAnswers[newAnswers.indexOf('')] = value;
        } else {
          newAnswers = [...newAnswers, value];
        }
      }
      setAnswers(newAnswers);
    };

  const handlePortal = (key: number) => (value: boolean) => {
    setOpenPortal(value ? key : null);
  };

  const allAnswers = answers.filter(Boolean);

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const suggestions = isSpanish ? spanishSuggestions : englishSuggestions;

  const suggestionAreas = Array.from({ length: maxSelections }).map(() => ({
    title: isSpanish
      ? 'Sugerencias de actividades físicas'
      : 'Physical Activity Suggestions',
    placeholder: isSpanish
      ? 'Escriba sus propias estrategias o escoja de la lista.'
      : 'Type your own or choose from the list',
  }));

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pencil"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? `Su turno: Eliga hasta 3 actividades que podría probar 
              con su hijo para aumentar la actividad física.`
            : `Your turn: Choose up to 3 activities you could try out 
              with your child to increase physical activity.`
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="pt-4 px-5">
            {suggestionAreas.map((area, idx) => (
              <SuggestionTextArea
                key={idx}
                placeholder={area.placeholder}
                value={answers[idx]}
                suggestions={suggestions}
                suggestionsTitle={area.title}
                pickedSuggestions={allAnswers}
                onValueChange={handleAnswer(idx)}
                showSuggestions={openPortal === idx}
                openPortal={handlePortal(idx)}
                maxSelections={maxSelections}
              />
            ))}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 my-12 self-center"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine el Plan' : 'Finish Plan'}
          </PillButton>
        </div>
        <QuizCompletionOverlay
          open={showOverlay}
          onNext={next}
          bearCharacter="GIRL_WINKING"
          activityPoints={activity.pointValue}
        />
      </ActivityBox>
    </SlideContainer>
  );
};
