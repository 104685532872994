import { ComponentPropsWithoutRef, FC } from 'react';
import { PlanLetterOption } from 'shared/lib/types/PlanLetterOption';

export interface PlanLetterProps extends ComponentPropsWithoutRef<'div'> {
  className?: string;
  activeClassName?: string;
  letterClassName?: string;
  letterOption?: PlanLetterOption;
  active?: boolean;
}

export const PlanLetter: FC<PlanLetterProps> = ({
  className = '',
  activeClassName = '',
  letterClassName = '',
  letterOption = PlanLetterOption.P,
  active = true,
  ...rest
}) => (
  <div
    className={`flex justify-center items-center 
      rounded-full w-10 h-10 bg-blue-400 ${className}
    ${active ? `border border-blue-200 ${activeClassName}` : ''}
    `}
    {...rest}
  >
    <span
      className={`font-bold ${
        active ? 'text-white' : 'text-blue-550'
      } ${letterClassName}`}
    >
      {letterOption}
    </span>
  </div>
);
