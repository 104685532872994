import { FC } from 'react';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_Restate_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish ? 'Repita con  sentimiento' : 'Restate with feeling'
      }
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Empiece por usar sus propias palabras para repetir lo que le dijo 
            su hijo/a. Después, use declaraciones de sentimientos para mostrar 
            que notó la importancia de lo que compartió.`
          : `Start by using your own words to restate what your child told you. Next
            use feeling statements to show you noticed the importance of what they
            shared.`}
      </p>
      <ExampleSection
        title={
          <h3 className="uppercase font-bold text-lg ml-2">
            {isSpanish ? 'Ejemplo' : 'Example:'}
          </h3>
        }
        className="mt-4"
      >
        <div className="pl-8">
          <p className="mb-8 mt-2">
            <b className="font-bold">
              {isSpanish ? 'El niño/a dice: ' : 'Child says: '}
            </b>
            {isSpanish
              ? `Estaba almorzando en la escuela con algunos amigos y creo que
                van a ir a la fiesta de cumpleaños de Jenna este fin de semana.
                Descubrí que no me invitaron.`
              : `I was eating lunch at school with
                some friends and I guess they are going to Jenna’s birthday
                party this weekend. I found out I wasn’t invited.`}
          </p>
          <b className="font-bold">
            {isSpanish ? 'El padre o la madre dice:' : 'Parent says:'}
          </b>
          <ExampleSection
            title={
              <h4 className="uppercase text-red-600 font-bold ml-2">
                {isSpanish ? 'Los Hechos' : 'The Facts'}
              </h4>
            }
            icon="magnifyingGlass"
            className="font-light mb-8 mt-4"
          >
            <p className="pl-12">
              {isSpanish
                ? `Estabas hablando con tus amigos y te enteraste de la fiesta de 
                  Jenna este fin de semana.`
                : `You were talking to your friends and found out about Jenna’s
                  party this weekend.`}
            </p>
          </ExampleSection>
          <ExampleSection
            title={
              <h4 className="uppercase text-red-600 font-bold ml-2">
                {isSpanish ? 'Los Sentimientos' : 'The Feelings'}
              </h4>
            }
            icon="pulsingHeart"
            className="font-light"
          >
            <p className="pl-12">
              {isSpanish
                ? 'Eso suena muy dificil, lo siento que no te invitaron.'
                : 'That sounds really hard, I’m sorry you weren’t invited.'}
            </p>
          </ExampleSection>
        </div>
      </ExampleSection>
    </SlideContainer>
  );
};
