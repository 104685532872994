import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useSlideContext } from '../../../contexts/slideContext';
import rewardIconSrc from '../../../images/ribbon_reward_icon.png';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_i: FC = () => {
  const { next } = useSlideContext();
  const { setValue, isReview, activity } = useActivityState<{
    bool: boolean | null;
  }>({ bool: null });
  const [showToast, setShowToast] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleToast = () => {
    setShowToast(false);
    if (isReview) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      className="flex-col px-14 font-light text-white"
      hideButton={!isReview}
    >
      <ExampleSection
        title={
          <h3 className="uppercase font-bold ml-2">
            {isSpanish ? 'Ejemplo' : 'Example:'}
          </h3>
        }
      >
        <p className="pl-8 mt-2">
          {isSpanish
            ? `Ud. va a entrar a una tienda de ropa y Ud. le dice a su hijo/a 
              "Si puedes jugar juegos en mi teléfono por 15 minutos mientras 
              yo compro, podemos pasar por helado cuando vamos rumbo para 
              la casa.`
            : `You are about to enter a clothing store and you say to your kid, 
              “If you can play games on my phone for 15 minutes while I shop, 
              we can stop for ice cream on our way home.”`}
        </p>
      </ExampleSection>
      <p className="text-red-600 text-center font-bold mt-10">
        {isSpanish
          ? '¿En este ejemplo es un soborno o premio?'
          : 'Is this example a bribe or a reward?'}
      </p>
      <PillButton
        theme={ButtonTheme.WHITE}
        className="w-52 self-center mt-10 mb-4 pt-2 pb-2"
        onClick={() => {
          setValue({ bool: true });
          setShowToast(true);
        }}
      >
        {isSpanish ? 'Soborno' : 'Bribe'}
      </PillButton>
      <PillButton
        theme={ButtonTheme.WHITE}
        className="w-52 self-center pt-2 pb-2"
        onClick={() => {
          setValue({ bool: false });
          setShowToast(true);
        }}
      >
        {isSpanish ? 'Premio' : 'Reward'}
      </PillButton>
      {showToast && (
        <BottomToast
          onButtonClick={handleToast}
          buttonLabel={isSpanish ? 'Termine la Actividad' : 'Finish Activity'}
          buttonTheme={ButtonTheme.YELLOW}
        >
          <div className="flex flex-col justify-center items-center px-5 pb-7">
            <img
              src={rewardIconSrc}
              alt={isSpanish ? 'premio' : 'reward'}
              className="w-24 pt-4 pb-3"
            />
            <div className="bg-blue-350 rounded-lg p-5 pb-3 text-blue-800 text-center font-bold">
              {isSpanish
                ? `Esto es un premio, porque el helado se le va dar al niño/a después de 
                  él/ella la deje comprar por 15 minutos y se usa con un incentivo.`
                : `This is a reward because the ice cream is given after they leave
                  you free to shop for 15 minutes.`}
            </div>
          </div>
        </BottomToast>
      )}
      {showOverlay && (
        <QuizCompletionOverlay
          open={showOverlay}
          onNext={next}
          activityPoints={activity.pointValue}
          bearCharacter="GIRL"
        />
      )}
    </SlideContainer>
  );
};
