import { FC, MouseEventHandler } from 'react';
import { ReactComponent as ChevronRightIcon } from '../../images/feather/chevron-right.svg';

export const ChevronCircleButton: FC<{
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}> = ({ disabled, onClick, className, ...rest }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`border-none p-0 m-0 h-10 w-10 rounded-full
      text-yellow flex justify-center items-center text-center transition duration-1000
      ${disabled ? 'bg-blue-850' : 'bg-blue-600'}
      ${className}`}
    {...rest}
  >
    <ChevronRightIcon strokeWidth={3} />
  </button>
);
