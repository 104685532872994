import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse15Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Communication' : 'Comunicación'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Using these positive communication strategies can help you express
            yourself more clearly, and improve your important relationships.
          </>
        ) : (
          <>
            El uso de estas estrategias de comunicación positiva pueden ayudarle
            a expresarse más claramente y mejorar sus relaciones importantes.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
