import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_g: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Sobornos y cómo son diferentes a los premios'
          : 'Bribes & how they differ from rewards'
      }
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `Hay otra estrategia que los padres le gustan usar para hacer que los niños se porten la manera deseada, el soborno.`
          : `There is a third strategy parents use to make their children behave in a
            desired way, bribery.`}
      </p>
      <p>
        {isSpanish ? (
          <>
            Un soborno ocurre antes del comportamiento deseado, y un premio
            ocurre después del comportamiento. Porque el soborno se da{' '}
            <strong className="font-bold">antes</strong> del comportamiento, es
            menos eficaz. El hijo/a puede o no puede hacer el comportamiento
            deseado.
          </>
        ) : (
          <>
            Because the bribe is given{' '}
            <strong className="font-bold">before</strong> the behavior, they are
            less effective. The child may or may not do the desired behavior.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
