import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Bodyposi_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={isSpanish ? 'Positivadad Corporal' : 'Body Positivity'}
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_17_spanish' : 'ani_17'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? 'Observe cómo esta mamá habla sobre la imagen corporal positiva con su hija.'
            : 'Watch how this Mom talks about positive body image with her child.'}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
