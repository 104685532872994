import { FC, useState, useEffect } from 'react';
import checkStar from '../../images/CheckStar@3x.png';
import bearMan from '../../images/bears/BearMan@3x.png';
import { PillButton } from '../PillButton/PillButton';
import { Blurb } from '../Blurb/Blurb';
import getPositiveBlurbText from '../../utils/getPositiveBlurbText';
import { useUserOptional } from '../../contexts/userContext';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { Modal } from '../Modal/Modal';
import { useProductLanguage } from '../../hooks/useProductLanguage';

/**
 * @param {string} [blurbText=getPositiveBlurbText()] - Defaults to function which returns a random blurb
 * @param {string} [nextButtontext=Continue] - Defaults to Continue
 */

const SectionCompletionOverlay: FC<{
  currentSegment: number;
  segmentCount: number;
  moduleName: string;
  onNext(): void;
  onReminderEnabledChange(enabled: boolean): void;
  blurbText?: string;
  nextButtonText?: string;
}> = ({
  currentSegment,
  segmentCount,
  moduleName,
  onNext,
  onReminderEnabledChange,
  blurbText,
  nextButtonText,
  ...rest
}) => {
  const { logout } = useUserOptional();
  const [returnLater, setReturnLater] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const handleReturnLaterClick = () => {
    setReturnLater(true);
    onReminderEnabledChange(true);
  };

  const handleNextClick = () => {
    if (returnLater) {
      onReminderEnabledChange(false);
    }
    return onNext();
  };

  // Keep overlay content at the top on long viewport heights
  useEffect(() => window.scrollTo(0, 0), []);
  useBodyOverflowHidden();

  const bearBlurb = blurbText || getPositiveBlurbText(language);

  return (
    // Overlay
    <Modal>
      <div
        className="flex relative overflow-y-auto flex-col w-screen h-screen text-white bg-blue-400 z-40 px-3"
        {...rest}
      >
        {/* Content Container */}
        <div className="flex-1 max-w-full mx-auto flex flex-col justify-between items-center">
          {/* Upper Content Container */}
          <div className="flex flex-col flex-1 items-center">
            {/* Star Check Image Container */}
            <div className="flex justify-center items-center content-center flex-shrink-0 my-5">
              <img src={checkStar} alt="Star Approval Logo" className="w-24" />
            </div>
            <div className="flex justify-center text-3xl flex-shrink-0 font-light">
              {isSpanish ? '¡Felicidades!' : 'Congratulations!'}
            </div>
            {/* Module Progress Container */}
            <div className="font-normal text-center my-10 text-blue-900">
              {returnLater ? (
                <p>
                  {isSpanish ? (
                    <>
                      No hay problema, recibirás un recordatorio
                      <br />
                      por mensaje de texto para que regreses mañana!
                    </>
                  ) : (
                    <>
                      No problem, you'll get a text
                      <br />
                      reminder to come back tomorrow!
                    </>
                  )}
                </p>
              ) : (
                <>
                  <div>{isSpanish ? 'Ha terminado' : "You've finished"}</div>
                  <div
                    className="font-extrabold my-px"
                    style={{ lineHeight: '1.4rem' }}
                  >
                    <div className="inline-block h-5 w-5 rounded-full mr-1 bg-blue-425">
                      {currentSegment}
                    </div>
                    {isSpanish ? 'de' : 'out of'} {segmentCount}
                  </div>
                  <div>
                    {isSpanish ? 'partes de' : 'parts of'} {moduleName}.
                  </div>
                </>
              )}
            </div>
            {/* Button Column */}
            <div className="flex flex-col content-center items-center flex-shrink-0 w-full">
              <PillButton
                className="w-52 mb-5"
                onClick={returnLater ? logout : handleNextClick}
              >
                {
                  //prettier-ignore
                  returnLater
                      ? (isSpanish ? 'Terminar' : 'Log Out')
                      : (nextButtonText || isSpanish ? 'Siguiente' : 'Continue')
                }
              </PillButton>
              <PillButton
                className="w-52 bg-opacity-0 can-hover:hover:bg-coolGray-900 can-hover:hover:bg-opacity-30"
                onClick={returnLater ? handleNextClick : handleReturnLaterClick}
              >
                {
                  //prettier-ignore
                  returnLater
                      ? (nextButtonText || isSpanish ? 'Siguiente' : 'Continue')
                      : (isSpanish ? 'Regrese Más Tarde' : 'Return Later')
                }
              </PillButton>
            </div>
          </div>

          {/* Bear Row */}
          <div className="flex flex-row justify-center flex-grow-0 flex-shrink-0 w-full">
            {/* Bear Man */}
            <img
              src={bearMan}
              alt="Bear Character"
              className="w-48 md:w-60 flex-grow-0"
            />
            {/* Bear Blurb */}
            <Blurb className="p-10 px-14">
              {returnLater ? (
                <>
                  {isSpanish ? (
                    <>
                      Te veo <br /> pronto
                    </>
                  ) : (
                    <>
                      see you <br /> soon!{' '}
                    </>
                  )}{' '}
                </>
              ) : (
                bearBlurb
              )}
            </Blurb>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SectionCompletionOverlay;
