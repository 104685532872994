import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import {
  englishHelpfulUnhelpfulQuiz,
  spanishHelpfulUnhelpfulQuiz,
} from '../../../constants/early_childhood/positive_parenting_17_quiz';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type HelpfulQuizKey = keyof typeof englishHelpfulUnhelpfulQuiz;

type ActivityState = Partial<Record<HelpfulQuizKey, boolean>>;

export const PositiveParenting17Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const { value, setValue, isReview, activity } =
    useActivityState<ActivityState>({});

  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const moveForward = Object.keys(value).length > 2;
  const helpfulUnhelpfulQuiz = isEnglish
    ? englishHelpfulUnhelpfulQuiz
    : spanishHelpfulUnhelpfulQuiz;
  const helpfulWord = isEnglish ? 'Helpful' : 'Útil';
  const unhelpfulWord = isEnglish ? 'Unhelpful' : 'No Útil';
  return (
    <SlideContainer
      buttonText="Start Sorting"
      buttonTheme={ButtonTheme.YELLOW}
      className="flex-col items-center text-white"
      hideButton
      lightProgressBar
      lightBg
    >
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-900"
        instructions={
          isEnglish
            ? 'Your turn: Helpful or Unhelpful?'
            : 'Su turno: ¿Util o No Útil? '
        }
        icon="pointer"
      >
        <p className="px-14 mb-10 font-light">
          {isEnglish ? (
            <>
              This is an activity to help you tell the difference between
              helpful and unhelpful ways to give directions to your child. Check
              the button where you think the example statement belongs.
            </>
          ) : (
            <>
              Decida si cada afirmación es útil o no útil para dar instrucciones
              a su niño/a.
            </>
          )}
        </p>
        <HelpfulUnhelpfulQuiz
          title={
            isEnglish
              ? 'Decide if these ways of giving direction are helpful or unhelpful.'
              : 'Decida si estas formas de dar instrucciones son útiles o no útiles.'
          }
          iconPair="helpful/unhelpful"
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
        >
          {Object.entries(helpfulUnhelpfulQuiz).map(
            ([key, { answer, question, feedback }]) => (
              <HelpfulUnhelpfulQuizQuestion
                helpfulWord={helpfulWord}
                unhelpfulWord={unhelpfulWord}
                key={key}
                isHelpfulCorrect={answer}
                value={value[key as HelpfulQuizKey] ?? null}
                questionText={question}
                feedback={feedback}
                onChange={(bool) => {
                  setValue({
                    ...value,
                    [key]: bool,
                  });
                }}
              />
            ),
          )}
        </HelpfulUnhelpfulQuiz>
        {moveForward && (
          <PillButton
            className="mt-10"
            theme={ButtonTheme.YELLOW}
            onClick={() => (isReview ? next() : setShowOverlay(true))}
          >
            {isEnglish ? 'Finish Activity' : 'Termine la Actividad'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
