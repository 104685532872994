import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Reduce stress, depression, and anxiety.',
  'Support healthy habits like eating and exercise.',
  'Improve cognitive, social, and emotional development of children.',
  'Reduce substance use.',
  'Improve academic outcomes.',
];

const spanishList = [
  'Reducen el estrés, la depresión y la ansiedad.',
  'Apoyan los hábitos saludables como comer y hacer ejercicio.',
  'Mejoran el desarrollo cognitivo, social y emocional de los niños.',
  'Reducen el uso de sustancias.',
  'Mejoran los resultados académicos.',
];

export const SSS_Healthy_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas Saludables' : 'Healthy Routines'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? 'Los Beneficios de las Rutinas Saludables:'
        : 'Benefits of Healthy routines:'}

      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
