import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { FC, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { ProductVisit } from 'shared/lib/types/ProductVisit';
import {
  getMostRecentUnitLocation,
  getUnitSlides,
  isUnitComplete,
  isUnitUnlocked,
} from 'shared/lib/utils/unitUtils';
import { api } from '../api';
import { Spinner } from '../components/Spinner/Spinner';
import { ParticipantProductContextValue } from '../contexts/participantProductContext';
import { useParticipantUser } from '../contexts/userContext';
import { ChooseRolePage } from '../pages/ChooseRolePage/ChooseRolePage';
import { ParticipantAccountPage } from '../pages/ParticipantAccountPage/ParticipantAccountPage';
import { UnitListPage } from '../pages/UnitListPage/UnitListPage';
import { UnitPage } from '../pages/UnitPage/UnitPage';
import { ParticipantCoachPage } from '../pages/ParticipantCoachPage/ParticipantCoachPage';
import { ParticipantLibraryPage } from '../pages/ParticipantLibraryPage/ParticipantLibraryPage';
import { ParticipantPlanPage } from '../pages/ParticipantPlanPage/ParticipantPlanPage';
import { PrivacyPolicyModal } from '../components/PrivacyPolicyModal/PrivacyPolicyModal';
import { WelcomeOverlay } from '../components/WelcomeOverlay/WelcomeOverlay';
import { WelcomeBackOverlay } from '../components/WelcomeBackOverlay/WelcomeBackOverlay';
import {
  usePatchSearchParams,
  useSearchParams,
} from '../hooks/useSearchParams';
import { usePostTestRedirect } from '../hooks/product/usePostTestRedirect';
import { PostTestPage } from '../pages/PostTestPage/PostTestPage';
import {
  useParticipantWelcomeScreen,
  WelcomeScreenType,
} from '../hooks/participant/useParticipantWelcomeScreen';
import { PostTestSurveyOverlay } from '../components/PostTestSurveyOverlay/PostTestSurveyOverlay';
import {
  getUnitNumberOrResults,
  isPostTestResults,
  POST_TEST_ROOT,
} from '../constants/routes/postTestRoutes';
import { PostTestResultsPage } from '../pages/PostTestResultsPage/PostTestResultsPage';
import { usePostTestSurveys } from '../hooks/product/usePostTestSurveys';
import { useParticipantCoachContextManager } from '../contexts/participantCoachContext';
import { useOrgDocument } from '../hooks/org/useOrgDocument';
import { ParticipantAppContext } from '../contexts/ParticipantAppContext';
import { ParticipantResourcesPage } from '../pages/ParticipantResources/ParticipantResourcesPage';
import { OrgDocumentCategory } from 'shared/lib/constants/org/OrgDocumentCategory';

export const ParticipantApp: FC = () => {
  const { user, orgProduct, participant, roles } = useParticipantUser();
  const history = useHistory();
  const orgId = participant.orgId;
  const productId = orgProduct.productId;
  const participantId = participant.id;
  const [productSession, setProductSession] =
    useState<DraftParticipantProductSession | null>(null);
  const showPrivacyPolicyModal = useSearchParams().get('privacy-policy') === '';
  const patchSearchParams = usePatchSearchParams();

  useProductVisitInterval({ orgId, productId });

  const setWelcomed = useCallback(async () => {
    setProductSession(
      (current) =>
        current && {
          ...current,
          participantProduct: {
            ...current.participantProduct,
            welcomedAt: new Date().toISOString(),
          },
        },
    );
    try {
      await api.setWelcomed({ orgId, productId });
    } catch (error) {
      console.error('Failed to record user welcome', error);
    }
  }, [orgId, productId]);

  /**
   * Fetch the participant's progress in the currently selected product.
   */
  useAsyncEffect(
    async (isCancelled) => {
      const fetchedSession = await api.getParticipantProductSession({
        orgId,
        productId,
        participantId,
      });

      if (!isCancelled()) {
        setProductSession({
          ...fetchedSession,
          allModulesComplete: fetchedSession.unitSessions.every(isUnitComplete),
        });
      }
    },
    [orgId, productId, participantId],
  );

  const { goToPostTest } = usePostTestRedirect(productSession);

  const {
    welcomeScreenType,
    onWelcomeScreenDismissed,
    showWelcomeBackOverlay,
  } = useParticipantWelcomeScreen(productSession);

  const { postTestSurveys } = usePostTestSurveys(productSession);

  const coachContextValues = useParticipantCoachContextManager(productSession);

  const { orgDocument } = useOrgDocument(orgId, OrgDocumentCategory.RESOURCE);

  if (!productSession) {
    return (
      <div className="h-screen bg-blue-900 flex flex-col justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <ParticipantAppContext
      productSession={productSession}
      setProductSession={
        setProductSession as ParticipantProductContextValue['setProductSession']
      }
      orgDocument={orgDocument}
      {...coachContextValues}
    >
      {welcomeScreenType === WelcomeScreenType.INITIAL_WELCOME && (
        <WelcomeOverlay
          onVideoEnd={setWelcomed}
          onClose={setWelcomed}
          showCloseButton={!!productSession.participantProduct.welcomedAt}
        />
      )}
      {welcomeScreenType === WelcomeScreenType.WELCOME_BACK && (
        <WelcomeBackOverlay
          onClose={onWelcomeScreenDismissed}
          onContinue={() => {
            onWelcomeScreenDismissed();
            const mostRecentLocation = productSession
              ? getMostRecentUnitLocation(productSession)
              : null;

            /**
             * No location detected. Navigate to the first unit.
             */
            if (!mostRecentLocation || !productSession) {
              history.replace(`/unit/1`);
              return;
            }

            const unitSession = productSession.unitSessions.find(
              (unitSession) =>
                unitSession.unit.id === mostRecentLocation?.unitId,
            );

            if (unitSession) {
              const slideIndex = getUnitSlides(unitSession.unit).findIndex(
                (slide) => slide.id === mostRecentLocation.slideId,
              );

              history.replace(
                `/unit/${unitSession.unit.index + 1}/${
                  slideIndex === -1 ? 1 : slideIndex + 1
                }`,
              );
            } else {
              history.replace(`/unit/1`);
            }
          }}
        />
      )}
      {welcomeScreenType === WelcomeScreenType.POST_TEST && (
        <PostTestSurveyOverlay
          onGoToPostTestClicked={() => {
            onWelcomeScreenDismissed();
            goToPostTest();
          }}
        />
      )}
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal
          onClose={() => patchSearchParams({ 'privacy-policy': null })}
          language={productSession.product.language}
        />
      )}
      {!showWelcomeBackOverlay && (
        <Switch>
          <Route path="/unit/:unitNumber">
            {({ match }) => {
              if (!match) {
                return null;
              }
              const unitNumber = +match.params.unitNumber;
              const unitIndex = unitNumber - 1;
              const unitSession = productSession.unitSessions.find(
                ({ unit }) => unit.index === unitIndex,
              );
              const unit = unitSession?.unit;

              if (!unit || !isUnitUnlocked(user, productSession, unit.id)) {
                return <Redirect to="/" />;
              }

              return (
                <UnitPage
                  key={
                    unit.id /* force the unit page to remount when switching between different units */
                  }
                  unit={unit}
                />
              );
            }}
          </Route>
          <Route path="/coach">
            <ParticipantCoachPage />
          </Route>
          <Route path="/account">
            <ParticipantAccountPage />
          </Route>
          <Route path="/library">
            <ParticipantLibraryPage />
          </Route>
          <Route path="/plan">
            <ParticipantPlanPage />
          </Route>
          {roles.length > 0 && (
            <Route path="/products">
              <ChooseRolePage
                onRoleChange={() => history.push('/')}
                header={
                  <>
                    Do you want to change FCU Products? We will save your place
                    in FCU {productSession.product.name}.
                  </>
                }
              />
            </Route>
          )}
          {productSession.postTestUnlocked && (
            <Route path={POST_TEST_ROOT}>
              {({ match }) => {
                if (showWelcomeBackOverlay) {
                  return;
                }
                if (!match) {
                  return;
                }
                const surveyIdOrResults = getUnitNumberOrResults(
                  match.params.surveyIdOrResults,
                );
                if (surveyIdOrResults === null) {
                  return <Redirect to="/" />;
                }
                if (isPostTestResults(surveyIdOrResults)) {
                  return <PostTestResultsPage />;
                }
                const surveyId = +surveyIdOrResults;
                const survey = postTestSurveys.find(
                  ({ id }) => id === surveyId,
                );
                if (!survey) {
                  return <Redirect to="/" />;
                }
                return <PostTestPage key={survey.id} survey={survey} />;
              }}
            </Route>
          )}
          <Route path="/resources">
            <ParticipantResourcesPage />
          </Route>
          <Route path="/">
            <UnitListPage />
          </Route>
        </Switch>
      )}
    </ParticipantAppContext>
  );
};

/**
 * Creates a product visit and update it every 10 seconds while the component is mounted.
 */
function useProductVisitInterval({
  orgId,
  productId,
}: {
  orgId: number;
  productId: number;
}): void {
  useEffect(() => {
    let productVisit: ProductVisit | null = null;
    let lastTick = Date.now();

    const interval = setInterval(async () => {
      if (productVisit !== null) {
        const now = Date.now();
        try {
          const elapsed = now - lastTick;
          lastTick = now;
          await api.tickProductVisit({
            orgId,
            productId,
            visitId: productVisit.id,
            elapsed,
          });
        } catch (error) {
          console.error(`Failed to tick product visit`, {
            response: error.response,
          });
          if (error.status === 401) {
            clearInterval(interval);
          }
        }
      }
    }, 10000);

    api
      .visitProduct({ orgId, productId })
      .then((newProductVisit) => {
        productVisit = newProductVisit;
      })
      .catch((error) => {
        console.error(`Failed to tick product visit`, error);

        // Not logged in
        if (error.status === 401) {
          clearInterval(interval);
        }
      });

    return () => {
      clearInterval(interval);
    };
  }, [orgId, productId]);
}
