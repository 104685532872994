import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import {
  RoutineCalculator,
  Routine,
} from '../../../components/RoutineCalculator/RoutineCalculator';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishMorningRoutines = [
  'Brush teeth',
  'Shower',
  'Get dressed',
  'Eat breakfast',
  'Prepare backpack',
  'Make lunch',
];

const spanishMorningRoutines = [
  'Lavarse los dientes',
  'Ducharse',
  'Vestirse',
  'Comer el desayuno',
  'Preparar la mochila',
  'Hacer el almuerzo',
];

export const SSS_Morning_b: FC = () => {
  const { next } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const morningRoutines = isSpanish
    ? spanishMorningRoutines
    : englishMorningRoutines;

  const defaultRoutine: Routine = {
    tasks: Object.fromEntries(
      morningRoutines.map((routine, idx) => [idx, { task: routine, time: 0 }]),
    ),
    leaveTime: '',
  };

  const {
    value: routine,
    setValue: setRoutine,
    isReview,
    hasInteracted,
    activity,
  } = useActivityState(defaultRoutine);

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pencil"
        className="items-center pt-10 pb-4 px-4 text-blue-600 flex-1"
        instructions={
          <div className="text-center">
            <h2 className="text-lg leading-6">
              {isSpanish
                ? `Su turno: Trabaje con su hijo/a para pensar en todo lo que 
                  necesita hacer antes de la escuela. Utilice la Calculadora 
                  de Rutina de la Mañana para ayudarse.`
                : `Your turn: Work with your child to come up with all that needs to
                  be done before school. Use the Morning Routine Calculator to help
                  you.`}
            </h2>
            <p className="text-xs mt-3">
              {isSpanish
                ? `(Le hemos dado unos ejemplos, pero siéntase libre de cambiarlos 
                  como lo sea necesario.)`
                : `(We’ve given you some examples, but feel free to edit them as
                  needed.)`}
            </p>
          </div>
        }
      >
        <div className="flex flex-col justify-between items-center w-full m-0 p-0 flex-1">
          <div className="w-full">
            <RoutineCalculator routine={routine} setRoutine={setRoutine} />
          </div>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="mt-10 mb-10 w-52"
            onClick={handleSubmit}
          >
            {
              // prettier-ignore
              routine.leaveTime
              ? (isSpanish ? 'Terminar' : 'Finish')
              : (isSpanish ? 'Siguiente' : 'Next')
            }
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="GIRL"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
