import { FC, useCallback, useMemo, useState } from 'react';
import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { DraftParticipantUnitSession } from 'shared/lib/types/ParticipantUnitSession';
import { ReactComponent as ChevronDownIcon } from '../../images/feather/chevron-down.svg';
import {
  isUnitUnlocked,
  setLocalActivityResponse,
} from 'shared/lib/utils/unitUtils';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { SlideContext } from '../../contexts/slideContext';
import { useActivitySaveInterval } from '../../hooks/useActivitySaveInterval';
import { getUnitSlideComponents } from '../../slides';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { PlusMinusIcon } from '../PlusMinusIcon/PlusMinusIcon';
import { User } from 'shared/lib/types/User';
import { twMerge } from 'tailwind-merge';
import { getPlanActivities } from 'shared/lib/utils/unitUtils';

export const ParticipantPlan: FC<{
  productSession: DraftParticipantProductSession;
  user: User;
  isStaffViewer: boolean;
  className?: string;
}> = ({ productSession, className = '', user, isStaffViewer }) => {
  const unitSessions = useMemo(() => {
    return productSession.unitSessions
      .filter(({ unit }) => isUnitUnlocked(user, productSession, unit.id))
      .reverse(); // Show the most recent unit at the top
  }, [productSession, user]);
  const [expandedUnitIndex, setExpandedUnitIndex] = useState<number | null>(0);

  return (
    <ul className={`text-white ${className}`}>
      {unitSessions.map((unitSession, index) => (
        <PlanItem
          key={unitSession.unit.id}
          unitSession={unitSession}
          expanded={index === expandedUnitIndex}
          onToggleExpanded={() =>
            setExpandedUnitIndex(index === expandedUnitIndex ? null : index)
          }
          isStaffViewer={isStaffViewer}
        />
      ))}
    </ul>
  );
};

const PlanItem: FC<{
  unitSession: DraftParticipantUnitSession;
  expanded: boolean;
  isStaffViewer: boolean;
  onToggleExpanded(): void;
}> = ({ unitSession, expanded, onToggleExpanded, isStaffViewer }) => {
  const { unit, activityResponses } = unitSession;

  const planActivities = useMemo(() => {
    return getPlanActivities(unitSession);
  }, [unitSession]);

  const [expandedActivityIndex, setExpandedActivityIndex] = useState<
    number | null
  >(0);
  const { productSession, setProductSession } = useParticipantProductContext();
  const slideComponents = getUnitSlideComponents(
    productSession.product.key,
    unit.name,
  );
  const queueActivityResponse = useActivitySaveInterval({
    orgId: productSession.orgProduct.orgId,
    unitId: unit.id,
    productId: productSession.product.id,
  });
  const setActivityResponse = useCallback(
    ({ activityId, value }: { activityId: number; value: unknown }): void => {
      queueActivityResponse({ activityId, value });

      setProductSession((productSession) => ({
        ...productSession,
        unitSessions: productSession.unitSessions.map((innerUnitSession) =>
          innerUnitSession.unit.id === unit.id
            ? setLocalActivityResponse(innerUnitSession, {
                activityId,
                value,
                participantProductId: productSession.participantProduct.id,
              })
            : innerUnitSession,
        ),
      }));
    },
    [setProductSession, queueActivityResponse, unit.id],
  );

  return (
    <li>
      {/* Unit name */}
      <button
        onClick={onToggleExpanded}
        className="flex items-center justify-between w-full px-10 mt-4"
      >
        {unit.icon && (
          <img alt="" src={getPublicFilePath(unit.icon)} className="w-8 h-8" />
        )}
        <div className="flex-auto ml-3 text-left text-sm font-medium">
          {unit.name}
        </div>
        {!isStaffViewer && <PlusMinusIcon expanded={expanded} />}
        {isStaffViewer && (
          <ChevronDownIcon
            strokeWidth={3}
            className={twMerge(
              'text-blue-400 transition-transform',
              expanded && 'transform rotate-180',
            )}
          />
        )}
      </button>
      {/* Activity list */}
      {expanded && (
        <ul className="pt-5">
          {planActivities.map(({ section, slide, activity }, index) => {
            const activityExpanded = index === expandedActivityIndex;
            const SlideComponent = slideComponents[slide.name];
            return (
              <li key={activity.name}>
                {/* Activity name */}
                <button
                  onClick={() =>
                    setExpandedActivityIndex(activityExpanded ? null : index)
                  }
                  className="flex mx-20 mb-3 text-sm text-left font-medium"
                >
                  <PlusMinusIcon
                    expanded={activityExpanded}
                    className={isStaffViewer ? 'dark bg-blue-400' : ''}
                  />
                  {activity.name}
                </button>
                {/* Activity component */}
                {activityExpanded && (
                  <SlideContext.Provider
                    // It's possible that this can move outside the outer <ul> --
                    // <SlideContainer /> only uses `next` and `minimalSlideWrapper`
                    value={{
                      section,
                      unit,
                      slide,
                      setActivityResponse,
                      activityResponses: activityResponses.filter(
                        ({ activityId }) =>
                          slide.activities.some(({ id }) => id === activityId),
                      ),
                      back: onToggleExpanded,
                      next: onToggleExpanded,
                      minimalSlideWrapper: true,
                      myPlanActivity: true,
                      viewOnly: isStaffViewer,
                    }}
                  >
                    <SlideComponent />
                  </SlideContext.Provider>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};
