import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare12Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      className="text-white font-light"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_01'
          : 'early_childhood_spanish_Ani_01'
      }
      videoTitle={isEnglish ? 'Coping with stress' : 'Afrontar el estrés'}
      slideTitle={isEnglish ? 'Managing Stress' : 'Manejar El Estrés'}
      upperSlideContent={
        <p className="px-14">
          {isEnglish
            ? 'Watch this video to see how one mom used breathing to calm down.'
            : 'Mire este video para ver cómo una mamá usó la respiración para calmarse.'}
        </p>
      }
      lowerSlideContent={
        <p className="px-14 mt-5">
          {isEnglish ? (
            <>
              In the coming week, use one of the strategies you learned to help
              you cope when you’re feeling stressed!
            </>
          ) : (
            <>
              ¡En la próxima semana, use una de las estrategias que aprendió
              para ayudarle a afrontar cuando se sienta estresado/a!
            </>
          )}
        </p>
      }
      bearCharacter="YOUNG_GIRL_THUMBS_UP"
    />
  );
};
