import { FC, ReactNode } from 'react';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { useSlideContextOptional } from '../../contexts/slideContext';
import { ReactComponent as ChevronLeftIcon } from '../../images/feather/chevron-left.svg';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';

export const SlideHeader: FC<{
  title?: ReactNode;
  light?: boolean;
  className?: string;
  onBack?(): unknown;
  unit?: UnitWithSections;
  hideBackButton?: boolean;
  headerContent?: ReactNode;
}> = ({
  title,
  light,
  className = '',
  onBack,
  unit: propsUnit,
  hideBackButton,
  headerContent,
  ...rest
}) => {
  const slideContext = useSlideContextOptional();
  const section = slideContext?.section;
  const unit = propsUnit ?? slideContext?.unit;
  const slideIndex = slideContext?.slide?.index ?? 0;
  const sectionIndex = slideContext?.section?.index ?? 0;

  return (
    <div
      {...rest}
      className={`flex flex-col flex-shrink-0 px-4 pt-5 pb-8 ${className}`}
    >
      {/* Back button and hamburger menu */}
      <div
        className={`flex flex-row ${
          hideBackButton ? 'justify-end' : 'justify-between'
        }`}
      >
        {!hideBackButton && (
          <button
            onClick={onBack ?? slideContext?.back}
            aria-label="Back"
            className="w-8"
          >
            <ChevronLeftIcon
              className={`${light ? 'text-purple-600' : 'text-white'}`}
              strokeWidth={4}
            />
          </button>
        )}
        <ParticipantNavButton
          highlightedUnitId={unit?.id}
          highlightedSectionId={slideContext?.section.id}
        />
      </div>

      {/* Title or unit name */}
      <h1 className="w-full mx-auto text-center text-2xl text-blue-400">
        {title ?? unit?.name}
      </h1>

      {/* Segmented progress bar */}
      <div
        className="flex flex-row h-4 flex-shrink-0 mt-3 mx-8"
        role="progressbar"
        aria-valuetext={`Section ${sectionIndex + 1} of ${
          unit?.sections.length
        } slide ${slideIndex + 1} of ${(section?.slides ?? []).length}`}
      >
        {unit?.sections.map((otherSection, i) => {
          let sectionProgress: number;
          if (otherSection.index < sectionIndex) {
            sectionProgress = 1;
          } else if (otherSection.index > sectionIndex) {
            sectionProgress = 0;
          } else {
            sectionProgress =
              (slideIndex + 1) / ((section?.slides.length ?? 0) + 1);
          }

          return (
            /* Progress bar segment */
            <div
              key={otherSection.id}
              className={`w-0 flex-grow h-full rounded-full bg-white border-4 ${
                i > 0 ? 'ml-1' : ''
              } ${light ? 'border-blue-100' : 'border-blue-500 '}`}
            >
              {/* Progress segment fill */}
              <div
                className={`blue-gradient-bg rounded-full h-full ${
                  // Only animate the current section's progress bar
                  section && otherSection.id === section.id
                    ? 'transition-all'
                    : ''
                }`}
                style={{
                  width: `${sectionProgress * 100}%`,
                }}
              />
            </div>
          );
        })}
      </div>
      {headerContent}
    </div>
  );
};
