import { FC } from 'react';
import {
  SurveyQuestion,
  SurveyQuestionKind,
  SurveyQuestionValue,
} from 'shared/lib/types/SurveyQuestion';
import { MultipleChoiceQuestionInput } from './MultipleChoiceQuestionInput';
import { RangeQuestionInput } from './RangeQuestionInput';

interface SurveyQuestionAndResponseProps {
  question: SurveyQuestion<SurveyQuestionKind>;
  value: SurveyQuestionValue<SurveyQuestionKind> | null;
  onChange(value: SurveyQuestionValue<SurveyQuestionKind>): unknown;
  className?: string;
}

export const SurveyQuestionAndResponse: FC<SurveyQuestionAndResponseProps> = ({
  question,
  value,
  onChange,
  className = '',
  ...rest
}) => (
  <div className={`flex-1 flex flex-col justify-center ${className}`} {...rest}>
    {/* Question text */}
    <div className="text-center text-blue-800 mt-5">
      {!!question.preQuestionText && (
        /**
         * Surveys question text is never user-authored, so these `dangerouslySetInnerHTML` usages
         * should be safe for now. We may need to switch to something like react-markdown if we can't
         * trust raw HTML here.
         */
        <p
          className="mb-5"
          dangerouslySetInnerHTML={{ __html: question.preQuestionText }}
        />
      )}

      <p
        className="font-bold"
        dangerouslySetInnerHTML={{ __html: question.questionText }}
      />

      {!!question.postQuestionText && (
        <p dangerouslySetInnerHTML={{ __html: question.postQuestionText }} />
      )}
    </div>

    {/* Question input */}
    <div className="mt-5 flex flex-col flex-shrink-0">
      {question.kind === SurveyQuestionKind.MULTIPLE_CHOICE ? (
        <MultipleChoiceQuestionInput
          key={question.id}
          options={question.options as any}
          value={value as any}
          onChange={onChange}
        />
      ) : question.kind === SurveyQuestionKind.RANGE ? (
        <RangeQuestionInput
          key={question.id}
          options={question.options as any}
          value={value as any}
          onChange={onChange}
        />
      ) : null}
    </div>
  </div>
);
