import { FC } from 'react';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences15Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      className="flex-col text-white px-14 font-light"
      slideTitle={
        isEnglish ? 'When....then commands' : 'Cuando... entonces órdenes'
      }
    >
      <p>
        {isEnglish ? (
          <>
            Try to use rules that build in natural consequences to good
            behavior.
          </>
        ) : (
          <>
            Trate de usar reglas que incorporen consecuencias naturales al buen
            comportamiento.
          </>
        )}
      </p>

      <ExampleSection
        className="mt-6"
        title={
          <h3 className="bold text-lg uppercase ml-2.5 font-bold">
            {isEnglish ? 'Example:' : 'Ejemplo:'}
          </h3>
        }
      >
        <p className="mt-2 ml-9">
          {isEnglish ? (
            <>
              When you finish your dinner, then you can have a cookie. Here the
              “When…then” command helps you link the desired behavior with a
              natural (positive) consequence.
            </>
          ) : (
            <>
              Cuando termines tu cena, entonces puedes comer una galleta. Aquí,
              la oracion "Cuando... entonces" le ayuda a vincular el
              comportamiento deseado con una consecuencia natural (positiva).
            </>
          )}
        </p>
      </ExampleSection>
    </SlideContainer>
  );
};
