import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import cautionAlertIconSrc from '../../../images/CautionAlertIcon.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'I can’t believe you did that!',
  'How many times have I told you…?',
  'What did you think would happen if you acted that way?',
  'Why would you hang out with kids like that?',
];

const spanishList = [
  '¡No puedo creer que hiciste eso!',
  '¿Cuantas veces te he dicho…?',
  '¿Qué pensaste que iba a pasar cuando actuaste de esa manera?',
  'Por que te juntas con niños como ellos?',
];

export const Com_AskQuestions_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      className="flex-col items-center px-14 font-light text-white"
    >
      <img src={cautionAlertIconSrc} alt="caution" className="w-28" />
      <p className="text-red-600 mb-5">
        {isSpanish
          ? 'Cuidado: Algunas preguntas pueden cerrar la conversación.'
          : 'Caution: Some questions will shut down conversation.'}
      </p>
      <p className="self-start">
        {isSpanish
          ? 'Trate de evitar comentarios críticos o que ponen culpa como:'
          : 'Try to avoid judgemental or blaming comments like:'}
      </p>
      <YellowCircleList className="font-bold mt-5">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
