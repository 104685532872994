import { FC } from 'react';
import { NotebookPage } from '../../../components/NotebookPage/NotebookPage';
import { LabeledCheckbox } from '../../../components/LabeledCheckbox/LabeledCheckbox';
import { LabeledInput } from '../../../components/LabeledInput/LabeledInput';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type Assignment = {
  title: string;
  dueDate: string;
  understand: boolean;
  complete: boolean;
};

const defaultAssignments: { [key: number]: Assignment } = Object.fromEntries(
  [1, 2, 3, 4, 5].map((idx) => [
    idx,
    {
      title: '',
      dueDate: '',
      understand: false,
      complete: false,
    },
  ]),
);

export const SSS_Schoolwork_c: FC = () => {
  const { value: assignments, setValue: setAssignments } =
    useActivityState(defaultAssignments);
  const setAssignment = (key: string) => (assignment: Assignment) => {
    setAssignments({ ...assignments, [key]: assignment });
  };
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer className="justify-center my-4">
      <NotebookPage
        className="flex-col"
        titleText={
          isSpanish
            ? 'Organice La Hoja de Trabajo'
            : 'Organize Homework Worksheet'
        }
      >
        {Object.entries(assignments).map(([key, assignment]) => (
          <LabelCheckboxContainer
            key={key}
            assignment={assignment}
            onInputChange={setAssignment(key)}
          />
        ))}
      </NotebookPage>
    </SlideContainer>
  );
};

const LabelCheckboxContainer: FC<{
  assignment: Assignment;
  onInputChange: (value: Assignment) => void;
}> = ({ assignment, onInputChange }) => {
  const { isSpanish } = useProductLanguage();

  return (
    <section className="mt-1">
      <div className="flex items-start">
        <LabeledInput
          className="w-3/5"
          inputLabel={isSpanish ? 'Tarea' : 'Assignment'}
          onInputChange={(title) => onInputChange({ ...assignment, title })}
          inputValue={assignment.title}
        />
        <LabeledInput
          className="w-1/4 ml-2"
          inputLabel={isSpanish ? 'Fecha de entrega' : 'Date Due'}
          onInputChange={(dueDate) => onInputChange({ ...assignment, dueDate })}
          inputValue={assignment.dueDate}
        />
      </div>
      <div className="flex mt-2">
        <LabeledCheckbox
          darkBorder
          label={isSpanish ? '¿Entiendes?' : 'Understand?'}
          className="font-bold text-xs"
          checked={assignment.understand}
          onChange={(e) =>
            onInputChange({ ...assignment, understand: e.target.checked })
          }
        />
        <LabeledCheckbox
          darkBorder
          label={isSpanish ? '¿Completado?' : 'Complete?'}
          className="font-bold text-xs ml-4"
          checked={assignment.complete}
          onChange={(e) =>
            onInputChange({ ...assignment, complete: e.target.checked })
          }
        />
      </div>
    </section>
  );
};
