import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Clear_Rules_e: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      bearCharacter="GIRL_WINKING"
      videoKey={isSpanish ? 'ani_12_spanish' : 'ani_12'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Observe cómo estos padres trabajan juntos para decidir qué 
              reglas son importantes para ellos. `
            : `Watch these parents work together to decide what rules are 
              important to them.`}
        </p>
      }
    />
  );
};
