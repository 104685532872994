import { FC } from 'react';
import { ParentingAndSubstanceUse1Slide } from './parenting_and_substance_use_1';
import { ParentingAndSubstanceUse2Slide } from './parenting_and_substance_use_2';
import { ParentingAndSubstanceUse3Slide } from './parenting_and_substance_use_3';
import { ParentingAndSubstanceUse4Slide } from './parenting_and_substance_use_4';
import { ParentingAndSubstanceUse5Slide } from './parenting_and_substance_use_5';
import { ParentingAndSubstanceUse6Slide } from './parenting_and_substance_use_6';
import { ParentingAndSubstanceUse7Slide } from './parenting_and_substance_use_7';
import { ParentingAndSubstanceUse8Slide } from './parenting_and_substance_use_8';
import { ParentingAndSubstanceUse9Slide } from './parenting_and_substance_use_9';
import { ParentingAndSubstanceUse10Slide } from './parenting_and_substance_use_10';
import { ParentingAndSubstanceUse11Slide } from './parenting_and_substance_use_11';
import { ParentingAndSubstanceUse12Slide } from './parenting_and_substance_use_12';
import { ParentingAndSubstanceUse13Slide } from './parenting_and_substance_use_13';
import { ParentingAndSubstanceUse14Slide } from './parenting_and_substance_use_14';
import { ParentingAndSubstanceUse15Slide } from './parenting_and_substance_use_15';
import { ParentingAndSubstanceUse16Slide } from './parenting_and_substance_use_16';
import { ParentingAndSubstanceUse17Slide } from './parenting_and_substance_use_17';
import { ParentingAndSubstanceUse18Slide } from './parenting_and_substance_use_18';
import { ParentingAndSubstanceUse19Slide } from './parenting_and_substance_use_19';
import { ParentingAndSubstanceUse20Slide } from './parenting_and_substance_use_20';

export const parentingAndSubstanceUseSlides: Record<string, FC> = {
  // Coping without alcohol and drugs
  parenting_and_substance_use_1: ParentingAndSubstanceUse1Slide,
  parenting_and_substance_use_2: ParentingAndSubstanceUse2Slide,
  parenting_and_substance_use_3: ParentingAndSubstanceUse3Slide,
  parenting_and_substance_use_4: ParentingAndSubstanceUse4Slide,
  parenting_and_substance_use_5: ParentingAndSubstanceUse5Slide,
  parenting_and_substance_use_6: ParentingAndSubstanceUse6Slide,
  parenting_and_substance_use_7: ParentingAndSubstanceUse7Slide,
  parenting_and_substance_use_8: ParentingAndSubstanceUse8Slide,
  parenting_and_substance_use_9: ParentingAndSubstanceUse9Slide,
  parenting_and_substance_use_10: ParentingAndSubstanceUse10Slide,

  // Healthy relationships
  parenting_and_substance_use_11: ParentingAndSubstanceUse11Slide,

  // Communication
  parenting_and_substance_use_12: ParentingAndSubstanceUse12Slide,
  parenting_and_substance_use_13: ParentingAndSubstanceUse13Slide,
  parenting_and_substance_use_14: ParentingAndSubstanceUse14Slide,
  parenting_and_substance_use_15: ParentingAndSubstanceUse15Slide,

  // Parenting Responsibility
  parenting_and_substance_use_16: ParentingAndSubstanceUse16Slide,
  parenting_and_substance_use_17: ParentingAndSubstanceUse17Slide,
  parenting_and_substance_use_18: ParentingAndSubstanceUse18Slide,
  parenting_and_substance_use_19: ParentingAndSubstanceUse19Slide,
  parenting_and_substance_use_20: ParentingAndSubstanceUse20Slide,
};
