import { FC } from 'react';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { ReactComponent as PhoneIcon } from '../../images/feather/phone.svg';
import { ReactComponent as MailIcon } from '../../images/feather/mail.svg';
import { ReactComponent as CalendarIcon } from '../../images/calendar-icon.svg';
import { CoachBadge } from '../../components/CoachBadge/CoachBadge';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { formatPhoneNumberPretty } from 'shared/lib/utils/formatPhoneNumber';
import { formatDateLong } from 'shared/lib/utils/formatDate';
import { formatTime } from 'shared/lib/utils/formatTime';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { VisibleStaffField } from 'shared/lib/constants/staff/VisibleStaffField';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useParticipantCoachContext } from '../../contexts/participantCoachContext';

export const ParticipantCoachPage: FC = (props) => {
  const { isSpanish } = useProductLanguage();

  const { coach, visibleCoachFields, nextAppointmentDate } =
    useParticipantCoachContext();
  useDocumentTitle('Provider');

  return (
    <div {...props} className="min-h-screen pb-5 bg-blue-700">
      <ParticipantHeader hideCircle />
      <h1 className="text-blue-400 text-2xl text-center mb-4">
        {isSpanish ? 'Mi Proveedor' : 'My Provider'}
      </h1>

      <div className="flex flex-col px-3 md:mx-auto md:max-w-2xl flex-shrink-0 mt-16">
        {!!coach && (
          <div className="flex flex-col bg-white rounded-lg p-5">
            <div className="flex flex-row items-center">
              <CoachBadge size="medium" />{' '}
              <div className="text-xl ml-2">{formatFirstLast(coach)}</div>
            </div>
            <address className="not-italic mt-4">
              {/* Provider phone number */}
              {!!coach.phone &&
                visibleCoachFields.includes(VisibleStaffField.PHONE) && (
                  <div className="flex flex-row">
                    <div>
                      <PhoneIcon
                        className="text-blue-400 rotate-134 scale-75 fill-current transform"
                        strokeWidth={0}
                      />
                    </div>
                    <div className="ml-2">
                      <div className="font-semibold">Phone:</div>
                      <a
                        href={`tel:${coach.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {formatPhoneNumberPretty(coach.phone)}
                      </a>
                    </div>
                  </div>
                )}

              {/* Provider email address */}
              {visibleCoachFields.includes(VisibleStaffField.EMAIL) && (
                <div className={`flex flex-row ${coach.phone ? 'mt-4' : ''}`}>
                  <div>
                    <MailIcon
                      className="text-blue-400 fill-current scale-75 transform"
                      stroke="white"
                      strokeWidth={1}
                    />
                  </div>
                  <div className="ml-2">
                    <div className="font-semibold">Email:</div>
                    <a
                      href={`mailto:${coach.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {coach.email}
                    </a>
                  </div>
                </div>
              )}
            </address>
          </div>
        )}
        {!!nextAppointmentDate &&
          visibleCoachFields.includes(VisibleStaffField.APPOINTMENT_INFO) && (
            <div className="flex flex-col bg-white rounded-lg p-5 mt-2">
              <div className="flex flex-row items-center">
                <CalendarIcon className="text-blue-400 scale-75 transform" />{' '}
                <div className="text-xl ml-2">Next Appointment</div>
              </div>
              <time
                dateTime={nextAppointmentDate}
                className="font-semibold text-lg leading-5 pl-8 mt-4"
              >
                {formatDateLong(nextAppointmentDate)}
                <br />
                {formatTime(nextAppointmentDate)}
              </time>
            </div>
          )}
      </div>
    </div>
  );
};
