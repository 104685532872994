import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting11Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Family Routines' : 'Rutinas Familiares'}
      className="text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            When you have a daily routine, it’s easier to parent well. It helps
            you stay organized and on track, and it helps your child know what
            to expect.
          </>
        ) : (
          <>
            Cuando tiene una rutina diaria, es más fácil ser un buen padre. Le
            ayuda a mantenerse organizado y al tanto, y ayuda a su hijo a saber
            qué esperar.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
