import { FC, useState } from 'react';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SelectUnderscoreElement } from '../../../components/SelectUnderscoreElement/SelectUnderscoreElement';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const stepContent = [
  {
    step: {
      english: 'Describe the family problem neutrally, using a calm voice.',
      spanish:
        'Describa el problema familiar de manera neutral, con voz tranquila.',
    },
    advice: {
      english: 'Don’t try to solve hot issues right away.',
      spanish: 'No trate de resolver problemas candentes de inmediato.',
    },
  },
  {
    step: {
      english: 'Take turns coming up with potential solutions.',
      spanish: 'Tomen turnos en crear soluciones posibles.',
    },
    advice: {
      english: 'Don’t judge any ideas while you are brainstorming.',
      spanish: 'No juzgue ninguna idea mientras realizan una lista de ideas.',
    },
  },
  {
    step: {
      english:
        'Work with your child to list the pros and cons of each potential solution.',
      spanish:
        'Trabaje con su hijo/a para enumerar las razones al favor y en contra de cada solución potencial.',
    },
    advice: {
      english: 'Make sure to listen to your child’s opinions.',
      spanish: 'Asegúrese de escuchar las opiniones de su hijo/a.',
    },
  },
  {
    step: {
      english: 'Agree on a solution to try out.',
      spanish: 'Pónganse de acuerdo en una solución para intentar.',
    },
    advice: {
      english:
        'Remind your child that the solution can be changed if it doesn’t work.',
      spanish:
        'Recuérdele a su hijo/a que la solución puede cambiar si no funciona.',
    },
  },
  {
    step: {
      english:
        'Choose a date and time to check-in about how the solution is working.',
      spanish:
        'Elija una fecha y hora para evaluar cómo está funcionando la solución.',
    },
  },
];

export const Com_ProblemSolving_b: FC = () => {
  const { next } = useSlideContext();
  const [chosenOne, setChosenOne] = useState<number | undefined>();
  const {
    setValue: setCompleted,
    isReview,
    activity,
  } = useActivityState({ bool: false });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  // If chosen one is undefined, make sure content will come up undefined
  const content = stepContent[chosenOne ? chosenOne - 1 : -1];
  const { step, advice } = { ...content };

  const handleSubmit = () => {
    if (isReview) {
      next();
    } else {
      setCompleted({ bool: true });
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Resolución de Problemas Familiares'
          : 'Family Problem Solving'
      }
      className="flex-col px-14 font-light text-white"
      hideButton={chosenOne !== stepContent.length && !isReview}
      onButtonClick={handleSubmit}
    >
      <p>
        {isSpanish
          ? `Antes de comenzar, busque un lugar neutral y asegúrese que tengan 
            suficiente tiempo para hablar. Aquí están los pasos para resolver 
            problemas con su familia.`
          : `Before you start, find a neutral location, and be sure you have enough
            time to talk. Here are the steps to problem solving with your family.`}
      </p>
      <p className="text-red-600 text-center font-medium mt-7 mb-5 px-3">
        {isSpanish
          ? 'Pulse los círculos abajo para ver cada paso.'
          : 'Tap on the circles below to see each step.'}
      </p>
      <div className="flex justify-center mb-11">
        {stepContent.map((el, index) => (
          <SelectUnderscoreElement
            key={index}
            className="mx-2"
            selected={chosenOne === index + 1}
            element={index + 1}
            onSelect={setChosenOne}
          />
        ))}
      </div>
      {step && <b className="font-bold">{step[language]}</b>}
      {advice && (
        <div className="mt-3 pl-3">
          <YellowCircleList>
            <li>{advice[language]}</li>
          </YellowCircleList>
        </div>
      )}
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="GIRL"
      />
    </SlideContainer>
  );
};
