import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_ProblemSolving_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={
        isSpanish
          ? 'Resolución de Problemas Familiares'
          : 'Family Problem Solving'
      }
      className="text-white font-light"
      videoKey={isSpanish ? 'ani_11_spanish' : 'ani_11'}
      upperSlideContent={
        <div className="px-14 leading-5">
          <p>
            {isSpanish
              ? `La resolución de problemas con su hijo/a desarrolla habilidades críticas, 
              incluyendo la negociación, la comunicación y el poder llegar a un arreglo.`
              : `Problem solving with your children builds critical skills including
              negotiation, communication, and compromise.`}
          </p>
          <p className="mt-4">
            {isSpanish
              ? 'Observe cómo la Familia Oso trabajan juntos para resolver el problema.'
              : 'Watch how the Bear Family works together to solve a problem.'}
          </p>
        </div>
      }
      bearCharacter="BOY"
    />
  );
};
