import { FC, useEffect, useState } from 'react';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { ThumbsUpIcon } from '../ThumbsUpIcon/ThumbsUpIcon';
import { Language } from 'shared/lib/types/Language';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import { PostTestFeedback } from 'shared/lib/types/PostTestFeedback';
import { NO_IMPROVEMENTS_POST_TEST_FEEDBACK } from 'shared/lib/constants/post-tests/NoImprovementsPostTestFeedback';
import { Confetti } from '../Confetti/Confetti';
import { ButtonTheme, PillButton } from '../PillButton/PillButton';
import { PostTestFeedbackDrawer } from './PostTestFeedbackDrawer';
import { BEAR_IMG_MAP } from '../../constants/bears/bearImageMapping';
import { Blurb } from '../Blurb/Blurb';
import { getPostTestResultBearAndMessage } from '../../constants/post-test/postTestBears';

interface PostTestResultsProps {
  className?: string;
  language: Language;
  productKey: ProductKey;
  postTestFeedback: PostTestFeedback[];
  onContinueClicked(): void;
  onReturnLaterClicked(): void;
}

export const PostTestResults: FC<PostTestResultsProps> = ({
  className = '',
  language,
  productKey,
  postTestFeedback,
  onContinueClicked,
  onReturnLaterClicked,
  ...rest
}) => {
  const [showConfetti, setShowConfetti] = useState(true);
  const [selectedFeedback, setSelectedFeedback] =
    useState<PostTestFeedback | null>(null);

  useEffect(() => {
    // One way switch, if some feedback is selected, turn off confetti.
    if (selectedFeedback) {
      setShowConfetti(false);
    }
  }, [selectedFeedback]);
  const { bear, message } = getPostTestResultBearAndMessage(
    productKey,
    language,
  );
  const isSpanish = language === 'spanish';

  let feedbackHeading = isSpanish
    ? 'Usted ha mejorado en estas áreas'
    : 'You’ve improved in these areas';
  // If there is no feedback, just show a general message
  if (postTestFeedback.length === 0) {
    feedbackHeading = NO_IMPROVEMENTS_POST_TEST_FEEDBACK.feedback[language];
  }

  let messageClassName = 'bottom-16 -left-6';
  let bearContainerTranslations = 'translate-y-4 md:translate-y-12';
  if (productKey === ProductKey.SCHOOL_AGE) {
    messageClassName = 'bottom-12 left-0';
    bearContainerTranslations = 'translate-y-0 md:translate-y-2';
  }

  return (
    <div className={`flex flex-col bg-white ${className}`} {...rest}>
      {/* Head */}
      {showConfetti && <Confetti />}
      <div className="flex flex-row justify-end px-4 pt-5">
        <ParticipantNavButton />
      </div>
      <div className="flex flex-col w-full items-center">
        <ThumbsUpIcon
          backgroundColor="red"
          className="w-18 h-18"
          thumbsUpClassName="w-12 h-12"
        />
        <h1 className="text-blue-400 text-2xl mt-3 mb-5">
          {isSpanish ? '!Encuesta Completada!' : 'Survey Complete!'}
        </h1>
        <div className="flex flex-col items-center w-full bg-blue-400 text-white text-lg font-bold p-6">
          {isSpanish ? 'Resultados de la encuesta' : 'Survey Results'}
        </div>
      </div>
      <div className="flex flex-col w-full h-full flex-grow flex-shrink-0 justify-between items-center">
        {/* Main Content */}
        <div className="flex flex-col items-center w-full">
          <h3 className="text-center text-blue-800 text-sm pt-7 pr-12 pl-12">
            {isSpanish
              ? '¡Felicidades y gracias por haber completado esta encuesta de Family Check-Up!'
              : 'Congratulations and thank you for completing this Family Check-Up survey!'}
          </h3>
          <h4 className="text-center text-blue-800 font-bold text-sm pt-3">
            {feedbackHeading}
          </h4>
          <ul className="pt-4 space-y-2 z-40 mb-8">
            {postTestFeedback.map((feedback) => {
              const { surveyName } = feedback;
              const selected = selectedFeedback === feedback;
              return (
                <li key={surveyName}>
                  <button
                    className={`flex flex-row justify-center items-center border-5 rounded-lg min-h-12 py-1 w-60 border-survey-result-good ${
                      selected ? 'bg-lightGrey' : 'bg-white'
                    } transition-colors`}
                    onClick={() =>
                      setSelectedFeedback((current) =>
                        current === feedback ? null : feedback,
                      )
                    }
                  >
                    <div className="select-none">{surveyName}</div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Bear and feedback */}
        <div className="relative flex flex-col w-full justify-center items-end h-60">
          <div
            className={`flex flex-row justify-center mt-8 relative w-full transform ${bearContainerTranslations}`}
          >
            <img
              src={BEAR_IMG_MAP[bear]}
              alt="Bear Character"
              className="w-48 md:w-60 z-20"
            />
            <Blurb
              className="p-8 z-0"
              rootClassName={`${messageClassName} z-10`}
            >
              {message}
            </Blurb>
          </div>
          <PostTestFeedbackDrawer
            postTestFeedback={selectedFeedback}
            show={selectedFeedback !== null}
            onClose={() => setSelectedFeedback(null)}
          />
        </div>
        {/* Buttons*/}
        <div className="relative flex flex-col items-center w-full bg-blue-700 pt-6 pb-6">
          <PillButton className="w-64 pt-5 pb-5" onClick={onContinueClicked}>
            {isSpanish ? (
              <>
                Continuar al
                <br /> FamilyCheck-up
              </>
            ) : (
              <>
                Continue to
                <br />
                Family Check-Up
              </>
            )}
          </PillButton>
          <PillButton
            className="w-64 mt-4"
            onClick={onReturnLaterClicked}
            theme={ButtonTheme.LIGHT_BLUE}
          >
            {isSpanish ? 'Regrese más tarde' : 'Return later'}
          </PillButton>
        </div>
      </div>
    </div>
  );
};
