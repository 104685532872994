import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting10Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_07'
          : 'early_childhood_spanish_Ani_07'
      }
      videoTitle={
        <h1 className="font-bold text-lg text-blue-800 px-5">
          {isEnglish ? 'Bedtime routine' : 'Rutinas a la hora de acostarse'}
        </h1>
      }
      bearCharacter="YOUNG_GIRL"
    />
  );
};
