import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { StatementCheckbox } from '../../../components/StatementCheckbox/StatementCheckbox';
import plantPhase1Src from '../../../images/plant_phase_1.png';
import plantPhase2Src from '../../../images/plant_phase_2.png';
import plantPhase3Src from '../../../images/plant_phase_3.png';
import plantPhase4Src from '../../../images/plant_phase_4.png';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishPraises = [
  'Thank you for brushing your teeth',
  'Great job picking up the toys, give me a high five!',
  'I am so proud of you for getting into bed when i asked, you get a big hug!',
];

const spanishPraises = [
  'Gracias por cepillarte los dientes.',
  'Buen trabajo levantando tus juguetes, ¡chócala!',
  'Estoy muy orgulloso/a de ti por haberte ido a la cama cuando te lo pedí, ¡aquí te va un abrazo grande!',
];

const plantImages = [
  plantPhase1Src,
  plantPhase2Src,
  plantPhase3Src,
  plantPhase4Src,
];

export const PositiveParenting7Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview } = useActivityState<
    Record<string, boolean>
  >({});

  const handlePraiseClick = (praise: string) => {
    setValue({ ...value, [praise]: !value[praise] });
  };
  const truePraises = Object.values(value).filter(Boolean);

  const currentPlantImage = plantImages[truePraises.length];
  const endOfContent = truePraises.length === plantImages.length - 1;

  const handleNext = () => (isReview ? next() : setShowOverlay(true));

  const praises = isEnglish ? englishPraises : spanishPraises;

  return (
    <SlideContainer className="flex-col text-blue-800" hideButton>
      <ActivityBox
        style={{ height: '97vh' }}
        className="items-center pt-10 px-10"
        instructions={
          isEnglish
            ? "Your turn: Tap each praise idea and watch your child's cooperation grow."
            : 'Su turno: Cheque cada idea de elogio y vea el crecimiento!'
        }
        icon="pointer"
      >
        {praises.map((praise) => {
          const checked = value[praise] ?? false;

          return (
            <StatementCheckbox
              className={`mb-1.5 w-full ${
                checked ? 'italic font-light text-gray-400' : ''
              }`}
              key={praise}
              onStatementClick={() => handlePraiseClick(praise)}
              statement={praise}
              checked={checked}
            />
          );
        })}
        <div className="flex flex-col flex-grow flex-shrink-0 justify-between">
          <div className="flex flex-col">
            <span className="mt-1 font-semibold">
              {isEnglish
                ? 'What you focus on, grows!'
                : '¡En lo que se enfoca, crece!'}
            </span>
            <span className="text-red-600 mb-2 font-semibold">
              {isEnglish
                ? '(so focus on the positive)'
                : '(enfóquese en lo positivo)'}
            </span>
            <div className="h-56 relative">
              <img
                className="w-52 absolute bottom-0 mx-auto left-0 right-0"
                src={currentPlantImage}
                alt="growing potted plant"
              />
            </div>
          </div>
        </div>
      </ActivityBox>
      {endOfContent && (
        <div className="bg-yellow-secondary text-center">
          <PillButton
            onClick={handleNext}
            theme={ButtonTheme.YELLOW}
            className="my-10"
          >
            {isEnglish ? 'Finish Activity' : 'Termine la Actividad'}
          </PillButton>
        </div>
      )}
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
