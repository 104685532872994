import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { ReactComponent as XIcon } from '../../images/feather/x.svg';

export const LabeledCheckbox: FC<
  { label: ReactNode; darkBorder?: boolean } & Pick<
    ComponentPropsWithoutRef<'input'>,
    | 'checked'
    | 'onChange'
    | 'disabled'
    | 'readOnly'
    | 'className'
    | 'name'
    | 'required'
  >
> = ({
  className = '',
  label,
  name,
  checked,
  disabled,
  readOnly,
  required,
  onChange,
  darkBorder = false,
  ...rest
}) => {
  const canChange = !disabled && !readOnly;
  const iconSize = darkBorder ? '10' : '20';
  return (
    <label
      className={`flex flex-row items-center ${
        canChange ? 'cursor-pointer' : 'cursor-default'
      } ${className}`}
    >
      <input
        {...rest}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        className={
          'sr-only' /* Hide the real checkbox except for screen readers */
        }
      />
      <div
        className={`not-sr-only flex-shrink-0 ${
          canChange ? 'bg-white' : 'bg-gray-300'
        } ${
          darkBorder
            ? 'rounded-none border border-blue-800 w-3 h-3 w-'
            : 'rounded w-5 h-5'
        }`}
      >
        {checked && (
          <XIcon
            className="text-black"
            width={iconSize}
            height={iconSize}
            strokeWidth={4}
          />
        )}
      </div>
      <div
        className={`${darkBorder ? 'ml-1 text-blue-800' : 'ml-4'} ${
          disabled ? 'opacity-50' : ''
        }`}
      >
        {label}
      </div>
    </label>
  );
};
