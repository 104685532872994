import { FC } from 'react';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { ShoeList, ShoeListItem } from '../../../components/ShoeList/ShoeList';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting10Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      className="text-white px-14 font-light"
      buttonText={isEnglish ? 'Start Practice' : 'Empezar a Practicar'}
      buttonTheme={ButtonTheme.YELLOW}
    >
      <ExampleSection
        icon="star"
        title={
          <h2 className="font-semibold uppercase ml-2.5 text-lg">
            {isEnglish ? 'Example:' : 'Ejemplo'}
          </h2>
        }
      >
        <div className="flex flex-col pl-9 mt-2">
          <span className="font-semibold text-red-600 mb-2">
            {isEnglish ? 'Situation' : 'Situación'}
          </span>
          {isEnglish
            ? 'Your toddler has been playing with his brother and is tired and hungry.'
            : 'Su niño pequeño ha estado jugando con su hermano y está cansado y hambriente.'}
          <ShoeList className="mt-5">
            <ShoeListItem className="flex flex-col mb-8">
              <h3 className="uppercase font-semibold">
                {isEnglish ? 'Be Sensitive' : 'Sea sensible'}
              </h3>
              {isEnglish
                ? 'You walk over to him and ask him why he is upset'
                : 'Ud. camina hacia él y le pregunta qué es lo que lo ha hecho enojar.'}
            </ShoeListItem>
            <ShoeListItem className="flex flex-col mb-8">
              <h3 className="uppercase font-semibold">
                {isEnglish ? 'Be Responsive' : 'Sea receptivo/a'}
              </h3>
              {isEnglish
                ? ' You pick him up, calm him down, and offer him a snack.'
                : 'Ud. lo recoge, lo calma, y le ofrece algo de comer o una botana.'}
            </ShoeListItem>
          </ShoeList>
        </div>
        {isEnglish
          ? "Now it's your turn to practice. Press Start Practice to begin."
          : 'Ahora es hora de practicar. Presione para comenzar.'}
      </ExampleSection>
    </SlideContainer>
  );
};
