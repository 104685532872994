import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences2Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Home Safety' : 'Seguridad del hogar'}
      className="flex-col text-white px-14 font-light"
    >
      <h3 className="font-medium mb-2.5">
        {isEnglish
          ? 'Toddlers and Preschoolers'
          : 'Niños/as pequeños/as y preescolares'}
      </h3>
      {isEnglish ? (
        <>
          Young children have the highest risk of being injured at home because
          that's where they spend most of their time. Many parents child-proof
          their house for their infants, but do not remember to adapt as their
          children grow
        </>
      ) : (
        <>
          Los niños/as pequeños/as corren el mayor riesgo de lesionarse en casa
          porque es donde pasan la mayor parte de su tiempo. Muchos padres
          preparan su casa a prueba de niños para sus bebés, pero no recuerdan
          adaptarse a medida que crecen sus hijos.
        </>
      )}
    </SlideContainer>
  );
};
