import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import { Language } from 'shared/lib/types/Language';

type BearImage = 'GIRL' | 'BOY' | 'BABY_BEAR' | 'YOUNG_GIRL';

const PRE_POST_TEST_MESSAGES_ENGLISH: Record<BearImage, string> = {
  GIRL: 'Hello Again!',
  BOY: 'Hello Again!',
  BABY_BEAR: 'Hello Again!',
  YOUNG_GIRL: 'Hello Again!',
};

const PRE_POST_TEST_MESSAGES_SPANISH: Record<BearImage, string> = {
  GIRL: '!Hola de nuevo!',
  BOY: '!Hola de nuevo!',
  BABY_BEAR: '!Hola de nuevo!',
  YOUNG_GIRL: '!Hola de nuevo!',
};

const POST_TEST_RESULT_MESSAGES_ENGLISH: Record<BearImage, string> = {
  GIRL: 'Nice job!',
  BOY: 'Nice job!',
  BABY_BEAR: 'Nice job!',
  YOUNG_GIRL: 'Nice job!',
};

const POST_TEST_RESULT_MESSAGES_SPANISH: Record<BearImage, string> = {
  GIRL: '¡Buen trabajo!',
  BOY: '¡Buen trabajo!',
  BABY_BEAR: '¡Buen trabajo!',
  YOUNG_GIRL: '¡Buen trabajo!',
};

function getBearImageForProductAndLanguage(
  productKey: ProductKey,
  language: Language,
) {
  switch (productKey) {
    case ProductKey.EARLY_CHILDHOOD:
      return language === 'english' ? 'YOUNG_GIRL' : 'BABY_BEAR';
    default:
      return language === 'english' ? 'GIRL' : 'BOY';
  }
}

interface BearAndMessage {
  bear: BearImage;
  message: string;
}

export function getWelcomeBackBearAndMessage(
  productKey: ProductKey,
  language: Language,
): BearAndMessage {
  const bear = getBearImageForProductAndLanguage(productKey, language);
  return {
    bear,
    message:
      language === 'english'
        ? PRE_POST_TEST_MESSAGES_ENGLISH[bear]
        : PRE_POST_TEST_MESSAGES_SPANISH[bear],
  };
}

export function getPostTestResultBearAndMessage(
  productKey: ProductKey,
  language: Language,
): BearAndMessage {
  const bear = getBearImageForProductAndLanguage(productKey, language);
  return {
    bear,
    message:
      language === 'english'
        ? POST_TEST_RESULT_MESSAGES_ENGLISH[bear]
        : POST_TEST_RESULT_MESSAGES_SPANISH[bear],
  };
}
