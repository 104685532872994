import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { Language } from 'shared/lib/types/Language';

const correctMessages = {
  english: `That’s right! The dad used all of these great skills to 
            help his daughter cope with feeling left out.`,
  spanish: `¡Así es! El padre usó todas estas grandes habilidades
            para ayudar a su hija a sobrellevar sentirse excluida.`,
};

const incorrectMessages = {
  english: `Actually, the dad used all of these great skills to help 
            his daughter cope with feeling left out.`,
  spanish: `En realidad, el padre usó todas estas excelentes habilidades 
            para ayudar a su hija a sobrellevar sentirse excluida.`,
};

const getCorrectIncorrectMessages = (lang: Language, correct: boolean) =>
  correct ? correctMessages[lang] : incorrectMessages[lang];

export const HBST_Connect_c: FC = () => {
  const { isSpanish, language } = useProductLanguage();
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity, hasInteracted } =
    useActivityState({
      question1: false,
      question2: false,
      question3: false,
    });
  const [showPointOverlay, setShowPointOverlay] = useState(false);
  const allCorrect = value.question1 && value.question2 && value.question3;

  const handleSubmit = () => {
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowPointOverlay(true);
    }
  };

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? `Su turno: ¿Qué habilidades notó que usaba este padre 
              cuando hablaba con su hija sobre las redes sociales?`
            : `Your turn: What skills did you notice this dad using 
              when he talked to his child about social media?`
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center">
          <FloatingCard className="w-full pt-3 px-4">
            <SlideCheckbox
              className="mb-3"
              checked={value.question1}
              onChange={() =>
                setValue({ ...value, question1: !value.question1 })
              }
              label={isSpanish ? 'La escuchó.' : 'Listens to her.'}
            />
            <SlideCheckbox
              className="mb-3"
              checked={value.question2}
              onChange={() =>
                setValue({ ...value, question2: !value.question2 })
              }
              label={
                isSpanish
                  ? 'Se relacionó con su experiencia.'
                  : 'Relates to her experience.'
              }
            />
            <SlideCheckbox
              className="mb-3"
              checked={value.question3}
              onChange={() =>
                setValue({ ...value, question3: !value.question3 })
              }
              label={
                isSpanish
                  ? 'Enseñó con comprensión.'
                  : 'Teaches with understanding.'
              }
            />
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 self-center my-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Entregue' : 'Submit'}
          </PillButton>
          <QuizCompletionOverlay
            open={showPointOverlay}
            icon={allCorrect ? 'thumbs-up' : 'alert'}
            onNext={next}
            activityPoints={activity.pointValue}
            badgeLocation="right"
            activityMessage={getCorrectIncorrectMessages(language, allCorrect)}
            bearCharacter="BOY"
          />
        </div>
      </ActivityBox>
    </SlideContainer>
  );
};
