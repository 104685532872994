import { FC, useState, useEffect } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import plantSlide1 from '../../../images/slides/middleschool/healthyBehavior/plantSlide1@2x.png';
import plantSlide2 from '../../../images/slides/middleschool/healthyBehavior/plantSlide2@2x.png';
import plantSlide3 from '../../../images/slides/middleschool/healthyBehavior/plantSlide3@2x.png';
import plantSlide4 from '../../../images/slides/middleschool/healthyBehavior/plantSlide4@2x.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const plantSlides = [plantSlide1, plantSlide2, plantSlide3, plantSlide4];

export const HBST_Stress_c: FC = () => {
  const { next } = useSlideContext();
  const [curSlide, setCurSlide] = useState(0);
  const { isSpanish } = useProductLanguage();

  useEffect(() => {
    if (curSlide + 1 < plantSlides.length) {
      const timeout = setTimeout(() => setCurSlide(curSlide + 1), 500);
      return () => clearTimeout(timeout);
    }
  }, [curSlide]);

  const lastSlide = curSlide + 1 === plantSlides.length;

  return (
    <SlideContainer
      className="bg-yellow-secondary flex-col justify-between items-center"
      hideButton
      lightBg
      lightProgressBar
      buttonText="Next"
    >
      <div className="flex flex-col flex-1 justify-between items-center">
        <h2 className="font-black text-blue-800 text-2xl leading-7 text-center mt-20 px-14">
          {isSpanish
            ? `Cuando usted nutre las necesidades sociales y emocionales de su
              hijo/a, aumentará su capacidad para afrontar las situaciones.`
            : `When you nurture your child's social and emotional needs, their
              ability to cope will grow.`}
        </h2>
        <img
          src={plantSlides[curSlide]}
          alt="house plant"
          className="w-56 flex-shrink-0"
        />
      </div>
      <PillButton
        theme={ButtonTheme.YELLOW}
        className={`w-52 my-12 ${lastSlide ? '' : 'invisible'}`}
        disabled={!lastSlide}
        onClick={next}
      >
        {isSpanish ? 'Siguiente' : 'Next'}
      </PillButton>
    </SlideContainer>
  );
};
