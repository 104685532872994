import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Use a calm and firm tone of voice.',
  'Label the problem behavior in terms of your rule.',
  'State the consequences clearly.',
  'Ignore your child’s negative reaction.',
  'Avoid arguing.',
];

const spanishList = [
  'Use un tono de voz tranquilo y firme',
  'Etiquete el comportamiento problemático en términos de su regla',
  'Declare las consecuencias claramente',
  'Ignore la reacción negativa de su hijo/a',
  'Evite pelear',
];

export const RC_Logical_e: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer className="flex-col px-14 font-light text-white">
      {isSpanish
        ? 'Cuando le da una consequencia a su hijo/a:'
        : 'When you give your child a consequence:'}

      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
