import babyBear from '../../images/bear_baby_neutral.png';
import babyBearWinking from '../../images/baby_bear_winking.png';
import babyBearThumbsUp from '../../images/bear_baby_thumbsUp.png';
import babyBearThumbsUpWinking from '../../images/bear_baby_thumbsUpWinking.png';
import bearBoy from '../../images/bear_boy.png';
import bearBoyWinking from '../../images/bear_boy_winking.png';
import youngGirlBear from '../../images/bear_girl_neutral.png';
import youngGirlBearThumbsUp from '../../images/bear_girl_thumbsUp.png';
import youngGirlBearThumbsUpWinking from '../../images/bear_girl_thumbsUpWinking.png';
import bearGirl from '../../images/bear_girl.png';
import bearGirlWinking from '../../images/bear_girl_winking.png';
import youngGirlBearWinking from '../../images/young_bear_girl_winking.png';

export const BEAR_IMG_MAP: Record<string, string> = {
  BABY_BEAR: babyBear,
  BABY_BEAR_WINKING: babyBearWinking,
  BABY_BEAR_THUMBS_UP: babyBearThumbsUp,
  BABY_BEAR_THUMBS_UP_WINKING: babyBearThumbsUpWinking,
  BOY: bearBoy,
  BOY_WINKING: bearBoyWinking,
  YOUNG_GIRL: youngGirlBear,
  YOUNG_GIRL_THUMBS_UP: youngGirlBearThumbsUp,
  YOUNG_GIRL_THUMBS_UP_WINKING: youngGirlBearThumbsUpWinking,
  YOUNG_GIRL_WINKING: youngGirlBearWinking,
  GIRL: bearGirl,
  GIRL_WINKING: bearGirlWinking,
};
