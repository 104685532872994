import { FC } from 'react';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { ParticipantPlan } from '../../components/ParticipantPlan/ParticipantPlan';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useUser } from '../../contexts/userContext';

export const ParticipantPlanPage: FC = () => {
  const { productSession } = useParticipantProductContext();
  const { isSpanish } = useProductLanguage();
  const { user } = useUser();

  return (
    <div className="box-border min-h-screen">
      <div className="flex flex-col md:mx-auto md:max-w-2xl min-h-screen flex-shrink-0 bg-blue-700">
        <ParticipantHeader hideCircle />
        <h2 className="mb-4 text-center text-2xl font-bold text-blue-400">
          {`${isSpanish ? 'Mi' : 'My'} Plan`}
        </h2>
        <ParticipantPlan
          productSession={productSession}
          className="m-6"
          user={user}
          isStaffViewer={false}
        />
      </div>
    </div>
  );
};
