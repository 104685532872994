import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import { OverheadBlurb } from '../../../components/OverheadBlurb/OverheadBlurb';
import bearWoman from '../../../images/bear_woman.png';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_f: FC = () => {
  const { next } = useSlideContext();
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      hideButton
      className="flex-col justify-between items-center font-light text-white"
    >
      <p className="px-14 mb-10 self-start">
        {isSpanish
          ? `Navegar por las redes sociales es complejo tanto para padres 
            como para los niños.`
          : `Navigating social media is complex for both parents and kids.`}
      </p>
      <div className="flex flex-col flex-1 justify-end items-center w-full">
        <OverheadBlurb>
          {isSpanish
            ? `¿Cómo usa su hijo/a las redes sociales para conectarse 
              con sus amigos/as?`
            : `How does your child use social media to connect 
              with their peers?`}
        </OverheadBlurb>
        <img
          src={bearWoman}
          className="w-52 mt-5 -mr-16 "
          alt={isSpanish ? 'mujer oso preocupada' : 'concerned bear woman'}
        />
      </div>
      <BottomToast
        onButtonClick={next}
        buttonLabel={isSpanish ? 'Siguiente' : 'Next'}
        animate={false}
        fixed={false}
      />
    </SlideContainer>
  );
};
