export const englishStatements = {
  1: {
    text: 'You never help around the house!',
    recommendedResponse: 'I would really like more help with household chores.',
  },
  2: {
    text: 'You complain too much!',
    recommendedResponse:
      'I want to hear about the good parts of your day, too!',
  },
  3: {
    text: 'You and the kids are driving me crazy!',
    recommendedResponse: 'I need a few minutes to relax.',
  },
  4: {
    text: 'You always criticize my parenting!',
    recommendedResponse:
      'It hurts my feelings when you comment on my parenting. Sometimes an I statement can be a focus on your feelings, rather than using an actual “I.”',
  },
  5: {
    text: 'You always talk about yourself.',
    recommendedResponse: 'I want you to ask me how I’m doing.',
  },
};

export const spanishStatements = {
  1: {
    text: '¡Nunca ayudas en la casa!',
    recommendedResponse:
      'Realmente me gustaría más ayuda con las tareas del hogar.',
  },
  2: {
    text: '¡Te quejas demasiado!',
    recommendedResponse:
      '¡También quiero escuchar sobre las partes buenas de tu día!',
  },
  3: {
    text: '¡Tú y los niños me están volviendo loco/a!',
    recommendedResponse: 'Necesito unos minutos para relajarme.',
  },
  4: {
    text: '¡Siempre criticas mi crianza!',
    recommendedResponse:
      'Me hiere los sentimientos cuando comentas sobre mi crianza. A veces, una declaración de "yo" puede centrarse en sus sentimientos, en lugar de usar un "Yo" real.',
  },
  5: {
    text: 'Siempre hablas de ti mismo.',
    recommendedResponse: 'Quiero que me preguntes cómo estoy.',
  },
};
