import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishContent = [
  {
    question:
      'Call or text if you get a ride home from school with someone else.',
    feedback: 'This is a clear rule',
    helpfulCorrect: true,
  },
  {
    question: 'Don’t play video games while you do your homework.',
    feedback:
      '“Please turn off video games when you are working” is a clear rule because it tells your child what you want them to do.',
    helpfulCorrect: true,
  },
  {
    question: 'Use a respectful tone when talking to adults.',
    feedback: 'This is a clear rule.',
    helpfulCorrect: true,
  },
  {
    question: 'Lights need to be out by 9:30 PM.',
    feedback: 'This is a clear rule',
    helpfulCorrect: true,
  },
  {
    question: 'No slamming doors.',
    feedback:
      'Close doors quietly is a clear rule because it tells your child what you want them to do.',
    helpfulCorrect: false,
  },
  {
    question: 'Do you want to do your homework before watching TV?',
    feedback:
      'This is a question, not a clear expression of the rule that homework needs to be done before starting screentime.',
    helpfulCorrect: false,
  },
];

const spanishContent = [
  {
    question:
      'Llama o envía un mensaje de texto si otra persona te trae a casa de la escuela.',
    feedback: 'Esta es una regla clara.',
    helpfulCorrect: true,
  },
  {
    question: 'No juegues videojuegos mientras haces tu tarea.',
    feedback:
      '“Apaga los videojuegos cuando esté trabajando" es una regla clara porque le dice a su hijo/a lo que usted quiere que haga.”',
    helpfulCorrect: true,
  },
  {
    question: 'Usa un tono respetuoso al hablar con los adultos.',
    feedback: 'Esta es una regla clara.',
    helpfulCorrect: true,
  },
  {
    question: 'Las luces deben estar apagadas a las 9:30pm.',
    feedback: 'Esta es una regla clara.',
    helpfulCorrect: true,
  },
  {
    question: 'No golpees las puertas.',
    feedback:
      'Cierra las puertas calladamente es una regla clara porque le dice a su hijo/a lo que usted quiere que haga.',
    helpfulCorrect: true,
  },
  {
    question: '¿Quieres hacer tu tarea antes de ver la televisión?',
    feedback:
      'Esta es una pregunta, no una expresión clara de la regla de que la tarea debe hacerse antes de ver la televisión.',
    helpfulCorrect: false,
  },
];

export const RC_Clear_Rules_g: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<
    { [key in `question${1 | 2 | 3 | 4 | 5 | 6}`]: boolean | null }
  >({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
    question6: null,
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const minReqAnswered =
    Object.values(value).filter((answer) => answer !== null).length >= 3;

  const handleSubmit = () => (isReview ? next() : setShowOverlay(true));

  const content = isSpanish ? spanishContent : englishContent;

  return (
    <SlideContainer
      buttonText=""
      lightBg
      lightProgressBar
      hideButton
      className="text-white"
    >
      <ActivityBox
        icon="pointer"
        className="flex flex-col items-center flex-1 mt-8 pt-8 pb-6"
        instructions={
          <h2 className="text-lg font-bold">
            {isSpanish
              ? 'Su Turno: ¿Reglas claras o poco claras?'
              : 'Your turn: Clear or unclear rules?'}
          </h2>
        }
      >
        <HelpfulUnhelpfulQuiz
          className="mb-10"
          iconPair="clear/unclear"
          title={
            isSpanish
              ? '¿Cuáles de las siguientes son reglas claras que se prepararán para el éxito?'
              : 'Which of the following are clear rules that will set you up for success?'
          }
          helpfulWord={isSpanish ? 'Claras' : 'Clear'}
          unhelpfulWord={isSpanish ? 'Poco claras' : 'Unclear'}
        >
          {(Object.keys(value) as (keyof typeof value)[]).map((key, idx) => {
            const { question, feedback, helpfulCorrect } = content[idx];
            return (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                questionText={question}
                isHelpfulCorrect={helpfulCorrect}
                onChange={(bool) => setValue({ ...value, [key]: bool })}
                value={value[key]}
                feedback={feedback}
                helpfulWord={isSpanish ? 'Claras' : 'Clear'}
                unhelpfulWord={isSpanish ? 'Poco claras' : 'Unclear'}
              />
            );
          })}
        </HelpfulUnhelpfulQuiz>
        {minReqAnswered && (
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-56 mb-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine la Actividad' : 'Finish Activity'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="GIRL"
      />
    </SlideContainer>
  );
};
