import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting24Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Bribes & how they differ from rewards and incentives'
          : 'Los sobornos y como son diferentes de las recompensas e incentivos'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            There is a third strategy parents use to make their children behave
            in a desired way, bribery.
          </>
        ) : (
          <>
            Hay una tercera estrategia que usan los padres para hacer que sus
            niños se comporten de la manera deseada, el soborno.
          </>
        )}
      </p>
      <p>
        {isEnglish ? (
          <>
            A bribe occurs before the desired behavior, and a reward occurs
            after the behavior. Bribes are less effective than rewards and
            incentives because they do not teach children what they should do
            instead.
          </>
        ) : (
          <>
            Un soborno ocurre antes del comportamiento deseado y una recompensa
            ocurre después del comportamiento. Los sobornos son menos efectivos
            que las recompensas y los incentivos porque no les enseñan a los
            niños lo que deben hacer.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
