import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import wideAwakeSrc from '../../../images/MessyBedroom_wide_awake.png';
import noElectronicsSrc from '../../../images/MessyBedroom_no_electronics.png';
import shadesDownSrc from '../../../images/MessyBedroom_shades_down.png';
import noCaffeineSrc from '../../../images/MessyBedroom_no_caffeine.png';
import bedTimeSrc from '../../../images/MessyBedroom_bed_time.png';
import { InfoIcon } from '../../../components/InfoIcon/InfoIcon';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { ToolTipTriPosition } from 'shared/lib/types/ToolTipTriPosition';

export const SSS_Sleep_b: FC = () => {
  const { next } = useSlideContext();
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [visibleTooltip, setVisibleTooltip] = useState<null | number>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [bedTime, setBedTime] = useState(false);
  const {
    setValue: setCompleted,
    isReview,
    activity,
  } = useActivityState({ bool: false });
  const { isSpanish, language } = useProductLanguage();

  const handleIcon = () => {
    setVisibleTooltip((tip) => {
      if (tip === currentContentIndex) {
        return null;
      }
      return currentContentIndex;
    });

    setCurrentSceneIndex((sceneIndex) =>
      sceneIndex > currentContentIndex ? sceneIndex : sceneIndex + 1,
    );
  };

  const lastScene = currentSceneIndex === slideScenes.length - 1;

  const handleTooltipClose = () => {
    setVisibleTooltip(null);
    setCurrentContentIndex((contentIndex) =>
      contentIndex === messyBedroomContent.length - 1
        ? contentIndex
        : contentIndex + 1,
    );
    if (lastScene) {
      setBedTime(true);
    }
  };

  const handleSubmit = () => {
    setCompleted({ bool: true });
    if (isReview) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const { iconClasses, tooltipText, tooltipClasses, tooltipTriPosition } =
    messyBedroomContent[currentContentIndex];

  const scene = slideScenes[currentSceneIndex];

  return (
    <SlideContainer
      className="flex-col font-light text-white"
      hideButton={!isReview && currentContentIndex === 0}
      onButtonClick={handleSubmit}
    >
      <p className="px-14 mb-8">
        {!bedTime ? (
          <>
            {isSpanish ? 'Pulse la' : 'Tap on an'} <InfoIcon />{' '}
            {isSpanish
              ? `en el cuarto para ver algunos hábitos que puedan 
                    mejorar su sueño:`
              : `in the room to see some habits that can improve 
                    your child's sleep:`}
          </>
        ) : (
          <>
            {isSpanish
              ? 'Dormir bien preparará a su hijo/a para un día exitoso.'
              : `Getting enough good sleep will set your child up for a 
                successful day.`}
          </>
        )}
      </p>
      <div className="relative flex flex-col items-center max-w-full min-w-93 w-93 sm:w-151 self-center">
        <img
          src={scene}
          alt={
            isSpanish
              ? 'dormitorio de niño oso desordenado'
              : 'messy bear boy bedroom'
          }
          style={{ width: 680 }}
        />
        {!bedTime && (
          <InfoIcon className={iconClasses} onIconClick={handleIcon} />
        )}
        <div className={`absolute ${tooltipClasses}`}>
          <InfoTooltip
            text={tooltipText[language]}
            textAlign="left"
            visible={visibleTooltip === currentContentIndex}
            position={tooltipTriPosition}
            onClose={handleTooltipClose}
          />
        </div>
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};

const messyBedroomContent: Array<{
  iconClasses: string;
  tooltipText: { english: string; spanish: string };
  tooltipClasses: string;
  tooltipTriPosition: ToolTipTriPosition;
}> = [
  {
    iconClasses: 'absolute right-1/4 top-1/2 mt-3 -mr-7 z-10 w-8 h-8',
    tooltipText: {
      english: `Have a bedtime routine. Go to bed at the same time each night and get 
                up at the same time each morning, even on weekends.`,
      spanish: `Tenga una rutina para la hora de dormir. Acuéstese a la misma hora cada noche 
                y levántese a la misma hora cada mañana, incluso los fines de semana.`,
    },

    tooltipClasses:
      'absolute w-52 md:w-full bottom-40 sm:bottom-72 mb-12 right-14 sm:right-24 md:right-24 sm:pr-6 pr-1 md:pr-1',
    tooltipTriPosition: 'bottomRight',
  },
  {
    iconClasses: 'absolute left-1/4 bottom-3/4 mb-16 z-10 w-8 h-8',
    tooltipText: {
      english: `Make sure your bedroom is quiet, dark, relaxing, and at a comfortable 
                temperature.`,
      spanish: `Asegúrese que su cuarto está silencioso, oscuro, relajante, y a una 
                temperatura agradable. `,
    },
    tooltipClasses:
      'absolute top-12 md:top-52 left-20 md:left-36 ml-1 md:-ml-3  md:-mt-24',
    tooltipTriPosition: 'topLeft',
  },
  {
    iconClasses: 'absolute left-1/4 bottom-1/4 ml-5 -mb-4 z-10 w-8 h-8',
    tooltipText: {
      english:
        'Remove electronic devices, such as TVs, computers, and smart phones from the bedroom.',
      spanish: `Retire los electrónicos, como televisores, computadoras y teléfonos inteligentes, 
                del dormitorio.`,
    },
    tooltipClasses:
      'absolute bottom-1/3 sm:bottom-48 left-1/4 sm:pb-2 sm:left-32 -mt-24',
    tooltipTriPosition: 'bottomLeft',
  },
  {
    iconClasses:
      'absolute left-1/4 bottom-1/2 ml-11 mb-8 z-10 w-8 h-8 md:ml-20 md:mb-10 md:left-28',
    tooltipText: {
      english: `Avoid large meals and caffeine before bedtime.`,
      spanish: 'Evite comidas grandes y cafeína antes de acostarse.',
    },
    tooltipClasses: 'absolute top-1/2 left-1/3 sm:left-40 ml-2 sm:ml-6 -mt-5',
    tooltipTriPosition: 'topLeft',
  },
  {
    iconClasses: 'absolute right-1/4 bottom-1/4 -mr-14 mb-10 z-10 w-8 h-8',
    tooltipText: {
      english: `Get some exercise. Being physically active during the day can help you 
                fall asleep more easily at night.`,
      spanish: `Haga ejercicio. La actividad física durante el día le puede ayudar 
                dormirse más fácilmente en la noche.`,
    },
    tooltipClasses: 'absolute bottom-1/4 right-1/4 -mr-16  md:-mr-20 mb-22',
    tooltipTriPosition: 'bottomRight',
  },
];

const slideScenes = [
  wideAwakeSrc,
  wideAwakeSrc,
  shadesDownSrc,
  noElectronicsSrc,
  noCaffeineSrc,
  bedTimeSrc,
];
