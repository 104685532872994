import { FC } from 'react';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import {
  SurveyQuestion,
  SurveyQuestionKind,
  SurveyQuestionValue,
} from 'shared/lib/types/SurveyQuestion';
import { PostTestProgress } from 'shared/lib/types/PostTestProgress';
import { PostTestProgressBar } from '../PostTestProgressBar/PostTestProgressBar';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { ReactComponent as ArrowLeftIcon } from '../../images/feather/arrow-left.svg';
import { PillButton } from '../PillButton/PillButton';
import { SurveyQuestionAndResponse } from './SurveyQuestionAndResponse';

interface PostTestQuestionScreenProps {
  survey: SurveyWithQuestions;
  question: SurveyQuestion<SurveyQuestionKind>;
  value: SurveyQuestionValue<SurveyQuestionKind> | null;
  isSpanish: boolean;
  onNext(): unknown;
  onBack(): unknown;
  onChange(value: SurveyQuestionValue<SurveyQuestionKind>): unknown;
  postTestProgress: PostTestProgress[];
  hideBack: boolean;
  className?: string;
}

export const PostTestQuestionScreen: FC<PostTestQuestionScreenProps> = ({
  survey,
  question,
  value,
  className = '',
  isSpanish,
  onBack,
  onNext,
  onChange,
  postTestProgress,
  hideBack,
  ...rest
}) => {
  const { questions } = survey;
  const { index } = question;

  const showNext = value !== null;

  return (
    <div
      className={`flex flex-col min-h-screen bg-white ${className}`}
      {...rest}
    >
      <div className="flex flex-row justify-end px-4 pt-5">
        <ParticipantNavButton />
      </div>
      {/* No icon for the exit survey */}
      {survey.icon ? (
        <img
          src={getPublicFilePath(survey.icon)}
          alt=""
          className="w-20 h-20 self-center flex-shrink-0 object-contain -mt-2 bg-blue rounded-full"
        />
      ) : (
        <div className="w-20 h-20 self-center flex-shrink-0 object-contain -mt-2">
          {/* no icon placeholder*/}
        </div>
      )}
      <h1 className="text-center text-blue-400 text-xl mb-3.5">
        {isSpanish
          ? 'Cuestionario de Family Check-Up'
          : 'Family Check-Up Survey'}
      </h1>
      <PostTestProgressBar
        postTestProgress={postTestProgress}
        className="px-8"
      />
      <div className="bg-gray-100 flex-grow flex flex-col mt-3 pt-3 pb-8 px-8">
        <h2 className="text-blue-400 text-lg text-center px-4 font-bold">
          {survey.name}
        </h2>
        <div className="flex flex-row items-center justify-center">
          <div className="w-0 flex-grow flex flex-row justify-end">
            <button onClick={onBack} disabled={hideBack}>
              <ArrowLeftIcon
                className={`text-yellow ${hideBack ? 'invisible' : 'visible'}`}
                strokeWidth={3}
                aria-label="Back"
              />
            </button>
          </div>
          <div className="text-blue-400 text-sm text-center pl-2">
            {index + 1} / {questions.length}
          </div>
          <div className="w-0 flex-grow" />
        </div>
        <SurveyQuestionAndResponse
          question={question}
          value={value}
          onChange={onChange}
        />
        <PillButton
          disabled={!showNext}
          onClick={onNext}
          className="w-48 self-center flex-shrink-0 mt-12"
        >
          {isSpanish ? 'Siguiente' : 'Next'}
        </PillButton>
      </div>
    </div>
  );
};
