import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { SlideTextarea } from '../../../components/SlideTextarea/SlideTextarea';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare8Slide: FC = () => {
  const { next, myPlanActivity, viewOnly } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({ str: '' });
  const { isEnglish } = useProductLanguage();

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    // or activity is located on my plan page
    if (isReview || !hasInteracted || myPlanActivity) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer
      hideButton
      lightBg
      lightProgressBar
      buttonTheme={ButtonTheme.YELLOW}
    >
      <ActivityBox
        icon="pencil"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isEnglish
            ? 'Your turn: Start small. Think of one fun thing you could do this week.'
            : 'Su turno: Comience poco a poco. Piense en algo divertido que podría hacer esta semana.'
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="w-full p-4 mb-10">
            <p className="text-black font-semibold">
              {isEnglish
                ? 'This week I will _____________________ to improve my mood.'
                : 'Esta semana voy a ___________________ para mejorar mi estado de ánimo.'}
            </p>
            <SlideTextarea
              className="mt-4"
              disabled={viewOnly}
              placeholder={isEnglish ? 'Type your idea.' : 'Escriba su idea.'}
              value={value.str}
              onTextAreaChange={(value) => setValue({ str: value })}
            />
          </FloatingCard>

          {!viewOnly && (
            <PillButton
              onClick={handleSubmit}
              theme={ButtonTheme.YELLOW}
              className="w-52 self-center mt-auto"
            >
              {isEnglish ? 'Next' : 'Siguiente'}
            </PillButton>
          )}
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        icon={'thumbs-up'}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
