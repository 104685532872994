import {
  FC,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { UserWithRoles } from 'shared/lib/types/User';
import { ParticipantVisit } from 'shared/lib/types/ParticipantVisit';
import { SECOND } from 'shared/lib/constants/time';
import {
  UserRole,
  UserRoleKind,
  findStaffRole,
} from 'shared/lib/types/UserRole';
import { OrgDocumentCategory } from 'shared/lib/constants/org/OrgDocumentCategory';
import { api } from './api';
import { UserContext, UserContextValue } from './contexts/userContext';
import { ParticipantApp } from './entries/ParticipantApp';
import { UnauthorizedApp } from './entries/UnauthorizedApp';
import { ChooseRoleApp } from './entries/ChooseRoleApp';
import { useHistory } from 'react-router';
import { OrgProvider } from './contexts/orgContext';
import { SpinnerOverlay } from './components/SpinnerOverlay/SpinnerOverlay';
import { ViewVideoContext } from './contexts/viewVideoContext';
import { useIdleTimer } from 'react-idle-timer';
import {
  IDLE_TIMEOUT,
  IDLE_WARNING_TIMEOUT,
  REFRESH_AUTH_TOKEN_INTERVAL,
} from './env';
import { IdleTimeoutOverlay } from './components/IdleTimeoutOverlay/IdleTimeoutOverlay';
import { ShowEulaOverlay } from './components/ShowEulaOverlay/ShowEulaOverlay';
import { useOrgDocument } from './hooks/org/useOrgDocument';
import { isSuperAdmin } from 'shared/lib/utils/permissionUtils';
import { GetUserManual } from 'shared/lib/types/getUserManual';

// Lazy load the staff and super admin apps only as needed
const StaffApp = lazy(async () => ({
  default: (await import('./entries/StaffApp')).StaffApp,
}));
const SuperAdminApp = lazy(async () => ({
  default: (await import('./entries/SuperAdminApp')).SuperAdminApp,
}));

export const App: FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserWithRoles | null>(null);
  const [userManual, setUserManual] = useState<GetUserManual>();
  const roles = useMemo(() => user?.roles ?? [], [user]);
  const [activeRole, setActiveRole] = useState<UserRole | null>(null);
  const [userIdle, setUserIdle] = useState(false);
  const userId = user?.id;
  const loggedIn = !!user;

  useParticipantVisitInterval(
    // Start recording participant visit if they've selected a participant role or
    // if they haven't selected any role but have a participant role.
    activeRole
      ? activeRole.kind === UserRoleKind.PARTICIPANT
      : roles.some((role) => role.kind === UserRoleKind.PARTICIPANT),
  );

  const login = useCallback(
    async (credential: { username: string; password: string }) => {
      const user = await api.login(credential);
      setUser(user);
      setLoading(false);
    },
    [],
  );

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await api.logout();
      setUser(null);
      history.replace('/');
    } finally {
      setLoading(false);
    }
  }, [history]);

  const refreshCurrentUser = useCallback(
    async (isCancelled?: () => boolean) => {
      const fetchedUser = await api.getCurrentUser();
      if (typeof isCancelled !== 'function' || !isCancelled()) {
        setUser(fetchedUser);
        setActiveRole(null);
        setLoading(false);
      }
    },
    [],
  );

  const onUserAcceptedEula = useCallback(async () => {
    setLoading(true);
    try {
      await api.userAcceptedEula();
      refreshCurrentUser();
    } finally {
      setLoading(false);
    }
  }, [refreshCurrentUser]);

  const updateProfile = useCallback<UserContextValue['updateProfile']>(
    async (fields) => {
      if (!userId) {
        return;
      }
      const updatedUser = await api.updateProfile({ ...fields, userId });
      delete fields.password;
      setUser(
        (existingUser) => existingUser && { ...existingUser, ...updatedUser },
      );
    },
    [userId],
  );

  const viewVideo = useCallback(async (videoKey: string) => {
    try {
      await api.viewVideo(videoKey);
    } catch (error) {
      console.error(`Failed to record video view.`);
    }
  }, []);

  const getUserManual = useCallback(async () => {
    try {
      const userManual = await api.getUserManual();
      setUserManual(userManual);
    } catch (error) {
      console.error(`Failed to fetch user manual.`);
    }
  }, []);

  useAsyncEffect(getUserManual, []);

  useAsyncEffect(refreshCurrentUser, []);

  /**
   * If only one role is available, select it automatically.
   * Otherwise, the user must choose a role.
   */
  useEffect(() => {
    if (user) {
      if (!activeRole && roles?.length === 1) {
        setActiveRole(roles[0]);
      }
    } else if (activeRole) {
      // Clear the role when the user logs out
      setActiveRole(null);
    }
  }, [user, roles, activeRole]);

  /**
   * Keep track of whether or not the user is idling.
   */
  useIdleTimer({
    timeout: IDLE_WARNING_TIMEOUT,
    debounce: 500,
    onActive: useCallback(() => setUserIdle(false), []),
    onIdle: useCallback(() => setUserIdle(true), []),
  });

  /**
   * If the user is idling and logged in, wait a bit before logging them out.
   */
  useEffect(() => {
    if (userIdle && loggedIn) {
      const timeout = setTimeout(logout, IDLE_TIMEOUT - IDLE_WARNING_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [userIdle, loggedIn, logout]);

  useEffect(() => {
    if (loggedIn) {
      const interval = setInterval(
        api.refreshAuthToken,
        REFRESH_AUTH_TOKEN_INTERVAL,
      );

      return () => clearInterval(interval);
    }
  }, [loggedIn]);

  const userContext: UserContextValue = {
    user,
    roles,
    activeRole,
    userManual,
    getUserManual,
    setActiveRole,
    login,
    logout,
    refreshCurrentUser,
    updateProfile,
  };

  // Ensure the active user has agreed to the Eula.
  const shouldFetchEula = user && !user.hasAcceptedEula && !isSuperAdmin(user);
  const { orgDocument: eula } = useOrgDocument(
    shouldFetchEula ? user.orgId : null,
    OrgDocumentCategory.EULA,
  );

  if (loading) {
    return (
      <div className="min-h-screen">
        <SpinnerOverlay />
      </div>
    );
  }

  if (!user) {
    return (
      <UserContext.Provider value={userContext}>
        <UnauthorizedApp />
      </UserContext.Provider>
    );
  }

  // We only fetch the EULA if we actually need it so show it any time it's available.
  if (eula) {
    return (
      <UserContext.Provider value={userContext}>
        <ShowEulaOverlay
          onAcceptEula={onUserAcceptedEula}
          onCancel={logout}
          eula={eula}
          isStaffRole={findStaffRole(roles) !== undefined}
        />
      </UserContext.Provider>
    );
  }

  const idleOverlay = userIdle ? <IdleTimeoutOverlay /> : null;

  switch (activeRole?.kind) {
    case UserRoleKind.PARTICIPANT:
      return (
        <UserContext.Provider value={userContext}>
          <OrgProvider org={activeRole.org}>
            <ViewVideoContext.Provider value={{ viewVideo }}>
              {idleOverlay}
              <ParticipantApp />
            </ViewVideoContext.Provider>
          </OrgProvider>
        </UserContext.Provider>
      );
    case UserRoleKind.STAFF:
      return (
        <UserContext.Provider value={userContext}>
          <OrgProvider org={activeRole.org}>
            <Suspense fallback={<SpinnerOverlay />}>
              {idleOverlay}
              <StaffApp />
            </Suspense>
          </OrgProvider>
        </UserContext.Provider>
      );
    case UserRoleKind.SUPER_ADMIN:
      return (
        <UserContext.Provider value={userContext}>
          <Suspense fallback={<SpinnerOverlay />}>
            {idleOverlay}
            <SuperAdminApp />
          </Suspense>
        </UserContext.Provider>
      );
    default:
      /**
       * If no role is selected, display a choose role page.
       */
      return (
        <UserContext.Provider value={userContext}>
          {idleOverlay}
          <ChooseRoleApp />
        </UserContext.Provider>
      );
  }
};

/**
 * Creates a participant visit and update it every 10 seconds.
 */
function useParticipantVisitInterval(enabled: boolean): void {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    let participantVisit: ParticipantVisit | null = null;
    let lastTick = Date.now();

    const interval = setInterval(async () => {
      if (participantVisit !== null) {
        const now = Date.now();
        try {
          const elapsed = now - lastTick;
          lastTick = now;
          await api.tickParticipantVisit({
            visitId: participantVisit.id,
            elapsed,
          });
        } catch (error) {
          console.error(`Failed to tick participant visit`, {
            response: error.response,
          });
          if (error.status === 401) {
            clearInterval(interval);
          }
        }
      }
    }, 10 * SECOND);

    api
      .beginParticipantVisit({
        page: window.location.pathname,
        userAgent: navigator.userAgent,
      })
      .then((newParticipantVisit) => {
        participantVisit = newParticipantVisit;
      })
      .catch((error) => {
        console.error(`Failed to tick participant visit`, error);

        // Not logged in
        if (error.status === 401) {
          clearInterval(interval);
        }
      });

    return () => {
      clearInterval(interval);
    };
  }, [enabled]);
}
