import { FC, FormEvent, useState } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { api } from '../../api';
import { ReactComponent as ChevronLeftIcon } from '../../images/feather/chevron-left.svg';
import { PillButton, PillLink } from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Logo } from '../../components/Logo/Logo';

export const ForgotPasswordPage: FC = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [email, setEmail] = useState('');
  useDocumentTitle('Forgot Password');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (isBlank(email)) {
        throw new Error('Email is required.');
      }
      await api.sendPasswordReset(email);
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error);
    }
  }

  return (
    <div className="min-h-screen flex flex-col bg-blue-700 px-4 pb-24 pt-36 text-white">
      <Logo className="flex-shrink-0 self-center" />
      {submitSuccess ? (
        <div className="container w-96 max-w-full mx-auto flex flex-col px-4 mt-20 text-center">
          <h1 className="text-xl font-bold">
            Password reset sent successfully
          </h1>
          <p className="mt-8">Check your email.</p>
          <PillLink to="/" className="mt-8 flex flex-row mx-auto">
            <ChevronLeftIcon className="mr-2 mt-0.5" /> Back to Sign In
          </PillLink>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="container w-96 max-w-full mx-auto flex flex-col px-4 mt-20"
        >
          <div className="flex flex-row items-center">
            <Link to="/" className="mr-2">
              <ChevronLeftIcon title="Back to Sign In" />
            </Link>
            <h1 className="text-xl font-bold">Forgot Password</h1>
          </div>
          <p className="mt-8">Enter your email to send a password reset.</p>
          {submitError && (
            <p className="text-rose-600 py-2">{submitError.message}</p>
          )}
          <TextInput
            required
            value={email}
            onValueChange={setEmail}
            type="email"
            placeholder="Email"
            autoComplete="current-username"
            className="h-12 pl-5 mt-8"
          />
          <PillButton className="mt-16 mx-auto">Send Reset</PillButton>
        </form>
      )}
    </div>
  );
};
