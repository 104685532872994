import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Como usar los premios:' : 'How to use rewards:'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Comience con las cosas que motivan a su hijo/a. Para hacerlo divertido, 
          puede trabajar junto con su hijo/a para crear una lista de premios que 
          le puede dar.`
        : `You start by knowing what motivates your child. To make it fun, you can
           work with your child to come up with a list of rewards you can give.`}
    </SlideContainer>
  );
};
