import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ClipBoard } from '../../../components/ClipBoard/ClipBoard';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import kitchenImgSrc from '../../../images/FCU_Kitchen.png';
import clipboardImgSrc from '../../../images/clipboard.png';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Clear_Rules_d: FC = () => {
  const { next } = useSlideContext();
  const { setValue: setCompleted, isReview } = useActivityState({
    bool: false,
  });
  const [clipBoardOpen, setClipBoardOpen] = useState(false);
  const [showButton, setShowButton] = useState(isReview);
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  const handleClipBoard = () => {
    if (clipBoardOpen) {
      setShowButton(true);
    } else {
      setClipBoardOpen(!open);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = () => {
    setCompleted({ bool: true });
    next();
  };

  return (
    <SlideContainer className="relative flex-col text-white" hideButton>
      <p className="font-light px-14 mb-9">
        {isSpanish
          ? `Toque el portapapeles para aprender algunas pautas 
            útiles para establecer reglas domésticas exitosas.`
          : `Tap the clipboard to learn some helpful guidelines 
            for setting successful household rules.`}
      </p>
      <img
        src={kitchenImgSrc}
        alt={isSpanish ? 'cocina' : 'kitchen'}
        className={`w-full flex-1 ${showButton ? 'mb-7' : ''}`}
      />
      <button
        className="absolute w-2/12 top-1/3 right-1/4 z-10 p-1 sm:-mt-20"
        onClick={() => handleClipBoard()}
      >
        <div
          className="absolute w-full text-center text-blue-400 font-boris text-4px sm:text-5px"
          style={{ top: '13%', left: '0' }}
        >
          {isSpanish ? (
            <>
              Reglas
              <br />
              Domésticas Exitosas
            </>
          ) : (
            <>
              Successful
              <br />
              Household Rules:
            </>
          )}
        </div>
        <img
          src={clipboardImgSrc}
          alt={isSpanish ? 'portapapeles' : 'clipboard'}
          className="w-full h-full"
        />
      </button>
      {clipBoardOpen && (
        <ClipBoard
          onClose={handleClipBoard}
          className="absolute w-full h-full z-20 pt-5 px-3"
          title={
            <h2 className="font-bold font-boris text-2xl text-center">
              {isSpanish ? 'Reglas' : 'Successfull'}
              <br />
              {isSpanish ? 'Domésticas Exitosas:' : 'Household Rules:'}
            </h2>
          }
        >
          <div className="mt-8 text-blue-700">
            <PlusExpandable
              buttonTheme="dark"
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish ? 'SON CLARAS' : 'ARE CLEAR'}
                </b>
              }
              open={open === 0}
              onExpand={() => handleOpen(0)}
            >
              {isSpanish
                ? `Sea específico sobre lo que quiere, para que su 
                  hijo/a sepa exactamente lo que espera que haga.`
                : `Be specific about what you want, so your child knows 
                  exactly what you expect them to do.`}

              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `“Pon los platos en el lavaplatos cuando hayas terminado de comer.”`
                    : `“Put dishes in the dishwasher when you’re done eating.”`}
                </p>
              </ExampleSection>
            </PlusExpandable>
            <PlusExpandable
              buttonTheme="dark"
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish ? 'SON RAZONABLES' : 'ARE REASONABLE'}
                </b>
              }
              open={open === 1}
              onExpand={() => handleOpen(1)}
            >
              {isSpanish
                ? `Sea conciente sobre la cantidad y el tamaño de las tareas que le asigna a su hijo/a.`
                : 'Be thoughtful about the number and size of tasks you give your child.'}

              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `“Mantén tu habitación limpia" en lugar de "¡Limpia toda la casa!”`
                    : '“Keep your room clean.” instead of, “Clean the whole house!”'}
                </p>
              </ExampleSection>
            </PlusExpandable>
            <PlusExpandable
              buttonTheme="dark"
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish ? 'DIGA LO QUE QUIERA' : 'SAY WHAT YOU WANT'}
                </b>
              }
              open={open === 2}
              onExpand={() => handleOpen(2)}
            >
              {isSpanish ? 'No lo que no quiera.' : "Not what you don't want."}

              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `“Llega a casa a las 5:00 pm" describe exactamente lo 
                      que espera en lugar de "¡No vuelvas a llegar tarde!”`
                    : `“Be home by 5pm.” describes exactly what you expect instead
                      of, “Don’t be late again!”`}
                </p>
              </ExampleSection>
            </PlusExpandable>
            <PlusExpandable
              buttonTheme="dark"
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish ? 'SON SIMPLES' : 'ARE SIMPLE'}
                </b>
              }
              open={open === 3}
              onExpand={() => handleOpen(3)}
            >
              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `Dar una orden a la vez es más efectivo que dar varias órdenes ("Apaga la televisión, báñate, cepíllate los dientes, lee tu libro y vete a la cama”).`
                    : `Giving one command at a time is more effective than giving
                      multiple commands (“Turn off the TV, take a shower, brush your
                      teeth, read your book, and go to bed”).`}
                </p>
              </ExampleSection>
            </PlusExpandable>
            <PlusExpandable
              buttonTheme="dark"
              open={open === 4}
              onExpand={() => handleOpen(4)}
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish
                    ? `SON REALISTAS (para la edad de su hijo/a) Y FÁCILES DE SEGUIR (y hacer cumplir)`
                    : `ARE REALISTIC (for your child’s age) AND EASY TO FOLLOW (and enforce)`}
                </b>
              }
            >
              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `“Si comes tu cena, puedes comer postre.” O “Apaga 
                      tu teléfono y ponlo sobre la mesa antes de acostarte. ”`
                    : `“If you eat your dinner, you can have dessert.” or “Turn your
                      phone off and put it on the table before going to bed.”`}
                </p>
              </ExampleSection>
            </PlusExpandable>
            <PlusExpandable
              buttonTheme="dark"
              open={open === 5}
              onExpand={() => handleOpen(5)}
              headerText={
                <b className="text-lg font-extrabold">
                  {isSpanish ? 'NO ULTICE PREGUNTAS' : 'DON’T USE QUESTIONS'}
                </b>
              }
            >
              {isSpanish
                ? `Su hijo/a podría decir "no". Tenga claro que su solicitud no es opcional.`
                : `Your child might say “no.” Be clear that your request is not optional.`}

              <ExampleSection
                className="text-blue-700 mt-4"
                title={
                  <h3 className="uppercase font-bold ml-2">
                    {isSpanish ? 'Ejemplo' : 'Example'}:
                  </h3>
                }
              >
                <p className="pl-8 mt-2">
                  {isSpanish
                    ? `“¿Quieres limpiar la mesa?” es menos eficaz que “¡Limpia 
                      la mesa, por favor!”`
                    : `“Do you want to clean the table?” is less effective than
                      “Clear the table, please!”`}
                </p>
              </ExampleSection>
            </PlusExpandable>
          </div>
        </ClipBoard>
      )}
      <BottomToast
        hideButton={!showButton}
        onButtonClick={handleSubmit}
        animate={false}
        buttonLabel={isSpanish ? 'Siguiente' : 'Next'}
      />
    </SlideContainer>
  );
};
