import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import stressRelief from '../../../images/relieves_stress.png';
import improvesSleep from '../../../images/improves_sleep.png';
import bonesStronger from '../../../images/bones_stronger.png';
import fullEnergy from '../../../images/full_energy.png';
import strengthEndurance from '../../../images/strength_endurance.png';
import selfLove from '../../../images/self_love.png';
import decreaseBoredom from '../../../images/decrease_boredom.png';
import connectSocially from '../../../images/connect_socially.png';
import { ChevronCircleButton } from '../../../components/ChevronCircleButton/ChevronCircleButton';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const IMG_COL = [
  {
    img: stressRelief,
    alt: {
      english: 'two people doing yoga',
      spanish: 'dos personas haciendo yoga',
    },
    caption: {
      english: 'Relieves stress.',
      spanish: 'Alivia el estrés',
    },
  },
  {
    img: improvesSleep,
    alt: {
      english: 'girl sleeping',
      spanish: 'niña durmiendo',
    },
    caption: {
      english: 'Improves sleep.',
      spanish: 'Mejora el sueño',
    },
  },
  {
    img: bonesStronger,
    alt: {
      english: 'kids playing soccer',
      spanish: 'Niñas jugando futbol',
    },
    caption: {
      english: 'Makes bones & muscles stronger.',
      spanish: 'Fortalece los huesos y los músculos',
    },
  },
  {
    img: fullEnergy,
    alt: {
      english: 'kids running around track',
      spanish: 'Niños corriendo por la pista',
    },
    caption: {
      english: 'Makes you feel full of energy.',
      spanish: 'Te hace sentir lleno/a de energía',
    },
  },
  {
    img: strengthEndurance,
    alt: {
      english: 'young girl swimming',
      spanish: 'niña nadando',
    },
    caption: {
      english: 'Builds strength and endurance.',
      spanish: 'Aumenta la fuerza y la resistencia',
    },
  },
  {
    img: selfLove,
    alt: {
      english: 'love who you are',
      spanish: 'amas lo que eres',
    },
    caption: {
      english: 'Helps you feel good about yourself.',
      spanish: 'Le ayuda a sentirse bien contigo mismo/a',
    },
  },
  {
    img: decreaseBoredom,
    alt: {
      english: 'family playing basketball',
      spanish: 'familia jugando baloncesto',
    },
    caption: {
      english: "Gives you something to do when you're bored.",
      spanish: 'Le da algo que hacer cuando está aburrido/a',
    },
  },
  {
    img: connectSocially,
    alt: {
      english: 'group of kids smiling',
      spanish: 'Grupo de niños sonriendo',
    },
    caption: {
      english: 'Provides ways to connect with each other.',
      spanish: 'Da formas de conectar con los demás',
    },
  },
];

export const HBST_Physicalactivity_a: FC = () => {
  const { next } = useSlideContext();
  const [curPicIndex, setCurPicIndex] = useState(0);
  const { img, alt, caption } = IMG_COL[curPicIndex];
  const lastImgSlide = IMG_COL.length === curPicIndex + 1;
  const { isSpanish, language } = useProductLanguage();

  const handleClick = () =>
    lastImgSlide ? next() : setCurPicIndex((idx) => idx + 1);

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Actividad Física' : 'Physical Activity'}
      className="flex-col px-14 font-light text-white"
      hideButton
    >
      {isSpanish
        ? `Junto con una alimentación saludable, estar activo es fundamental para 
          mantener la buena salud de su hijo/a. Hay muchas formas de mantenerse 
          activo. El ejercicio, como correr o practicar deportes organizados, 
          puede quemar mucha energía. Hacer ejercicio también:`
        : `Along with healthy eating, being active is critical to maintaining your
          child’s good health. There are many ways to be active. Exercise, like
          running or playing organized sports, can burn a lot of energy. Exercise
          also:`}

      <img src={img} alt={alt[language]} className="mt-5 mb-3" />
      <div className="flex flex-col justify-center items-center">
        <p className="text-center text-red-600 font-medium">
          {caption[language]}
        </p>
        <ChevronCircleButton className="mt-10" onClick={handleClick} />
      </div>
    </SlideContainer>
  );
};
