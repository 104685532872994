import { FC } from 'react';
import letterASrc from '../../images/bordered_a.png';
import letterBSrc from '../../images/bordered_b.png';
import letterCSrc from '../../images/bordered_c.png';
import letterDSrc from '../../images/bordered_d.png';
import { ReactComponent as ThumbsUpIcon } from '../../images/feather/thumbs-up.svg';
import { ReactComponent as ThumbsDownIcon } from '../../images/feather/thumbs-down.svg';

export const letterOptions = {
  A: {
    img: letterASrc,
    alt: 'option a',
  },
  B: {
    img: letterBSrc,
    alt: 'option b',
  },
  C: {
    img: letterCSrc,
    alt: 'option c',
  },
  D: {
    img: letterDSrc,
    alt: 'option d',
  },
};

export type LetterOption = keyof typeof letterOptions;

/**
 * @param {string} [letterOption="A"] - Defaults to letter A
 */

export interface RadioLetterProps {
  className?: string;
  letterOption?: LetterOption;
  optionCorrect?: boolean;
  checked: boolean;
  onRadioSelect(): unknown;
}

export const RadioLetterOption: FC<RadioLetterProps> = ({
  className = '',
  letterOption = 'A',
  optionCorrect,
  checked,
  onRadioSelect,
  ...rest
}) => {
  const { img, alt } = letterOptions[letterOption];

  return (
    <div
      className={`flex items-center justify-center flex-shrink-0 cursor-pointer w-11 h-11 ${className}`}
      {...rest}
      onClick={onRadioSelect}
    >
      <input
        type="radio"
        className="hidden"
        checked={checked}
        onChange={onRadioSelect}
      />
      {checked ? (
        <div className="flex justify-center items-center rounded-full bg-blue-200 w-11 h-11 border-3 border-blue-400">
          <div className="flex justify-center items-center w-4/6 h-4/6 bg-red rounded-full">
            {optionCorrect ? (
              <ThumbsUpIcon
                className="w-3/5"
                fill="white"
                strokeWidth="1"
                stroke="#FF595B"
              />
            ) : (
              <ThumbsDownIcon
                className="w-3/5"
                fill="white"
                strokeWidth="1"
                stroke="#FF595B"
              />
            )}
          </div>
        </div>
      ) : (
        <img src={img} alt={alt} className="w-full h-full" />
      )}
    </div>
  );
};
