import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_f: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      bearCharacter="BOY_WINKING"
      videoKey={isSpanish ? 'ani_3_spanish' : 'ani_3'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? 'Vea como esta mamá usa los premios.'
            : 'Watch how this mom uses rewards'}
        </p>
      }
    />
  );
};
