import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { useSlideContext } from '../../../contexts/slideContext';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { Language } from 'shared/lib/types/Language';

const englishLabels = [
  'Praise and reward your child.',
  'Smile at your child.',
  'Spend quality time with your child.',
  "Pay attention to your child's good behavior.",
];

const spanishLabels = [
  'Elogiar y premiar a su hijo/a.',
  'Sonreírle a su hijo/a.',
  'Pasar tiempo con su hijo/a.',
  'Ponerle atención al buen comportamiento de su hijo/a.',
];

const correctMessages = {
  english: 'You really know what it takes to parent in a positive way!',
  spanish: '¡Ud. sabe lo que toma de criar en una manera positiva!',
};

const incorrectMessages = {
  english: 'Actually, all of these are examples of positive parenting.',
  spanish:
    'En realidad, todos estos son ejemplos de maneras de criar positivamente. ',
};

const getCorrectIncorrectMessages = (lang: Language, correct: boolean) =>
  correct ? correctMessages[lang] : incorrectMessages[lang];

export const Pospar_Pospar_b: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      question1: false,
      question2: false,
      question3: false,
      question4: false,
    });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const allCorrect =
    value.question1 && value.question2 && value.question3 && value.question4;

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const labels = isSpanish ? spanishLabels : englishLabels;

  return (
    <SlideContainer lightBg lightProgressBar hideButton className="text-white">
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? `Su turno: Marque todas las cajas que describen lo que significa usar la crianza positiva.`
            : `Your turn: Check all the boxes that describe what 
              it means to use positive parenting.`
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center px-3">
          <FloatingCard className="w-full py-3 px-4">
            {Object.keys(value).map((key, idx) => {
              const valueKey = key as keyof typeof value;
              return (
                <SlideCheckbox
                  key={key}
                  className="mb-3"
                  checked={value[valueKey]}
                  onChange={() =>
                    setValue({ ...value, [key]: !value[valueKey] })
                  }
                  label={labels[idx]}
                />
              );
            })}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 mb-12"
            onClick={() => handleSubmit()}
          >
            {isSpanish ? 'Entregar' : 'Submit'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        icon={allCorrect ? 'thumbs-up' : 'alert'}
        onNext={next}
        activityPoints={activity.pointValue}
        badgeLocation="right"
        activityMessage={getCorrectIncorrectMessages(language, allCorrect)}
        showConfetti={allCorrect === true}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};
