import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import { useParticipantProductContextOptional } from '../../contexts/participantProductContext';

export function useProductKey(): ProductKey {
  const participantContext = useParticipantProductContextOptional();

  // Safe default
  if (!participantContext) {
    return ProductKey.SCHOOL_AGE;
  }

  const { productSession } = participantContext;
  const { product } = productSession;
  switch (product.key) {
    case ProductKey.EARLY_CHILDHOOD:
      return ProductKey.EARLY_CHILDHOOD;
    case ProductKey.SCHOOL_AGE:
    default:
      return ProductKey.SCHOOL_AGE;
  }
}
