import { User } from 'shared/lib/types/User';
import { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../api';
import { ParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { VisibleStaffField } from 'shared/lib/constants/staff/VisibleStaffField';

export interface ParticipantCoachContextValues {
  coach: User | null;
  visibleCoachFields: VisibleStaffField[];
  nextAppointmentDate: string | null;
}

export const ParticipantCoachContext =
  createContext<ParticipantCoachContextValues>({
    coach: null,
    visibleCoachFields: [],
    nextAppointmentDate: null,
  });

export function useParticipantCoachContext(): ParticipantCoachContextValues {
  return useContext(ParticipantCoachContext);
}

export function useParticipantCoachContextManager(
  productSession: Pick<
    ParticipantProductSession,
    'orgProduct' | 'participantProduct'
  > | null,
): ParticipantCoachContextValues {
  const [values, setValues] = useState<ParticipantCoachContextValues>({
    coach: null,
    visibleCoachFields: [],
    nextAppointmentDate: null,
  });

  const orgId = productSession?.orgProduct.orgId;
  const participantId = productSession?.participantProduct.participantId;

  useEffect(() => {
    const loadCoach = async () => {
      if (orgId && participantId) {
        const { coach, visibleStaffFields, nextAppointmentDate } =
          await api.getParticipantCoach({
            orgId,
            participantId,
          });
        setValues({
          coach,
          visibleCoachFields: visibleStaffFields,
          nextAppointmentDate,
        });
      }
    };

    loadCoach();

    return () => {
      setValues({
        coach: null,
        visibleCoachFields: [],
        nextAppointmentDate: null,
      });
    };
  }, [orgId, participantId]);

  return values;
}
