import { FC } from 'react';
import PointsBadge from '../PointsBadge/PointsBadge';
import { useProductLanguage } from '../../hooks/useProductLanguage';

const textWidth = {
  SHRUNK: 'w-3/4',
  FULL: 'w-full',
};

/**
 * @param {string} [messageWidth="FULL"] - Defaults to full width
 */

const PointsBadgeMessage: FC<{
  className?: string;
  points: number;
  pointsBadgeLabel: string;
  messageWidth?: keyof typeof textWidth;
}> = ({
  className = '',
  points,
  pointsBadgeLabel,
  messageWidth = 'FULL',
  ...rest
}) => {
  const { isSpanish } = useProductLanguage();
  return (
    <div
      className={`relative flex flex-col flex-shrink-0 content-center justify-center items-center text-center ${className}`}
      {...rest}
    >
      <PointsBadge points={points} label={pointsBadgeLabel} />
      <p
        className={`mt-3 text-blue-800 font-medium flex-1 ${textWidth[messageWidth]}`}
      >
        {isSpanish
          ? `¡Se ha ganado ${points} puntos por completar la actividad!`
          : `You've earned ${points} points for completing an activity!`}
      </p>
    </div>
  );
};

export default PointsBadgeMessage;
