import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Bodyposi_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Positivadad Corporal' : 'Body Positivity'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish ? 'Considere si su hijo/a:' : 'Consider whether your child:'}
      <YellowCircleList className="py-6">
        <li>
          {isSpanish
            ? 'Habla extensamente sobre la imagen de su cuerpo.'
            : 'Talks at length about their body image.'}
        </li>
        <li>
          {isSpanish
            ? 'Parece estar comiendo en exceso o limitando lo que come.'
            : 'Seems to be overeating or limiting food intake.'}
        </li>
        <li>{isSpanish ? 'No duerme bien.' : 'Sleeps poorly.'}</li>
        <li>
          {isSpanish
            ? 'Parece deprimido/a o ansioso/a.'
            : 'Seems depressed or anxious'}
        </li>
      </YellowCircleList>
      {isSpanish
        ? `Cualquiera de estos puede ser una señal de que su hijo/a 
          necesita apoyo adicional para mantenerse saludable.`
        : `Any of these can be a sign that your child needs extra support 
          to stay healthy`}
    </SlideContainer>
  );
};
