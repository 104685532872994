import { FC, useState } from 'react';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import {
  englishIsOrNotProactiveQuiz,
  spanishIsOrNotProactiveQuiz,
} from '../../../constants/early_childhood/proactive_parenting_5_quiz';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type QuizKey =
  | keyof typeof englishIsOrNotProactiveQuiz
  | keyof typeof spanishIsOrNotProactiveQuiz;

type ActivityState = Partial<Record<QuizKey, boolean>>;

export const ProactiveParenting5Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview } =
    useActivityState<ActivityState>({});

  const moveForward = Object.keys(value).length > 2;
  const isOrNotProactiveQuiz = isEnglish
    ? englishIsOrNotProactiveQuiz
    : spanishIsOrNotProactiveQuiz;

  return (
    <SlideContainer
      className="flex-col items-center"
      hideButton
      lightBg
      lightProgressBar
    >
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-900"
        instructions={
          isEnglish
            ? 'Your turn: Proactive or Not Proactive?'
            : 'Su turno: ¿Proactivo o no proactivo?'
        }
        icon="pointer"
      >
        <HelpfulUnhelpfulQuiz
          className="mt-5"
          title={
            <h3 className="w-3/4">
              {isEnglish
                ? 'Decide if these are examples of proactive parenting or not.'
                : 'Decida si estos son ejemplos de crianza proactiva o no.'}
            </h3>
          }
          iconPair="helpful/unhelpful"
          helpfulWord={isEnglish ? 'Proactive' : 'Proactivo'}
          unhelpfulWord={isEnglish ? 'Not proactive' : 'No Proactivo'}
        >
          {Object.entries(isOrNotProactiveQuiz).map(
            ([key, { answer, question, feedback }]) => (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                isHelpfulCorrect={answer}
                value={value[key as QuizKey] ?? null}
                questionText={question}
                feedback={feedback}
                helpfulWord={isEnglish ? 'Proactive' : 'Proactivo'}
                unhelpfulWord={isEnglish ? 'Not proactive' : 'No Proactivo'}
                onChange={(bool) => {
                  setValue({
                    ...value,
                    [key]: bool,
                  });
                }}
              />
            ),
          )}
        </HelpfulUnhelpfulQuiz>
        {moveForward && (
          <PillButton
            className="mt-10"
            theme={ButtonTheme.YELLOW}
            onClick={() => (isReview ? next() : setShowOverlay(true))}
          >
            {isEnglish ? 'Finish Activity' : 'Termine la Actividad'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="YOUNG_GIRL"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
