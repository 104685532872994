import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Stress_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Bienestar emocional y afrontamiento del estrés'
          : 'Emotional well-being and coping with stress'
      }
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `En las siguientes secciones, aprenderá sobre las formas en que puede
          ayudar a su hijo/a a enfrentar sus sentimientos y emociones y apoyar
          su salud y bienestar en general.`
        : `In the next sections, you will learn about ways you can help your
          child cope with their feelings and emotions and support their overall
          health and well-being.`}
    </SlideContainer>
  );
};
