import { useEffect, useState } from 'react';

/**
 * Hook that monitors the status of a media query and returns
 * whether it currently matches or not.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries for available queries.
 * @param query The media query to watch.
 */
export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    function handleMediaMatchChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }
    const matcher = window.matchMedia(query);
    setMatches(matcher.matches);
    matcher.addEventListener('change', handleMediaMatchChange);

    return () => {
      matcher.removeEventListener('change', handleMediaMatchChange);
      setMatches(false);
    };
  }, [query]);

  return matches;
}
