import { FC } from 'react';
import thumbsUpIconSrc from '../../images/thumbs_up.png';
import thumbsDownIconSrc from '../../images/thumbs_down.png';
import sunIconSrc from '../../images/fcu_icon_clear.png';
import cloudyIconSrc from '../../images/fcu_icon_unclear.png';
import logicalIconSrc from '../../images/fcu_icon_logical.png';
import illogicalIconSrc from '../../images/fcu_icon_illogical.png';
import doIconSrc from '../../images/sort_house_correct.png';
import dontIconSrc from '../../images/sort_house_incorrect.png';

export const QUIZ_ICONS = {
  thumbsUp: {
    img: thumbsUpIconSrc,
    alt: 'thumbs up',
  },
  thumbsDown: {
    img: thumbsDownIconSrc,
    alt: 'thumbs down',
  },
  sunny: {
    img: sunIconSrc,
    alt: 'bright sun',
  },
  cloudy: {
    img: cloudyIconSrc,
    alt: 'rain cloud',
  },
  logical: {
    img: logicalIconSrc,
    alt: 'logical',
  },
  illogical: {
    img: illogicalIconSrc,
    alt: 'illogical',
  },
  do: {
    img: doIconSrc,
    alt: 'do',
  },
  dont: {
    img: dontIconSrc,
    alt: "don't",
  },
};

/**
 *
 * @param {string} [icon="thumbsUp"] - Defaults to thumbs up icon
 */

export const QuizIcon: FC<{
  icon?: keyof typeof QUIZ_ICONS;
  className?: string;
}> = ({ icon = 'thumbsUp', className, ...rest }) => {
  const { img, alt } = QUIZ_ICONS[icon];

  return (
    <img
      className="w-30 rounded-full flex-shrink-0"
      src={img}
      alt={alt}
      {...rest}
    />
  );
};
