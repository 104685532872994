import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences6Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Clear Rules' : 'Reglas Claras'}
      className="flex-col text-white px-14 font-light"
    >
      <span className="mb-2.5">
        {isEnglish
          ? 'Why are rules important?'
          : '¿Por qué son importantes las reglas?'}
      </span>
      <p>
        {isEnglish ? (
          <>
            Setting clear rules and limits is an important part of parenting.
            Parents who have clear rules and limits give their children many
            advantages in life.
          </>
        ) : (
          <>
            Establecer reglas y límites claros es una parte importante de la
            crianza. Los padres que tienen reglas y límites claros les dan a sus
            hijos muchas ventajas en la vida.
          </>
        )}
      </p>
      <YellowCircleList className="mt-5">
        {isEnglish ? (
          <>
            <li>Fewer emotional problems</li>
            <li>Improved brain development</li>
            <li>Higher grades and school performance</li>
            <li>Better behavior at home</li>
            <li>More friends</li>
          </>
        ) : (
          <>
            <li>Menos problemas emocionales</li>
            <li>Mejor desarrollo de cerebro</li>
            <li>Mejores notas y mejor rendimiento en la escuela</li>
            <li>Mejor comportamiento en el hogar</li>
            <li>Mas amigos</li>
          </>
        )}
      </YellowCircleList>
    </SlideContainer>
  );
};
