import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Start small—change only one thing at a time.',
  'Help your child choose something realistic.',
  'Plan together with your child.',
  'Talk about how it went—did they like it? Did it make life easier?',
  'Praise and reward success!',
];

const spanishList = [
  'Empiece pequeño-- cambie solo una cosa a la vez',
  'Ayude a su hijo/a a elegir algo realista',
  'Planifique junto con su hijo/a',
  'Hable de cómo les fue-- ¿Les gustó? ¿Les hizo la vida más facil?',
  '¡Elogie y recompense el éxito!',
];

export const SSS_Choose_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Escoger Rutinas' : 'Choose Routine'}
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? 'Hacer cambios puede ser difícil, así que:'
          : 'Making changes can be hard, so:'}
      </p>
      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
