import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import praiseExampleSrc from '../../../images/rules_and_consequences_praise_example.png';
import spanishPraiseExampleSrc from '../../../images/spanish_rules_and_consequences_praise_example.png';

export const RulesAndConsequences10Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer className="flex-col text-white font-light">
      <p className="px-14">
        {isEnglish ? (
          <>
            Finally, don’t forget to use praise when your child follows your
            rules. When you use these guidelines, both you and your child will
            know what to expect.
          </>
        ) : (
          <>
            Finalmente, no olvide elogiar a su hijo/a cuando siga sus reglas.
            Cuando utiliza estas guías, tanto usted como su hijo/a sabrán qué
            esperar.
          </>
        )}
      </p>
      <img
        src={isEnglish ? praiseExampleSrc : spanishPraiseExampleSrc}
        alt={isEnglish ? 'bear mother in living room' : 'madre oso en la sala'}
        className="my-6 w-full"
      />
    </SlideContainer>
  );
};
