import { FC } from 'react';
import { PositiveParenting1Slide } from './positive_parenting_1';
import { PositiveParenting2Slide } from './positive_parenting_2';
import { PositiveParenting3Slide } from './positive_parenting_3';
import { PositiveParenting4Slide } from './positive_parenting_4';
import { PositiveParenting5Slide } from './positive_parenting_5';
import { PositiveParenting6Slide } from './positive_parenting_6';
import { PositiveParenting7Slide } from './positive_parenting_7';
import { PositiveParenting8Slide } from './positive_parenting_8';
import { PositiveParenting9Slide } from './positive_parenting_9';
import { PositiveParenting10Slide } from './positive_parenting_10';
import { PositiveParenting11Slide } from './positive_parenting_11';
import { PositiveParenting12Slide } from './positive_parenting_12';
import { PositiveParenting13Slide } from './positive_parenting_13';
import { PositiveParenting14Slide } from './positive_parenting_14';
import { PositiveParenting15Slide } from './positive_parenting_15';
import { PositiveParenting16Slide } from './positive_parenting_16';
import { PositiveParenting17Slide } from './positive_parenting_17';
import { PositiveParenting18Slide } from './positive_parenting_18';
import { PositiveParenting19Slide } from './positive_parenting_19';
import { PositiveParenting20Slide } from './positive_parenting_20';
import { PositiveParenting21Slide } from './positive_parenting_21';
import { PositiveParenting22Slide } from './positive_parenting_22';
import { PositiveParenting23Slide } from './positive_parenting_23';
import { PositiveParenting24Slide } from './positive_parenting_24';
import { PositiveParenting25Slide } from './positive_parenting_25';

export const positiveParentingSlides: Record<string, FC> = {
  // What is positive parenting?
  positive_parenting_1: PositiveParenting1Slide,
  positive_parenting_2: PositiveParenting2Slide,
  positive_parenting_3: PositiveParenting3Slide,

  // Encouragement & praise
  positive_parenting_4: PositiveParenting4Slide,
  positive_parenting_5: PositiveParenting5Slide,
  positive_parenting_6: PositiveParenting6Slide,
  positive_parenting_7: PositiveParenting7Slide,
  positive_parenting_8: PositiveParenting8Slide,

  // Relationship building
  positive_parenting_9: PositiveParenting9Slide,
  positive_parenting_10: PositiveParenting10Slide,
  positive_parenting_11: PositiveParenting11Slide,
  positive_parenting_12: PositiveParenting12Slide,
  positive_parenting_13: PositiveParenting13Slide,

  // Giving direction
  positive_parenting_14: PositiveParenting14Slide,
  positive_parenting_15: PositiveParenting15Slide,
  positive_parenting_16: PositiveParenting16Slide,
  positive_parenting_17: PositiveParenting17Slide,
  positive_parenting_18: PositiveParenting18Slide,

  // Using incentives and rewards
  positive_parenting_19: PositiveParenting19Slide,
  positive_parenting_20: PositiveParenting20Slide,
  positive_parenting_21: PositiveParenting21Slide,
  positive_parenting_22: PositiveParenting22Slide,
  positive_parenting_23: PositiveParenting23Slide,
  positive_parenting_24: PositiveParenting24Slide,
  positive_parenting_25: PositiveParenting25Slide,
};
