import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Fewer emotional problems.',
  'Improved brain development.',
  'Higher grades and school performance.',
  'Better behavior at home.',
  'More friends.',
];

const spanishList = [
  'Menos problemas emocionales',
  'Mejor desarrollo cerebral',
  'Calificaciones más altas y rendimiento escolar',
  'Mejor comportamiento en casa',
  'Más amistades',
];

export const RC_Clear_Rules_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Reglas Claras' : 'Clear Rules'}
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Establecer reglas y límites es una parte importante de la crianza de 
            los hijos. Le hacen saber a su hijo/a lo que quiere que haga. Los niños 
            que reciben reglas claras tienen:`
          : `Setting rules and limits are an important part of parenting. They let your
            child know what you want them to do. Children who are given clear rules
            have:`}
      </p>
      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
