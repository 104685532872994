import { FC } from 'react';
import { SelectOverlay } from '../SelectOverlay/SelectOverlay';

export const RoutineTask: FC<{
  task?: string;
  time?: number;
  className?: string;
  placeholder: string;
  onTaskChange(value: string): void;
  onTimeChange(value: string): void;
  openPortal(value: boolean): unknown;
  showSuggestions?: boolean;
  suggestionsTitle?: string;
  options?: number[];
}> = ({
  task,
  time = 0,
  className = '',
  placeholder,
  onTaskChange,
  onTimeChange,
  openPortal,
  showSuggestions = false,
  suggestionsTitle = 'Minutes',
  options = [],
  ...rest
}) => (
  <div className={`flex ${className}`} {...rest}>
    <textarea
      value={task}
      className="min-h-6 flex-1 border-2 border-r-0 border-gray-200 resize-none overflow-auto rounded-l-lg bg-white m-0 pt-4 pl-3 text-blue-900"
      placeholder={placeholder}
      onChange={(e) => onTaskChange(e.currentTarget.value)}
    ></textarea>
    <button
      className="flex justify-center w-24 items-center rounded-r-lg italic
      flex-shrink-0 bg-blue-700 text-white font-bold p-0 pl-4 pr-2"
      onClick={() => openPortal(true)}
    >
      {time} min
    </button>
    {showSuggestions && (
      <SelectOverlay
        title={suggestionsTitle}
        options={options.map((option) => ({
          value: `${option}`,
          label: `${option}`,
        }))}
        initValues={[`${time}`]}
        onValueChange={onTimeChange}
        openPortal={openPortal}
        value={`${time}`}
      />
    )}
  </div>
);
