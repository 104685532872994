import { FC } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { StatementCheckbox } from '../../../components/StatementCheckbox/StatementCheckbox';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import plantSlide1 from '../../../images/slides/middleschool/healthyBehavior/plantSlide1@2x.png';
import plantSlide2 from '../../../images/slides/middleschool/healthyBehavior/plantSlide2@2x.png';
import plantSlide3 from '../../../images/slides/middleschool/healthyBehavior/plantSlide3@2x.png';
import plantSlide4 from '../../../images/slides/middleschool/healthyBehavior/plantSlide4@2x.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishStatements = [
  'Great job checking in after school! I really like hearing about your day.',
  'Thank you for starting your homework before playing video games.',
  'I appreciate you keeping your phone in your pocket during dinner.',
];

const spanishStatements = [
  'Buen trabajo en chequear conmigo después de la escuela. Me gusta escuchar cómo te fue en el día.',
  'Gracias por comenzar tu tarea antes de jugar videojuegos.',
  'Aprecio que has mantenido tu teléfono en tu bolsa durante la cena.',
];

const plantSlides = [plantSlide1, plantSlide2, plantSlide3, plantSlide4];

export const Pospar_Encouragement_d: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue } = useActivityState({
    question1: false,
    question2: false,
    question3: false,
  });
  const { isSpanish } = useProductLanguage();

  const toggledCount =
    Number(value.question1) + Number(value.question2) + Number(value.question3);

  const allChecked = toggledCount === plantSlides.length - 1;

  const statements = isSpanish ? spanishStatements : englishStatements;

  return (
    <SlideContainer hideButton>
      <ActivityBox
        icon="pointer"
        className="flex-1 items-center mt-14 pt-10 px-7 text-blue-600"
        instructions={
          isSpanish
            ? `Su turno: Toque cada oración de elogio específico, y vea como 
              crece la cooperación de su hijo/a.`
            : `Your turn: Tap each specific praise statement, and watch your 
              child’s cooperation grow.`
        }
      >
        <div
          className="activityContainer flex flex-col flex-1 flex-shrink-0 items-center
          justify-between md:object-cover sm:object-cover"
        >
          <div>
            {Object.keys(value).map((key, idx) => {
              const valueKey = key as keyof typeof value;
              return (
                <StatementCheckbox
                  key={key}
                  className="mb-2"
                  onStatementClick={() =>
                    setValue({ ...value, [key]: !value[valueKey] })
                  }
                  statement={statements[idx]}
                  checked={value[valueKey]}
                />
              );
            })}
            <p className="text-sm font-bold text-center">
              {isSpanish
                ? '¡En lo que te enfocas crece!'
                : 'What you focus on grows!'}
            </p>
            <p className="text-red-600 text-sm font-medium text-center">
              {isSpanish
                ? '(enfócate en lo positivo)'
                : '(so focus on the positive)'}
            </p>
          </div>
          <img
            src={plantSlides[toggledCount]}
            alt="potted plant growing"
            className="w-56"
          />
        </div>
        <PillButton
          className={`w-56 self-center my-10 ${allChecked ? '' : 'invisible'}`}
          theme={ButtonTheme.YELLOW}
          disabled={!allChecked}
          onClick={next}
        >
          {isSpanish ? 'Termine la Actividad' : 'Finish Activity'}
        </PillButton>
      </ActivityBox>
    </SlideContainer>
  );
};
