import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse1Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Coping without alcohol and drugs'
          : 'Afrontar sin alcohol ni drogas'
      }
      className="flex-col text-white px-14 font-light"
    >
      {isEnglish ? (
        <>
          No matter where you are on your journey with substance use, the
          following information can help you parent in a positive way.
        </>
      ) : (
        <>
          No importa dónde se encuentre en su viaje con el uso de sustancias, la
          siguiente información puede ayudarle a ser padre de una manera
          positiva.
        </>
      )}
    </SlideContainer>
  );
};
