import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import { useSlideContext } from '../../../contexts/slideContext';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const positiveParentingCheckboxItems = [
  'Praise and reward your child',
  'Smile at your child',
  'Spend quality time with your child',
  "Pay attention to your child's good behavior",
];

const spanishPositiveParentingCheckboxItems = [
  'Elogiar y premiar a su niño/a',
  'Sonreír a su niño/a',
  'Pasar tiempo con su niño/a',
  'Poner atención a los buenos comportamientos de su niño/a',
];

export const PositiveParenting2Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, isReview, activity, hasInteracted } =
    useActivityState<Record<string, boolean>>({});

  const checkboxItems = isEnglish
    ? positiveParentingCheckboxItems
    : spanishPositiveParentingCheckboxItems;
  const answeredCorrectly =
    Object.values(value).length === checkboxItems.length;

  const correctAnswerText = isEnglish
    ? 'You really know what it takes to parent in a positive way!'
    : '¡Ud. sabe lo que se necesita para criar a sus hijos en una manera positiva!';

  const incorrectAnswerText = isEnglish
    ? "'Actually, all of these are examples of positive parenting.'"
    : 'En realidad, todos estos son ejemplos de crianza positiva.';
  return (
    <SlideContainer lightBg lightProgressBar hideButton className="flex-col">
      <ActivityBox
        className="items-center mt-14 pt-10 pb-6 flex-1"
        icon="checkbox"
        instructions={
          isEnglish
            ? 'Your turn: Check all the boxes that describe what it means to use positive parenting.'
            : 'Su turno: marque todas las cajas que describen lo que significa usar la crianza positiva.'
        }
      >
        <FloatingCard className="py-4 px-5 mb-10 w-full">
          {checkboxItems.map((positive, index) => {
            const indexKey = index.toString();
            return (
              <SlideCheckbox
                key={positive}
                label={positive}
                checked={value[indexKey]}
                onChange={(event) =>
                  setValue({
                    ...value,
                    [indexKey]: event.currentTarget.checked,
                  })
                }
                className={index + 1 !== checkboxItems.length ? 'mb-4' : ''}
              />
            );
          })}
        </FloatingCard>
        <PillButton
          className="mt-auto w-52"
          theme={ButtonTheme.YELLOW}
          onClick={() =>
            isReview || !hasInteracted ? next() : setShowOverlay(true)
          }
        >
          {isEnglish ? 'Submit' : 'Entregar'}
        </PillButton>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        badgeLocation="right"
        icon={answeredCorrectly ? 'thumbs-up' : 'alert'}
        activityMessage={
          answeredCorrectly ? correctAnswerText : incorrectAnswerText
        }
        showConfetti={answeredCorrectly}
        bearCharacter="BABY_BEAR"
      />
    </SlideContainer>
  );
};
