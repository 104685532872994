import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare3Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Self-Care' : 'Autocuidado'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish
          ? 'When your adult needs are being met, it’s easier to maintain your overall well-being, and to have a strong loving relationship with your kids.'
          : 'Cuando se satisfacen sus necesidades de adulto, es más fácil mantener su bienestar general y tener una relación sólida y amorosa con sus hijos.'}
      </p>
    </SlideContainer>
  );
};
