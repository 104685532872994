import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Stress_b: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Bienestar emocional y afrontamiento del estrés'
          : 'Emotional well-being and coping with stress'
      }
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Su hijo/a puede sentir triste, estresado/a, ansioso/a o incluso
          deprimido/a. Los signos de que su hijo/a puede necesitar apoyo
          adicional incluyen:`
        : `Your child may feel sad, stressed, anxious or even depressed. Signs
          that your child may need extra support include:`}
      <h3 className="text-red-600 font-bold my-5">
        {isSpanish
          ? 'Toque el signo más para aprender más.'
          : 'Tap on the plus below to learn more'}
      </h3>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cambios físicos' : 'Physical Changes'}
          </b>
        }
      >
        {isSpanish
          ? `Dormir mucho o poco, comer mucho o poco, dolores de cabeza, dolores
            de estómago, y dolores o molestias frecuentes.`
          : `Sleeping a lot or a little, eating a lot or a little, headaches, stomachaches, frequent aches and pains.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cambios emocionales' : 'Emotional Changes'}
          </b>
        }
      >
        {isSpanish
          ? 'Tristeza, enojo, miedo, preocupación, soledad, culpa y vergüenza.'
          : 'Sadness, anger, fear, worry, loneliness, guilt, and shame'}
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cambios en comportamiento' : 'Changes in behavior'}
          </b>
        }
      >
        {isSpanish
          ? `Pérdida de interes en actividades, calificaciones bajas,
            comporatmiento irritable o controlador.`
          : `Losing interest in activities, poor grades, irritable or controlling
            behavior.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 3}
        onExpand={() => handleOpen(3)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cambios en los pensamientos' : 'Changes in thinking'}
          </b>
        }
      >
        {isSpanish
          ? `Pensamientos o fijaciones constantes en temas específicos,
            preocupacion y dificultad tomando decisiones.`
          : `Constant thoughts or fixations on specific subjects, worried and
            having a hard time making decisions. `}
      </PlusExpandable>
      <PlusExpandable
        open={open === 4}
        onExpand={() => handleOpen(4)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? 'Cambios en sus relaciones familiares'
              : 'Changes in relationships'}
          </b>
        }
      >
        {isSpanish
          ? `Aislamiento de amigos o familiares o cambios repentinos en las
            relaciones con amigos o familiares.`
          : `Isolation from friends or family or sudden changes in relationships
            with friends or family.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 5}
        onExpand={() => handleOpen(5)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cuestionando la identidad' : 'Questioning identity'}
          </b>
        }
      >
        <p className="mb-5">
          {isSpanish
            ? `Los adolescentes pueden luchar emocionalmente cuando están formando
              sus identidades personales y se vuelven cada vez más independientes.
              Cuando experimentan este proceso, los adolescentes pueden preocuparse
              por ser rechazados por otros. Pueden sentir la presión de sus amigos
              para participar en comportamientos riesgosos. Aquí es donde los
              cuidadores juegan un papel importante. Las investigaciones muestran
              que cuando los padres apoyan y hablan abiertamente con sus hijos sobre
              sus experiencias es más probable que los hijos hagan buenas
              decisiones y crezcan para ser adultos felices y saludables.`
            : `Adolescents can struggle emotionally as they form their personal 
              identity and become increasingly independent. As they go through this
              process, teens may worry about being rejected by others. They may 
              experience pressure from peers to engage in risky behaviors. This is
              where caregivers play an important role. Research shows that when
              parents engage in open and supportive conversations with their
              children about their experiences, they are more likely to make good
              choices and grow up to be happy and healthy adults.`}
        </p>
      </PlusExpandable>
      <p className="pt-6 flex-1">
        {isSpanish
          ? `Estas son experiencias comunes que los niños pueden tener durante
            momentos estresantes. Hay muchas cosas que puede hacer para apoyar a
            su hijo/a.`
          : `These are common experiences children can have during stressful
            times. There are lots of things you can do to support your child.`}
      </p>
    </SlideContainer>
  );
};
