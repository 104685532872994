import { FC, useState } from 'react';
import { ChevronCircleButton } from '../../../components/ChevronCircleButton/ChevronCircleButton';
import { PlanLetter } from '../../../components/PlanLetter/PlanLetter';
import { LETTER_OPTIONS } from 'shared/lib/types/PlanLetterOption';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { letterContent } from '../../../constants/early_childhood/proactive_parenting_2_content';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting2Slide: FC = () => {
  const [planning, setPlanning] = useState(false);
  const [letterOptionIndex, setLetterOptionIndex] = useState(0);
  const { isEnglish } = useProductLanguage();

  const currentPlanLetter = LETTER_OPTIONS[letterOptionIndex];

  const { statement, listItem, spanishStatement, spanishListItem } =
    letterContent[currentPlanLetter];

  const endOfContent = letterOptionIndex + 1 === LETTER_OPTIONS.length;

  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Do you know how to plan?' : '¿Sabe planificar?'}
      className="flex-col items-center text-white px-14 pt-6 font-light"
      hideButton={!endOfContent}
    >
      {!planning ? (
        <>
          <div className="flex mb-10">
            {LETTER_OPTIONS.map((option, index) => (
              <PlanLetter
                key={option}
                letterOption={option}
                style={{
                  margin:
                    index === 1
                      ? '0 5px 0 10px'
                      : index === 2
                      ? '0 10px 0 5px'
                      : '',
                }}
              />
            ))}
          </div>
          <ChevronCircleButton
            className="w-13 h-13"
            onClick={() => setPlanning(true)}
          />
        </>
      ) : (
        <>
          <div className="relative mb-16">
            <PlanLetter
              letterOption={currentPlanLetter}
              className="w-36 h-36"
              activeClassName="border-3"
              letterClassName="text-6xl"
            />
            {!endOfContent && (
              <ChevronCircleButton
                className="w-13 h-13 absolute -bottom-6 right-0 left-0 mx-auto"
                onClick={() => setLetterOptionIndex((curIndex) => curIndex + 1)}
              />
            )}
          </div>
          <div className="flex flex-col">
            {isEnglish ? statement : spanishStatement}
            <YellowCircleList className="mt-2.5">
              {isEnglish ? listItem : spanishListItem}
            </YellowCircleList>
          </div>
        </>
      )}
    </SlideContainer>
  );
};
