import { FC, ComponentPropsWithoutRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export enum ButtonTheme {
  PURPLE = 'purple',
  YELLOW = 'yellow',
  LIGHT_BLUE = 'lightBlue',
  WHITE = 'white',
  SKY_BLUE = 'skyBlue',
}

const getButtonTheme = (theme: ButtonTheme, selected?: boolean) => {
  const options = {
    purple: ` border-5 bg-purple text-white border-blue-950 can-hover:hover:bg-purple-800 ${
      selected ? 'bg-purple-800' : ''
    } `,
    yellow: ` border-5 bg-yellow-secondary border-blue-900 text-blue-900 can-hover:hover:bg-coolGray-600 can-hover:hover:bg-opacity-60 ${
      selected ? 'bg-coolGray-600 bg-opacity-60' : ''
    }`,
    lightBlue: ` border-5 bg-blue-400 border-blue-900 text-white can-hover:hover:bg-blue-500 ${
      selected ? 'bg-blue-500' : ''
    }`,
    white: `bg-white text-blue-900 can-hover:hover:bg-blue-100 border-2 border-blue-400 ${
      selected ? 'bg-blue-100' : ''
    }`,
    skyBlue: `bg-white text-blue-900 can-hover:hover:bg-blue-200 border-2 border-blue-450 ${
      selected ? 'bg-blue-200' : ''
    }`,
  };

  return options[theme];
};

export interface PillButtonProps {
  theme?: ButtonTheme;
  disableXPadding?: boolean;
  selected?: boolean;
}

export const PillButton: FC<
  PillButtonProps & ComponentPropsWithoutRef<'button'>
> = (props) => {
  const classNames = useMemo(() => getPillButtonClassNames(props), [props]);
  return <button {...props} className={classNames} />;
};

export const PillLink: FC<PillButtonProps & ComponentPropsWithoutRef<Link>> = (
  props,
) => <Link {...props} className={getPillButtonClassNames(props)} />;

function getPillButtonClassNames({
  theme = ButtonTheme.PURPLE,
  disabled = false,
  className = '',
  disableXPadding,
  selected,
}: PillButtonProps & {
  disabled?: boolean;
  className?: string;
}): string {
  return twMerge(
    !disableXPadding && 'px-10',
    'py-4 text-lg text-center transition-all',
    'rounded-full font-medium',
    disabled && 'opacity-50 cursor-default',
    getButtonTheme(theme, selected),
    className,
  );
}
