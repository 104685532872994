import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare2Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Self-Care' : 'Autocuidado'}
      className="flex-col px-14"
    >
      <YellowCircleList>
        <li>
          <b>
            {isEnglish ? 'Identify activities ' : 'Identifique actividades '}
          </b>
          {isEnglish
            ? 'and practices that support your well-being and help you to sustain positive self-care now and into the future.'
            : 'y prácticas que respalden su bienestar y lo ayuden a mantener un autocuidado positivo ahora y en el futuro.'}
        </li>
        <li>
          <b>{isEnglish ? 'Build a network ' : 'Construya una red '}</b>
          {isEnglish
            ? 'of support, especially with friends who have children the same age as yours. Talking to someone with similar experiences can make you feel less alone.'
            : 'de apoyo, especialmente con amigos que tengan hijos de la misma edad que los de usted. Hablar con alguien con experiencias similares puede hacerle sentirse menos aislado.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
