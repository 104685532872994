import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import stock1Src from '../../../images/stock/stock_1.png';
import stock2Src from '../../../images/stock/stock_2.png';
import stock3Src from '../../../images/stock/stock_3.png';
import stock4Src from '../../../images/stock/stock_4.png';

export const WellnessAndSelfCare17Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Healthy Routines' : 'Rutinas Saludables'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish ? (
          <>
            When you have a routine, it’s easier to parent well. It helps you
            stay organized and on track, and it helps your child know what to
            expect.
          </>
        ) : (
          <>
            Cuando tiene una rutina, es más fácil criar bien. Le ayuda a
            mantenerse organizado/a, y ayuda a su hijo a saber qué esperar.
          </>
        )}
      </p>
      <h3 className="font-bold mt-4">
        {isEnglish ? 'Routines also:' : 'Rutinas también:'}
      </h3>
      <ul className="mt-4">
        {listItems.map((item, i) => (
          <li
            key={i}
            className={`flex flex-row items-center font-bold ${
              i > 0 ? 'mt-4' : ''
            }`}
          >
            <img
              className="w-14 h-14 flex-shrink-0 mr-4"
              alt={item.alt}
              src={item.image}
            />
            {isEnglish ? item.englishText : item.spanishText}
          </li>
        ))}
      </ul>
    </SlideContainer>
  );
};

const listItems = [
  {
    englishText: 'Reduce stress, depression & anxiety',
    spanishText: 'Reduce el estrés, la depresión y la ansiedad.',
    image: stock1Src,
    alt: 'woman relaxing in the sun',
  },
  {
    englishText:
      'Improve cognitive, social, and emotional development of children',
    spanishText:
      'Mejora el desarrollo cognitivo, social y emocional de los niños.',
    image: stock2Src,
    alt: 'child with hands on a paper heart',
  },
  {
    englishText: 'Improve quality time with children',
    spanishText: 'Mejora el tiempo de calidad con los niños.',
    image: stock3Src,
    alt: 'woman with daughter',
  },
  {
    englishText: 'Reduce substance use',
    spanishText: 'Reduce el consumo de sustancias.',
    image: stock4Src,
    alt: 'weary woman',
  },
];
