export const doOrDontQuiz = {
  storeSubstancesOutOfReachOfChild: {
    answer: true,
    question: 'Store substances out-of-reach of children.',
    feedback: `This is a safety DO. Storing substances out-of-reach prevents 
      children from accidental exposure to substances.`,
    spanishQuestion: 'Guarde la sustancias fuera del alcance de los niños.',
    spanishFeedback:
      'Esto SÍ es seguro. El almacenamiento de sustancias fuera del alcance evita que los niños se expongan accidentalmente a las sustancias.',
  },
  smokeInSameRoomAsChild: {
    answer: false,
    question: 'Smoke in the same room as your child.',
    feedback: `This is a safety DON’T. Secondhand smoke causes numerous 
      health problems in infants and children; there is no risk-free level 
      of exposure to secondhand smoke.`,
    spanishQuestion: 'Fumar en la misma habitación que su hijo/a.',
    spanishFeedback:
      'Esto NO es seguro. El humo de segunda mano causa numerosos problemas de salud en bebés y niños; no existe un nivel libre de riesgo de exposición al humo de segunda mano.',
  },
  smokeInRoomWhereChildSpendsTime: {
    answer: false,
    question: 'Smoke in the rooms that your child spends time in.',
    feedback: `This is a safety DON’T. Secondhand smoke causes numerous health 
      problems in infants and children and can linger in rooms afterward; 
      there is no risk-free level of exposure to secondhand smoke.`,
    spanishQuestion: 'Fumar en las habitaciones donde su hijo/a pasa tiempo.',
    spanishFeedback:
      'Esto NO es seguro. El humo de segunda mano causa numerosos problemas de salud en bebés y niños y puede permanecer en las habitaciones después; no existe un nivel libre de riesgo de exposición al humo de segunda mano.',
  },
  storeSubstancesWithChildSafeLocks: {
    answer: true,
    question: 'Store substances in cabinets with child-safe locks.',
    feedback: `This is a safety DO. Using child-safe locks prevents children 
      from accidental exposure to substances.`,
    spanishQuestion:
      'Guarde las sustancias en armarios con cerraduras a prueba de niños.',
    spanishFeedback:
      'Esto SÍ es seguro. El uso de seguros para niños evita que los niños se expongan accidentalmente a sustancias.',
  },
  useSubstancesWhenOnlyParent: {
    answer: false,
    question:
      'Use substances when you are the only person available to care for your child.',
    feedback: `This is a safety DON’T. Only use substances if a second caregiver is 
      available to care for children.`,
    spanishQuestion:
      'Use sustancias cuando sea la única persona disponible para cuidar a su hijo/a.',
    spanishFeedback:
      'Esto NO es seguro. Solo use sustancias si hay un segundo cuidador disponible para cuidar a los niños.',
  },
  takeSmokeBreaksOutside: {
    answer: true,
    question: 'Take smoke breaks outside (away from children).',
    feedback: `This is a safety DO. Secondhand smoke causes numerous 
      health problems in infants and children; there is no risk-free 
      level of exposure to secondhand smoke.`,
    spanishQuestion: 'Tome descansos para fumar afuera (lejos de los niños).',
    spanishFeedback:
      'Esto SÍ es seguro. El humo de segunda mano causa numerosos problemas de salud en bebés y niños; no existe un nivel libre de riesgo de exposición al humo de segunda mano.',
  },
  useSubstancesWhenOtherCaregiverExists: {
    answer: true,
    question:
      'Only use substances if a second caregiver is available to care for children',
    feedback: `This is a safety DO. Make sure another person is available to care 
      for your child if you are using substances.`,
    spanishQuestion:
      'Solo use sustancias si hay un segundo cuidador disponible para cuidar a sus hijos.',
    spanishFeedback:
      'Esto SI es seguro. Asegurarse que otra persona esta disponible para cuidar a su niño/a en caso que este usando sustancias.',
  },
  surroundYourselfWithSupportingPeople: {
    answer: true,
    question:
      'Surround yourself with people who support you not using substances while parenting.',
    feedback: `This is a safety DO. Having a support system that encourages you to avoid 
      substance use when parenting is a healthy choice for both you and your child.`,
    spanishQuestion:
      'Rodése de personas que le ayuden a no consumir sustancias mientras esta cuidando de sus niños.',
    spanishFeedback:
      'Esto SÍ es seguro. Tener un sistema de apoyo que lo aliente a evitar el uso de sustancias durante la crianza es una opción saludable tanto para usted como para su hijo/a.',
  },
  maintainRelationshipsWithTemptingPeople: {
    answer: false,
    question:
      'Maintain close relationships with people who tempt you to use substances while parenting.',
    feedback: `This is a safety DON’T. Having a support system that encourages you to avoid 
      substance use when parenting is a healthy choice for both you and your child.`,
    spanishQuestion:
      'Mantenga relaciones cercanas con personas que lo tienten a usar sustancias durante la crianza.',
    spanishFeedback:
      'Esto NO es seguro. Tener un sistema de apoyo que lo aliente a evitar el uso de sustancias durante la crianza es una opción saludable tanto para usted como para su hijo/a.',
  },
};
