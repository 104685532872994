import { FC, useState } from 'react';
import { ChevronCircleButton } from '../../../components/ChevronCircleButton/ChevronCircleButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { StarIcon } from '../../../components/StarIcon/StarIcon';
import depressedPersonSrc from '../../../images/depressed_person.png';
import scatteredPillsSrc from '../../../images/scattered_pills.png';
import childWithArmsCrossedSrc from '../../../images/child_with_arms_crossed.png';
import childWithSnowmanToySrc from '../../../images/child_with_snowman_toy.png';
import crawlingBabySrc from '../../../images/crawling_baby.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

enum SubstanceImpact {
  START,
  DEPRESSION,
  SUBSTANCE_FOCUS,
  IGNORING_CHILD,
  NO_CHILD_PLAY,
  NO_ATTENTION_TO_CHILD,
}

export const ParentingAndSubstanceUse16Slide: FC = () => {
  const [impactStep, setImpactStep] = useState<SubstanceImpact>(
    SubstanceImpact.START,
  );
  const { isEnglish } = useProductLanguage();
  const { englishContent, spanishContent, img } = substanceContent[impactStep];

  const endOfContent = impactStep + 1 === substanceContent.length;

  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Parenting Responsibilities'
          : 'Responsabilidades de crianza'
      }
      className="flex-col text-white px-14"
      hideButton={!endOfContent}
    >
      <h2 className="font-bold">
        {isEnglish
          ? 'How does substance use impact your parenting?'
          : '¿Cómo afecta el uso de sustancias a su crianza?'}
      </h2>
      <div className="flex items-start mt-4">
        <StarIcon className="flex-shrink-0" />
        <p className="ml-2.5 -mt-1 text-sm font-light">
          {isEnglish ? (
            <>
              Many parents may have struggled with parenting and substance use.
              Think about some times that you have been using substances while
              trying to parent your child
            </>
          ) : (
            <>
              Muchos padres pueden haber tenido problemas con la crianza y el
              uso de sustancias. Piense en algunas ocasiones en las que ha
              estado usando sustancias mientras intentaba criar a su hijo/a.
            </>
          )}
        </p>
      </div>
      <div className="relative flex flex-col flex-1 mt-5 pr-5">
        {!endOfContent && (
          <ChevronCircleButton
            className="absolute h-13 w-13 z-10 top-0 right-0"
            onClick={() =>
              setImpactStep((step) => (endOfContent ? step : step + 1))
            }
          />
        )}
        {impactStep === SubstanceImpact.START && (
          <div
            className={`${
              isEnglish ? 'w-44' : 'w-56'
            } relative bg-blue-1000 pl-5 py-4 rounded-full text-white self-end`}
          >
            {isEnglish ? 'tap to begin' : 'toque para empezar'}
          </div>
        )}
        {impactStep !== SubstanceImpact.START && (
          <h4 className="text-blue-200 font-semibold mb-2">
            {isEnglish ? 'Have you noticed:' : 'Ha notado que:'}
          </h4>
        )}
        <p className="w-52">{isEnglish ? englishContent : spanishContent}</p>
        <img
          src={img.src}
          className="w-7/12 h-auto self-center mt-5 mb-10"
          alt={img.alt}
        />
      </div>
    </SlideContainer>
  );
};

const substanceContent = [
  {
    englishContent: undefined,
    spanishContennt: undefined,
    img: { src: undefined, alt: undefined },
  },
  {
    englishContent: 'Feeling down or depressed?',
    spanishContent: '¿Se siente decaído/a o deprimido/a?',
    img: { src: depressedPersonSrc, alt: 'person crying' },
  },
  {
    englishContent: 'Focusing on substance use instead of your child?',
    spanishContent: '¿Se enfoca en el uso de sustancias en lugar de su hijo/a?',
    img: { src: scatteredPillsSrc, alt: 'scattered pills' },
  },
  {
    englishContent: 'Ignoring your child when they need you?',
    spanishContent: '¿Ignora a su hijo/a cuando le necesitan?',
    img: { src: childWithArmsCrossedSrc, alt: 'child with arms crossed' },
  },
  {
    englishContent: 'Not playing with your child?',
    spanishContent: '¿No juega con su hijo/a?',
    img: { src: childWithSnowmanToySrc, alt: 'child with snowman toy' },
  },
  {
    englishContent: 'Not paying attention to your child?',
    spanishContent: '¿No le presta atención a su hijo/a?',
    img: { src: crawlingBabySrc, alt: 'crawling baby' },
  },
];
