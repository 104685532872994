import { FC, useState, useEffect } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import plantImage1 from '../../../images/slides/middleschool/healthyBehavior/plantSlide1@2x.png';
import plantImage2 from '../../../images/slides/middleschool/healthyBehavior/plantSlide2@2x.png';
import plantImage3 from '../../../images/slides/middleschool/healthyBehavior/plantSlide3@2x.png';
import plantImage4 from '../../../images/slides/middleschool/healthyBehavior/plantSlide4@2x.png';

export const RulesAndConsequences21Slide: FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isEnglish } = useProductLanguage();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((curIndex) => {
        if (curIndex + 1 !== plantImages.length) {
          return curIndex + 1;
        } else {
          clearInterval(interval);
          return curIndex;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { img, alt } = plantImages[currentImageIndex];

  return (
    <SlideContainer className="text-white flex-col items-center font-light">
      <p className="mb-9 px-14">
        {isEnglish ? (
          <>
            You learned that when you focus on your child’s positive behavior,
            and praise them for it, you increase the likelihood that they will
            behave the way you want them to. The same thing is true when you
            focus on your child’s unwanted behavior—another reason for you to
            get good at ignoring!
          </>
        ) : (
          <>
            Ud. aprendió que cuando se enfoca en el comportamiento positivo de
            su hijo/a y lo elogio/a por ello, aumenta la probabilidad de que se
            comporte de la manera que usted desea. Lo mismo es cierto cuando se
            enfoca en el comportamiento no deseado de su hijo/a: ¡otra razón
            para que se vuelva bien en ignorar!
          </>
        )}
      </p>
      <img src={img} alt={alt} className="w-53" />
    </SlideContainer>
  );
};

const plantImages = [
  {
    img: plantImage1,
    alt: 'pot on counter',
  },
  {
    img: plantImage2,
    alt: 'pot with seedling growing',
  },
  {
    img: plantImage3,
    alt: 'pot with plant blooming',
  },
  {
    img: plantImage4,
    alt: 'pot with plant in full bloom',
  },
];
