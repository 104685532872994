import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting22Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_03'
          : 'early_childhood_spanish_Ani_03'
      }
      slideTitle={
        isEnglish
          ? 'How to use incentives and rewards'
          : 'Cómo usar incentivos y recompensas'
      }
      videoTitle={
        <h3 className="text-lg text-blue-800">
          {isEnglish
            ? 'Notice positive behavior'
            : 'Reconocer el comportamiento positivo'}
        </h3>
      }
      upperSlideContent={
        <p className="px-14 text-white font-light">
          {isEnglish ? (
            <>
              You start by knowing what motivates your child. To make it fun,
              you can work with your child to come up with a list of rewards you
              can give.
            </>
          ) : (
            <>
              Empiece por saber qué motiva a su niño/a. Para que sea divertido,
              puede trabajar con su niño/a para crear una lista de recompensas
              que le puede dar.
            </>
          )}
        </p>
      }
      bearCharacter="BABY_BEAR"
    />
  );
};
