import { FC } from 'react';
import { Modal } from '../Modal/Modal';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import bearManSrc from '../../images/bears/BearMan@3x.png';
import { Blurb } from '../Blurb/Blurb';
import { PillButton, PillLink } from '../PillButton/PillButton';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const WelcomeBackOverlay: FC<{
  onContinue(): unknown;
  onClose(): unknown;
}> = ({ onContinue, onClose, ...rest }) => {
  useBodyOverflowHidden();
  const { isSpanish } = useProductLanguage();

  return (
    <Modal>
      <div
        {...rest}
        className="z-40 absolute top-0 left-0 min-h-screen w-screen flex flex-col justify-between text-center bg-blue-400"
      >
        <h1 className="text-white text-5xl mt-36">
          {isSpanish ? '¡Bienvenido de nuevo!' : 'Welcome back!'}
        </h1>

        <p className="mt-8">
          {isSpanish ? 'Quieres continuar' : 'Do you want to continue'} <br />
          {isSpanish
            ? 'donde lo dejaste o te diriges a'
            : 'where you left off or head to'}{' '}
          <br />
          {isSpanish ? 'el tablero?' : 'the dashboard?'}
        </p>

        <div className="flex flex-col w-60 self-center mt-8">
          <PillButton
            onClick={() => {
              onContinue();
              onClose();
            }}
          >
            {isSpanish ? 'Siguiente' : 'Continue'}
          </PillButton>
          <PillLink onClick={onClose} to="/" className="mt-4">
            {isSpanish ? 'Tablero' : 'Dashboard'}
          </PillLink>
        </div>

        <div className="flex flex-row justify-center mt-8">
          <img src={bearManSrc} alt="Bear man" className="w-48 md:w-60" />
          <Blurb className="p-10 px-14">
            {isSpanish
              ? 'Me alegro de que hayas vuelto.'
              : "I'm glad you're back."}
          </Blurb>
        </div>
      </div>
    </Modal>
  );
};
