import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import prepareFoodSrc from '../../../images/FCU_kitchen_cooking.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Morning_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas de la mañana' : 'Morning Routines'}
      className="flex-col font-light text-white"
    >
      <p className="px-14 mb-10">
        {isSpanish
          ? `¡Guau! Tienen muchas cosas que hacer en la mañana. Tratemos de determinar 
            algunas cosas que se pueden hacer la noche anterior- como elegir la ropa 
            o hacer el almuerzo.`
          : `Wow! You've got a lot of things to do in the morning. Let's try coming up
            with some things your child can do the night before--like choosing their
            clothes or making their lunch.`}
      </p>
      <img
        src={prepareFoodSrc}
        alt={
          isSpanish
            ? 'niños preparando comida en la cocina'
            : 'kids preparing food in the kitchen'
        }
        className="w-full"
      />
    </SlideContainer>
  );
};
