import { FC } from 'react';
import AnimatedOverlay, {
  HEIGHT_MAP,
} from '../AnimatedOverlay/AnimatedOverlay';
import { PillButton } from '../PillButton/PillButton';
import { useProductLanguage } from '../../hooks/useProductLanguage';

const ActivityOverlay: FC<{
  open: boolean;
  onNext(): void;
  height?: keyof typeof HEIGHT_MAP;
}> = ({ open, onNext, height, children }) => {
  const { isSpanish } = useProductLanguage();
  return (
    // Root
    <AnimatedOverlay height={height} open={open}>
      {/* Overlay Column */}
      <div className="relative flex flex-col h-full w-160 max-w-full bg-blue-200 my-0 mx-auto rounded-t-lg">
        <div className="h-leftover">{children}</div>
        {/* Footer */}
        <div className="absolute bottom-0 h-32 w-full flex justify-center items-center bg-blue-900">
          <PillButton
            onClick={onNext}
            className={isSpanish ? 'w-48' : undefined}
          >
            {isSpanish ? 'Siga' : 'Continue'}
          </PillButton>
        </div>
      </div>
    </AnimatedOverlay>
  );
};

export default ActivityOverlay;
