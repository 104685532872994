import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare7Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Managing Depression' : 'Manejar La Depresión'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish ? (
          <>
            The quickest way to improve your mood is to do something you enjoy.
            Activities take your mind off your problems, and help you feel
            better.
          </>
        ) : (
          <>
            La manera más rápida de mejorar su estado de ánimo es hacer algo que
            disfruta. Las actividades distraen su mente de sus problemas y lo
            ayudan a sentirse mejor.
          </>
        )}
      </p>
      <p>
        {isEnglish
          ? 'Plan things you can do easily that:'
          : 'Planifique cosas que pueda hacer fácilmente que:'}
      </p>
      <YellowCircleList className="mt-4 font-bold">
        <li>{isEnglish ? 'Are nearby' : 'Están cerca.'}</li>
        <li>{isEnglish ? 'Don’t cost much' : 'No cuesta mucho.'}</li>
        <li>
          {isEnglish
            ? 'Can be done anytime'
            : 'Se puede hacer en cualquier momento.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
