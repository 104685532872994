import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Sleep_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas de Sueño' : 'Sleep Routines'}
      className="px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `¡Una parte importante de despertarse a la hora es dormir lo 
            suficiente! Los niños necesitan un promedio de 9-10 horas de 
            sueño por noche. Cuando su hijo/a descansa bien, lo hace más 
            fácil para que él/ella haga otras actividades saludables 
            durante el día, incluso el concentrarse en la escuela.`
          : `An important part of getting up on time is getting enough sleep!
            School-aged children need an average of 9–10 hours of sleep per night.
            When your child is well-rested, it makes it easier for them to do other
            healthy activities during the day, including concentrate at school.`}
      </p>
    </SlideContainer>
  );
};
