import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Logical_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Consecuencias Lógicas' : 'Logical Consequences'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `Cuando su hijo/a no coopera con sus reglas, y esto sucederá con todos 
            los niños, debería recibir una consecuencia. La forma más eficaz de 
            respaldar sus reglas claras es utilizar consecuencias lógicas.`
          : `When your child does not cooperate with your rules — and this will happen
            with all children — they should receive a consequence. The most effective
            way to support your clear rules is to use logical consequences.`}
      </p>
      <p>
        {isSpanish
          ? `Las consecuencias lógicas tienen sentido porque están vinculadas al 
            comportamiento que está tratando de cambiar. Pueden incluir cosas como 
            perder un privilegio o hacer una tarea.`
          : `Logical consequences make sense because they are linked to the behavior
            you are trying to change. They can include things like losing a
            privilege, or doing a chore.`}
      </p>
    </SlideContainer>
  );
};
