import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import tipBagSpanishSrc from '../../../images/parenting_and_substance_use_tip_bag_spanish.png';
import tipBagEnglishSrc from '../../../images/parenting_and_substance_use_tip_bag_english.png';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import {
  parentingAndSubstanceUseCravingTips,
  spanishParentingAndSubstanceUseCravingTips,
} from '../../../constants/early_childhood/parenting_and_substance_use_8_tips';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse8Slide: FC = () => {
  const [currentCravingIndex, setCurrentCravingIndex] = useState(0);
  const { isReview, activity, value, setValue, hasInteracted } =
    useActivityState<Record<string, boolean>>({});
  const [showOverlay, setShowOverlay] = useState(false);
  const { next, viewOnly } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const { title, example } = isEnglish
    ? parentingAndSubstanceUseCravingTips[currentCravingIndex]
    : spanishParentingAndSubstanceUseCravingTips[currentCravingIndex];

  const endOfCravingTips =
    currentCravingIndex + 1 === parentingAndSubstanceUseCravingTips.length;

  const tipBagSrc = isEnglish ? tipBagEnglishSrc : tipBagSpanishSrc;

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1"
        icon="pointer"
        instructions={
          isEnglish
            ? 'Your turn: Tap on the bag for quick help to get you through a strong craving. Tap multiple times.'
            : 'Su turno: cada vez que toque la bolsa, obtendrá un consejo para ayudarle a superar un fuerte antojo.'
        }
      >
        <FloatingCard
          className="w-full pt-6 pb-2.5 px-2.5 flex flex-col 
          items-center text-center min-h-36 mb-2"
        >
          <h3 className="text-blue-400 mb-2.5 text-sm">{title}</h3>
          <p className="font-light text-blue-800">{example}</p>
        </FloatingCard>
        <button
          className="w-full mb-10"
          onClick={() => {
            if (!value.bool) {
              setValue({ bool: true });
            }
            setCurrentCravingIndex((curIndex) =>
              // loop back around through tips
              !endOfCravingTips ? curIndex + 1 : 0,
            );
          }}
        >
          <img src={tipBagSrc} alt="paper bag" className="w-full" />
        </button>
        {(hasInteracted || isReview) && !viewOnly && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={() => (isReview ? next() : setShowOverlay(true))}
          >
            {isEnglish ? 'Next' : 'Siguiente'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        activityPoints={activity.pointValue}
        bearCharacter="YOUNG_GIRL_THUMBS_UP"
        onNext={next}
      />
    </SlideContainer>
  );
};
