import { createContext, FC, useCallback, useContext, useState } from 'react';
import { Org } from 'shared/lib/types/Org';
import { missingProvider } from 'shared/lib/utils/errors';
import { UserRoleKind } from 'shared/lib/types/UserRole';
import { api } from '../api';
import { useUser } from './userContext';
import { noop } from 'shared/lib/utils/noop';
import { PostTestUnlockWeek } from 'shared/lib/constants/post-tests/PostTestUnlockWeek';

export interface OrgContextValue {
  org: Org;
  orgPath: string;

  updateOrg(
    fields: Omit<Parameters<typeof api.updateOrg>[0], 'orgId'>,
  ): ReturnType<typeof api.updateOrg>;

  updateOrgSelfEnrollmentDefaults(
    fields: Omit<
      Parameters<typeof api.updateOrgSelfEnrollmentDefaults>[0],
      'orgId'
    >,
  ): ReturnType<typeof api.updateOrgSelfEnrollmentDefaults>;

  deleteOrg(): ReturnType<typeof api.deleteOrg>;

  /**
   * Manage locations
   */
  listOrgLocations(): ReturnType<typeof api.listOrgLocations>;

  createOrgLocation(
    fields: Omit<Parameters<typeof api.createOrgLocation>[0], 'orgId'>,
  ): ReturnType<typeof api.createOrgLocation>;

  updateOrgLocation(
    fields: Omit<Parameters<typeof api.updateOrgLocation>[0], 'orgId'>,
  ): ReturnType<typeof api.updateOrgLocation>;

  deleteOrgLocation(
    locationId: number,
  ): ReturnType<typeof api.deleteOrgLocation>;

  /**
   * Manage staff
   */
  listOrgStaff(): ReturnType<typeof api.listOrgStaff>;

  listOrgCoaches(): ReturnType<typeof api.listOrgCoaches>;

  createOrgStaff(
    fields: Omit<Parameters<typeof api.createOrgStaff>[0], 'orgId'>,
  ): ReturnType<typeof api.createOrgStaff>;

  updateOrgStaff(
    fields: Omit<Parameters<typeof api.updateOrgStaff>[0], 'orgId'>,
  ): ReturnType<typeof api.updateOrgStaff>;

  deleteOrgStaff(staffId: number): ReturnType<typeof api.deleteOrgStaff>;

  /**
   * Manage participants
   */
  getOrgParticipant(
    participantId: number,
  ): ReturnType<typeof api.getOrgParticipant>;

  getParticipantProductSession(
    fields: Omit<
      Parameters<typeof api.getParticipantProductSession>[0],
      'orgId'
    >,
  ): ReturnType<typeof api.getParticipantProductSession>;

  getProductVisitReport(
    fields: Omit<Parameters<typeof api.getProductVisitReport>[0], 'orgId'>,
  ): ReturnType<typeof api.getProductVisitReport>;

  listOrgParticipants(
    fields: Omit<Parameters<typeof api.listOrgParticipants>[0], 'orgId'>,
  ): ReturnType<typeof api.listOrgParticipants>;

  createOrgParticipant(
    fields: Omit<Parameters<typeof api.createOrgParticipant>[0], 'orgId'>,
  ): ReturnType<typeof api.createOrgParticipant>;

  updateOrgParticipant(
    fields: Omit<Parameters<typeof api.updateOrgParticipant>[0], 'orgId'>,
  ): ReturnType<typeof api.updateOrgParticipant>;

  deleteOrgParticipant(
    participantId: number,
  ): ReturnType<typeof api.deleteOrgParticipant>;

  /**
   * Manage products
   */
  listOrgProducts(): ReturnType<typeof api.listOrgProducts>;

  createOrgProduct(
    fields: Omit<Parameters<typeof api.createOrgProduct>[0], 'orgId'>,
  ): ReturnType<typeof api.createOrgProduct>;

  deleteOrgProduct(productId: number): ReturnType<typeof api.deleteOrgProduct>;

  /**
   * Re-invite users.
   */
  resendUserInvite(userId: number): ReturnType<typeof api.resendUserInvite>;

  resendPostTestReminder(
    participantId: number,
  ): ReturnType<typeof api.resendPostTestReminder>;

  updatePostTestUnlockWeek(
    postTestUnlockWeek: PostTestUnlockWeek,
  ): ReturnType<typeof api.updatePostTestUnlockWeek>;
}

export const OrgContext = createContext<OrgContextValue | null>(null);

export function useOrg(): OrgContextValue {
  const contextValue = useContext(OrgContext);

  if (!contextValue) {
    missingProvider('useOrg', 'OrgContext.Provider');
  }

  return contextValue;
}

export const OrgProvider: FC<{
  org: Org;
  /**
   * An optional callback to use to propagate org updates outside of this OrgContext provider.
   * @param updatedOrg the org that was updated.
   */
  onOrgUpdated?(updatedOrg: Org): void;
}> = ({ org: initialOrg, onOrgUpdated = noop, children }) => {
  const { activeRole } = useUser();
  const [org, setOrg] = useState(initialOrg);
  const orgId = org.id;
  // URL Path prefix for linking to org sub-pages
  const orgPath =
    activeRole?.kind === UserRoleKind.SUPER_ADMIN ? `/org/${orgId}` : ``;

  const updateOrg = useCallback<OrgContextValue['updateOrg']>(
    async (fields) => {
      const org = await api.updateOrg({ ...fields, orgId });
      setOrg(org);
      onOrgUpdated(org);
      return org;
    },
    [orgId, onOrgUpdated],
  );

  const updateOrgSelfEnrollmentDefaults = useCallback<
    OrgContextValue['updateOrgSelfEnrollmentDefaults']
  >(
    async (fields) => {
      const org = await api.updateOrgSelfEnrollmentDefaults({
        ...fields,
        orgId,
      });
      setOrg(org);
      return org;
    },
    [orgId],
  );

  const deleteOrg = useCallback(async () => api.deleteOrg(orgId), [orgId]);

  const listOrgLocations = useCallback<OrgContextValue['listOrgLocations']>(
    async () => api.listOrgLocations(orgId),
    [orgId],
  );

  const createOrgLocation = useCallback<OrgContextValue['createOrgLocation']>(
    async (fields) => api.createOrgLocation({ ...fields, orgId }),
    [orgId],
  );

  const updateOrgLocation = useCallback<OrgContextValue['updateOrgLocation']>(
    async (fields) => api.updateOrgLocation({ ...fields, orgId }),
    [orgId],
  );

  const deleteOrgLocation = useCallback<OrgContextValue['deleteOrgLocation']>(
    async (locationId) => api.deleteOrgLocation({ locationId, orgId }),
    [orgId],
  );

  const listOrgStaff = useCallback<OrgContextValue['listOrgStaff']>(
    async () => api.listOrgStaff(orgId),
    [orgId],
  );

  const listOrgCoaches = useCallback<OrgContextValue['listOrgCoaches']>(
    async () => api.listOrgCoaches(orgId),
    [orgId],
  );

  const createOrgStaff = useCallback<OrgContextValue['createOrgStaff']>(
    async (fields) => api.createOrgStaff({ ...fields, orgId }),
    [orgId],
  );

  const updateOrgStaff = useCallback<OrgContextValue['updateOrgStaff']>(
    async (fields) => api.updateOrgStaff({ ...fields, orgId }),
    [orgId],
  );

  const deleteOrgStaff = useCallback<OrgContextValue['deleteOrgStaff']>(
    async (staffId) => api.deleteOrgStaff({ staffId, orgId }),
    [orgId],
  );

  const listOrgParticipants = useCallback<
    OrgContextValue['listOrgParticipants']
  >(async (fields) => api.listOrgParticipants({ ...fields, orgId }), [orgId]);

  const getOrgParticipant = useCallback<OrgContextValue['getOrgParticipant']>(
    (participantId) => api.getOrgParticipant({ orgId, participantId }),
    [orgId],
  );

  const getParticipantProductSession = useCallback<
    OrgContextValue['getParticipantProductSession']
  >(
    (fields) => api.getParticipantProductSession({ ...fields, orgId }),
    [orgId],
  );

  const getProductVisitReport = useCallback<
    OrgContextValue['getProductVisitReport']
  >((fields) => api.getProductVisitReport({ ...fields, orgId }), [orgId]);

  const createOrgParticipant = useCallback<
    OrgContextValue['createOrgParticipant']
  >(async (fields) => api.createOrgParticipant({ ...fields, orgId }), [orgId]);

  const updateOrgParticipant = useCallback<
    OrgContextValue['updateOrgParticipant']
  >(async (fields) => api.updateOrgParticipant({ ...fields, orgId }), [orgId]);

  const deleteOrgParticipant = useCallback<
    OrgContextValue['deleteOrgParticipant']
  >(
    async (participantId) => api.deleteOrgParticipant({ participantId, orgId }),
    [orgId],
  );

  const listOrgProducts = useCallback(
    async () => api.listOrgProducts(orgId),
    [orgId],
  );

  const createOrgProduct = useCallback<OrgContextValue['createOrgProduct']>(
    async (fields) => api.createOrgProduct({ ...fields, orgId }),
    [orgId],
  );

  const deleteOrgProduct = useCallback<OrgContextValue['deleteOrgProduct']>(
    async (productId) => api.deleteOrgProduct({ productId, orgId }),
    [orgId],
  );

  const resendUserInvite = useCallback<OrgContextValue['resendUserInvite']>(
    (userId) => api.resendUserInvite(userId),
    [],
  );

  const updatePostTestUnlockWeek = useCallback(
    async (postTestUnlockWeek: PostTestUnlockWeek) => {
      const org = await api.updatePostTestUnlockWeek(orgId, postTestUnlockWeek);
      setOrg(org);
      onOrgUpdated(org);
      return org;
    },
    [onOrgUpdated, orgId],
  );

  const resendPostTestReminder = useCallback<
    OrgContextValue['resendPostTestReminder']
  >(
    async (participantId) => {
      api.resendPostTestReminder({ orgId, participantId });
    },
    [orgId],
  );

  return (
    <OrgContext.Provider
      value={{
        org,
        orgPath,
        updateOrg,
        updateOrgSelfEnrollmentDefaults,
        deleteOrg,
        listOrgLocations,
        createOrgLocation,
        updateOrgLocation,
        deleteOrgLocation,
        listOrgStaff,
        listOrgCoaches,
        createOrgStaff,
        updateOrgStaff,
        deleteOrgStaff,
        getOrgParticipant,
        getParticipantProductSession,
        getProductVisitReport,
        listOrgParticipants,
        createOrgParticipant,
        updateOrgParticipant,
        deleteOrgParticipant,
        listOrgProducts,
        createOrgProduct,
        deleteOrgProduct,
        resendUserInvite,
        resendPostTestReminder,
        updatePostTestUnlockWeek,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};
