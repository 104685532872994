import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_AskQuestions_b: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      input1: '',
      input2: '',
      input3: '',
    });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer hideButton>
      <ActivityBox
        icon="pencil"
        className="flex flex-1 pt-8"
        instructions={
          <h3 className="text-blue-800 font-black text-lg leading-6">
            {isSpanish
              ? `Su turno: Piense sobre una interacción reciente con su hijo/a. 
                ¿Qué son algunas maneras que podría haber hecho preguntas 
                para animar una conversación?`
              : `Your turn: Think about a recent interaction with your child. What
                are some ways you could have asked questions that encourage
                conversation?`}
          </h3>
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="flex flex-col py-4 px-4">
            {Object.keys(value).map((key, idx) => (
              <textarea
                key={key}
                className={`py-2 px-3 flex-1 max-w-full overflow-auto rounded-lg 
                  bg-white border-warmGray-200 border-2 min-h-24 text-black ${
                    idx ? 'mt-8' : ''
                  }`}
                value={value[key as keyof typeof value]}
                onChange={(event) =>
                  setValue({ ...value, [key]: event.currentTarget.value })
                }
                placeholder={
                  isSpanish ? 'Toque para escribir...' : 'Tap to type...'
                }
              />
            ))}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 self-center my-10"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Enviar' : 'Submit'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="GIRL"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
