import { FC, useCallback, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import stock16Src from '../../../images/stock/stock_16.png';
import stock17Src from '../../../images/stock/stock_17.png';
import stock18Src from '../../../images/stock/stock_18.png';
import stock19Src from '../../../images/stock/stock_19.png';
import { ReactComponent as DownwardChevron } from '../../../images/feather/chevron-down.svg';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { useSlideContext } from '../../../contexts/slideContext';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';

export const RulesAndConsequences1Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [viewContent, setViewContent] = useState(true);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { activity, isReview, setValue } = useActivityState<
    Record<string, boolean>
  >({});

  const handleImageButtonClick = (index: number) => {
    setViewContent(true);
    setCurrentContentIndex(index);
  };

  const managingToddlersContent = isEnglish
    ? managingToddlersEnglishContent
    : managingToddlersSpanishContent;

  const endOfContent =
    currentContentIndex + 1 === managingToddlersContent.length;

  const imagePointerIndex = !viewContent
    ? 0
    : endOfContent
    ? undefined
    : currentContentIndex + 1;

  const { header, statement, example } =
    managingToddlersContent[currentContentIndex];

  const handleNextButtonClick = useCallback(() => {
    if (isReview) {
      next();
    } else {
      setValue({});
      setShowOverlay(true);
    }
  }, [isReview, next, setValue]);

  return (
    <SlideContainer
      slideTitle={
        isEnglish ? 'Managing Toddlers' : 'Manejando a niños/as pequeño/as'
      }
      className="flex-col text-white font-light"
      hideButton={!endOfContent}
      onButtonClick={handleNextButtonClick}
    >
      <div className="px-14 flex flex-col">
        <b className="font-medium">
          {isEnglish
            ? 'Supervision, clear rules, and consequences'
            : 'Supervisión, reglas claras y consecuencias'}
        </b>
        <span className="mt-4">
          {isEnglish ? (
            <>
              Supervision is important to keep your children safe, help them
              learn, and build strong relationships.
            </>
          ) : (
            <>
              La supervisión es importante para mantener a sus hijos seguros,
              ayudarlos a aprender y construir relaciones fuertes.
            </>
          )}
        </span>
      </div>
      <div
        className="flex flex-col px-3 mt-8 flex-shrink-0"
        style={{ height: 180 }}
      >
        <h4 className="font-semibold mb-2 text-center">
          {isEnglish
            ? "The four C's of supervision are:"
            : 'Las cuatros Cs de la supervisión son: '}
        </h4>
        <div
          className="w-full bg-blue-200 rounded-lg text-blue-800 
          text-center text-xs py-2.5 font-semibold"
        >
          {isEnglish
            ? 'Tap the image to learn more'
            : 'Oprima las imágenes para obtener más información.'}
        </div>
        {/* Image container */}
        <div className="flex justify-between items-center mt-auto px-4">
          {images.map((image, index) => (
            <button
              key={image.alt}
              onClick={() => handleImageButtonClick(index)}
              className={`relative flex-shrink-0 rounded-full ${
                viewContent && currentContentIndex === index
                  ? 'border-5 border-blue-200 -mb-1.5'
                  : ''
              }`}
            >
              <img style={{ width: '70px' }} src={image.src} alt={image.alt} />
              {/* Pointer Arrow */}
              {imagePointerIndex === index && (
                <div
                  className="absolute flex justify-center items-end -top-12 
                right-0 left-0 mx-auto w-1 h-7 bg-blue-200 flex-shrink-0"
                >
                  <div
                    className="bg-blue-200 w-5 h-5 rounded-full flex 
                    justify-center items-center -mb-2 flex-shrink-0"
                  >
                    <DownwardChevron
                      className="w-5 text-blue-800"
                      style={{ strokeWidth: 3 }}
                    />
                  </div>
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
      {viewContent && (
        <div className="flex flex-col px-20 mt-8">
          <h4 className="text-xl uppercase text-red-600 font-bold">{header}</h4>
          <p className="mt-5 mb-6">{statement}</p>
          {example && (
            <ExampleSection
              title={
                <h5 className="font-bold font-lg uppercase ml-2.5">
                  {isEnglish ? 'Example:' : 'Ejemplo:'}
                </h5>
              }
            >
              <p className="ml-9 mt-1.5">{example}</p>
            </ExampleSection>
          )}
        </div>
      )}
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR"
      />
    </SlideContainer>
  );
};

const images = [
  { src: stock19Src, alt: 'child watering plant' },
  { src: stock16Src, alt: 'child smiling with paint on face' },
  { src: stock17Src, alt: 'Mother holding crying child' },
  { src: stock18Src, alt: 'Mother with two children' },
];

const managingToddlersEnglishContent = [
  {
    header: 'Checking Up',
    statement: 'Be able to see young children at all times',
    example: 'Young children should be in the same room as you are when awake.',
  },
  {
    header: 'Clear Rules',
    statement: 'Set up clear household rules.',
    example: 'When we are inside the house, we use our inside voice.',
  },
  {
    header: 'Consequences',
    statement: 'Use logical consequences to manage behavior.',
    example: "If you throw the Legos, you can't play with them anymore today.",
  },
  {
    header: 'Consistency',
    statement: `Whether you’re checking up, setting clear rules, or using consequences, 
      being consistent — acting the same way each time — lets your child know what to expect.`,
    example: undefined,
  },
];

const managingToddlersSpanishContent = [
  {
    header: 'Chequeo',
    statement:
      'Tener la habilidad de ver a los niños pequeños en todo momento.',
    example:
      'Los niños pequeños deben estar en la misma habitación que usted cuando esten despiertos.',
  },
  {
    header: 'Reglas claras',
    statement: 'Establezca reglas claras para su hogar.',
    example: 'Cuando estamos dentro de la casa, usamos nuestra voz interior.',
  },
  {
    header: 'Consecuencias',
    statement: 'Use consecuencias lógicas para manejar los comportamientos.',
    example: 'Si tiras los Legos/bloques, no vas a poder jugar con ellos hoy.',
  },
  {
    header: 'Consistencia',
    statement: `Cuando esté revisando, estableciendo reglas claras o usando las consecuencias, 
    ser consistente y actuar de la misma manera cada vez, le permite a su hijo/a saber qué esperar.`,
    example: undefined,
  },
];
