import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { ExpandableBlock } from '../../../components/ExpandableBlock/ExpandableBlock';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting21Slide: FC = () => {
  const [expandedBlock, setExpandedBlock] = useState<number | null>(0);
  const { isEnglish } = useProductLanguage();

  const rewards = isEnglish ? englishRewards : spanishRewards;
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'What is a reward?' : '¿Qué es una recompensa?'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish
          ? "A reward is what you give to your child after they've completed a desired behavior."
          : 'Una recompensa es lo que le da a su niño/a después de que haya completado un comportamiento deseado.'}
      </p>
      <ExampleSection
        icon="star"
        className="my-5"
        title={
          <h2 className="font-semibold uppercase ml-2.5 text-lg">
            {isEnglish ? 'Example:' : 'Ejemplo:'}
          </h2>
        }
      >
        <p className="ml-8 mt-2.5">
          {isEnglish
            ? 'Rewards come in many forms:'
            : 'Las recompensas vienen en muchas formas:'}
        </p>
      </ExampleSection>
      {rewards.map((reward, index) => (
        <ExpandableBlock
          key={reward.headerTitle}
          className="mb-1"
          headerTitle={reward.headerTitle}
          expanded={expandedBlock === index}
          onClick={() =>
            setExpandedBlock((curBlockIndex) =>
              curBlockIndex === index ? null : index,
            )
          }
        >
          <p className="px-5 mb-2.5">{reward.description}</p>
        </ExpandableBlock>
      ))}
      <p className="my-5">
        {isEnglish
          ? 'The most important thing to consider when making your list of rewards is that you can follow through'
          : 'Lo más importante para considerar al hacer su lista de recompensas es si puede cumplirlas.'}
      </p>
    </SlideContainer>
  );
};

const englishRewards = [
  {
    headerTitle: 'Encouragement and praise',
    description: `Say, “Thank you for using the potty, 
      you’re getting to be such a big kid!”`,
  },
  {
    headerTitle: 'Time and activities with you',
    description: 'Play “Go fish” with Mom.',
  },
  { headerTitle: 'Entertainment', description: 'Trip to the park.' },
  { headerTitle: 'Privileges', description: '10 minutes of screen time.' },
  {
    headerTitle: 'Things that cost money',
    description: 'A small toy or stickers.',
  },
];

const spanishRewards = [
  {
    headerTitle: 'Ánimo y elogio',
    description:
      'Diga: “Gracias por usar el baño, ¡te estás convirtiendo en un niño/a grande!”',
  },
  {
    headerTitle: 'Tiempo y actividades contigo',
    description: 'Jugar a las cartas con mamá.',
  },
  { headerTitle: 'Entretenimiento', description: 'Viaje al parque.' },
  {
    headerTitle: 'Privilegios',
    description: '10 minutos de tiempo de pantalla.',
  },
  {
    headerTitle: 'Cosas que cuestan dinero',
    description: 'Un juguete pequeño o calcomanías',
  },
];
