import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { SuggestionTextArea } from '../../../components/SuggestionTextArea/SuggestionTextArea';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSuggestions = [
  'Improve morning routine',
  'Increase physical activity/exercise',
  'Eat healthier meals',
  'Reduce screen time',
  'Go to bed at the same time each night',
  'Plan fun activities',
  'Make a daily schoolwork plan',
  'Build in time for personal hygiene',
];

const spanishSuggestions = [
  'Mejore la rutina de la mañana',
  'Incremente la actividad física/el ejercicio',
  'Coma comidas más saludables',
  'Reduzca el tiempo de pantalla',
  'Se acueste a la misma hora cada noche',
  'Planee actividades divertidas',
  'Haga un plan diario de trabajo de escuela',
  'Crear tiempo para la higiene personal',
];

export const SSS_Choose_b: FC = () => {
  const { next } = useSlideContext();
  const {
    value: answers,
    setValue: setAnswers,
    isReview,
    hasInteracted,
    activity,
  } = useActivityState(['', '', '']);
  const [openPortal, setOpenPortal] = useState<null | number>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const maxSelections = 3;

  const handleAnswer =
    (key: number) => (type: 'text' | 'select') => (value: string) => {
      let newAnswers = [...answers];

      if (type === 'text') {
        newAnswers[key] = value;
      } else if (type === 'select') {
        if (newAnswers.includes(value)) {
          // if value already in array, get rid of it
          newAnswers = newAnswers.filter((item) => item !== value);
        }
        // if max selections reached, replace current field with new selection
        else if (newAnswers.filter(Boolean).length === maxSelections) {
          newAnswers[key] = value;
        }
        // if current index has no value, fill it
        else if (!newAnswers[key]) {
          newAnswers[key] = value;
        }
        // find any empty indexes and fill selection
        else if (newAnswers.indexOf('') !== -1) {
          newAnswers[newAnswers.indexOf('')] = value;
        } else {
          newAnswers = [...newAnswers, value];
        }
      }
      setAnswers(newAnswers);
    };

  const handlePortal = (key: number) => (value: boolean) => {
    setOpenPortal(value ? key : null);
  };

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const allAnswers = answers.filter(Boolean);

  const suggestions = isSpanish ? spanishSuggestions : englishSuggestions;

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pencil"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? 'Su turno: Rutinas Saludables'
            : 'Your turn: Healthy routines'
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="pt-4 px-5">
            {Array.from({ length: 3 }).map((val, idx) => (
              <SuggestionTextArea
                value={answers[idx]}
                suggestions={suggestions}
                suggestionsTitle={
                  isSpanish
                    ? 'Sugerencias de Rutinas Saludables'
                    : 'Healthy Routines Suggestions'
                }
                pickedSuggestions={allAnswers}
                onValueChange={handleAnswer(idx)}
                showSuggestions={openPortal === idx}
                openPortal={handlePortal(idx)}
                maxSelections={maxSelections}
              />
            ))}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 mt-10 mb-10 self-center"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine Mi Plan' : 'Finish My Plan'}
          </PillButton>
        </div>
        <QuizCompletionOverlay
          open={showOverlay}
          onNext={next}
          bearCharacter="GIRL_WINKING"
          activityPoints={activity.pointValue}
        />
      </ActivityBox>
    </SlideContainer>
  );
};
