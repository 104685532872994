import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import ActivityIcon, { ActivityIconProps } from '../ActivityIcon/ActivityIcon';

interface ActivityBoxProps extends ComponentPropsWithoutRef<'div'> {
  icon?: ActivityIconProps['icon'];
  bgColor?: 'white' | 'yellow';
  instructions?: ReactNode;
  className?: string;
}

const ActivityBox: FC<ActivityBoxProps> = ({
  icon,
  bgColor,
  instructions,
  children,
  className = '',
  ...rest
}) => (
  <div
    {...rest}
    className={`relative flex flex-col box-border flex-shrink-0 mt-8 px-3 ${
      bgColor === 'white' ? 'bg-white' : 'bg-yellow-secondary'
    } ${className}`}
  >
    {!!icon && (
      <ActivityIcon
        icon={icon}
        borderColor={bgColor}
        className="absolute left-2/4 top-0 z-10 transform -translate-x-2/4 -translate-y-2/4"
      />
    )}
    {!!instructions && (
      <div className="px-4 mb-5 leading-5 text-center font-black text-lg text-blue-800">
        {instructions}
      </div>
    )}
    {children}
  </div>
);

export default ActivityBox;
