import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_e: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Usando Premios' : 'Using Rewards'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `¡Para que trabaje esta estrategia tiene que observar que su hijo/a haya 
          hecho lo que Ud. quiere, y después cumpla dándole un premio!`
        : `For this strategy to work, you must notice that your child did what you
          wanted, and then follow up by giving them the reward!`}

      <YellowCircleList className="py-6">
        <li>
          <b className="font-bold">Plan: </b>
          {isSpanish
            ? `Dígale a su hijo/a que si
              cuelgue la mochila cuando llega de la escuela por una semana,
              puede pedir pizza para cenar el viernes.`
            : `Tell your child if they hang up
              their backpack when they get home from school for a week, they can
              order pizza for dinner on Friday.`}
        </li>
        <li>
          <b className="font-bold">{isSpanish ? 'Observe: ' : 'Notice: '}</b>
          {isSpanish
            ? `Su hijo/a llega a la casa
              después de la escuela y cuelga su mochila todos los días.`
            : `Your child comes home after
              school and hangs their backpack up each day.`}
        </li>
        <li>
          <b className="font-bold">{isSpanish ? 'Premio: ' : 'Reward: '}</b>
          {isSpanish
            ? `Le agradece por hacer buen trabajo y le permite pedir pizza para
              cenar.`
            : `You thank them for doing their job
              and pull up the menu on your phone to order their favorite pizza.`}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
