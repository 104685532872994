import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Eating_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Comiendo' : 'Eating'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Los niños están sanos y felices cuando comen comidas balanceadas. 
          Se recomienda que los niños coman de dos a tres porciones de frutas
          y verduras al día.`
        : `Children are healthy and happy when they eat balanced meals. It’s
          recommended that children eat two to three servings of fruit and vegetables
          each day.`}
    </SlideContainer>
  );
};
