import { FC } from 'react';
import './ShoeList.css';

export const ShoeList: FC<{ className?: string }> = ({
  className = '',
  ...rest
}) => <ol className={`shoe-list list-none pl-9 ${className}`} {...rest} />;

export const ShoeListItem: FC<{
  className?: string;
  dark?: boolean;
}> = ({ className = '', dark, ...rest }) => (
  <li
    className={`relative text-white ${
      dark ? 'dark-shoe' : 'shoe'
    } ${className}`}
    {...rest}
  />
);
