import { FC } from 'react';
import checkboxIconSrc from '../../images/checkbox_icon.png';
import pencilIconSrc from '../../images/pencil_icon.png';
import pointerIconSrc from '../../images/pointer_icon.png';
import filmIconSrc from '../../images/film_icon.png';
import chatIconSrc from '../../images/chat_icon.png';
import phoneIconSrc from '../../images/phone_icon_white.png';

const icons = {
  checkbox: checkboxIconSrc,
  pencil: pencilIconSrc,
  pointer: pointerIconSrc,
  film: filmIconSrc,
  chat: chatIconSrc,
  phone: phoneIconSrc,
};

export interface ActivityIconProps {
  icon: keyof typeof icons;
  borderColor?: 'white' | 'yellow';
  className?: string;
}

const ActivityIcon: FC<ActivityIconProps> = ({
  borderColor,
  icon,
  className = '',
  ...rest
}) => (
  <div
    className={`w-13 h-13 bg flex flex-col flex-shrink-0 bg-white justify-center items-center rounded-full border-2 ${
      borderColor === 'white' ? 'border-white' : 'border-yellow-secondary'
    } ${className}`}
    {...rest}
  >
    <img
      src={icons[icon]}
      alt={`${icon}`}
      className="h-2/5 w-2/5 object-contain"
    />
  </div>
);

export default ActivityIcon;
