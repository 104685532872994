import { FC, useState, ReactNode } from 'react';
import { StarIcon, FillOption } from '../StarIcon/StarIcon';

enum Compare {
  USERS_RESPONSE,
  RECOMMENDED_REPONSE,
}

export interface ResponseCompareProps {
  className?: string;
  usersResponse?: ReactNode;
  recommendedResponse?: ReactNode;
  isSpanish?: boolean;
}

export const ResponseCompare: FC<ResponseCompareProps> = ({
  isSpanish = false,
  className = '',
  usersResponse = 'The users response',
  recommendedResponse = 'The recommended response',
  ...rest
}) => {
  const [activeSelection, setActiveSelection] = useState<Compare>(
    Compare.USERS_RESPONSE,
  );

  return (
    <div className={`flex flex-col text-blue-800 ${className}`} {...rest}>
      {/* Comparison select bar */}
      <div
        className="relative flex border-4 rounded-full
       border-white bg-white text-sm mb-5"
      >
        <button
          onClick={() => setActiveSelection(Compare.USERS_RESPONSE)}
          className={`flex-1 text-center py-3 rounded-l-full
          ${
            activeSelection === Compare.USERS_RESPONSE
              ? 'font-bold'
              : 'bg-yellow-secondary'
          }
          `}
        >
          <p className="mx-1.5">
            {isSpanish ? 'Sus respuestas' : 'Your Responses'}
          </p>
        </button>
        <button
          onClick={() => setActiveSelection(Compare.RECOMMENDED_REPONSE)}
          className={`flex-1 text-center py-3 rounded-r-full
          ${
            activeSelection === Compare.RECOMMENDED_REPONSE
              ? 'font-bold'
              : 'bg-yellow-secondary'
          }
          `}
        >
          <p className="mx-1.5 mr-5">
            {isSpanish ? 'Respuestas recomendadas' : 'Recommended Responses'}
          </p>
        </button>
        <StarIcon
          className="absolute right-3 top-0 bottom-0 my-auto"
          fillOption={
            activeSelection === Compare.USERS_RESPONSE
              ? FillOption.WHITE
              : FillOption.RED
          }
        />
      </div>
      {/* Comparison content */}
      {activeSelection === Compare.USERS_RESPONSE
        ? usersResponse
        : recommendedResponse}
    </div>
  );
};
