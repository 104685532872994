import { FC, ReactNode } from 'react';
import { SlideContainer } from '../SlideContainer/SlideContainer';
import ActivityBox from '../ActivityBox/ActivityBox';
import { Video } from '../Video/Video';
import QuizCompletionOverlay from '../QuizCompletionOverlay/QuizCompletionOverlay';
import { useVideoActivityState } from '../../hooks/useActivityState';
import { useSlideContext } from '../../contexts/slideContext';
import { BEAR_IMG_MAP } from '../../constants/bears/bearImageMapping';

export const SlideVideo: FC<{
  className?: string;
  slideTitle?: string;
  upperSlideContent?: ReactNode;
  lowerSlideContent?: ReactNode;
  bearCharacter: keyof typeof BEAR_IMG_MAP;
  videoTitle?: ReactNode;
  videoKey: string;
  buttonText?: string | false;
}> = ({
  className,
  slideTitle,
  upperSlideContent,
  lowerSlideContent,
  bearCharacter,
  videoTitle,
  videoKey,
  buttonText,
}) => {
  const { next } = useSlideContext();
  const { watched, onEnded, isReview, activity } = useVideoActivityState();

  return (
    <SlideContainer
      buttonText={buttonText}
      className={`flex-col ${className}`}
      hideButton={!watched && !isReview}
      slideTitle={slideTitle}
    >
      {upperSlideContent}
      <ActivityBox icon="film" className="items-center mt-14 pt-10 pb-6">
        {videoTitle ? (
          <div className="text-center text-blue-800 font-bold mb-4 text-lg">
            {videoTitle}
          </div>
        ) : null}
        <Video videoKey={videoKey} onEnded={onEnded} />
      </ActivityBox>
      {lowerSlideContent}
      <QuizCompletionOverlay
        open={watched && !isReview}
        onNext={next}
        bearCharacter={bearCharacter}
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
