import { SECOND, MINUTE } from 'shared/lib/constants/time';

/**
 * The path prefix to use when requesting files stored in the `public` directory.
 * Needed when the app's base URL isn't the domain's base URL (ex. )
 *
 * Example: if the app is hosted here: `https://example.com/app` and you wanted
 * to reference the `/favicon.ico` static file, you would need to use `/app/favicon.ico`, so
 * `PUBLIC_BASE_PATH` needs to be "/app".
 */
export const PUBLIC_BASE_PATH =
  process.env.REACT_APP_PUBLIC_BASE_PATH ??
  process.env.STORYBOOK_PUBLIC_BASE_PATH ??
  '';

/**
 * Whether or not to display unit slide names for QA/debugging.
 */
export const SHOW_SLIDE_NAMES =
  process.env.REACT_APP_SHOW_SLIDE_NAMES === 'true';

/**
 * How long to wait while the user idles before automatically logging them out.
 */
export const IDLE_TIMEOUT = +(
  process.env.REACT_APP_IDLE_TIMEOUT ?? MINUTE * 10
);

/**
 * How long to wait while the user idles before showing the warning that they're
 * about to be logged out.
 * Defaults to 30 seconds before the idle timeout.
 */
export const IDLE_WARNING_TIMEOUT = +(
  process.env.REACT_APP_IDLE_WARNING_TIMEOUT ?? IDLE_TIMEOUT - SECOND * 30
);

/**
 * How frequently to request a new auth token to keep the session from expiring.
 */
export const REFRESH_AUTH_TOKEN_INTERVAL = +(
  process.env.REACT_APP_REFRESH_AUTH_TOKEN_INTERVAL ?? MINUTE
);
