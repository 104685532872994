import { FC, useCallback } from 'react';
import ReactSlider from 'react-slider';
import {
  SurveyQuestionKind,
  SurveyQuestionOptions,
  SurveyQuestionValue,
} from 'shared/lib/types/SurveyQuestion';

interface Props {
  options: SurveyQuestionOptions<SurveyQuestionKind.RANGE>;
  value: SurveyQuestionValue<SurveyQuestionKind.RANGE> | null;
  readonly?: boolean;
  onChange(answer: SurveyQuestionValue<SurveyQuestionKind.RANGE>): unknown;
}

export const RangeQuestionInput: FC<Props> = ({
  options,
  value,
  readonly,
  onChange,
  ...rest
}) => {
  const { min, max } = options;
  const handleChange = useCallback(
    (newValue: number | number[] | null | undefined) => {
      if (typeof newValue === 'number' && !readonly) {
        onChange(newValue as any);
      }
    },
    [onChange, readonly],
  );

  return (
    <div {...rest} className="w-full flex flex-col">
      <div className="relative border-3 border-blue-450 border-opacity-20 rounded-lg bg-white">
        <ReactSlider
          className={`h-6 ${readonly ? 'cursor-default' : 'cursor-pointer'}`}
          min={min}
          max={max}
          disabled={readonly}
          value={value ?? undefined}
          onChange={handleChange}
          renderThumb={(props, state) => (
            <div
              {...props}
              className={`h-8 w-2 -mt-1 rounded bg-blue-450 transition-all ${
                value === null ? 'opacity-0' : ''
              }`}
            >
              {value !== null && value !== min && value !== max && (
                <div className="absolute top-full mt-2">{state.valueNow}</div>
              )}
            </div>
          )}
          renderTrack={(props, state) => (
            <div
              {...props}
              className={`h-full transition-all rounded ${
                state.index > 0 ? 'bg-white' : 'blue-gradient-bg'
              }`}
            />
          )}
        />
      </div>
      <div className="flex flex-row mt-2 justify-between">
        <div>{min}</div>
        <div>{max}</div>
      </div>
    </div>
  );
};
