import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { useCallback, useState } from 'react';
import { isPostTestComplete } from 'shared/lib/utils/postTestUtils';

export enum WelcomeScreenType {
  INITIAL_WELCOME = 'initial-welcome',
  WELCOME_BACK = 'welcome-back',
  POST_TEST = 'post-test',
}

export function useParticipantWelcomeScreen(
  productSession: DraftParticipantProductSession | null,
) {
  const [showWelcomeBackOverlay, setShowWelcomeBackOverlay] = useState(true);

  let welcomeScreenType: WelcomeScreenType | null = null;

  if (productSession && showWelcomeBackOverlay) {
    const {
      participantProduct,
      postTestUnlocked,
      unitSessions,
      exitSurveyAndTake,
    } = productSession;
    if (!participantProduct.welcomedAt) {
      welcomeScreenType = WelcomeScreenType.INITIAL_WELCOME;
    } else if (
      postTestUnlocked &&
      !isPostTestComplete(unitSessions, exitSurveyAndTake)
    ) {
      welcomeScreenType = WelcomeScreenType.POST_TEST;
    } else if (
      unitSessions.some((unitSession) => unitSession.slideSessions.length > 0)
    ) {
      welcomeScreenType = WelcomeScreenType.WELCOME_BACK;
    }
  }

  const onWelcomeScreenDismissed = useCallback(() => {
    if (welcomeScreenType !== WelcomeScreenType.INITIAL_WELCOME) {
      setShowWelcomeBackOverlay(false);
    }
  }, [welcomeScreenType]);

  return {
    showWelcomeBackOverlay: showWelcomeBackOverlay && !!welcomeScreenType,
    welcomeScreenType,
    onWelcomeScreenDismissed,
  };
}
