import { FC, ComponentPropsWithRef, forwardRef } from 'react';

export interface TextInputProps extends ComponentPropsWithRef<'input'> {
  onValueChange?(value: string): unknown;
}

export const TextInput: FC<TextInputProps> = forwardRef(
  ({ onValueChange, onChange, className = '', ...rest }, ref) => (
    <input
      type="text"
      {...rest}
      ref={ref}
      className={`h-10 rounded-lg px-2 text-blue-800 ${className}`}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (onValueChange) {
          onValueChange(event.currentTarget.value);
        }
      }}
    />
  ),
);

export interface TextAreaProps extends ComponentPropsWithRef<'textarea'> {
  onValueChange?(value: string): unknown;
}

export const TextArea: FC<TextAreaProps> = forwardRef(
  ({ onValueChange, onChange, className = '', ...rest }, ref) => (
    <textarea
      {...rest}
      ref={ref}
      className={`h-48 resize-none rounded-lg p-2 text-blue-800 ${className}`}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (onValueChange) {
          onValueChange(event.currentTarget.value);
        }
      }}
    />
  ),
);
