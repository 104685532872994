import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isLanguage } from 'shared/lib/types/Language';
import { PrivacyPolicyModal } from '../components/PrivacyPolicyModal/PrivacyPolicyModal';
import {
  usePatchSearchParams,
  useSearchParams,
} from '../hooks/useSearchParams';
import { AcceptInvitePage } from '../pages/AcceptInvitePage/AcceptInvitePage';
import { AcceptPasswordResetPage } from '../pages/AcceptPasswordResetPage/AcceptPasswordResetPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { RegisterPage } from '../pages/RegisterPage/RegisterPage';

export const UnauthorizedApp: FC = () => {
  const searchParams = useSearchParams();
  const showPrivacyPolicyModal = searchParams.get('privacy-policy') === '';
  const language = searchParams.get('language') ?? 'english';
  const patchSearchParams = usePatchSearchParams();

  return (
    <>
      <Switch>
        <Route path="/accept-invite">
          <AcceptInvitePage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/reset-password">
          <AcceptPasswordResetPage />
        </Route>
        <Route path="/register/:orgId/:productId">
          <RegisterPage />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal
          onClose={() => patchSearchParams({ 'privacy-policy': null })}
          language={isLanguage(language) ? language : 'english'}
        />
      )}
    </>
  );
};
