import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare6Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      slideTitle={isEnglish ? 'Managing Depression' : 'Manejar La Depresión'}
      className="text-white font-light"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Spiral2020'
          : 'early_childhood_spanish_Spiral2020'
      }
      videoTitle={isEnglish ? 'Mood Spiral' : 'Espiral de Humor'}
      upperSlideContent={
        <p className="px-14">
          {isEnglish ? (
            <>
              There are lots of things you can do to feel better. Watch this
              video to see how your mood, actions, and thoughts are related, and
              can work against you to make you feel worse.
            </>
          ) : (
            <>
              Hay muchas cosas que puede hacer para sentirse mejor. Mire este
              video para ver cómo se relacionan su estado de ánimo, sus acciones
              y sus pensamientos, y cómo pueden jugar en su contra para hacerlo
              sentir peor.
            </>
          )}
        </p>
      }
      bearCharacter="BABY_BEAR"
    />
  );
};
