import { FC, ReactNode } from 'react';
import { noop } from 'shared/lib/utils/noop';
import { PillButton, ButtonTheme } from '../PillButton/PillButton';

/**
 * @param {ReactNode} [buttonLabel="Continue"] - Defaults to Continue
 * @param {boolean} [hideButton=false] - Defaults to false
 * @param {boolean} [animate=true] - Defaults to true
 */

export const BottomToast: FC<{
  className?: string;
  onButtonClick?(): unknown;
  buttonLabel?: ReactNode;
  hideButton?: boolean;
  buttonTheme?: ButtonTheme;
  animate?: boolean;
  fixed?: boolean;
  fixedButtonWidth?: boolean;
}> = ({
  children,
  className = '',
  onButtonClick = noop,
  buttonLabel = 'Continue',
  hideButton = false,
  buttonTheme,
  animate = true,
  fixed = true,
  fixedButtonWidth = true,
  ...rest
}) => (
  <div
    className={`flex flex-col bottom-0 left-0 w-full z-30
    bg-blue-200 rounded-t-lg overflow-hidden
    ${animate ? 'animate-slideAnimation' : ''}
    ${className}
    ${fixed ? 'fixed' : ''}`}
    {...rest}
  >
    {children}
    {!hideButton && (
      <div
        className="flex justify-center items-center py-8 px-4
        bg-blue-900 flex-shrink-0"
      >
        <PillButton
          className={fixedButtonWidth ? 'w-56' : ''}
          onClick={onButtonClick}
          theme={buttonTheme}
        >
          {buttonLabel}
        </PillButton>
      </div>
    )}
  </div>
);
