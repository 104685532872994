import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivacyPolicyModal } from '../components/PrivacyPolicyModal/PrivacyPolicyModal';
import {
  usePatchSearchParams,
  useSearchParams,
} from '../hooks/useSearchParams';
import { ChooseRolePage } from '../pages/ChooseRolePage/ChooseRolePage';

export const ChooseRoleApp: FC = () => {
  const showPrivacyPolicyModal = useSearchParams().get('privacy-policy') === '';
  const patchSearchParams = usePatchSearchParams();

  return (
    <>
      <Switch>
        <Route path="/">
          <ChooseRolePage
            header={
              <>
                Where would you like to <br />
                begin today?
              </>
            }
          />
        </Route>
      </Switch>
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal
          onClose={() => patchSearchParams({ 'privacy-policy': null })}
          language="english"
        />
      )}
    </>
  );
};
