import { FC } from 'react';
import { noop } from 'shared/lib/utils/noop';
import { ReactComponent as CheckIcon } from '../../images/feather/check.svg';

export const StatementCheckbox: FC<{
  className?: string;
  onStatementClick?(): void;
  checked?: boolean;
  statement: string;
}> = ({
  className = '',
  onStatementClick = noop,
  checked = false,
  statement,
  ...rest
}) => (
  <div
    onClick={onStatementClick}
    className={`flex justify-between items-center shadow-lg bg-white rounded-lg py-2 pl-3 cursor-pointer text-black leading-5  ${className}`}
    {...rest}
  >
    {statement}
    {/* Checkbox container */}
    <div className="w-10 flex flex-shrink-0 justify-center items-center">
      {checked && <CheckIcon stroke="#4DACBE" />}
    </div>
  </div>
);
