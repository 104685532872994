import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_e: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_14_spanish' : 'ani_14'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Mire a esta mamá hablar con su hija sobre las publicaciones 
              apropiadas en las redes sociales.`
            : `Watch this Mom check in with her child about appropriate 
              social media posting.`}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
