import { ComponentPropsWithoutRef, FC } from 'react';
import infoIconSrc from '../../images/info_icon.png';
import { InfoTooltip } from '../InfoTooltip/InfoTooltip';
import { ToolTipTriPosition } from 'shared/lib/types/ToolTipTriPosition';

interface InfoPopoverButtonProps extends ComponentPropsWithoutRef<'div'> {
  onInfoClicked(): void;
  showInfoPopover: boolean;
  onInfoPopoverClosed(): void;
  popoverText: string;
  popoverClassName?: string;
  popoverArrowPosition?: ToolTipTriPosition;
}

function getBaseTooltipPosition(popoverArrowPosition: ToolTipTriPosition) {
  switch (popoverArrowPosition) {
    case 'bottom':
    case 'bottomCenter':
    case 'bottomLeft':
    case 'bottomRight':
      return 'bottom-12';
    // Let consumer handle it...
    default:
      return '';
  }
}

export const InfoPopoverButton: FC<InfoPopoverButtonProps> = ({
  className,
  onInfoClicked,
  showInfoPopover,
  onInfoPopoverClosed,
  popoverText,
  popoverClassName = '',
  popoverArrowPosition = 'bottom',
  ...rest
}) => (
  <div className={`absolute flex flex-col ${className}`} {...rest}>
    <div className="relative flex flex-col items-center">
      {showInfoPopover && (
        <InfoTooltip
          className={`${getBaseTooltipPosition(
            popoverArrowPosition,
          )} flex-shrink-0 ${popoverClassName}`}
          visible
          onClose={onInfoPopoverClosed}
          text={popoverText}
          position={popoverArrowPosition}
          absolutePositioned
        />
      )}
      <button className="relative flex flex-row pt-4" onClick={onInfoClicked}>
        <img src={infoIconSrc} alt="info" className="w-9" />
      </button>
    </div>
  </div>
);
