import { FC } from 'react';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import {
  SurveyQuestion,
  SurveyQuestionKind,
  SurveyQuestionValue,
} from 'shared/lib/types/SurveyQuestion';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { ReactComponent as ArrowLeftIcon } from '../../images/feather/arrow-left.svg';
import { PillButton } from '../PillButton/PillButton';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { SurveyQuestionAndResponse } from './SurveyQuestionAndResponse';

export const SurveyQuestionScreen: FC<{
  survey: SurveyWithQuestions;
  question: SurveyQuestion<SurveyQuestionKind>;
  value: SurveyQuestionValue<SurveyQuestionKind> | null;
  onChange(value: SurveyQuestionValue<SurveyQuestionKind>): unknown;
  onNext(): unknown;
  onBack(): unknown;
  className?: string;
}> = ({
  className = '',
  survey,
  question,
  value,
  onChange,
  onNext,
  onBack,
  ...rest
}) => {
  const { isSpanish } = useProductLanguage();
  return (
    <div
      {...rest}
      className={`flex flex-col min-h-screen bg-white ${className}`}
    >
      {/* hamburger menu */}
      <div className="flex flex-row justify-end px-4 pt-5">
        <ParticipantNavButton />
      </div>
      <img
        src={getPublicFilePath(survey.icon)}
        alt=""
        className="w-20 h-20 self-center flex-shrink-0 object-contain -mt-2 bg-blue rounded-full"
      />

      <h1 className="text-blue-400 text-2xl text-center font-light mt-4 px-4">
        {survey.name}
      </h1>
      <h2 className="text-blue-450 text-lg text-center font-bold -mt-1">
        {isSpanish ? 'Cuestionario' : 'Survey'}
      </h2>

      <div className="bg-gray-100 flex-grow flex flex-col mt-2 pt-2 pb-8 px-8">
        <div className="flex flex-row items-center justify-center">
          <div className="w-0 flex-grow pr-2 flex flex-row justify-end">
            {question.index > 0 && (
              <button onClick={onBack}>
                <ArrowLeftIcon
                  className="text-yellow"
                  strokeWidth={3}
                  aria-label="Back"
                />
              </button>
            )}
          </div>
          <div className="text-center text-blue-400 font-semibold">
            {question.index + 1} / {survey.questions.length}
          </div>
          <div className="w-0 flex-grow" />
        </div>

        {/* Progress bar */}
        <div className="w-full px-4 flex-shrink-0">
          <div className="w-full self-center h-4 rounded-full overflow-hidden bg-white border-3 border-blue-450 border-opacity-20 mt-2">
            <div
              className="h-full rounded-full blue-gradient-bg transition-all"
              style={{
                width: `${
                  ((question.index + 1) / survey.questions.length) * 100
                }%`,
              }}
            />
          </div>
        </div>
        <SurveyQuestionAndResponse
          question={question}
          value={value}
          onChange={onChange}
        />
        <PillButton
          disabled={value === null}
          onClick={onNext}
          className="w-48 self-center flex-shrink-0 mt-12"
        >
          {isSpanish ? 'Siguiente' : 'Next'}
        </PillButton>
      </div>
    </div>
  );
};
