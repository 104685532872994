import { FC } from 'react';

export const HEIGHT_MAP = {
  DEFAULT: 'h-full',
  MEDIUM: 'h-full smY:h-4/6',
  LARGE: 'h-full smY:h-3/4',
  LARGE_ON_TALLER: 'h-full mdY:h-3/4',
};

const AnimatedOverlay: FC<{
  open: boolean;
  height?: keyof typeof HEIGHT_MAP;
}> = ({ open, height = 'DEFAULT', ...rest }) => (
  <div
    className={`animate-slideAnimation fixed left-0 bottom-0 w-full z-10  bg-blue-900 rounded-t-lg ${
      open ? 'block' : 'hidden'
    } flex flex-col items-center ${HEIGHT_MAP[height]}`}
    {...rest}
  />
);
export default AnimatedOverlay;
