import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare5Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Managing Depression' : 'Manejar La Depresión'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish ? (
          <>
            Parenting a young child is hard in the best of times. Add in any
            challenges and it can become overwhelming. You might feel stressed
            out, anxious, or even depressed. These are common feelings when you
            are parenting young children. You may also feel guilty for having
            those feelings, because you enjoy and love your child.
          </>
        ) : (
          <>
            Criar a un niño/a pequeño/a es difícil en el mejor de los casos.
            Agregue cualquier desafío que puede volverse abrumador. Puede
            sentirse estresado/a, ansioso/a o incluso deprimido/a. Estos son
            sentimientos comunes cuando se crían niños/as pequeños/as. También
            puede sentirse culpable por tener esos sentimientos, porque disfruta
            y ama a su hijo/a.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
