import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Link, useLocation } from 'react-router-dom';
import { isParticipantRole } from 'shared/lib/types/UserRole';
import closeButtonImageSrc from '../../images/x_circle_close_button.png';
import { useUserOptional } from '../../contexts/userContext';
import { UnitList } from '../UnitList/UnitList';
import { useParticipantProductContextOptional } from '../../contexts/participantProductContext';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { usePostTestRedirect } from '../../hooks/product/usePostTestRedirect';
import { useParticipantCoachContext } from '../../contexts/participantCoachContext';
import { useOrgDocumentContext } from '../../contexts/orgDocumentContext';

const navOverlayRoot = document.createElement('div');
navOverlayRoot.classList.add('absolute', 'left-0', 'top-0');

document.body.appendChild(navOverlayRoot);

export const ParticipantNavOverlay: FC<{
  onClose(): unknown;
  highlightedUnitId?: number;
  highlightedSectionId?: number;
}> = ({ onClose, highlightedUnitId, highlightedSectionId, ...rest }) => {
  const { logout, activeRole, roles } = useUserOptional();
  const participantProductContext = useParticipantProductContextOptional();
  const isActiveParticipant = !!(activeRole && isParticipantRole(activeRole));
  const location = useLocation();
  const { isSpanish } = useProductLanguage();
  const { postTestLink } = usePostTestRedirect(
    participantProductContext?.productSession ?? null,
  );
  const { coach } = useParticipantCoachContext();
  const { orgDocument } = useOrgDocumentContext();

  return createPortal(
    <div
      {...rest}
      className="absolute left-0 right-0 w-screen h-screen overflow-auto z-40 bg-blue-700"
    >
      <div className="relative md:mx-auto md:max-w-2xl">
        <button onClick={onClose} className="absolute w-8 h-8 right-4 top-0">
          <img
            src={closeButtonImageSrc}
            alt="Close menu"
            className="w-full h-full"
          />
        </button>
        <h1 className="font-extralight text-yellow text-center text-3xl mt-8">
          Menu
        </h1>
        <ul className="mt-8 text-blue-800 text-center w-full flex flex-col mb-12">
          {participantProductContext && (
            <li>
              <h2 className="text-xl text-center font-medium bg-blue-200 py-4 w-full">
                {isSpanish ? 'Temas' : 'Topics'}
              </h2>
              <div className="px-3 py-4">
                <UnitList
                  productSession={participantProductContext.productSession}
                  hideProgressBars
                  hideSurveyLinks
                  backgroundColor="transparent"
                  sectionListPadding="small"
                  sectionListTextColor="light"
                  highlightedUnitId={highlightedUnitId}
                  highlightedSectionId={highlightedSectionId}
                />
              </div>
            </li>
          )}
          {isActiveParticipant && (
            <>
              <li className="mt-0.5">
                <Link
                  to="/"
                  onClick={() => (location.pathname === '/' ? onClose() : null)}
                  className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                >
                  {isSpanish ? 'Tablero' : 'Dashboard'}
                </Link>
              </li>
              <li className="mt-0.5">
                <Link
                  to="/plan"
                  className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                >
                  {`M${isSpanish ? 'i' : 'y'} Plan`}
                </Link>
              </li>
              <li className="mt-0.5">
                <Link
                  to="/library"
                  className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                >
                  {isSpanish ? 'Biblioteca' : 'Library'}
                </Link>
              </li>
              {orgDocument && (
                <li className="mt-0.5">
                  <Link
                    to="/resources"
                    className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                  >
                    {isSpanish ? 'Recursos' : 'Resources'}
                  </Link>
                </li>
              )}
              {coach && (
                <li className="mt-0.5">
                  <Link
                    to="/coach"
                    className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                  >
                    {isSpanish ? 'Proveedor' : 'Provider'}
                  </Link>
                </li>
              )}
              <li className="mt-0.5">
                <Link
                  to="/account"
                  className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                >
                  {isSpanish ? 'Mi Cuenta' : 'My Account'}
                </Link>
              </li>
              {participantProductContext?.productSession.postTestUnlocked &&
                postTestLink && (
                  <li className="mt-0.5">
                    <Link
                      to={postTestLink}
                      className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                    >
                      {isSpanish
                        ? 'Resultados de mi encuesta'
                        : 'My Survey Results'}
                    </Link>
                  </li>
                )}
              {roles.length > 1 && (
                <li className="mt-0.5">
                  <Link
                    to="/products"
                    className="block text-xl text-center font-medium bg-blue-200 py-4 w-full"
                  >
                    {isSpanish ? 'Productos' : 'Products'}
                  </Link>
                </li>
              )}
            </>
          )}
          <li className="mt-0.5">
            <button
              onClick={logout}
              className="text-xl text-center font-medium bg-blue-200 py-4 w-full"
            >
              {isSpanish ? 'Cerrar Sesión' : 'Log Out'}
            </button>
          </li>
        </ul>
      </div>
    </div>,
    navOverlayRoot,
  );
};
