import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      bearCharacter="GIRL"
      videoKey={isSpanish ? 'ani_8_spanish' : 'ani_8'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Observe cómo esta madre establece expectativas claras sobre cuándo su hijo/a estará lejos de ella.`
            : `Watch this parent set clear expectations for when her child 
              will be away from her.`}
        </p>
      }
    />
  );
};
