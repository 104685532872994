import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Connect_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={isSpanish ? 'Conexiones Sociales' : 'Social Connections'}
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_15_spanish' : 'ani_15'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Las redes sociales pueden ser una forma saludable para que los niños 
              establezcan conexiones. Pero también puede crear problemas entre los 
              niños y sus amigos. Mire cómo este papá ayuda a su hija sobrellevar 
              sentirse excluida.`
            : `Social media can be a healthy way for children to make connections. But
              it can also create problems between children and their friends. Watch
              how this Dad helps his child cope with feeling left out.`}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
