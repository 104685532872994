import { FC, useState } from 'react';
import { FloatingCard } from '../FloatingCard/FloatingCard';
import { RoutineTask } from '../RoutineTask/RoutineTask';
import { SelectOverlay } from '../SelectOverlay/SelectOverlay';
import alarmClockSrc from '../../images/alarm-clock.png';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export type Routine = {
  tasks: {
    [key: number]: { task: string; time: number };
  };
  leaveTime: string;
};

export const RoutineCalculator: FC<{
  routine?: Routine;
  setRoutine(value: Routine): unknown;
}> = ({ routine = { tasks: {}, leaveTime: '' }, setRoutine, ...rest }) => {
  const [showSuggestionsKey, setShowSuggestionsKey] = useState<null | number>(
    null,
  );
  const [showLeaveTimes, setShowLeaveTimes] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleChange =
    (key: number) => (field: 'task' | 'time') => (value: string) => {
      setRoutine({
        leaveTime: routine.leaveTime,
        tasks: {
          ...routine.tasks,
          [key]: {
            task: field === 'task' ? value : routine.tasks[key].task,
            time: field === 'time' ? +value : routine.tasks[key].time,
          },
        },
      });
    };

  const toMinutes = (time: string) => {
    const [hr1, hr2, , ...min] = time.split('');
    return +`${min.join('')}` + +`${hr1}${hr2}` * 60;
  };

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );

  // 5 minutes intervals to pick from
  const taskIntervals = range(0, 60, 5);

  // 15 minute intervals which represent military time from 5am through 11am. EX: 06:15, 06:30, 06:45
  const morningIntervals = range(5, 11, 1)
    .map((hour) => {
      const theHour = hour >= 10 ? `${hour}` : `0${hour}`;
      return [
        `${theHour}:00`,
        `${theHour}:15`,
        `${theHour}:30`,
        `${theHour}:45`,
      ];
    })
    .flat();

  const wakeTime = () => {
    let meridiem: 'AM' | 'PM';
    const totalTaskMinutes = Object.entries(routine.tasks).reduce(
      (acc, [key, { task, time }]) => acc + time,
      0,
    );

    const leaveTimeInMin = toMinutes(routine.leaveTime);

    const wakeUpTime = leaveTimeInMin - totalTaskMinutes;

    let wakeHrs = Math.floor(wakeUpTime / 60);
    const wakeMinutes = wakeUpTime - wakeHrs * 60;
    if (wakeHrs < 1) {
      wakeHrs = 12 + wakeHrs;
      meridiem = 'PM';
    } else {
      meridiem = 'AM';
    }
    return `${wakeHrs}:${
      wakeMinutes < 10 ? `0${wakeMinutes}` : wakeMinutes
    }${meridiem}`;
  };

  const handleSuggestions = (key: number) => (value: boolean) => {
    setShowSuggestionsKey(value ? key : null);
  };

  const handleLeaveTime = (value: string) => {
    if (value) {
      setRoutine({ ...routine, leaveTime: value });
    }
  };

  return (
    <div {...rest}>
      <FloatingCard className="py-4 px-5">
        {Object.entries(routine.tasks).map(([key, { task, time }]) => (
          <RoutineTask
            key={+key}
            task={task}
            time={time}
            className="mb-3"
            onTaskChange={handleChange(+key)('task')}
            onTimeChange={handleChange(+key)('time')}
            openPortal={handleSuggestions(+key)}
            showSuggestions={showSuggestionsKey === +key}
            options={taskIntervals}
            placeholder={
              isSpanish
                ? '¿Qué tarea quieres completar?'
                : 'What task do you want to complete?'
            }
          />
        ))}
        <div className="flex justify-between items-center pl-2 font-bold text-sm">
          {isSpanish
            ? '¿A qué hora tenemos que salir para llegar a tiempo a la escuela?'
            : 'What time do we need to leave to get to school on time?'}

          <button
            className="bg-blue-700 w-24 flex-shrink-0 text-white p-3 pl-4 rounded-xl font-bold italic text-base ml-2"
            onClick={() => setShowLeaveTimes(true)}
          >
            {routine.leaveTime || '00:00'}
          </button>
          {showLeaveTimes && (
            <SelectOverlay
              title="Morning Times"
              options={morningIntervals.map((option) => ({
                value: option,
                label: `${option} AM`,
              }))}
              initValues={[routine.leaveTime]}
              onValueChange={handleLeaveTime}
              openPortal={setShowLeaveTimes}
              value={routine.leaveTime}
            />
          )}
        </div>
      </FloatingCard>
      <FloatingCard className="flex justify-between font-bold mt-5 py-4 px-5 text-sm">
        <div className="flex items-center">
          <img className="w-6 h-6 mr-3" src={alarmClockSrc} alt="alarm clock" />
          {isSpanish ? 'Su hora de despertar es:' : 'Your wake up time is'}
        </div>
        <span className="text-xl italic">
          {routine.leaveTime ? wakeTime() : '00:00'}
        </span>
      </FloatingCard>
    </div>
  );
};
