export const englishHelpfulUnhelpfulQuiz = {
  askPermissionToLeaveTable: {
    answer: true,
    question: 'Please ask permission to leave the table.',
    feedback: 'This is a clear direction with specific request.',
  },
  wantToCleanYourRoom: {
    answer: false,
    question: 'Do you want to clean you room? ',
    feedback:
      'Questions are not directions because they provide an option to say "no".',
  },
  lookAtMePlease: {
    answer: true,
    question: 'Look at me, please.',
    feedback: 'This is a clear direction with specific request.',
  },
  itsYourFaultTheHouseIsAMess: {
    answer: false,
    question: "It's your fault the house is a mess.",
    feedback:
      'Blame statements tend to make children defensive, moodier and less likely to follow directions.',
  },
  iWillTurnTheTvOff: {
    answer: true,
    question: 'I will turn the TV off so i am sure you can hear me.',
    feedback: 'This is a clear praise statement.',
  },
  putYourClothesInTheBasket: {
    answer: true,
    question:
      'I can tell you put your clothes in the laundry basket, thank you!',
    feedback: 'This is a clear praise statement.',
  },
  wowYouClearedYourPlate: {
    answer: true,
    question: 'Wow, you cleared your plate without me asking, nicely done!',
    feedback:
      'This praise statement gives your child information about the behavior you want them to continue.',
  },
  brushYourTeethBeforeBed: {
    answer: true,
    question: 'Brush your teeth before getting in bed.',
    feedback: 'This is a clear direction with specific request.',
  },
  putAwayYourToys: {
    answer: false,
    question:
      'Put away your toys, wash your hands, then come to the table, please.',
    feedback:
      'Giving multiple directions at once is less effective than giving one direction at a time.',
  },
  youveAlwaysBeenASlob: {
    answer: false,
    question: "You've always been a slob.",
    feedback:
      'Terms such as “always,” “never” and negative words reduce cooperation and self-esteem in children and adults.',
  },
  dontTalkWithFullMouth: {
    answer: false,
    question: "Don't talk with your mouth full.",
    feedback:
      'Directions that tell your children what to do, instead of what not to do, are more effective.',
  },
  dontGetInBedWithoutBrushing: {
    answer: false,
    question: "Don't get in bed without brushing your teeth.",
    feedback:
      'Directions that tell your children what to do, instead of what not to do, are more effective.',
  },
  youreBeingSoLoud: {
    answer: false,
    question: "You're being so loud, it's annoying!",
    feedback:
      'This is a statement, not a direction. It is unclear what behavior is being requested.',
  },
  yourCleanUpSkillsNeedWork: {
    answer: false,
    question: 'I love you, but your clean up skills need some work.',
    feedback: 'Do not pair praise statements with critical comments.',
  },
};

export const spanishHelpfulUnhelpfulQuiz = {
  askPermissionToLeaveTable: {
    answer: true,
    question: 'Por favor pide permiso para dejar la mesa.',
    feedback: 'Esta es una instrucción clara con una solicitud específica.',
  },
  wantToCleanYourRoom: {
    answer: false,
    question: '¿Quieres limpiar tu habitación?',
    feedback:
      'Las preguntas no son instrucciones porque brindan una opción para decir "no".',
  },
  lookAtMePlease: {
    answer: true,
    question: 'Mírame, por favor.',
    feedback: 'Esta es una instrucción clara con una solicitud específica.',
  },
  itsYourFaultTheHouseIsAMess: {
    answer: false,
    question: 'Es tu culpa que la casa sea un desastre.',
    feedback:
      'Las declaraciones de culpa tienden a poner a los niños a la defensiva, de mal  humor, y menos propensos a seguir instrucciones.',
  },
  iWillTurnTheTvOff: {
    answer: true,
    question: 'Apagaré la televisión para estar seguro/a de que me puedan oír.',
    feedback: 'Esta es una declaración que transmite información a su niño/a.',
  },
  putYourClothesInTheBasket: {
    answer: true,
    question: 'Noté que pusiste tu ropa en la canasta de ropa sucia, ¡gracias!',
    feedback: 'Esta es una clara declaración de elogio.',
  },
  wowYouClearedYourPlate: {
    answer: true,
    question:
      '¡Wow!, limpiaste tu plato sin que yo te lo pidiera, ¡bien hecho!',
    feedback:
      'Esta declaración de elogio le da a su niño/a  información sobre el comportamiento que Ud. desea que continúe.',
  },
  brushYourTeethBeforeBed: {
    answer: true,
    question: 'Cepíllate los dientes antes de acostarte.',
    feedback: 'Esta es una instrucción clara con una solicitud específica.',
  },
  putAwayYourToys: {
    answer: false,
    question:
      'Guarda tus juguetes, lávate las manos y luego ven a la mesa, por favor.',
    feedback:
      'Dar múltiples instrucciones a la vez es menos efectivo que dar una instruccion a la vez.',
  },
  youveAlwaysBeenASlob: {
    answer: false,
    question: 'Siempre has sido un/a haragán/a.',
    feedback:
      'Términos como “siempre”, “nunca” y palabras negativas reducen la cooperación y el autoestima en niños y adultos.',
  },
  dontTalkWithFullMouth: {
    answer: false,
    question: 'No hables con la boca llena.',
    feedback:
      'Las instrucciones que le dicen a sus niños qué hacer, en lugar de qué no hacer, son más efectivas.',
  },
  dontGetInBedWithoutBrushing: {
    answer: false,
    question: 'No te acuestes en la cama sin cepillarte los dientes.',
    feedback:
      'Las instrucciones que le dicen a sus niños qué hacer, en lugar de qué no hacer, son más efectivas.',
  },
  youreBeingSoLoud: {
    answer: false,
    question: '¡Estás siendo muy ruidoso, es molesto!',
    feedback:
      'Esta es una declaración, no una instrucción. No está claro qué comportamiento se está solicitando.',
  },
  yourCleanUpSkillsNeedWork: {
    answer: false,
    question:
      'Te amo, pero tus habilidades de limpieza necesitan algo de trabajo.',
    feedback: 'No combine declaraciones de elogio con comentarios críticos.',
  },
};
