import { FC } from 'react';
import dumbbellIconSrc from '../../images/dumbbell.png';
import connectIconSrc from '../../images/connect.png';

// We can expand this in the future. Just using these two for now.
const ICON_MAP = {
  dumbbell: {
    img: dumbbellIconSrc,
    alt: 'dumbell',
  },
  connect: {
    img: connectIconSrc,
    alt: 'connect',
  },
};

/**
 *
 * @param {string} [icon="dumbbell"] - Defaults to dumbbell icon
 */

export const ChangeableIconList: FC<{
  icon?: keyof typeof ICON_MAP;
  className?: string;
}> = ({ icon = 'dumbbell', children, className = '', ...rest }) => {
  const { img, alt } = ICON_MAP[icon];

  return (
    <div {...rest} className={`flex ${className}`}>
      {/* Icon container */}
      <div className="relative mr-5">
        <img src={img} alt={alt} className="w-10" />
      </div>
      {/* Text/Children container */}
      <ul className="pt-2 flex-1 text-white">{children}</ul>
    </div>
  );
};
