export const englishLogicalNotLogicalQuiz = {
  cantStayInBackYard: {
    answer: true,
    question:
      "If you can't stay in the backyard, you need to play in the house.",
    feedback: 'This is a logical consequence.',
  },
  cantPlayWithToys: {
    answer: false,
    question:
      "You can't play with your toys for 3 days because you hit your sister.",
    feedback: `This is too large and is not logical. Instead say, “You don’t get 
              to keep playing with your blocks because you hit your sister.”`,
  },
  throwBallInHouse: {
    answer: true,
    question:
      'If you throw the ball in the house, I will need to take it away.',
    feedback: 'This is a logical consequence.',
  },
  dontEatPeas: {
    answer: false,
    question:
      "If you don't eat your peas, you need to go to timeout for 10 minutes.",
    feedback: `Instead say, “If you don’t eat your peas, you can’t have 
              dessert with the rest of us.”`,
  },
};

export const spanishLogicalNotLogicalQuiz = {
  cantStayInBackYard: {
    answer: true,
    question:
      'Si no puedes quedarte en el patio, entonces debes jugar adentro de la casa.',
    feedback: 'Esta es una consecuencia lógica.',
  },
  cantPlayWithToys: {
    answer: false,
    question:
      'No puedes jugar con tus juguetes por 3 días porque le pegaste a tu hermana.',
    feedback: `Esto es demasiado grande y no es lógico. En vez diga, 
    "No puedes seguir jugando con tus bloques porque le pegaste a tu hermana".`,
  },
  throwBallInHouse: {
    answer: true,
    question: 'Si tiras la pelota en la casa, te la tendré que quitar.',
    feedback: 'Esta es una consecuencia lógica.',
  },
  dontEatPeas: {
    answer: false,
    question:
      'Si no te comes tus guisantes, entonces tienes un "time-out" por diez minutos.',
    feedback: `En vez diga, "Si no te comes tus guisantes, no podras comer 
    el postre con el resto de nosotros".`,
  },
};
