import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { IconTextArea } from '../../../components/IconTextArea/IconTextArea';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { ResponseCompare } from '../../../components/ResponseCompare/ResponseCompare';
import { ShoeList, ShoeListItem } from '../../../components/ShoeList/ShoeList';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export type Response = {
  userWouldSay: string;
  userWouldDo: string;
};

const englishSituation = {
  text: `Your toddler was playing quietly, but now she's tugging at your leg
      saying “Mommy” over and over again.`,
  recShouldSay: `Stop what you’re doing, and meet her at her level so she knows
      she has your attention.`,
  recShouldDo: 'Ask her what she needs, and then give it to her.',
};

const spanishSituation = {
  text: `Su niña pequeña estaba jugando silenciosamente, pero ahora le está jalando la pierna 
      diciendo, "Mami" una y otra vez.`,
  recShouldSay: `Pare lo que está haciendo, y reúnase con ella a su nivel 
      para que ella sepa que tiene su atención.`,
  recShouldDo: 'Pregúntele qué es lo que necesita, y después se lo da.',
};

export const PositiveParenting11Slide: FC = () => {
  const [comparingResponse, setComparingResponse] = useState(false);
  const { next, viewOnly } = useSlideContext();
  const { isEnglish, isSpanish } = useProductLanguage();
  const { value, setValue } = useActivityState<Response>({
    userWouldSay: '',
    userWouldDo: '',
  });

  const onTextAreaChange = (key: keyof Response) => (text: string) => {
    setValue({
      ...value,
      [key]: text,
    });
  };

  const { userWouldSay, userWouldDo } = value;

  const { text, recShouldSay, recShouldDo } = isEnglish
    ? englishSituation
    : spanishSituation;

  const compareResponseText = isEnglish
    ? 'How do your responses compare?'
    : 'Cómo se comparan sus respuestas?';

  const yourTurnText = isEnglish
    ? 'Your turn: Practice Situation 1'
    : 'Su turno: Práctica 1';

  const continueText = isEnglish ? 'Continue' : 'Siga';
  const nextText = isEnglish ? 'Next' : 'Entregar';
  const beSensitiveText = isEnglish ? 'BE SENSITIVE' : 'SEA SENSIBLE';
  const beResponsiveText = isEnglish ? 'BE RESPONSIVE' : 'SEA RECEPTIVO/A';

  return (
    <SlideContainer
      className="flex-col text-blue-800"
      hideButton
      lightBg
      lightProgressBar
    >
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1"
        instructions={comparingResponse ? compareResponseText : yourTurnText}
        icon="chat"
      >
        {!comparingResponse ? (
          <>
            <p className="flex flex-col px-8 font-light">
              <span className="font-semibold self-start mb-1">
                {isEnglish ? 'Situation' : 'Situación'}
              </span>
              {text}
            </p>
            <FloatingCard className="pt-3.5 pb-5 px-4 mt-5 w-full">
              <IconTextArea
                label={beSensitiveText}
                disabled={viewOnly}
                icon="leftShoe"
                placeholder={
                  isEnglish
                    ? 'Enter what you would say.'
                    : 'Escriba lo que Ud. diría.'
                }
                value={value.userWouldSay}
                onChange={(value) => onTextAreaChange('userWouldSay')(value)}
                elementOverlay={
                  <span className="absolute z-10 top-1 right-3.5 font-bold text-xl">
                    1
                  </span>
                }
              />
            </FloatingCard>
            <FloatingCard className="pt-2.5 pb-5 px-4 mt-2.5 mb-10 w-full">
              <IconTextArea
                label={beResponsiveText}
                icon="rightShoe"
                placeholder={
                  isEnglish
                    ? 'Enter what you would do.'
                    : 'Escribe lo que harías.'
                }
                value={value.userWouldDo}
                onChange={(value) => onTextAreaChange('userWouldDo')(value)}
                elementOverlay={
                  <span className="absolute z-10 top-1 right-3.5 font-bold text-xl">
                    2
                  </span>
                }
              />
            </FloatingCard>
          </>
        ) : (
          <ResponseCompare
            className="w-full"
            isSpanish={isSpanish}
            usersResponse={
              <ShoeList className="my-5 ml-5 pr-12 font-light">
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold">{beSensitiveText}</h3>
                  {userWouldSay}
                </ShoeListItem>
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold mt-10">
                    {beResponsiveText}
                  </h3>
                  {userWouldDo}
                </ShoeListItem>
              </ShoeList>
            }
            recommendedResponse={
              <ShoeList className="my-5 ml-5 pr-12 font-light">
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold">{beSensitiveText}</h3>
                  {recShouldSay}
                </ShoeListItem>
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold mt-10">
                    {beResponsiveText}
                  </h3>
                  {recShouldDo}
                </ShoeListItem>
              </ShoeList>
            }
          />
        )}
        {userWouldSay && userWouldDo && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={() =>
              !comparingResponse ? setComparingResponse(true) : next()
            }
          >
            {comparingResponse ? continueText : nextText}
          </PillButton>
        )}
      </ActivityBox>
    </SlideContainer>
  );
};
