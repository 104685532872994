import { FC } from 'react';
import { Language } from 'shared/lib/types/Language';

export const LanguageBadge: FC<{ language: Language; className?: string }> = ({
  language,
  className = '',
  ...rest
}) => {
  let letter: string;
  let backgroundColor: string;

  if (language === 'english') {
    letter = 'E';
    backgroundColor = 'bg-blue-200';
  } else {
    letter = 'S';
    backgroundColor = 'bg-yellow';
  }

  return (
    <div
      {...rest}
      className={`
      inline-flex flex-row justify-center items-center
      w-6 h-6 flex-shrink-0
      rounded-full
      text-center text-lg font-semibold text-blue-800
      ${backgroundColor}
      ${className}
    `}
    >
      {letter}
    </div>
  );
};
