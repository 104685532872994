import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Logical_g: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer className="px-14 font-light text-white">
      {isSpanish
        ? `Si constantemente proporciona consecuencias cuando no se siguen 
          sus reglas, su hijo/a comprenderá que lo dice en serio.`
        : `If you consistently provide consequences when your rules are not followed,
          your child will understand that you mean what you say.`}
    </SlideContainer>
  );
};
