import { FC, useState, useEffect } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  MythFactCard,
  Answer,
} from '../../../components/MythFactCard/MythFactCard';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { parentingAndSubstanceAbuseMythOrFactProblems } from '../../../constants/early_childhood/parenting_and_substance_use_2_problems';
import { useSlideContext } from '../../../contexts/slideContext';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { Feedback } from '../../../components/Feedback/Feedback';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse2Slide: FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { value, setValue, isReview, activity } = useActivityState<
    Record<string, boolean>
  >({});

  const { isEnglish } = useProductLanguage();
  const { next } = useSlideContext();

  const {
    id,
    englishStatement,
    spanishStatement,
    correct,
    englishFeedback,
    spanishFeedback,
  } = parentingAndSubstanceAbuseMythOrFactProblems[currentQuestionIndex];

  const questionsLength = parentingAndSubstanceAbuseMythOrFactProblems.length;
  const endOfQuiz = questionsLength === currentQuestionIndex + 1;
  const currentAnswer = value[id];

  const minQuizRequirement = !!Object.values(value).length;
  const canContinue = !!(isReview || minQuizRequirement);

  const correctIsFact = correct === Answer.FACT;

  /**
   * Auto-hide feedback after 8 seconds.
   */
  useEffect(() => {
    if (showFeedback) {
      const timeout = setTimeout(() => {
        setShowFeedback(false);
      }, 8000);

      return () => clearTimeout(timeout);
    }
    return;
  }, [showFeedback]);

  const handleAnswer = (answer: Answer) => {
    setValue({
      ...value,
      [id]: answer === correct,
    });
    setShowFeedback(true);
  };

  const getUsersAnswer = () => {
    if (currentAnswer !== undefined) {
      if (currentAnswer === true) {
        return correctIsFact ? Answer.FACT : Answer.MYTH;
      }
      return correctIsFact ? Answer.MYTH : Answer.FACT;
    }
  };

  const handleContinue = () => {
    setShowFeedback(false);
    setShowOverlay(true);
  };

  return (
    <SlideContainer lightBg hideButton lightProgressBar className="flex-col">
      <ActivityBox
        className="items-center mt-14 pt-10 pb-6 flex-1"
        icon="pointer"
        instructions={
          <h2 className="text-lg px-5 text-blue-800 leading-5">
            {isEnglish
              ? 'Your turn: Play this Myth or Fact game to check what you know about substances.'
              : 'Su turno: Juege este juego de Mito o Realidad para comprobar lo que sabe sobre las sustancias.'}
          </h2>
        }
      >
        <MythFactCard
          className="w-full mb-10"
          isEnglish={isEnglish}
          statement={isEnglish ? englishStatement : spanishStatement}
          statementIndex={currentQuestionIndex}
          totalStatements={questionsLength}
          selectedOption={getUsersAnswer()}
          onFactClick={() => handleAnswer(Answer.FACT)}
          onMythClick={() => handleAnswer(Answer.MYTH)}
          backButtonVisible={!!currentQuestionIndex}
          onBackButtonClick={() => {
            setShowFeedback(false);
            setCurrentQuestionIndex((curIndex) =>
              !!curIndex ? curIndex - 1 : curIndex,
            );
          }}
          backButtonDisabled={!currentQuestionIndex}
          onForwardButtonClick={() => {
            if (!endOfQuiz) {
              setShowFeedback(false);
              setCurrentQuestionIndex((curIndex) => curIndex + 1);
            } else if (!isReview) {
              setShowFeedback(false);
              setShowOverlay(true);
            } else {
              handleContinue();
            }
          }}
          forwardButtonDisabled={currentAnswer === undefined}
        />
        {canContinue && (
          <PillButton
            className="mt-10 w-52"
            theme={ButtonTheme.YELLOW}
            onClick={() => (!isReview ? handleContinue() : next())}
          >
            {isEnglish ? 'Continue' : 'Entregar'}
          </PillButton>
        )}
        {showFeedback && (
          <BottomToast hideButton>
            <Feedback
              className="mt-12 mb-7 mx-5"
              icon={getUsersAnswer() === correct ? 'THUMBS_UP' : 'EXCLAMATION'}
            >
              {isEnglish ? englishFeedback : spanishFeedback}
            </Feedback>
          </BottomToast>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR"
      />
    </SlideContainer>
  );
};
