import { FC, ReactNode } from 'react';
import { noop } from 'shared/lib/utils/noop';
import { ChevronCircleButton } from '../ChevronCircleButton/ChevronCircleButton';
import { FloatingCard } from '../FloatingCard/FloatingCard';

export interface SituatonCardProps {
  className?: string;
  cardImage: { src: string; alt: string };
  onCardButtonClick?(): void;
  children: ReactNode;
}

export const SituationCard: FC<SituatonCardProps> = ({
  className = '',
  children,
  cardImage,
  onCardButtonClick = noop,
  ...rest
}) => (
  <FloatingCard className={`relative p-5 ${className}`} {...rest}>
    <ChevronCircleButton
      className="absolute -right-5 top-10"
      onClick={onCardButtonClick}
    />
    <img
      src={cardImage.src}
      alt={cardImage.alt}
      className="h-20 w-20 mx-auto mb-5"
    />
    {children}
  </FloatingCard>
);
