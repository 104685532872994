import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ChangeableIconList } from '../../../components/ChangeableIconList/ChangeableIconList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Relation_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Crezca su relación' : 'Build your relationship'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `¿Cómo puede construir encima de estas cualidades fuertes o conectar 
          alrededor de sus intereses?`
        : `How can you build on these strengths or connect with them around their
           interests?`}

      <ChangeableIconList className="mt-10">
        <li>
          <b className="font-bold">{isSpanish ? 'Fortaleza:' : 'Strength:'}</b>
          {isSpanish
            ? ' Mi hijo/a es fuerte y tiene muchas opiniones.'
            : ' My child is strong and has lots of opinions.'}
        </li>
      </ChangeableIconList>
      <ChangeableIconList icon="connect" className="mb-10">
        <li>
          <b className="font-bold">
            {isSpanish ? 'Cómo conectarse:' : 'How to connect:'}
          </b>
          {isSpanish
            ? ` Guarde cinco minutos cada día para escuchar sus ideas. De les su
              interés y atención completa, guardando su teléfono celular,
              trabajo, o quehaceres caseros. Busque oportunidades para
              elogiarlos.`
            : ` Set aside five minutes each day to
              listen to their ideas. Give them your full interest and attention
              by putting away cell phones, work or household chores. Look for
              opportunities to praise them.`}
        </li>
      </ChangeableIconList>
      <ChangeableIconList>
        <li>
          <b className="font-bold">{isSpanish ? 'Fortaleza:' : 'Strength:'}</b>
          {isSpanish
            ? ' Mi hijo/a es social y amigable.'
            : ' My child is social and friendly.'}
        </li>
      </ChangeableIconList>
      <ChangeableIconList icon="connect" className="mb-10">
        <li>
          <b className="font-bold">
            {isSpanish ? 'Cómo conectar:' : 'How to connect:'}
          </b>
          {isSpanish
            ? ` Pase cinco minutos cada día hablando con su hijo/a sin
              distracciones (no teléfonos, no quehaceres).`
            : ` Spend 5 minutes each day talking
              with your child without any distractions (no phones, no chores).`}
        </li>
      </ChangeableIconList>
      <ChangeableIconList>
        <li>
          <b className="font-bold">{isSpanish ? 'Fortaleza:' : 'Strength:'}</b>
          {isSpanish
            ? ' Mi hijo/a es bueno en los videojuegos.'
            : ' My child is good at video games.'}
        </li>
      </ChangeableIconList>
      <ChangeableIconList icon="connect" className="mb-10">
        <li>
          <b className="font-bold">
            {isSpanish ? 'Cómo conectar:' : 'How to connect:'}
          </b>
          {isSpanish
            ? ` Pase unos minutos cada día preguntándole a su hijo/a sobre los
              juegos que le gustan, o juegue con él/ella. Busque oportunidades
              para elogiarlo/a.`
            : ` Spend a few minutes each day asking
              your child about the games they like, or play it with them. Look
              for opportunities to praise them.`}
        </li>
      </ChangeableIconList>
    </SlideContainer>
  );
};
