import { FC } from 'react';
import { ReactComponent as CheckIcon } from '../../images/feather/check.svg';

export const FakeCheckbox: FC<{
  checked?: boolean;
  disabled?: boolean;
}> = ({ checked, disabled }) => (
  <div
    className={`absolute w-6 h-6 rounded border-3 left-0 top-0 bg-white text-white flex items-center justify-center transition-all duration-200 text-xl ${
      checked ? 'bg-blue-400 border-blue-400' : 'bg-white border-coolGray-300'
    } ${disabled ? 'bg-coolGray-300' : 'bg-white'}`}
  >
    <CheckIcon strokeWidth={5} color={disabled ? '#D1D5DB' : 'white'} />
  </div>
);
