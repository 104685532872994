import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import bribeIconSrc from '../../../images/bribe_icon.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_h: FC = () => {
  const { next } = useSlideContext();
  const { setValue, isReview } = useActivityState<{ bool: boolean | null }>({
    bool: null,
  });
  const [showToast, setShowToast] = useState(false);
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      className="flex-col px-14 font-light text-white"
      hideButton={!isReview}
    >
      <ExampleSection
        title={
          <h3 className="uppercase font-bold ml-2">
            {isSpanish ? 'Ejemplo:' : 'Example:'}
          </h3>
        }
      >
        <p className="pl-8 mt-2">
          {isSpanish
            ? `Ud. ha estado de compras con su hijo/a y quiere ir a una tienda más para buscar ropa para Ud. Su hijo/a se quiere ir a la casa. Ud. le da su teléfono para que se entretenga.`
            : `You’ve been out shopping with your child, and you want to go to one
              more store to look at clothes for yourself. Your kid just wants to go
              home. You give them your phone to entertain them.`}
        </p>
      </ExampleSection>
      <p className="text-red-600 text-center font-bold mt-10">
        {isSpanish
          ? '¿En este ejemplo es un soborno o premio?'
          : 'Is this example a bribe or a reward?'}
      </p>
      <PillButton
        theme={ButtonTheme.WHITE}
        className="w-52 self-center mt-10 mb-4 pt-2 pb-2"
        onClick={() => {
          setValue({ bool: true });
          setShowToast(true);
        }}
      >
        {isSpanish ? 'Soborno' : 'Bribe'}
      </PillButton>
      <PillButton
        theme={ButtonTheme.WHITE}
        className="w-52 self-center pt-2 pb-2"
        onClick={() => {
          setValue({ bool: false });
          setShowToast(true);
        }}
      >
        {isSpanish ? 'Premio' : 'Reward'}
      </PillButton>
      {showToast && (
        <BottomToast
          buttonLabel={isSpanish ? 'Siguiente' : 'Continue'}
          onButtonClick={next}
          buttonTheme={ButtonTheme.YELLOW}
        >
          <div className="flex flex-col justify-center items-center px-5 pb-7">
            <img
              src={bribeIconSrc}
              alt={isSpanish ? 'soborno' : 'bribe'}
              className="w-32"
            />
            <div className="bg-blue-350 rounded-lg p-5 pb-3 text-blue-800 text-center font-bold">
              {isSpanish
                ? `Esto es un soborno, porque el teléfono se le dio antes 
                  de que el comportamiento pase.`
                : `That is a bribe, because the phone was given before 
                  the behavior happened.`}
            </div>
          </div>
        </BottomToast>
      )}
    </SlideContainer>
  );
};
