import { FC } from 'react';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { RuleCloud } from '../../../components/RuleCloud/RuleCloud';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

export const RulesAndConsequences8Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const { next } = useSlideContext();
  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <RuleCloud
        cloudStatement={
          isEnglish
            ? 'But to your child, rules might often feel like this.'
            : 'Pero para su hijo/a, las reglas se pueden sentirs así.'
        }
        bearImageOption="BABY_BOY"
        style={{ paddingBottom: 130 }}
        productKey={ProductKey.EARLY_CHILDHOOD}
      />
      <BottomToast
        buttonLabel={isEnglish ? 'Next' : 'Siguiente'}
        onButtonClick={next}
        animate={false}
      />
    </SlideContainer>
  );
};
