import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { Feedback } from '../../../components/Feedback/Feedback';
import bribeIconSrc from '../../../images/bribe_icon.png';
import rewardIconSrc from '../../../images/ribbon_reward_icon.png';
import { useSlideContext } from '../../../contexts/slideContext';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

enum Choice {
  BRIBE = 'bribe',
  REWARD = 'reward',
}

type AnswerState = Record<string, Choice | undefined>;

export const PositiveParenting25Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);

  const { value, setValue, isReview, activity } = useActivityState<AnswerState>(
    {
      example1: undefined,
      example2: undefined,
    },
  );

  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const bribeRewardExamples = isEnglish
    ? englishBribeRewardExamples
    : spanishBribeRewardExamples;

  const handleClick = (choice: Choice) => {
    setValue({
      ...value,
      [`example${questionIndex + 1}`]: choice,
    });
    setShowFeedback(true);
  };

  const handleToastButton = () => {
    setShowFeedback(false);
    if (questionIndex + 1 !== bribeRewardExamples.length) {
      setQuestionIndex((curIndex) => curIndex + 1);
    } else if (!isReview) {
      setShowOverlay(true);
    } else {
      next();
    }
  };

  const { example, isBribe, feedback } = bribeRewardExamples[questionIndex];

  const endOfActivity = questionIndex + 1 === bribeRewardExamples.length;
  const continueText = isEnglish ? 'Continue' : 'Siga';
  const finishActivityText = isEnglish
    ? 'Finish Activity'
    : 'Termine la Actividad';
  return (
    <SlideContainer
      className="flex-col items-center text-white px-14 font-light"
      buttonText={isEnglish ? 'Continue' : 'Siga'}
      hideButton={!isReview}
    >
      <ExampleSection
        icon="star"
        className="my-5"
        title={
          <h2 className="font-semibold uppercase ml-2.5 text-lg">
            {isEnglish ? 'Example:' : 'Ejemplo:'}
          </h2>
        }
      >
        <p className="ml-8 mt-2.5">{example}</p>
      </ExampleSection>
      <span className="mb-10 font-bold text-red-600 text-center">
        {isEnglish ? 'Is this example a bribe' : '¿Este ejemplo es un soborno'}
        <br /> {isEnglish ? 'or a reward?' : 'o una recompensa?'}
      </span>
      <PillButton
        className="w-52 mb-2.5 pt-2 pb-2"
        theme={ButtonTheme.WHITE}
        onClick={() => handleClick(Choice.BRIBE)}
      >
        {isEnglish ? 'Bribe' : 'Soborno'}
      </PillButton>
      <PillButton
        className="w-52 pt-2 pb-2"
        theme={ButtonTheme.WHITE}
        onClick={() => handleClick(Choice.REWARD)}
      >
        {isEnglish ? 'Reward' : 'Recompensa'}
      </PillButton>
      {showFeedback && (
        <BottomToast
          buttonLabel={!endOfActivity ? continueText : finishActivityText}
          buttonTheme={ButtonTheme.YELLOW}
          onButtonClick={handleToastButton}
          fixedButtonWidth={!(endOfActivity && !isEnglish)}
        >
          <img
            className={`self-center ${isBribe ? 'w-32' : 'w-24 my-4'}`}
            src={isBribe ? bribeIconSrc : rewardIconSrc}
            alt={isBribe ? 'bribe' : 'reward'}
          />
          <Feedback className="mb-7 mx-5" hideIcon>
            {feedback}
          </Feedback>
        </BottomToast>
      )}
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};

const englishBribeRewardExamples = [
  {
    example: `You are talking on the phone and your kids are demanding 
      your attention, you give them a cookie to entertain them.`,
    isBribe: true,
    feedback: `That is a bribe, beacuse the cookie was given 
      before the behavior happened.`,
  },
  {
    example: `You are about to get on the phone and you say to your kids, 
    “If you can play with your legos for 5 minutes while I am on the phone, 
    you can have a cookie when I get off.”`,
    isBribe: false,
    feedback: `This is a reward, because the cookie is given to 
      the child after they are quiet for 5 minutes and is used 
      as an incentive.`,
  },
];

const spanishBribeRewardExamples = [
  {
    example: `Está hablando por teléfono y sus niños demandan su atención, 
        les da una galleta para entretenerlos.`,
    isBribe: true,
    feedback: `Eso es un soborno, porque la galleta se entregó antes de 
        que ocurriera el comportamiento.`,
  },
  {
    example: `Está a punto de hablar por teléfono y les dice a sus niños: 
        "Si pueden jugar con sus legos durante 5 minutos mientras estoy 
        hablando por teléfono, pueden comer una galleta cuando termino".`,
    isBribe: false,
    feedback: `Esta es una recompensa, porque la galleta se le da al niño/a 
        después de que se haya callado durante 5 minutos y se usa como incentivo.`,
  },
];
