import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Directions_e: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Dar Instrucciones' : 'Giving Directions'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Cuando su hijo/a ya sepa lo que se espera de él/ella, haga preguntas para 
          asegurarse que sabe lo que se le ha pedido. Acuérdese, el expresar su 
          apreciación puede hacer mucho para reforzar los comportamientos positivos.`
        : `Once your child knows what you expect of them, follow-up by making sure they
          did what you asked for. Remember, expressing your appreciation can go a long
          way to help reinforce their positive behavior.`}
    </SlideContainer>
  );
};
