import { Language } from 'shared/lib/types/Language';

type Field =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'childFirstName'
  | 'childLastName';

type FieldTranslations = Record<Field, string>;

const FIELD_TRANSLATIONS_BY_LANGUAGE: Record<Language, FieldTranslations> = {
  english: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone',
    childFirstName: 'Child First Name',
    childLastName: 'Child Last Name',
  },
  spanish: {
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    email: 'Correo Electrónico',
    phoneNumber: 'Teléfono',
    childFirstName: 'Primer nombre del niño',
    childLastName: 'Apellido',
  },
};

const ENGLISH_ERRORS = {
  callContact: 'call',
  emailContact: 'email',
  visitSite: 'visit',
  emailOnFile:
    'Please make sure to use the email address on file with this organization.',
  contactAssistance: 'For assistance, please',
  isRequired: 'is required',
  invalidPhoneNumber: 'Phone must be a valid phone number.',
  emailNotInRecords: 'The email you have entered does not match our records.',
  notEnabled: 'Sorry, this organization has not enabled self-registration.',
  productNotFound:
    'Sorry, the product does not exist. Please check the invite URL you received.',
};

type ErrorBundle = Record<keyof typeof ENGLISH_ERRORS, string>;

const SPANISH_ERRORS: ErrorBundle = {
  callContact: 'llame a',
  emailContact: 'envíe un correo electrónico',
  visitSite: 'visita',
  emailOnFile:
    'Asegúrese de utilizar el correo electrónico registrado con esta organización.',
  contactAssistance: 'Para obtener ayuda,',
  isRequired: 'es requerido',
  invalidPhoneNumber: 'El número de teléfono debe ser válido.',
  emailNotInRecords:
    'El correo electrónico que ha dado no coincide con nuestros registros.',
  notEnabled:
    'Lo sentimos, esta organización no ha habilitado el autorregistro.',
  productNotFound:
    'Lo sentimos, el producto no existe. Verifique la URL de invitación que recibió.',
};

const ERRORS_BY_LANGUAGE: Record<Language, ErrorBundle> = {
  english: ENGLISH_ERRORS,
  spanish: SPANISH_ERRORS,
};

const GENERAL_TRANSLATIONS_ENGLISH = {
  mainHeading: 'Registration',
  successHeading: 'Success!',
  successMessage:
    'You should receive an email from Family Check-Up in a moment. Please click the account activation link in that email to complete your registration.',
  registerButton: 'Register',
  trademark:
    'Family Check-up® is a Registered Trademark of the University of Oregon',
  privacyPolicy: 'privacy policy',
};

type GeneralTranslationBundle = Record<
  keyof typeof GENERAL_TRANSLATIONS_ENGLISH,
  string
>;

const GENERAL_TRANSLATIONS_SPANISH: GeneralTranslationBundle = {
  mainHeading: 'Registración',
  successHeading: '¡Éxito!',
  successMessage:
    'Debería recibir un correo electrónico de Family Check-Up en un momento. Haga clic en el enlace de activación en ese correo electrónico para completar su registro.',
  registerButton: 'Registrarse',
  trademark:
    'Family Check-up® es una marca registrada de la Universidad de Oregón',
  privacyPolicy: 'política de privacidad',
};

const GENERAL_TRANSLATIONS_BY_LANGUAGE: Record<
  Language,
  GeneralTranslationBundle
> = {
  english: GENERAL_TRANSLATIONS_ENGLISH,
  spanish: GENERAL_TRANSLATIONS_SPANISH,
};

export function getRegistrationTranslations(language: Language): {
  translatedFields: FieldTranslations;
  errorBundle: ErrorBundle;
  generalTranslations: GeneralTranslationBundle;
} {
  return {
    translatedFields: FIELD_TRANSLATIONS_BY_LANGUAGE[language],
    errorBundle: ERRORS_BY_LANGUAGE[language],
    generalTranslations: GENERAL_TRANSLATIONS_BY_LANGUAGE[language],
  };
}
