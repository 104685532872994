import { FC } from 'react';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences13Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Logical Consequences' : 'Consecuencias lógicas'}
      className="px-14 text-white flex-col font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Use logical consequences if you can. Small consequences work just as
            well as large ones.
          </>
        ) : (
          <>
            Use las consecuencias lógicas si puede. Consecuencias pequeñas
            trabajan igual que las grandes.
          </>
        )}
      </p>

      <ExampleSection
        className="mt-6"
        title={
          <h2 className="font-bold uppercase text-lg ml-2.5">
            {isEnglish ? 'Example:' : 'Ejemplo:'}
          </h2>
        }
      >
        <p className="mt-2 ml-8">
          {isEnglish ? (
            <>
              If you can’t use the markers without drawing on the table, they
              will be taken away.
            </>
          ) : (
            <>
              Si dibujas en la mesa cuando estes usando los marcadores, te los
              voy a quitar.
            </>
          )}
        </p>
      </ExampleSection>
    </SlideContainer>
  );
};
