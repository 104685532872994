import { FC } from 'react';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare18Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const healthyRoutinesList = isEnglish
    ? englishHealthyRoutinesList
    : spanishHealthyRoutinesList;
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Healthy Routines' : 'Rutinas Saludables'}
      buttonText={isEnglish ? 'Build My Plan' : 'Construir mi plan'}
      buttonTheme={ButtonTheme.YELLOW}
      className="flex-col px-14"
    >
      <p>
        {isEnglish
          ? 'Making changes can be hard, so:'
          : 'Hacer cambios puede ser difícil, así que:'}
      </p>
      <YellowCircleList className="mt-6">
        {healthyRoutinesList.map((healthyRoutineItem, i) => {
          return <li key={i}>{healthyRoutineItem}</li>;
        })}
      </YellowCircleList>
      <p className="mt-6">
        {isEnglish
          ? 'On the next screen, choose 3 things you would like to add to your family’s daily routine.'
          : 'En la siguiente pantalla, elija 3 cosas que le gustaría agregar a la rutina diaria de su familia.'}
      </p>
    </SlideContainer>
  );
};

const englishHealthyRoutinesList = [
  'Start small — change only one thing at a time',
  'Choose something realistic for your family',
  'Include your partner and children in your plan',
  'After you tried it, talk about how it went — did you like it? Did it make your life easier?',
  'Reward success!',
];

const spanishHealthyRoutinesList = [
  'Comience poco a poco: cambie solo una cosa a la vez.',
  'Elija algo realista para su familia.',
  'Incluya a su pareja e hijos en su plan.',
  'Después de intentarlo, hable sobre cómo le fue: ¿le gustó? ¿Le hizo la vida más fácil?',
  '¡Recompense el éxito!',
];
