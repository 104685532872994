import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Clear_Rules_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Reglas Claras' : 'Clear Rules'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Las reglas claras del hogar pueden ayudar a reducir las 
          conductas problemáticas y los malentendidos con su hijo/a 
          y mejorar las relaciones familiares.`
        : `Clear household rules can help to reduce problem behaviors and
          misunderstandings with your child, and improve family relationships.`}
    </SlideContainer>
  );
};
