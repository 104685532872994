import { FC, ReactNode } from 'react';
import { QuizCompleteOverlayIconType } from '../QuizCompletionOverlay/QuizCompletionOverlay';

const IconMessage: FC<{
  icon?: QuizCompleteOverlayIconType;
  activityMessage: ReactNode;
  className?: string;
}> = ({ icon, activityMessage, className = '', ...rest }) => (
  <div
    className={`relative w-11/12 rounded-lg text-blue-800 bg-teal-200 text-base font-bold leading-5 text-center px-4 py-8 ${className}`}
    {...rest}
  >
    {!!icon && (
      <div
        className={`
        absolute bg-contain bg-no-repeat bg-center -top-5 right-0 left-0 m-auto w-10 h-10 ${
          icon === 'thumbs-up' ? 'bg-thumbs-up' : 'bg-alert'
        }`}
      />
    )}
    {activityMessage}
  </div>
);

export default IconMessage;
