import { useCallback, useEffect, useRef } from 'react';
import { api } from '../api';

/**
 * Saves all unsaved activity responses every few seconds.
 * Returns a function for adding new responses to the save queue.
 */
export function useActivitySaveInterval({
  orgId,
  productId,
  unitId,
}: {
  orgId: number;
  productId: number;
  unitId: number;
}) {
  const unsavedActivityRef = useRef({
    dirty: false,
    map: new Map<number, unknown>(),
  });

  useEffect(() => {
    const saveUnsavedResponses = () => {
      if (!unsavedActivityRef.current.dirty) {
        return;
      }

      for (const [
        activityId,
        value,
      ] of unsavedActivityRef.current.map.entries()) {
        api
          .setActivityResponse({
            orgId,
            productId,
            unitId,
            activityId,
            value,
          })
          .catch((error) =>
            console.error(`Failed to save activity response`, error),
          );
      }

      unsavedActivityRef.current.map.clear();
      unsavedActivityRef.current.dirty = false;
    };

    const interval = setInterval(saveUnsavedResponses, 3000);

    return () => {
      clearInterval(interval);
      saveUnsavedResponses(); // save
    };
  }, [orgId, productId, unitId]);

  const queueUnsavedActivity = useCallback(
    ({ activityId, value }: { activityId: number; value: unknown }) => {
      unsavedActivityRef.current.map.set(activityId, value);
      unsavedActivityRef.current.dirty = true;
    },
    [],
  );

  return queueUnsavedActivity;
}
