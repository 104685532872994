import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare10Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      slideTitle={isEnglish ? 'Managing Stress' : 'Manejar El Estrés'}
      className="text-white font-light"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Leaf_Stream_Mood_v2'
          : 'early_childhood_spanish_Leaf_Stream_Mood_v2'
      }
      videoTitle={isEnglish ? 'Let thoughts go' : 'Deja ir los pensamientos'}
      upperSlideContent={
        <p className="px-14">
          {isEnglish ? (
            <>
              One useful strategy to try when you’re stressed is to let go of
              the thoughts that are adding to your bad feelings. Watch this
              video to see how.
            </>
          ) : (
            <>
              Una estrategia útil que puede probar cuando está estresado/a es
              dejar de lado los pensamientos que se suman a sus malos
              sentimientos. Mire este video para ver cómo.
            </>
          )}
        </p>
      }
      bearCharacter="BABY_BEAR"
    />
  );
};
