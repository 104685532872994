import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { Blurb } from '../../../components/Blurb/Blurb';
import bearWomanReadingSrc from '../../../images/FCU_listening_reading.png';
import bearWomanGlancingOverSrc from '../../../images/FCU_listening_glancing_over.png';
import bearWomanLookingOverSrc from '../../../images/FCU_listening_looking_over.png';
import bearWomanNoddingSrc from '../../../images/FCU_listening_nodding.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type ListeningScene = {
  img: string;
  alt: { english: string; spanish: string };
  description?: { english: string; spanish: string };
  blurb?: { english: string; spanish: string };
  blurbClasses?: string;
};

const listeningScenes: ListeningScene[] = [
  {
    img: bearWomanReadingSrc,
    alt: {
      english: 'bear woman reading a book',
      spanish: 'oso mujer leyendo un libro',
    },
  },
  {
    img: bearWomanGlancingOverSrc,
    alt: {
      english: 'bear woman glancing over from book',
      spanish: 'oso mujer mirando por encima del libro',
    },
  },
  {
    img: bearWomanLookingOverSrc,
    alt: {
      english: 'bear woman looking over from book',
      spanish: 'mujer oso mirando por encima del libro',
    },
    description: {
      english: 'Look at your child.',
      spanish: 'Mire a su hijo/a',
    },
  },
  {
    img: bearWomanNoddingSrc,
    alt: {
      english: 'bear woman looking over nodding',
      spanish: 'oso mujer mirando por encima asintiendo con la cabeza',
    },
    description: {
      english: `Nod your head “yes” or say “uh-huh” in a positive (or at least neutral) tone during pauses.`,
      spanish:
        'Asienta con la cabeza "sí" o diga "ajá" en un tono positivo (o al menos neutral) durante las pausas',
    },
    blurb: { english: 'Yes.', spanish: 'Si.' },
    blurbClasses: 'mr-5 sm:mr-0 md:-mr-20',
  },
  {
    img: bearWomanLookingOverSrc,
    alt: {
      english: 'bear woman looking over from book',
      spanish: 'mujer oso mirando por encima del libro',
    },
    description: {
      english: `Nod your head “yes” or say “uh-huh” in a positive (or at least neutral) tone during pauses.`,
      spanish:
        'Asienta con la cabeza "sí" o diga "ajá" en un tono positivo (o al menos neutral) durante las pausas',
    },

    blurb: { english: 'Uh-huh.', spanish: 'Uh-huh' },
    blurbClasses: 'sm:-mr-5 md:-mr-28',
  },
  {
    img: bearWomanLookingOverSrc,
    alt: {
      english: 'bear woman looking over from book',
      spanish: 'mujer oso mirando por encima del libro',
    },
    description: {
      english: `Don’t say anything else… your child will keep talking.`,
      spanish: 'No diga nada más ... su hijo/a seguirá hablando',
    },
  },
];

export const Com_Listening_a: FC = () => {
  const [sceneIndex, setSceneIndex] = useState(0);
  const lastScene = sceneIndex === listeningScenes.length - 1;
  const {
    img,
    alt,
    description,
    blurb,
    blurbClasses = '',
  } = listeningScenes[sceneIndex];
  const { isSpanish, language } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Enseñe que le Escucha' : "Show you're listening"}
      className="flex-col font-light text-white"
      // if not defined button click will default to next function
      onButtonClick={
        !lastScene ? () => setSceneIndex((scene) => scene + 1) : undefined
      }
    >
      <p className="px-14 mb-5">
        {isSpanish
          ? `Sus palabras y acciones demuestran que está interesado/a y hacen que 
            su hijo/a siguiente hablando.`
          : `Your words and actions show that you are interested and keep your child
            talking.`}
      </p>
      <div className="relative">
        <img src={img} alt={alt[language]} className="w-full" />
        {blurb && (
          <div
            className={`absolute z-10 transform top-1/2 right-1/4 -mt-5 scale-y-flip sm:right-1/2 ${blurbClasses}`}
          >
            <Blurb className="p-4 sm:p-8">
              <p className="transform scale-y-flip">{blurb[language]}</p>
            </Blurb>
          </div>
        )}
      </div>
      {description && (
        <p className="font-bold text-center mt-3 px-16">
          {description[language]}
        </p>
      )}
    </SlideContainer>
  );
};
