import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import infoIconSrc from '../../../images/info_icon.png';
import livingRoomSrc from '../../../images/rules_and_consequences_living_room.png';
import { Concern } from 'shared/lib/types/Concern';
import { useActivityState } from '../../../hooks/useActivityState';
import { InfoPopoverButton } from '../../../components/InfoPopoverButton/InfoPopoverButton';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences4Slide: FC = () => {
  const [activeConcern, setActiveConcern] = useState<string | null>(null);
  const { value, setValue, isReview } =
    useActivityState<Record<string, boolean>>();
  const { isEnglish } = useProductLanguage();
  const canProgress = isReview || value;
  const safetyConcerns = isEnglish
    ? englishSafetyConcerns
    : spanishSafetyConcerns;

  return (
    <SlideContainer
      hideButton={!canProgress}
      className="flex-col text-white font-light"
    >
      <p className="px-14">
        {isEnglish ? (
          <>
            Tap on a
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            in the living room below to learn more about everyday safety
            concerns in your home.
          </>
        ) : (
          <>
            Oprima una
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            en la sala a continuación para obtener más información sobre las
            preocupaciones diarias de seguridad en su hogar.
          </>
        )}
      </p>
      <div className="relative pt-24 flex flex-col items-center max-w-full min-w-93 w-93 sm:w-151 self-center">
        <img src={livingRoomSrc} alt="living room" className="w-full" />
        {Object.entries(safetyConcerns).map(([key, info]) => (
          <InfoPopoverButton
            key={key}
            onInfoClicked={() => {
              setActiveConcern((curConcernKey) =>
                curConcernKey !== key ? key : null,
              );
              setValue({});
            }}
            onInfoPopoverClosed={() => setActiveConcern(null)}
            popoverText={info.concern}
            className={info.iconClassName}
            popoverClassName={info.tooltipClassName}
            popoverArrowPosition={info.caretPosition}
            showInfoPopover={activeConcern === key}
          />
        ))}
      </div>
    </SlideContainer>
  );
};

const englishSafetyConcerns: Record<number, Concern> = {
  1: {
    concern:
      'Secure furniture that can topple (bookcases, Tvs, chests of drawers) to the walls.',
    iconClassName: 'bottom-32 right-20 sm:bottom-52 sm:right-40',
    tooltipClassName: 'w-60 right-12 -bottom-18',
    caretPosition: 'rightTop',
  },
  2: {
    concern: 'Cover electrical outlets.',
    iconClassName: 'bottom-20 left-10 sm:bottom-32 sm:left-20',
    tooltipClassName: 'w-60 -left-4',
    caretPosition: 'bottomLeft',
  },
  3: {
    concern: 'Cushion sharp corners, such as on coffee tables.',
    iconClassName: 'bottom-12 left-20 sm:bottom-24 sm:left-32',
    tooltipClassName: 'w-60 -left-4',
    caretPosition: 'bottomLeft',
  },
  4: {
    concern: "Move or store breakables out of child's reach.",
    iconClassName: 'bottom-12 right-14 sm:bottom-36 sm:right-24',
    tooltipClassName: 'w-60 -right-1',
    caretPosition: 'bottomRight',
  },
  5: {
    concern: 'Keep alcohol and other drugs out of reach.',
    iconClassName: 'bottom-20 sm:bottom-32',
    tooltipClassName: 'w-60 -right-26',
    caretPosition: 'bottom',
  },
};

const spanishSafetyConcerns: Record<number, Concern> = {
  1: {
    concern:
      'Asegure los muebles que puedan caerse (libreros, televisores, cajoneras) a las paredes.',
    iconClassName: 'bottom-32 right-20 sm:bottom-52 sm:right-40',
    tooltipClassName: 'w-60 right-12 -bottom-18',
    caretPosition: 'rightTop',
  },
  2: {
    concern: 'Cubra las enchúfeles eléctricos.',
    iconClassName: 'bottom-20 left-10 sm:bottom-32 sm:left-20',
    tooltipClassName: 'w-60 -left-4',
    caretPosition: 'bottomLeft',
  },
  3: {
    concern: 'Acojine las esquinas afiladas, como en las mesas de café.',
    iconClassName: 'bottom-12 left-20 sm:bottom-24 sm:left-32',
    tooltipClassName: 'w-60 -left-4',
    caretPosition: 'bottomLeft',
  },
  4: {
    concern:
      'Mueva o guarde los objetos frágiles fuera del alcance de los niños.',
    iconClassName: 'bottom-12 right-14 sm:bottom-36 sm:right-24',
    tooltipClassName: 'w-60 -right-1',
    caretPosition: 'bottomRight',
  },
  5: {
    concern: 'Guarde el alcohol y otras drogas fuera del alcance de los niños.',
    iconClassName: 'bottom-20 sm:bottom-32',
    tooltipClassName: 'w-60 -right-26',
    caretPosition: 'bottom',
  },
};
