import { FC, MouseEventHandler } from 'react';
import { ReactComponent as XIcon } from '../../images/feather/x.svg';

export const CircleXButton: FC<{
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}> = ({ className = '', onClick = () => {}, ...rest }) => (
  <button
    onClick={onClick}
    className={`flex justify-center items-center h-10 w-10 p-0 overflow-hidden rounded-full bg-white text-blue-700 can-hover:hover:bg-gray-300 ${className}`}
    {...rest}
  >
    <XIcon strokeWidth={2} className="h-4/6 w-4/6" />
  </button>
);
