import './org-branding-markdown.css';
import { FC, FormEvent, useState } from 'react';
import useInputState from '@emberex/react-utils/lib/useInputState';
import { useUserOptional } from '../../contexts/userContext';
import { PillButton } from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { Link, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import {
  usePatchSearchParams,
  useSearchParams,
} from '../../hooks/useSearchParams';
import { Switch } from '../../components/Switch/Switch';
import { Language } from 'shared/lib/types/Language';
import { tradeMarkText } from '../../utils/tradeMark';
import { Logo } from '../../components/Logo/Logo';

export const LoginPage: FC = (props) => {
  const { login } = useUserOptional();
  const location = useLocation();
  const searchParams = useSearchParams();
  const patchSearchParams = usePatchSearchParams();
  const language = (searchParams.get('language') ?? 'english') as Language;
  const isSpanish = language === 'spanish';
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [username, handleUsernameChange] = useInputState();
  const [password, handlePasswordChange] = useInputState();
  useDocumentTitle(location.pathname === '/' ? '' : 'Login');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setSubmitting(true);
      await login({
        username,
        password,
      });
    } catch (error) {
      setSubmitError(error as Error);
      setSubmitting(false);
    }
  };

  return (
    <div {...props} className="min-h-screen flex flex-col  bg-blue-700">
      {/* Login form */}
      <div className="bg-blue-700">
        <div className="container max-w-full mx-auto flex flex-col px-4 pb-24 pt-36">
          <Logo className="flex-shrink-0 self-center" />
          <form onSubmit={handleSubmit} className="flex flex-col mt-16">
            {submitError && (
              <p className="text-rose-500 text-center py-2">
                {submitError.message}
              </p>
            )}
            <TextInput
              value={username}
              onChange={handleUsernameChange}
              placeholder={isSpanish ? 'Correo Electronico' : 'Email Address'}
              autoComplete="current-username"
              className="h-12 w-72 mx-auto pl-5"
            />
            <TextInput
              value={password}
              onChange={handlePasswordChange}
              type="password"
              placeholder={isSpanish ? 'Contraseña' : 'Password'}
              autoComplete="current-password"
              className="h-12 w-72 mx-auto pl-5 mt-4"
            />
            <PillButton
              disabled={submitting}
              className="w-60 mt-10 px-0 self-center"
            >
              {isSpanish ? 'Iniciar Sesión' : 'Sign In'}
            </PillButton>
          </form>

          <div className="flex flex-row justify-center items-center text-white mt-5">
            <label className="text-xs" htmlFor="language">
              ENG
            </label>
            <Switch
              id="language"
              className="ml-1.5 mr-1"
              checked={isSpanish}
              onChange={(event) =>
                patchSearchParams({
                  language: event.currentTarget.checked ? 'spanish' : undefined,
                })
              }
            />
            <label className="text-xs" htmlFor="language">
              ESP
            </label>
          </div>
          <p className="text-white text-center text-xs mt-2">
            {isSpanish
              ? 'Tap to view in English.'
              : 'Toque para ver en español.'}
          </p>

          <Link
            to="/forgot-password"
            className="text-blue-400 self-center mt-10"
          >
            {isSpanish ? 'contraseña olvidada' : 'forgot password?'}
          </Link>
          <div className="text-white text-center text-xs mt-12 w-72 max-w-full mx-auto">
            {tradeMarkText(isSpanish)}
          </div>
          <Link
            to={{
              pathname: location.pathname,
              search: `?privacy-policy&language=${language}`,
            }}
            className="text-blue-400 text-xs self-center mt-1"
          >
            {isSpanish ? 'política de privacidad' : 'privacy policy'}
          </Link>
        </div>
      </div>
    </div>
  );
};
