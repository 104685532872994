import { FC } from 'react';

const sizes = {
  small: 'w-4 h-4 leading-3.5 text-xs',
  medium: 'w-5 h-5 leading-4.5 text-sm',
};

export const CoachBadge: FC<{
  className?: string;
  size?: keyof typeof sizes;
}> = ({ className = '', size = 'small', ...rest }) => (
  <div
    {...rest}
    aria-label="provider"
    className={`${sizes[size]} inline-block flex-shrink-0 font-normal text-white bg-blue rounded-full text-center ${className}`}
  >
    p
  </div>
);
