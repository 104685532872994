import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences20Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      bearCharacter="YOUNG_GIRL_THUMBS_UP_WINKING"
      upperSlideContent={
        <p className="px-14 text-white font-light">
          {isEnglish ? (
            <>Let's watch the bear family use ignoring to manage whining.</>
          ) : (
            <>
              Veamos cómo la familia de los osos usa el ignorar para manejar el
              gimoteo.
            </>
          )}
        </p>
      }
      videoTitle={
        <h2 className="text-lg text-blue-800 px-20">
          {isEnglish ? 'Ignoring' : 'Ignorando'}
        </h2>
      }
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_09'
          : 'early_childhood_spanish_Ani_09'
      }
    />
  );
};
