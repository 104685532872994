import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting6Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Specific Praise' : 'Elogio específico'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Specific praise is direct and focuses on the behavior your child is
            doing that you want to increase. Specific praise helps your child
            learn the skills you want them to do more of on a daily basis. It is
            the most effective praise to use with your child.
          </>
        ) : (
          <>
            El elogio específico es directo y enfocado en los comportamientos
            que su niño/a está haciendo que Ud. quiere que aumente. El elogio
            específico ayuda a su niño/a a aprender las habilidades que Ud.
            quiere que él/ella haga diariamente. Es el elogio más efectivo que
            puede usar con su niño/a.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
