import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Stress_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Bienestar emocional y afrontamiento del estrés'
          : 'Emotional well-being and coping with stress'
      }
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Los cambios en el estado de ánimo y el comportamiento son comunes
          especialmente durante los momentos difíciles. Su hijo/a puede verse
          afectado/a por problemas grandes y pequeños, desde desafíos personales
          hasta eventos mundiales.`
        : `Changes in mood and behavior are common, especially during difficult
          times. Your child can be affected by problems large and small, from
          personal challenges to worldwide events.`}
    </SlideContainer>
  );
};
