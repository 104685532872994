import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import { Mood } from 'shared/lib/types/Mood';
import { MoodQuestionInput } from '../../../components/MoodQuestionInput/MoodQuestionInput';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type Question =
  | 'hygiene'
  | 'healthyMeals'
  | 'sleep'
  | 'bedtimeRoutine'
  | 'friendTime'
  | 'hobbies'
  | 'chores'
  | 'excercises'
  | 'schoolwork';

const englishQuestions = [
  'Takes care of personal hygiene',
  'Eats regular healthy meals',
  'Gets plenty of sleep',
  'Has a bedtime routine',
  'Spends time with friends',
  'Does enjoyable activities',
  'Does chores',
  'Exercises',
  'Does schoolwork',
];

const spanishQuestions = [
  'Cuida su higiene personal',
  'Come comidas saludables regularmente',
  'Duerme lo suficiente',
  'Tiene una rutina a la hora de acostarse',
  'Pasa tiempo con sus amigos',
  'Hace actividades divertidas. ',
  'Hace quehaceres de la casa.',
  'Hace ejercicio',
  'Hace su tarea',
];

export const SSS_Healthy_c: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<
    { [key in Question]: Mood | null }
  >({
    hygiene: null,
    healthyMeals: null,
    sleep: null,
    bedtimeRoutine: null,
    friendTime: null,
    hobbies: null,
    chores: null,
    excercises: null,
    schoolwork: null,
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleSubmit = () => {
    if (isReview) {
      next();
    } else {
      setValue(value); // Set activity completed even if no values were changed
      setShowOverlay(true);
    }
  };

  const values = Object.values(value);

  const showNext = isReview || values.filter((val) => val !== null).length >= 3;

  const content = isSpanish ? spanishQuestions : englishQuestions;

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas Saludables' : 'Healthy Routines'}
      className="flex-col px-14 font-light text-white"
      hideButton={!showNext}
      onButtonClick={handleSubmit}
    >
      {isSpanish
        ? `Califique su satisfacción con lo bien que su hijo hace las 
          siguientes cosas todos los días.`
        : `Rate your satisfaction with how well your child does the 
          following things each day.`}

      <div className="flex flex-col justify-center items-center mt-5">
        {(Object.keys(value) as (keyof typeof value)[]).map((key, idx) => (
          <MoodQuestionInput
            key={key}
            value={value[key]}
            onChange={(hygiene) => setValue({ ...value, [key]: hygiene })}
            question={content[idx]}
          />
        ))}
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY_WINKING"
      />
    </SlideContainer>
  );
};
