import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences17Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Ignoring' : 'Ignorando'}
      className="px-14 text-white font-light flex-col"
    >
      <p>
        {isEnglish ? (
          <>
            You may think ignoring is not a parenting skill. Ignoring is one of
            the most effective parenting skills that you can use because it
            works quickly to decrease many problem behaviors.
          </>
        ) : (
          <>
            Puede pensar que ignorar no es una habilidad de crianza. Sin
            embargo, ignorar es una de las habilidades de crianza más efectivas
            que puede usar porque funciona rápidamente para disminuir muchos
            comportamientos problemáticos.
          </>
        )}
      </p>

      <p>
        {isEnglish ? (
          <>
            What is ignoring? Ignoring is an intentional parenting skill where
            you withhold attention to behaviors that you want your child to stop
            doing. Ignoring is always paired with providing positive attention
            for good behavior.
          </>
        ) : (
          <>
            ¿Qué es ignorar? Ignorar es una habilidad de crianza intencional en
            la que no presta atención a los comportamientos que desea que su
            hijo/a deje de hacer. Ignorar siempre se combina con brindar
            atención positiva por un buen comportamiento.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
