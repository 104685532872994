import { FC, ReactNode } from 'react';
import Clip from '../../images/Clip.png';
import { CircleXButton } from '../CircleXButton/CircleXButton';

export const ClipBoard: FC<{
  className?: string;
  title: ReactNode;
  onClose(): unknown;
}> = ({ className = '', children, title, onClose, ...rest }) => (
  <div className={`flex flex-1 items-start bg-blue-900 ${className}`} {...rest}>
    <div
      className="relative flex-1 bg-white border-10 border-t-16 border-b-16
      border-brown rounded-lg p-3"
    >
      <CircleXButton
        onClick={() => onClose()}
        className="absolute top-2 right-2 shadow-lg"
      />
      <img
        src={Clip}
        alt=""
        className="absolute z-10 -top-9 right-0 left-0 m-auto"
      />
      <div className="text-blue-400">{title}</div>
      {children}
    </div>
  </div>
);
