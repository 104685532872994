import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting23Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const strategyItems = isEnglish ? englishStrategyItems : spanishStrategyItems;
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Using incentives and rewards'
          : 'Use de incentivos y recompensas'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            For this strategy to work you must notice that your child did what
            you wanted, and then follow up by giving them the reward!
          </>
        ) : (
          <>
            Para que esta estrategia funcione, debe notar que su niño/a hizo lo
            que usted quería y luego continuar dándole la recompensa.
          </>
        )}
      </p>
      <YellowCircleList className="my-5">
        {strategyItems.map(({ boldedText, text }, i) => {
          return (
            <li key={i}>
              <b className="font-bold">{boldedText}</b> {text}
            </li>
          );
        })}
      </YellowCircleList>
    </SlideContainer>
  );
};

const englishStrategyItems = [
  {
    boldedText: 'Incentive:',
    text: 'Tell your child if you put your shoes away when you come inside from playing, you will get a juice box',
  },
  {
    boldedText: 'Notice:',
    text: 'Your child comes inside from playing and puts their shoes away',
  },
  {
    boldedText: 'Reward:',
    text: 'You thank them for doing their job and give them a juice box',
  },
];

const spanishStrategyItems = [
  {
    boldedText: 'Incentivo:',
    text: 'Dígale a su niño/a: “Si guardas tus zapatos cuando entras después de jugar, obtendrás una caja de jugo”.',
  },
  {
    boldedText: 'Nota:',
    text: 'Su niño/a entra después de jugar y guarda sus zapatos.',
  },
  {
    boldedText: 'Recompensa:',
    text: 'Les agradeces por hacer su trabajo y les das una caja de jugo',
  },
];
