import { FC, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Modal: FC = ({ children }) => {
  const target = useRef<null | HTMLElement>(null);

  useEffect(() => {
    return () => {
      if (target.current && target.current.childNodes.length === 0) {
        target.current.remove();
        target.current = null;
      }
    };
  }, []);

  if (!target.current) {
    target.current = document.createElement('div');
    target.current.classList.add('absolute', 'left-0', 'top-0');
    document.body.appendChild(target.current);
  }

  return createPortal(children, target.current);
};
