import { FC } from 'react';
import { OrgDocumentType } from 'shared/lib/constants/org/OrgDocumentType';
import { OrgDocumentCategory } from 'shared/lib/constants/org/OrgDocumentCategory';
import { Redirect } from 'react-router-dom';
import { useOrgDocumentContext } from '../../contexts/orgDocumentContext';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { api } from '../../api';
import { PdfViewer } from '../../components/PdfViewer/PdfViewer';

export const ParticipantResourcesPage: FC = (props) => {
  const { orgDocument } = useOrgDocumentContext();
  const { isSpanish } = useProductLanguage();

  if (!orgDocument) {
    return <Redirect to="/" />;
  }

  const { documentType, orgId } = orgDocument;

  return (
    <div className="box-border min-h-screen">
      <div className="flex flex-col md:mx-auto md:max-w-2xl min-h-screen flex-shrink-0 bg-blue-700 pb-4">
        <ParticipantHeader hideCircle />
        <h2 className="mb-4 text-center text-2xl text-blue-400">
          {isSpanish ? 'Recursos' : 'Resources'}
        </h2>
        <div className="bg-white w-full self-center md:max-w-xl h-full flex-1 rounded-lg mt-10 overflow-x-hidden">
          {documentType === OrgDocumentType.PDF && (
            <PdfViewer
              file={{
                url: api.formatOrgDocumentFileUrl(
                  orgId,
                  OrgDocumentCategory.RESOURCE,
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
