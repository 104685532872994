import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_f: FC = () => {
  const { isSpanish } = useProductLanguage();
  return (
    <SlideVideo
      slideTitle={
        isSpanish
          ? '¿Cómo Monitorear cuando no está allí?'
          : "How to monitor when you're not there"
      }
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_13_spanish' : 'ani_13'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Observe a este padre aclarar sus expectativas sobre el 
              consumo de drogas y alcohol.`
            : `Watch this parent make his expectations about drug and 
              alcohol use clear.`}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
