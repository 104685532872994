import { FC } from 'react';
import { PillButton, ButtonTheme } from '../PillButton/PillButton';
import { noop } from 'shared/lib/utils/noop';
import { FloatingCard } from '../FloatingCard/FloatingCard';
import { ChevronCircleButton } from '../ChevronCircleButton/ChevronCircleButton';

export enum Answer {
  MYTH = 'myth',
  FACT = 'fact',
}
export interface MythOrFactCardProps {
  className?: string;
  statement: string;
  statementIndex?: number;
  totalStatements?: number;
  selectedOption?: Answer;
  forwardButtonVisible?: boolean;
  forwardButtonDisabled?: boolean;
  backButtonVisible?: boolean;
  backButtonDisabled?: boolean;
  isEnglish?: boolean;
  onMythClick?(): unknown;
  onFactClick?(): unknown;
  onForwardButtonClick?(): unknown;
  onBackButtonClick?(): unknown;
}

export const MythFactCard: FC<MythOrFactCardProps> = ({
  className = '',
  statement,
  statementIndex = 0,
  totalStatements = 0,
  selectedOption,
  forwardButtonVisible = true,
  forwardButtonDisabled = true,
  backButtonVisible = true,
  backButtonDisabled = true,
  isEnglish = true,
  onMythClick = noop,
  onFactClick = noop,
  onForwardButtonClick = noop,
  onBackButtonClick = noop,
  ...rest
}) => {
  return (
    <FloatingCard
      className={`relative flex flex-col items-center p-6 ${className}`}
      {...rest}
    >
      {/* Progress */}
      <span className="text-blue-800 text-sm absolute top-2 left-3">{`${
        statementIndex + 1
      } / ${totalStatements}`}</span>

      <h2 className="text-base font-bold text-center mb-3.5 text-blue-950">
        {isEnglish ? 'Myth or Fact?' : '¿Mito o Realidad?'}
      </h2>
      <p className="mb-6 text-center font-light text-black">{statement}</p>

      <PillButton
        className="w-52 mx-1 flex-shrink-0"
        theme={ButtonTheme.SKY_BLUE}
        selected={selectedOption === Answer.MYTH}
        onClick={onMythClick}
      >
        {isEnglish ? 'Myth' : 'Mito'}
      </PillButton>
      <PillButton
        className="w-52 my-5 mx-1 flex-shrink-0"
        theme={ButtonTheme.SKY_BLUE}
        selected={selectedOption === Answer.FACT}
        onClick={onFactClick}
      >
        {isEnglish ? 'Fact' : 'Realidad'}
      </PillButton>

      {/* Back Button */}
      {backButtonVisible && (
        <ChevronCircleButton
          className="absolute bottom-1/4 mb-3 left-1 transform rotate-180"
          onClick={onBackButtonClick}
          disabled={backButtonDisabled}
        />
      )}
      {/* Forward Button */}
      {forwardButtonVisible && (
        <ChevronCircleButton
          className="absolute bottom-1/4 mb-3 right-1"
          onClick={onForwardButtonClick}
          disabled={forwardButtonDisabled}
        />
      )}
    </FloatingCard>
  );
};
