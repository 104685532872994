import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_AskQuestions_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Haga Preguntas' : 'Ask Questions'}
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Hacer las preguntas adecuadas puede animar a su hijo/a a contarle 
            más. Aquí hay unos ejemplos:`
          : `Asking the right kind of questions can encourage your child to tell you
            more. Here are some examples:`}
      </p>
      <YellowCircleList className="font-bold mt-5">
        <li>{isSpanish ? '¿Qué pasó después? ' : 'What happened next?'}</li>
        <li>
          {isSpanish
            ? `Hmm… No salió como esperabas. ¿Cómo podrías planear diferente para la 
              próxima vez?`
            : `Hmm.. it didn’t go as you’d hoped. How could you plan differently next
              time?`}
        </li>
        <li>
          {isSpanish ? 'Cuéntame más sobre eso.' : 'Tell me more about that.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
