import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_c: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer
      slideTitle={isSpanish ? '¿Qué es un premio?' : 'What is a reward?'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish ? (
        <p>
          Un premio es algo que se le da a un niño/a{' '}
          <strong className="font-bold">después</strong> de que ha completado un
          comportamiento deseado.
        </p>
      ) : (
        <p>
          A reward is what you give to your child{' '}
          <strong className="font-bold">after</strong> they've completed a
          desired behavior.
        </p>
      )}
      <h3 className="text-red-600 font-bold my-5">
        {isSpanish
          ? 'Toque el signo más para obtener más información.'
          : 'Tap on the plus below to learn more'}
      </h3>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Ánimo y elogio' : 'Encouragment and praise'}
          </b>
        }
      >
        {isSpanish
          ? `Decir: "¡Gracias por hacer tus quehaceres, te estás haciendo un 
            muchacho/a muy responsable!`
          : `Say, “Thank you for doing your chores, you’re getting to be such a
            responsible kid!”`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? 'Tiempo y actividades contigo.'
              : 'Time and activities with you'}
          </b>
        }
      >
        {isSpanish ? 'Ver películas con mamá.' : 'Watch movies with Mom.'}
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Entretenimiento' : 'Entertainment'}
          </b>
        }
      >
        {isSpanish
          ? 'Ir en bicicleta al centro comercial.'
          : 'Bike ride to the mall.'}
      </PlusExpandable>
      <PlusExpandable
        open={open === 3}
        onExpand={() => handleOpen(3)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Privilegios' : 'Privileges'}
          </b>
        }
      >
        {isSpanish
          ? '30 minutos de tiempo de pantalla.'
          : '30 minutes of screentime.'}
      </PlusExpandable>
      <PlusExpandable
        open={open === 4}
        onExpand={() => handleOpen(4)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Cosas que cuestan dinero.' : 'Things that cost money'}
          </b>
        }
      >
        {isSpanish
          ? 'Un libro nuevo o una compra dentro de una aplicación.'
          : 'A new book or in-app purchase.'}
      </PlusExpandable>
      <p className="mt-12">
        {isSpanish
          ? `Lo más importante para considerar cuando hace su lista de premios 
            es que pueda cumplir.`
          : `The most important thing to consider when making your list of rewards is
            that you can follow through.`}
      </p>
    </SlideContainer>
  );
};
