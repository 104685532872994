import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting1Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'What is Proactive Parenting?'
          : '¿Qué es la crianza proactiva?'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Proactive parenting is planning ahead, anticipating what might be a
            challenge for your child, and taking action to manage your child’s
            behavior before the problem occurs.
          </>
        ) : (
          <>
            La crianza proactiva es planificar con anticipación, anticipar lo
            que podría ser un desafío para su hijo/a y tomar medidas para
            controlar el comportamiento de su hijo/a antes de que ocurra el
            problema.
          </>
        )}
      </p>
      <p className="mt-5">
        {isEnglish ? (
          <>
            In other words, it is a style of parenting that sets you up for
            success.
          </>
        ) : (
          <>
            En otras palabras, es un estilo de crianza que lo prepara para el
            éxito.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
