import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { PostTestFeedback } from 'shared/lib/types/PostTestFeedback';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { api } from '../../api';

export function usePostTestResultsPage() {
  const { logout } = useUser();
  const { productSession } = useParticipantProductContext();
  const { orgProduct, product } = productSession;
  const history = useHistory();
  const [postTestFeedback, setPostTestFeedback] = useState<PostTestFeedback[]>(
    [],
  );
  const [loading, setLoading] = useState(true);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setPostTestFeedback([]);
      }
      try {
        const result = await api.getPostTestFeedback({
          orgId: orgProduct.orgId,
          productId: product.id,
        });
        if (!isCancelled()) {
          setPostTestFeedback(result.postTestFeedback);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (!isCancelled()) {
          setLoading(false);
        }
      }
    },
    [orgProduct, product],
  );

  const onContinueClicked = useCallback(() => {
    history.replace('/');
  }, [history]);

  return {
    onContinueClicked,
    onReturnLaterClicked: logout,
    loading,
    postTestFeedback,
  };
}
