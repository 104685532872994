import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Pospar_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish && 'Siguiente'}
      slideTitle={
        isSpanish
          ? '¿Qué es la crianza positiva?'
          : 'What is positive parenting?'
      }
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `La crianza positiva es una manera de interactuar con su hijo/a que le ayuda a enfocarse en los comportamientos positivos que su hijo/a hace en vez de lo negativo.`
        : `Positive parenting is a way of interacting with your child that helps you
          focus on the positive behaviors your child is doing, instead of the
          negative.`}
    </SlideContainer>
  );
};
