import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting7Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Family Routines' : 'Rutinas Familiares'}
      className="text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Family routines are activities that your family does regularly
            during the day. Routines provide structure, support, and consistency
            for your child.
          </>
        ) : (
          <>
            Las rutinas familiares son actividades que su familia realiza
            regularmente durante el día. Las rutinas brindan estructura, apoyo y
            consistencia a su hijo/as.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
