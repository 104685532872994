import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare1Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Self-Care' : 'Autocuidado'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish
          ? 'Being the parent of young children can feel lonely, isolating, and stressful at times.'
          : 'Ser padre de niños pequeños puede hacer que se sienta  solo/a, aislado/a y estresado/a a veces.'}
      </p>
      <p>
        {isEnglish
          ? 'Self-care can improve your health, well-being, and stress, and support you through the ups and downs of parenting young children.'
          : 'El cuidado personal puede mejorar su salud, bienestar y estrés, y brindarle apoyo durante los altibajos de la crianza de niños pequeños.'}
      </p>
    </SlideContainer>
  );
};
