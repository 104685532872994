import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare11Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      slideTitle={isEnglish ? 'Managing Stress' : 'Manejar El Estrés'}
      className="text-white font-light"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_02'
          : 'early_childhood_spanish_Ani_02'
      }
      videoTitle={isEnglish ? 'Breathing Exercise' : 'Ejercicio de respiración'}
      upperSlideContent={
        <p className="px-14">
          {isEnglish ? (
            <>
              Another strategy that can help you relax is deep breathing. Try
              this simple breathing exercise to take a short break from your
              problems —it works quickly, and you can do it anywhere.
            </>
          ) : (
            <>
              Otra estrategia que puede ayudarle a relajarse es la respiración
              profunda. Pruebe este sencillo ejercicio de respiración para tomar
              un breve descanso de sus problemas; funciona rápidamente y puede
              hacerlo en cualquier lugar.
            </>
          )}
        </p>
      }
      bearCharacter="YOUNG_GIRL_THUMBS_UP"
    />
  );
};
