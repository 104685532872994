import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Eating_b: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Comiendo' : 'Eating'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Consulte estas preguntas y respuestas comunes para agregar 
          alimentos saludables a la rutina diaria de su hijo/a.`
        : `Check out these common questions and answers for adding healthy 
          foods into your child’s daily routine.`}
      <h3 className="text-red-600 font-bold my-5">
        {isSpanish
          ? 'Toque el signo más para aprender más.'
          : 'Tap on the plus below to learn more'}
      </h3>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? '¿Cómo puedo hacer que mi hijo/a coma alimentos más saludables? '
              : 'How can I get my child to eat healthier food?'}
          </b>
        }
      >
        {isSpanish
          ? `A lo largo de este programa, aprenderá habilidades de crianza que 
            puede utilizar para apoyar la nutrición de sus hijos, como apoyo 
            positivo, establecimiento de límites y habilidades de comunicación.`
          : `Throughout this program, you will learn parenting skills you can use to
            support your child's nutrition, such as positive support, limit setting
            and communication skills.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? 'Mi hijo/a se niega a comer frutas o verduras, ¿qué debo hacer?'
              : 'My child refuses to eat fruits or vegetables, what should I do?'}
          </b>
        }
      >
        {isSpanish
          ? `Es importante evitar pelear con su hijo/a por lo que come. En su lugar, intente:`
          : `It’s important to avoid fighting with your child about what they eat.
              Instead, try to:`}

        <YellowCircleList className="mt-4">
          <li>
            {isSpanish
              ? `Comer juntos. Si sus hijos lo ven comiendo botanas saludables, 
                ellos también lo harán.`
              : `Eat together. If your children see you snacking on healthy foods
                they will too.`}
          </li>
          <li>
            {isSpanish
              ? `Corta las frutas y verduras en rodajas. Si le ofrece a su hijo/a una 
                manzana o una zanahoria, probablemente no lo quieran, pero si coloca 
                un plato de manzanas y zanahorias en rodajas, probablemente se las coman. 
                Intente incluir salsas saludables, como yogur para frutas o hummus para verduras.`
              : `Slice up fruit and vegetables. If you offer your child an apple or a
                carrot, they’ll probably pass, but if you set out a plate of sliced
                apples and carrots they will probably eat them. Try including
                healthy dips, like flavored yogurt for fruit or hummus for
                vegetables.`}
          </li>
          <li>
            {isSpanish
              ? `Limite el acceso a alimentos poco saludables. Limite las opciones de comida chatarra y comida rápida.`
              : `Limit access to unhealthy foods. Limit junk food and fast food`}
            options.
          </li>
          <li>
            {isSpanish
              ? `¡Sigue intentando! Suena extraño, pero la mayoría de nosotros 
                tenemos que intentar algo unas 10 veces (!) antes de aceptarlo, 
                es igual con sus hijos.`
              : `Keep on trying! It sounds strange, but most of us need to try
                something about 10 times (!) before we accept it-that applies to
                your child too.`}
          </li>
        </YellowCircleList>
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? `¿Cómo puedo animar a mi hijo/a a comer saludable cuando no estoy allí?`
              : `How can I encourage my child to eat healthy when I’m not there?`}
          </b>
        }
      >
        {isSpanish
          ? `Las opciones saludables en el hogar ayudarán a su hijo a tomar 
            decisiones saludables cuando no esté con usted.`
          : `Healthy choices at home will support your child in making healthy
            choices when they are not with you.`}
      </PlusExpandable>
    </SlideContainer>
  );
};
