import { FC, useState, useCallback } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import infoIconSrc from '../../../images/info_icon.png';
import bathroomSrc from '../../../images/rules_and_consequences_bath_room.png';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { Concern } from 'shared/lib/types/Concern';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { useSlideContext } from '../../../contexts/slideContext';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';

export const RulesAndConsequences5Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeConcern, setActiveConcern] = useState<number>();
  const { activity, value, setValue, isReview } =
    useActivityState<Record<string, boolean>>();
  const { isEnglish } = useProductLanguage();
  const { next } = useSlideContext();

  const canProgress = isReview || value;
  const safetyConcerns = isEnglish
    ? englishSafetyConcerns
    : spanishSafetyConcerns;

  const { concern, tooltipClassName, caretPosition } =
    safetyConcerns[activeConcern ?? 1];

  const handleClickNext = useCallback(() => {
    setActiveConcern(undefined);
    return isReview ? next() : setShowOverlay(true);
  }, [next, isReview]);

  return (
    <SlideContainer
      hideButton={!canProgress}
      className="flex-col text-white bottom"
      onButtonClick={handleClickNext}
    >
      <p className="px-14">
        {isEnglish ? (
          <>
            Tap on a
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            in the bathroom below to learn more about everyday safety concerns
            in your home.
          </>
        ) : (
          <>
            Oprima una
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            en el baño a continuación para obtener más información sobre las
            preocupaciones diarias de seguridad en su hogar.
          </>
        )}
      </p>

      <div className="relative">
        <img src={bathroomSrc} alt="bathroom" className="w-full mt-24" />
        {Object.entries(safetyConcerns).map(([key, concernInfo]) => (
          <button
            key={key}
            onClick={() => {
              setActiveConcern((curConcernKey) =>
                curConcernKey !== +key ? +key : undefined,
              );
              setValue({});
            }}
          >
            <img
              src={infoIconSrc}
              alt="info"
              className={`absolute w-9 ${concernInfo.iconClassName}`}
            />
          </button>
        ))}
        <div className={`absolute ${tooltipClassName}`}>
          <InfoTooltip
            className={`${tooltipClassName}`}
            visible={!!activeConcern}
            onClose={() => setActiveConcern(undefined)}
            text={concern}
            position={caretPosition}
          />
        </div>
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="YOUNG_GIRL"
      />
    </SlideContainer>
  );
};

const englishSafetyConcerns: Record<number, Concern> = {
  1: {
    concern: 'Keep the toilet seat down and latched.',
    iconClassName: 'bottom-1/4 mb-8 left-5',
    tooltipClassName: 'w-60 bottom-1/4 -mb-0.5 left-8',
    caretPosition: 'leftTop',
  },
  2: {
    concern: 'Keep medicine cabinets latched and dangerous items out of reach.',
    iconClassName: 'top-1/2 -mt-9 left-1/2 -ml-20',
    tooltipClassName: 'w-60 top-1/2 mt-1.5 left-1/4 -ml-5',
    caretPosition: 'topLeft',
  },
  3: {
    concern: 'Never leave children unattended in the tub.',
    iconClassName: 'bottom-1/4 -mb-4 right-1/4 -mr-8',
    tooltipClassName: 'w-52 bottom-1/4 mb-4 right-8 mr-1',
    caretPosition: 'bottomRight',
  },
};

const spanishSafetyConcerns: Record<number, Concern> = {
  1: {
    concern: 'Mantenga el asiento del inodoro cerrado y asegurado.',
    iconClassName: 'bottom-1/4 mb-8 left-5',
    tooltipClassName: 'w-60 bottom-1/4 -mb-0.5 left-8',
    caretPosition: 'leftTop',
  },
  2: {
    concern:
      'Mantenga el cajonero de medicina cerrada y los artículos peligrosos fuera del alcance de los niños.',
    iconClassName: 'top-1/2 -mt-9 left-1/2 -ml-20',
    tooltipClassName: 'w-60 top-1/2 mt-1.5 left-1/4 -ml-5',
    caretPosition: 'topLeft',
  },
  3: {
    concern: 'Nunca deje sin supervisión a los niños en la tina.',
    iconClassName: 'bottom-1/4 -mb-4 right-1/4 -mr-8',
    tooltipClassName: 'w-52 bottom-1/4 mb-4 right-8 mr-1',
    caretPosition: 'bottomRight',
  },
};
