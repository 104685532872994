import { FC } from 'react';
import { ReactComponent as CheckIcon } from '../../images/feather/check.svg';
import { ReactComponent as XIcon } from '../../images/feather/x.svg';

export const CorrectedRadio: FC<{
  className?: string;
  inputType?: string;
  checkedCorrect?: boolean;
  onRadioClick(): unknown;
  checked: boolean;
}> = ({
  className,
  inputType = 'radio',
  checkedCorrect,
  onRadioClick,
  checked,
  ...rest
}) => (
  <label className={`cursor-pointer ${className}`}>
    <input
      type={inputType}
      className="hidden"
      checked={checked}
      onChange={onRadioClick}
      {...rest}
    />
    {/* Fake Radio */}
    <div
      className={`flex-shrink-0 w-5 h-5 rounded-full text-white border-2  transition-all duration-200 ${
        checked && checkedCorrect
          ? 'bg-blue-400 border-blue-400'
          : checked && !checkedCorrect
          ? 'bg-red border-red'
          : 'border-warmGray-200 bg-white'
      }`}
    >
      {checkedCorrect ? (
        <CheckIcon className="w-full h-full" strokeWidth="3" />
      ) : (
        <XIcon className="w-full h-full" strokeWidth="3" />
      )}
    </div>
  </label>
);
