import React, { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getRoleName, isParticipantRole } from 'shared/lib/types/UserRole';
import { useUser } from '../../contexts/userContext';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { ParticipantNavButton } from '../../components/ParticipantNavButton/ParticipantNavButton';
import { LanguageBadge } from '../../components/LanguageBadge/LanguageBadge';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Logo } from '../../components/Logo/Logo';

export const ChooseRolePage: FC<{
  header: ReactNode;
  onRoleChange?(): unknown;
}> = ({ header, onRoleChange, ...rest }) => {
  const { roles, setActiveRole } = useUser();
  useDocumentTitle('Choose Product');
  const location = useLocation();

  return (
    <div
      {...rest}
      className="flex flex-col min-h-screen bg-white text-blue-700 pb-8"
    >
      <header className="bg-blue-700 h-16 px-4 flex-shrink-0">
        <div className="flex flex-row justify-between h-full md:mx-auto md:max-w-2xl">
          <div className="w-0 flex-grow pt-3">
            <Logo compact className="flex-shrink-0" />
          </div>

          <div className="flex flex-row justify-end w-0 flex-grow pt-5">
            <ParticipantNavButton />
          </div>
        </div>
      </header>
      <h1 className="text-lg text-center font-bold w-80 self-center mt-8">
        {header}
      </h1>
      <ul className="mt-8 flex flex-col items-center flex-shrink-0">
        {roles.map((role, i) => (
          <li key={role.id} className={i > 0 ? 'mt-3' : ''}>
            <button
              className="rounded-lg w-44 pt-4 px-4 text-sm font-semibold flex-shrink-0 flex flex-col items-center"
              style={{
                backgroundColor: isParticipantRole(role)
                  ? role.product.color
                  : '#CCFCEE',
              }}
              onClick={() => {
                setActiveRole(role);
                onRoleChange?.();
              }}
            >
              <p>Family Check-Up</p>
              <p>{getRoleName(role)}</p>
              {isParticipantRole(role) && (
                <LanguageBadge
                  language={role.product.language}
                  className="mt-2"
                />
              )}
              {isParticipantRole(role) && !!role.product.roleImage && (
                <img
                  className="mt-4 h-24 w-auto"
                  src={getPublicFilePath(role.product.roleImage)}
                  alt=""
                />
              )}
            </button>
          </li>
        ))}
      </ul>
      <div className="flex-grow flex flex-col justify-end mt-8 flex-shrink-0">
        <p className="text-center text-xs">
          Family Check-up® is a Registered Trademark
          <br />
          of the University of Oregon
        </p>
      </div>
      <Link
        to={{
          pathname: location.pathname,
          search: '?privacy-policy',
        }}
        className="text-blue-400 text-xs self-center mt-1"
      >
        privacy policy
      </Link>
    </div>
  );
};
