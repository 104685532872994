import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishTriggers = [
  'Feeling tired or stressed',
  'Overwhelmed with children',
  'Encountering someone using substances',
  'Visiting places where you have used substances in the past',
  'Substances that are available',
  'Thoughts about substances',
  'Physical pain due to health conditions',
];

const spanishTriggers = [
  'Sentirse cansado/a o estresado/a',
  'Abrumado/a con niños',
  'Encontrar a alguien que consume sustancias',
  'Visitar lugares donde ha consumido sustancias en el pasado',
  'Sustancias que están disponibles',
  'Pensamientos sobre la sustancia',
  'Dolor físico debido a condiciones de salud.',
];

export const ParentingAndSubstanceUse7Slide: FC = () => {
  const { value, setValue } = useActivityState<Record<string, boolean>>({});
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const triggers = isEnglish ? englishTriggers : spanishTriggers;
  return (
    <SlideContainer
      lightBg
      lightProgressBar
      hideButton
      headerContent={
        <div className="flex flex-col mt-8 px-10 font-light text-blue-800">
          <h2 className="font-semibold mb-2">
            {isEnglish ? 'Triggers' : 'Provocaciónes'}
          </h2>
          <div className="text-15px">
            {isEnglish ? (
              <>
                To fight them, it is helpful to know what situations cause, or
                trigger, your cravings.
              </>
            ) : (
              <>
                Para combatir sus antojos, es útil saber que situaciones le
                causan o provocan los antojos. Cuando conoce sus factores
                provocativos, puede evitarlos o estar preparado para ellos, para
                ayudar a prevenir los antojos.
              </>
            )}
          </div>
        </div>
      }
    >
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1"
        icon="checkbox"
        instructions={
          isEnglish
            ? 'Your turn: Check your most powerful trigger(s) from the list.'
            : 'Su turno: marque su(s) provocación(es) más poderoso(s) de la lista.'
        }
      >
        <FloatingCard className="py-4 px-5 w-full mb-10">
          {triggers.map((trigger, index) => {
            const indexKey = index.toString(10);
            return (
              <SlideCheckbox
                key={trigger}
                label={trigger}
                checked={!!value[indexKey]}
                onChange={(event) =>
                  setValue({
                    ...value,
                    [indexKey]: event.currentTarget.checked,
                  })
                }
                className={index + 1 !== triggers.length ? 'mb-4' : ''}
              />
            );
          })}
        </FloatingCard>
        <PillButton
          className="mt-auto w-52"
          theme={ButtonTheme.YELLOW}
          onClick={next}
        >
          {isEnglish ? 'Submit' : 'Entregar'}
        </PillButton>
      </ActivityBox>
    </SlideContainer>
  );
};
