import { FC } from 'react';
import { User } from 'shared/lib/types/User';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import placeholderProfilePic from '../../images/placeholder_profile_pic.png';

export const ProfilePicture: FC<{
  user: Pick<User, 'profilePicture' | 'firstName' | 'lastName'>;
  className?: string;
}> = ({ user, className = '' }) => (
  <div
    className={`w-22 h-22 flex-shrink-0 bg-blue-700 p-2 rounded-full ${className}`}
  >
    <img
      src={user.profilePicture ?? placeholderProfilePic}
      alt={formatFirstLast(user)}
      className="w-full h-full object-cover rounded-full"
    />
  </div>
);
