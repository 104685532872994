const title1 = (
  <>
    <b>Get out of high-risk situations:</b>
    <br /> <i>(avoid your triggers)</i> <b>consider going...</b>
  </>
);

const title1Spanish = (
  <>
    <b>Salga de situaciones de alto riesgo:</b>
    <br /> <i>(evite sus factores de provocación) considere...</i>
  </>
);

const title2 = (
  <>
    <b>Get out of high-risk situations:</b>
    <br /> <i>(avoid your triggers)</i>
    <b>consider...</b>
  </>
);

const title2Spanish = title1Spanish;

const title3 = (
  <b>
    Distract yourself:
    <br />
    try...
  </b>
);

const title3Spanish = (
  <b>
    Para distraerse:
    <br />
    puede...
  </b>
);

export const parentingAndSubstanceUseCravingTips = [
  {
    title: title1,
    example: 'home to be with supportive family members',
  },
  {
    title: title3,
    example: 'getting outside, take your kid(s) for a walk',
  },
  {
    title: title3,
    example: 'calling or visiting a supportive friend',
  },
  {
    title: title1,
    example: 'to the restroom',
  },
  {
    title: title3,
    example: 'watching a movie',
  },
  {
    title: title1,
    example: 'outside to get fresh air',
  },
  {
    title: title3,
    example: 'putting away dishes or laundry',
  },
  {
    title: title1,
    example: 'inside to relax',
  },
  {
    title: title3,
    example: 'staying busy with healthy behaviors, like exercise',
  },
  {
    title: title2,
    example:
      'limiting time with or avoiding friends and family that promote substance abuse',
  },
  {
    title: title3,
    example: 'spending time doing a healthy hobby',
  },
  {
    title: title3,
    example: 'practicing positive thinking (I can do this!)',
  },
];

export const spanishParentingAndSubstanceUseCravingTips = [
  {
    title: title1Spanish,
    example:
      'Ir a su hogar para estar con miembros de la familia que lo apoyen.',
  },
  {
    title: title3Spanish,
    example: 'salir, llevar a sus hijos a dar un paseo.',
  },
  {
    title: title3Spanish,
    example: 'llamar o visitar a un amigo/a de apoyo.',
  },
  {
    title: title1Spanish,
    example: 'ir al baño.',
  },
  {
    title: title3Spanish,
    example: 'Ver una película.',
  },
  {
    title: title1Spanish,
    example: 'Ir afuera a tomar aire fresco.',
  },
  {
    title: title3Spanish,
    example: 'guardar los platos o la ropa.',
  },
  {
    title: title1Spanish,
    example: 'Ir adentro para relajarse.',
  },
  {
    title: title3Spanish,
    example:
      'mantenerse ocupado/a con comportamientos saludables, como el ejercicio.',
  },
  {
    title: title2Spanish,
    example:
      'limitar el tiempo con o evitar amigos y familiares que promuevan el uso de sustancias.',
  },
  {
    title: title3Spanish,
    example: 'pasar tiempo haciendo un pasatiempo saludable.',
  },
  {
    title: title3Spanish,
    example: 'practicar el pensamiento positivo (¡puedo hacer esto!).',
  },
];
