export const englishLetterContent = {
  P: {
    letterStatement: (
      <>
        P = <b className="font-bold">P</b>ay attention and identify the problem
        situations for your child.
      </>
    ),
    statement: 'What is the problem?',
    listItem: <li>Getting dressed in the morning.</li>,
  },
  L: {
    letterStatement: (
      <>
        L = <b className="font-bold">L</b>ook realistically at your child's
        abilities in that situation.
      </>
    ),
    statement: 'Can your child get dressed on their own?',
    listItem: <li>Yes, if you lay their clothes out.</li>,
  },
  A: {
    letterStatement: (
      <>
        A = <b className="font-bold">A</b>djust difficult activities to maximize
        success and minimize negative emotions.
      </>
    ),
    statement: 'Adjust for success.',
    listItem: (
      <li>Lay out child’s clothes, keep TV off until child is dressed.</li>
    ),
  },
  N: {
    letterStatement: (
      <>
        N = <b className="font-bold">N</b>otice and remember to prompt, suggest,
        and reward success!
      </>
    ),
    statement: "Don't forget to tell your child they did a good job!",
    listItem: <li>Once your child is dressed, let them watch TV.</li>,
  },
};

export const spanishLetterContent = {
  P: {
    letterStatement: (
      <>
        <b className="font-bold">Preste atención</b> e identifique las
        situaciones problemáticas para su hijo/a.
      </>
    ),
    statement: '¿Qué es el problema?',
    listItem: <li>Vestirse por la mañana.</li>,
  },
  L: {
    letterStatement: (
      <>
        <b className="font-bold">Mirando de manera realistica</b> las
        habilidades de su niño/a en esa situación.
      </>
    ),
    statement: '¿Puede su hijo/a vestirse solo/a?',
    listItem: <li>Sí, si le deja la ropa afuera.</li>,
  },
  A: {
    letterStatement: (
      <>
        <b className="font-bold">Ajuste</b> las actividades difíciles para
        maximizar el éxito y minimizar las emociones negativas.
      </>
    ),
    statement: 'Ajuste para el éxito.',
    listItem: (
      <li>
        Extienda la ropa, mantenga la televisión apagada hasta que su hijo/a se
        vista.
      </li>
    ),
  },
  N: {
    letterStatement: (
      <>
        <b className="font-bold">Observe</b> y recuerde impulse, sugerir y
        recompensar el éxito.
      </>
    ),
    statement: '¡No se olvide decirle a su hijo/a que hizo un buen trabajo!',
    listItem: (
      <li>Después que su hijo/a esté vestido/a, déjelo/a ver la televisión.</li>
    ),
  },
};
