import { FC, useState } from 'react';
import { ChevronCircleButton } from '../../../components/ChevronCircleButton/ChevronCircleButton';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { PlanLetter } from '../../../components/PlanLetter/PlanLetter';
import {
  PlanLetterOption,
  LETTER_OPTIONS,
} from 'shared/lib/types/PlanLetterOption';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import {
  englishLetterContent,
  spanishLetterContent,
} from '../../../constants/early_childhood/proactive_parenting_3_content';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting3Slide: FC = () => {
  const [planning, setPlanning] = useState(false);
  const [letterOptionIndex, setLetterOptionIndex] = useState(0);
  const [reviewPlan, setReviewPlan] = useState(false);
  const { isEnglish } = useProductLanguage();
  const currentPlanLetter = LETTER_OPTIONS[letterOptionIndex];

  const letterContent = isEnglish ? englishLetterContent : spanishLetterContent;

  const { letterStatement, statement, listItem } =
    letterContent[currentPlanLetter];

  const endOfContent = letterOptionIndex + 1 === LETTER_OPTIONS.length;

  const handleClick = () => {
    if (!planning) {
      setPlanning(true);
    } else if (!endOfContent) {
      setLetterOptionIndex((curIndex) => curIndex + 1);
    } else {
      setReviewPlan(true);
    }
  };

  return (
    <SlideContainer
      className="flex-col text-white px-14 font-light"
      hideButton={!reviewPlan}
    >
      {!reviewPlan ? (
        <>
          {isEnglish ? (
            <>
              Next, we'll look at an example situation using the P-L-A-N model.
            </>
          ) : (
            <>A continuación, veremos una situación de ejemplo.</>
          )}

          <ExampleSection
            className="mt-5"
            title={
              <h2 className="font-bold text-lg uppercase ml-2.5">
                {isEnglish ? 'Example:' : 'Ejemplo:'}
              </h2>
            }
          >
            <p className="mt-2 pl-9">
              {isEnglish ? (
                <>
                  Your 4-year-old refuses to get dressed in the morning, and
                  watches TV instead.
                </>
              ) : (
                <>
                  Su hijo/a de 4 años se niega a vestirse por la mañana y, en
                  cambio, ve la televisión.
                </>
              )}
            </p>
          </ExampleSection>
          <div className="flex mt-10">
            {LETTER_OPTIONS.map((option, index) => (
              <PlanLetter
                className={index > 0 ? 'ml-2.5' : ''}
                key={option}
                letterOption={option}
                active={!planning || currentPlanLetter === option}
              />
            ))}
            <ChevronCircleButton className="ml-auto" onClick={handleClick} />
          </div>
          {planning && (
            <div className="flex flex-col mt-11 mb-2.5">
              <p>{letterStatement}</p>
              <span className="font-bold mt-8">{statement}</span>
              <YellowCircleList className="mt-2.5">{listItem}</YellowCircleList>
            </div>
          )}
        </>
      ) : (
        <>
          <h2 className="font-semibold text-red-600 text-lg mb-6">
            {isEnglish ? 'Review the PLAN:' : 'Revise el PLAN:'}
          </h2>
          {Object.entries(letterContent).map(
            ([key, { statement, listItem }], index) => (
              <div className={`flex ${index > 0 ? 'mt-8' : ''} `} key={key}>
                <PlanLetter
                  className="flex-shrink-0"
                  letterOption={key as PlanLetterOption}
                />
                <div className="flex flex-col ml-5">
                  <span className="font-bold mb-2">{statement}</span>
                  <YellowCircleList>{listItem}</YellowCircleList>
                </div>
              </div>
            ),
          )}
        </>
      )}
    </SlideContainer>
  );
};
