import { useState, useEffect } from 'react';

interface Size {
  width: number;
  height: number;
}

function getWindowSize(): Size {
  return {
    width: window.innerWidth,
    height: Math.max(window.innerHeight, document.body.clientHeight),
  };
}

export default function useWindowSize(): Size {
  const [size, setSize] = useState(getWindowSize);

  useEffect(() => {
    function handleResize() {
      setSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}
