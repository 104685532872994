import { FC } from 'react';
import { Unit } from 'shared/lib/types/Unit';
import { getPublicFilePath } from '../../utils/getPublicFilePath';

export const UnitIcon: FC<{
  className?: string;
  unit: Unit;
  faded?: boolean;
}> = ({ className = '', unit, faded, ...rest }) => (
  <div
    className={`w-15 h-15 rounded-full bg-blue-800 flex-shrink-0 ${className}`}
    {...rest}
  >
    <img
      src={getPublicFilePath(unit.icon ?? '')}
      alt={unit.name}
      className={`w-full h-full ${faded ? 'opacity-40' : 'opacity-100'}`}
    />
  </div>
);
