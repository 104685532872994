import { FC } from 'react';
import { RulesAndConsequences1Slide } from './rules_and_consequences_1';
import { RulesAndConsequences2Slide } from './rules_and_consequences_2';
import { RulesAndConsequences3Slide } from './rules_and_consequences_3';
import { RulesAndConsequences4Slide } from './rules_and_consequences_4';
import { RulesAndConsequences5Slide } from './rules_and_consequences_5';
import { RulesAndConsequences6Slide } from './rules_and_consequences_6';
import { RulesAndConsequences7Slide } from './rules_and_consequences_7';
import { RulesAndConsequences8Slide } from './rules_and_consequences_8';
import { RulesAndConsequences9Slide } from './rules_and_consequences_9';
import { RulesAndConsequences10Slide } from './rules_and_consequences_10';
import { RulesAndConsequences11Slide } from './rules_and_consequences_11';
import { RulesAndConsequences12Slide } from './rules_and_consequences_12';
import { RulesAndConsequences13Slide } from './rules_and_consequences_13';
import { RulesAndConsequences14Slide } from './rules_and_consequences_14';
import { RulesAndConsequences15Slide } from './rules_and_consequences_15';
import { RulesAndConsequences16Slide } from './rules_and_consequences_16';
import { RulesAndConsequences17Slide } from './rules_and_consequences_17';
import { RulesAndConsequences18Slide } from './rules_and_consequences_18';
import { RulesAndConsequences19Slide } from './rules_and_consequences_19';
import { RulesAndConsequences20Slide } from './rules_and_consequences_20';
import { RulesAndConsequences21Slide } from './rules_and_consequences_21';

export const rulesAndConsequencesSlides: Record<string, FC> = {
  // Managing toddlers
  rules_and_consequences_1: RulesAndConsequences1Slide,

  // Home safety
  rules_and_consequences_2: RulesAndConsequences2Slide,
  rules_and_consequences_3: RulesAndConsequences3Slide,
  rules_and_consequences_4: RulesAndConsequences4Slide,
  rules_and_consequences_5: RulesAndConsequences5Slide,

  // Clear rules
  rules_and_consequences_6: RulesAndConsequences6Slide,
  rules_and_consequences_7: RulesAndConsequences7Slide,
  rules_and_consequences_8: RulesAndConsequences8Slide,
  rules_and_consequences_9: RulesAndConsequences9Slide,
  rules_and_consequences_10: RulesAndConsequences10Slide,
  rules_and_consequences_11: RulesAndConsequences11Slide,

  // Logical consequences
  rules_and_consequences_12: RulesAndConsequences12Slide,
  rules_and_consequences_13: RulesAndConsequences13Slide,
  rules_and_consequences_14: RulesAndConsequences14Slide,
  rules_and_consequences_15: RulesAndConsequences15Slide,
  rules_and_consequences_16: RulesAndConsequences16Slide,

  // Ignoring
  rules_and_consequences_17: RulesAndConsequences17Slide,
  rules_and_consequences_18: RulesAndConsequences18Slide,
  rules_and_consequences_19: RulesAndConsequences19Slide,
  rules_and_consequences_20: RulesAndConsequences20Slide,
  rules_and_consequences_21: RulesAndConsequences21Slide,
};
