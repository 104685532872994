import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideTextarea } from '../../../components/SlideTextarea/SlideTextarea';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { ResponseCompare } from '../../../components/ResponseCompare/ResponseCompare';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import {
  englishStatements,
  spanishStatements,
} from '../../../constants/early_childhood/parenting_and_substance_use_14_statements';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type StatementKey = keyof typeof englishStatements;

type ActivityState = Partial<Record<string, string>>;

export const ParentingAndSubstanceUse14Slide: FC = () => {
  const [currentStatementId, setCurrentStatementId] = useState<StatementKey>(1);
  const [comparingResponse, setComparingResponse] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { isEnglish, isSpanish } = useProductLanguage();

  const { value, setValue, activity, isReview } =
    useActivityState<ActivityState>({});

  const { next, viewOnly } = useSlideContext();

  const statements = isEnglish ? englishStatements : spanishStatements;
  const endOfStatements = currentStatementId === Object.keys(statements).length;

  const handleSubmit = () => {
    if (!comparingResponse && !viewOnly) {
      setComparingResponse(true);
    } else if (!endOfStatements) {
      setComparingResponse(false);
      setCurrentStatementId((curId: number) => (curId + 1) as StatementKey);
    } else if (!isReview) {
      setShowOverlay(true);
    } else {
      next();
    }
  };

  const { text, recommendedResponse } = statements[currentStatementId];

  const usersAnswer = value[currentStatementId];

  const comparingResponseText = isEnglish
    ? 'How do your responses compare?'
    : '¿Cómo se comparan sus respuestas?';

  const yourTurnText = isEnglish
    ? 'Your turn: Turn these “You” statements into “I” statements.'
    : 'Su turno: Convierta estas declaraciones de "Tú" en declaraciones de "Yo".';

  const nextButtonText = isEnglish ? 'Next' : 'Siga';
  const submitButtonText = isEnglish ? 'Submit' : 'Entregar';
  const buttonText =
    comparingResponse || viewOnly ? nextButtonText : submitButtonText;

  return (
    <SlideContainer className="flex-col" lightBg lightProgressBar hideButton>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-800"
        icon="chat"
        instructions={comparingResponse ? comparingResponseText : yourTurnText}
      >
        {comparingResponse ? (
          <ResponseCompare
            isSpanish={isSpanish}
            className="w-full"
            usersResponse={
              <ResponseBlock response={usersAnswer} isEnglish={isEnglish} />
            }
            recommendedResponse={
              <ResponseBlock
                response={recommendedResponse}
                isEnglish={isEnglish}
              />
            }
          />
        ) : (
          <div className="flex flex-col self-start px-11 w-full">
            <div className="flex flex-col mb-5">
              <b>{isEnglish ? 'Prompt' : 'Instrucciones'}</b>
              {text}
            </div>
            <FloatingCard className="pt-4 pb-5 px-4 w-full mb-20">
              <SlideTextarea
                disabled={viewOnly}
                label={
                  isEnglish
                    ? 'Try using "i" instead'
                    : 'Intente utilizar "yo" en su lugar'
                }
                value={usersAnswer ?? undefined}
                placeholder={
                  isEnglish
                    ? 'Enter what you would say.'
                    : 'Ingrese lo que diría.'
                }
                onTextAreaChange={(text) =>
                  setValue({
                    ...value,
                    [currentStatementId.toString(10)]: text,
                  })
                }
              />
            </FloatingCard>
          </div>
        )}
        {!!usersAnswer && (
          <PillButton
            className="w-52 mt-20"
            theme={ButtonTheme.YELLOW}
            onClick={handleSubmit}
          >
            {buttonText}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};

const ResponseBlock: FC<{ response?: string; isEnglish: boolean }> = ({
  response,
  isEnglish,
}) => {
  return (
    <div className="px-5 font-light">
      <b className="uppercase font-bold">
        {isEnglish
          ? 'Try using "i" instead'
          : 'Intente utilizar "yo" en su lugar'}
      </b>
      <br />
      <p className="mt-2">{response}</p>
    </div>
  );
};
