import { FC, useMemo } from 'react';
import { Mood } from 'shared/lib/types/Mood';
import { MoodImage } from '../MoodImage/MoodImage';

const moods = Object.values(Mood);
let radioGroupCounter = 1;

export const MoodInput: FC<{
  value: Mood | null;
  onChange(value: Mood): unknown;
  className?: string;
  transparentBg?: boolean;
}> = ({ value, onChange, className = '', transparentBg = false, ...rest }) => {
  /**
   * Needed to connect the hidden radio inputs.
   */
  const radioGroupName = useMemo(() => `mood-input-${radioGroupCounter++}`, []);

  return (
    <div
      {...rest}
      className={`inline-flex flex-row justify-center flex-shrink-0 ${className}`}
    >
      {moods.map((mood, i) => {
        const checked = mood === value;

        return (
          <label key={mood} className={`cursor-pointer ${i > 0 ? 'ml-4' : ''}`}>
            <MoodImage
              transparentBg={transparentBg}
              highlighted={checked}
              mood={mood}
            />
            <input
              name={radioGroupName}
              type="radio"
              checked={checked}
              onChange={() => onChange(mood)}
              className="sr-only"
            />
          </label>
        );
      })}
    </div>
  );
};
