import { FC } from 'react';
import { OrgDocumentType } from 'shared/lib/constants/org/OrgDocumentType';
import { OrgDocumentCategory } from 'shared/lib/constants/org/OrgDocumentCategory';
import { OrgDocument } from 'shared/lib/types/OrgDocument';
import { api } from '../../api';
import { PdfViewer } from '../../components/PdfViewer/PdfViewer';
import { PillButton, ButtonTheme } from '../PillButton/PillButton';
import { CircleXButton } from '../CircleXButton/CircleXButton';
import { ParticipantHeader } from '../ParticipantHeader/ParticipantHeader';
import { twMerge } from 'tailwind-merge';

const staffOverlay =
  'flex flex-col bg-blue-50 pb-4 my-20 mx-10 w-full lg:max-w-265 shadow-raised relative';
const participantOverlay = 'flex flex-col pb-4 px-2';

const staffHeader = 'my-10 text-center font-sans font-bold text-2xl';
const participantHeader = 'mb-8 text-center text-2xl text-blue-400 px-12';

export const ShowEulaOverlay: FC<{
  onAcceptEula(): void;
  onCancel(): void;
  eula: OrgDocument;
  isStaffRole: boolean;
}> = ({ onAcceptEula, onCancel, eula, isStaffRole }) => {
  const { documentType, orgId } = eula;

  const overlayClassName = isStaffRole ? staffOverlay : participantOverlay;
  const headerClassName = isStaffRole ? staffHeader : participantHeader;

  return (
    <div
      className={twMerge(
        'box-border min-h-screen flex flex-row justify-center',
        !isStaffRole && 'bg-blue-700',
      )}
    >
      <div className={overlayClassName}>
        {isStaffRole && (
          <CircleXButton
            onClick={onCancel}
            className="absolute -right-12 bg-gray-100 shadow-md md:flex hidden"
          />
        )}
        {!isStaffRole && <ParticipantHeader hideCircle={true} />}
        <h2 className={headerClassName}>End-User License Agreement</h2>
        <div
          className={twMerge(
            'w-full self-center h-full flex-1 rounded-lg mb-3 shadow overflow-x-hidden',
            isStaffRole && 'bg-white max-w-180',
          )}
        >
          {documentType === OrgDocumentType.PDF && (
            <PdfViewer
              file={{
                url: api.formatOrgDocumentFileUrl(
                  orgId,
                  OrgDocumentCategory.EULA,
                ),
              }}
              className="p-0"
              pageClassName="p-2"
              gutterSize={isStaffRole ? 8 : 20}
              maxWidth={isStaffRole ? 700 : undefined}
            />
          )}
        </div>
        <div
          className={twMerge(
            'w-full justify-center mt-10 flex',
            isStaffRole ? 'flex-row' : 'flex-col items-center',
          )}
        >
          <PillButton
            theme={ButtonTheme.PURPLE}
            onClick={onCancel}
            className="mx-2.5 w-48 mb-5"
          >
            Cancel
          </PillButton>
          <PillButton
            theme={ButtonTheme.PURPLE}
            onClick={onAcceptEula}
            className="mx-2.5 w-48 mb-5"
          >
            I Accept
          </PillButton>
        </div>
      </div>
    </div>
  );
};
