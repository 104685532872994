import { FC, useMemo } from 'react';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { Modal } from '../Modal/Modal';
import { PillButton } from '../PillButton/PillButton';
import { useProductKey } from '../../hooks/product/useProductKey';
import { Blurb } from '../Blurb/Blurb';
import { BEAR_IMG_MAP } from '../../constants/bears/bearImageMapping';
import { getWelcomeBackBearAndMessage } from '../../constants/post-test/postTestBears';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

interface PostTestSurveyOverlayProps {
  onGoToPostTestClicked(): void;
}

export const PostTestSurveyOverlay: FC<PostTestSurveyOverlayProps> = ({
  onGoToPostTestClicked,
  ...rest
}) => {
  useBodyOverflowHidden();
  const { isSpanish, language } = useProductLanguage();
  const productKey = useProductKey();
  const { bear, message } = useMemo(
    () => getWelcomeBackBearAndMessage(productKey, language),
    [language, productKey],
  );

  let messageClassName = 'bottom-16 -left-6';
  if (productKey === ProductKey.SCHOOL_AGE) {
    messageClassName = 'bottom-12 left-0';
  }

  return (
    <Modal>
      <div
        className="z-40 absolute top-0 left-0 min-h-screen w-screen flex flex-col justify-between text-center bg-blue-400"
        {...rest}
      >
        <h1 className="text-white text-5xl mt-36">
          {isSpanish ? '¡Bienvenido de vuelta!' : 'Welcome back!'}
        </h1>
        <p className="mt-8">
          {isSpanish
            ? 'Tome la encuesta del Family Check-up para ver cómo ha mejorado su crianza.'
            : 'Take the Family Check-Up survey to see the improvements you have made!'}
        </p>
        <div className="flex flex-col w-60 self-center mt-8 items-center">
          <PillButton onClick={onGoToPostTestClicked} className="max-w-50">
            {isSpanish ? ' !Empiece!' : 'Get Started'}
          </PillButton>
        </div>
        <div className="flex flex-row justify-center mt-8 relative">
          <img
            src={BEAR_IMG_MAP[bear]}
            alt="Bear Character"
            className="w-48 md:w-60 z-10"
          />
          <Blurb className="p-8 z-0" rootClassName={messageClassName}>
            {message}
          </Blurb>
        </div>
      </div>
    </Modal>
  );
};
