import { FC } from 'react';
import { ProactiveParenting1Slide } from './proactive_parenting_1';
import { ProactiveParenting2Slide } from './proactive_parenting_2';
import { ProactiveParenting3Slide } from './proactive_parenting_3';
import { ProactiveParenting4Slide } from './proactive_parenting_4';
import { ProactiveParenting5Slide } from './proactive_parenting_5';
import { ProactiveParenting6Slide } from './proactive_parenting_6';
import { ProactiveParenting7Slide } from './proactive_parenting_7';
import { ProactiveParenting8Slide } from './proactive_parenting_8';
import { ProactiveParenting9Slide } from './proactive_parenting_9';
import { ProactiveParenting10Slide } from './proactive_parenting_10';
import { ProactiveParenting11Slide } from './proactive_parenting_11';
import { ProactiveParenting12Slide } from './proactive_parenting_12';
import { ProactiveParenting13Slide } from './proactive_parenting_13';
import { ProactiveParenting14Slide } from './proactive_parenting_14';
import { ProactiveParenting15Slide } from './proactive_parenting_15';
import { ProactiveParenting16Slide } from './proactive_parenting_16';

export const proactiveParentingSlides: Record<string, FC> = {
  proactive_parenting_1: ProactiveParenting1Slide,
  proactive_parenting_2: ProactiveParenting2Slide,
  proactive_parenting_3: ProactiveParenting3Slide,
  proactive_parenting_4: ProactiveParenting4Slide,
  proactive_parenting_5: ProactiveParenting5Slide,
  proactive_parenting_6: ProactiveParenting6Slide,
  proactive_parenting_7: ProactiveParenting7Slide,
  proactive_parenting_8: ProactiveParenting8Slide,
  proactive_parenting_9: ProactiveParenting9Slide,
  proactive_parenting_10: ProactiveParenting10Slide,
  proactive_parenting_11: ProactiveParenting11Slide,
  proactive_parenting_12: ProactiveParenting12Slide,
  proactive_parenting_13: ProactiveParenting13Slide,
  proactive_parenting_14: ProactiveParenting14Slide,
  proactive_parenting_15: ProactiveParenting15Slide,
  proactive_parenting_16: ProactiveParenting16Slide,
};
