import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import { InfoIcon } from '../../../components/InfoIcon/InfoIcon';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import bedroomDeskSrc from '../../../images/bedroom_desk.png';
import { Blurb } from '../../../components/Blurb/Blurb';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { ToolTipTriPosition } from 'shared/lib/types/ToolTipTriPosition';

export const SSS_DuringAfter_c: FC = () => {
  const { next } = useSlideContext();
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [visibleTooltip, setVisibleTooltip] = useState<null | number>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lastScene, setLastScene] = useState(false);
  const {
    setValue: setCompleted,
    isReview,
    activity,
  } = useActivityState({
    bool: false,
  });
  const { isSpanish, language } = useProductLanguage();

  const handleIcon = () => {
    setVisibleTooltip((tip) =>
      tip === currentContentIndex ? null : currentContentIndex,
    );
  };

  const handleTooltipClose = () => {
    setVisibleTooltip(null);
    // If not at last scene advance, else leave content where it is at.
    setCurrentContentIndex((index) => {
      if (index + 1 === schoolWorkContent.length) {
        setLastScene(true);
        return index;
      }
      return index + 1;
    });
  };

  const handleSubmit = () => {
    setCompleted({ bool: true });
    if (isReview) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const { iconClasses, tooltipText, tooltipClasses, tooltipTriPosition } =
    schoolWorkContent[currentContentIndex];

  return (
    <SlideContainer
      className="flex-col font-light text-white"
      hideButton={!isReview && currentContentIndex === 0}
      onButtonClick={handleSubmit}
    >
      <p className="px-14 mb-8">
        {!lastScene ? (
          <>
            {isSpanish ? 'Pulse la' : 'Tap on an'}
            <InfoIcon />
            {isSpanish
              ? `en la sala para ver algunos consejos para crear un
                ambiente de apoyo para el trabajo escolar.`
              : `in the room to see some tips for creating
                a supportive environment for schoolwork.`}
          </>
        ) : (
          <>
            {isSpanish
              ? 'Recuerde darle elogios especificos por los esfuerzos de su hijo/a.'
              : "Remember to give specific praise for your child's efforts."}
          </>
        )}
      </p>
      <div className="relative flex flex-col items-center max-w-full min-w-93 w-93 sm:w-100 self-center">
        <img
          src={bedroomDeskSrc}
          alt={isSpanish ? 'dormitorio y escritorio' : 'bedroom and desk'}
          className="w-full"
        />
        {!lastScene && (
          <InfoIcon
            className={`absolute z-10 w-8 h-8 ${iconClasses}`}
            onIconClick={handleIcon}
          />
        )}
        <div className={`absolute ${tooltipClasses}`}>
          <InfoTooltip
            text={tooltipText[language]}
            textAlign="left"
            visible={visibleTooltip === currentContentIndex}
            position={tooltipTriPosition}
            onClose={handleTooltipClose}
          />
        </div>
        {lastScene && (
          <div className="absolute top-1/4 right-10 mt-12 w-52 transform rotate-180">
            <Blurb className="p-4">
              <p className="transform rotate-180">
                {isSpanish
                  ? 'Buen trabajo en tener tu escritorio listo para hacer tarea. Estoy orgulloso de ti.'
                  : 'Great job having your desk ready for homework. I’m proud of you.'}
              </p>
            </Blurb>
          </div>
        )}
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};

const schoolWorkContent: Array<{
  iconClasses: string;
  tooltipText: { spanish: string; english: string };
  tooltipClasses: string;
  tooltipTriPosition: ToolTipTriPosition;
}> = [
  {
    iconClasses: 'left-1/4 bottom-1/4 mb-9 -ml-1  md:ml-1',
    tooltipText: {
      spanish: 'Comience la tarea a una hora regular del día',
      english: 'Start homework at a regular time of day.',
    },
    tooltipClasses:
      'right-32 sm:-right-7 w-52 sm:w-full bottom-1/4 mb-22 sm:mb-20 -mr-11',
    tooltipTriPosition: 'bottomLeft',
  },
  {
    iconClasses: 'right-3/4 bottom-1/4 -mb-16',
    tooltipText: {
      spanish: 'Tenga una área en su casa donde su hijo/a haga su tarea',
      english:
        'Have an area in your house where your child does their homework.',
    },
    tooltipClasses:
      'right-32 w-52 sm:w-full sm:-right-6 bottom-1/4 -mb-4 -mr-4 sm:-mr-2',
    tooltipTriPosition: 'bottomLeft',
  },
  {
    iconClasses: 'left-3/4 bottom-1/4 ml-5 mb-2',
    tooltipText: {
      spanish: `Minimice las distracciones. Recuérdese que puede motivar a su hijo/a 
                para que complete su trabajo escolar con declaraciones de cuando/entonces 
                como, "Cuando termine su trabajo escolar, entonces puede ver un video"`,
      english: `Minimize distractions. Remember you can motivate your child to complete their 
                schoolwork with when/then statements like, “When your schoolwork is done, 
                you can watch a video.”`,
    },
    tooltipClasses: 'bottom-1/3 right-1/4 mb-3 -mr-16 w-80',
    tooltipTriPosition: 'bottomRight',
  },
  {
    iconClasses: 'left-1/2 bottom-1/4 ml-8 mb-8',
    tooltipText: {
      spanish:
        'Tener opciones de bocadillos saludables ayuda a los niños concentrarse.',
      english: 'Having healthy snack options helps kids focus.',
    },
    tooltipClasses: 'top-1/2 left-1/3  -ml-11 sm:-ml-14 -mt-4',
    tooltipTriPosition: 'bottomCenter',
  },
  {
    iconClasses: 'left-1/2 bottom-1/4 -ml-6 -mb-3',
    tooltipText: {
      spanish: 'Tenga suministros listos para ellos.',
      english: 'Have supplies ready for them.',
    },
    tooltipClasses: 'bottom-1/4 right-1/4 mb-9 -mr-4 sm:-mr-2',
    tooltipTriPosition: 'bottomCenter',
  },
];
