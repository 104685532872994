import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences19Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer className="px-14 flex-col font-light">
      <h2 className="text-lg uppercase text-red-600 font-bold">
        {isEnglish ? 'Do Ignore' : 'Ignore'}
      </h2>
      <YellowCircleList className="mt-2.5">
        {isEnglish ? (
          <>
            <li>Whining</li>
            <li>Emotional outburts</li>
            <li>Complaining</li>
            <li>Pouting</li>
          </>
        ) : (
          <>
            <li>Gimoteo</li>
            <li>Arrebatos Emocionales</li>
            <li>Quejas</li>
            <li>Pucheros</li>
          </>
        )}
      </YellowCircleList>
      <h2 className="text-lg uppercase text-red-600 font-bold mt-9">
        {isEnglish ? "Don't Ignore" : 'No Ignore'}
      </h2>
      <YellowCircleList className="mt-2.5 mb-5">
        {isEnglish ? (
          <>
            <li>Aggressive behaviors</li>
            <li>Hitting</li>
            <li>Being unsafe</li>
          </>
        ) : (
          <>
            <li>Comportamientos agresivos </li>
            <li>Pegando</li>
            <li>Inseguridad</li>
          </>
        )}
      </YellowCircleList>
    </SlideContainer>
  );
};
