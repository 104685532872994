import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isUnitComplete } from 'shared/lib/utils/unitUtils';
import { Confetti } from '../Confetti/Confetti';
import { UnitIcon } from '../UnitIcon/UnitIcon';
import { PillButton } from '../PillButton/PillButton';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { useSlideContext } from '../../contexts/slideContext';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const ModuleCompleteSlide: FC<{ onHomeClick?(): unknown }> = ({
  onHomeClick,
  ...rest
}) => {
  const history = useHistory();
  const { productSession, setProductSession } = useParticipantProductContext();
  const { unit } = useSlideContext();
  const [stage, setStage] = useState<'badge' | 'wow'>('badge');
  const { isSpanish } = useProductLanguage();

  useEffect(() => window.scrollTo(0, 0), []);
  useBodyOverflowHidden();

  const firstFullCompletion = useMemo(
    () =>
      !productSession.allModulesComplete &&
      productSession.unitSessions.every(isUnitComplete),
    [productSession],
  );

  const handleHomeClick = useCallback(() => {
    if (onHomeClick) {
      onHomeClick();
    } else {
      history.push('/dashboard');
    }
  }, [onHomeClick, history]);

  const handleBadgeStageClick = () => {
    if (firstFullCompletion) {
      setStage('wow');
      setProductSession({ ...productSession, allModulesComplete: true });
    } else {
      handleHomeClick();
    }
  };

  return (
    <div
      className="absolute top-0 left-0 h-screen w-full overflow-y-auto flex
        text-center bg-blue-700 bg-wave bg-repeat
        bg-center bg-blend-multiply bg-auto z-40"
      {...rest}
    >
      <Confetti />
      <div className="flex flex-col flex-1 items-center w-full pt-5 pb-20 px-2">
        <ParticipantNavButton className="self-end mb-20" />
        <div className="relative flex flex-col flex-1 w-2/3 min-w-60 items-center text-yellow-200 text-xl">
          {stage === 'badge' && (
            <>
              <h1 className="text-3.5xl font-light m-0 mb-3">
                {isSpanish ? '¡Lo hizo!' : 'You did it!'}
              </h1>
              <h2 className="text-xl font-bold leading-6 m-0 mb-10">
                {isSpanish ? '¡Se ganó su medalla!' : 'You earned your badge!'}
              </h2>
              <UnitIcon className="mb-2 w-24 h-24" unit={unit} />
              <h2 className="text-white text-2xl leading-6 m-0 mb-24">
                {unit.name}
              </h2>
              <PillButton
                className="w-52 mt-12"
                onClick={handleBadgeStageClick}
              >
                {
                  //prettier-ignore
                  firstFullCompletion
                    ? (isSpanish ? "Siguiente" : 'Next')
                    : (isSpanish ? "Inicio" : 'Home')
                }
              </PillButton>
            </>
          )}
          {stage === 'wow' && (
            <>
              <h1 className="text-3.5xl font-light m-0 mb-3">
                {isSpanish ? '¡Guau!' : 'Wow!'}
              </h1>
              <p className="mt-2 mb-6 font-semibold">
                {isSpanish
                  ? `Ha completado el programa entero, ¡buen trabajo! ¡Siga
                     regresando para refrescar sus estratégias cuando lo necesite!`
                  : `You made it through the whole program, great work! Keep
                     coming back to refresh your strategies when you need to!`}
              </p>
              <p className="font-semibold">
                {isSpanish
                  ? '¡Le deseamos lo mejor de parte Family Check-Up!'
                  : 'Best wishes from Family Check-Up!'}
              </p>
              <PillButton className="w-52 mt-12" onClick={handleHomeClick}>
                {isSpanish ? 'Hogar' : 'Home'}
              </PillButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
