import { FC } from 'react';
import blurbArrowSrc from '../../images/blurb_arrow.png';

/**
 * @param {string} className - The classes passed down will affect the container holding the children, not the parent container.
 */

export const Blurb: FC<{ className?: string; rootClassName?: string }> = ({
  className = '',
  rootClassName = '',
  children,
  ...rest
}) => (
  <div
    className={`relative inline-flex flex-row justify-center items-center text-blue-800 font-bold ${rootClassName}`}
    {...rest}
  >
    <img src={blurbArrowSrc} className="absolute z-10 -left-5 w-7 h-7" alt="" />
    <div
      className={`border-blue-475 border-6 bg-white rounded-xl ${className}`}
    >
      {children}
    </div>
  </div>
);
