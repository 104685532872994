import { FC, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { api } from '../../api';
import { Spinner } from '../../components/Spinner/Spinner';
import { useSaveSurveyResponse } from '../../hooks/product/useSaveSurveyResponse';
import { PostTestScreen } from '../../components/Survey/PostTestScreen';
import {
  formatPostTestSurveyQuestionRoute,
  formatPostTestSurveyRoute,
  POST_TEST_RESULTS,
} from '../../constants/routes/postTestRoutes';
import { usePostTestSurveys } from '../../hooks/product/usePostTestSurveys';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import {
  updateProductSessionWithPostTestResponse,
  updateProductSessionWithPostTestTake,
} from 'shared/lib/utils/postTestUtils';

interface PostTestPageProps {
  survey: SurveyWithQuestions;
}
export const PostTestPage: FC<PostTestPageProps> = ({ survey, ...rest }) => {
  const { productSession, setProductSession } = useParticipantProductContext();
  const { unitSessions, postTestUnlocked, orgProduct } = productSession;
  const { orgId, productId } = orgProduct;
  const history = useHistory();
  const { postTestSurveys, incompletePostTestSurveys, postTestTakes } =
    usePostTestSurveys(productSession);
  const currentSurveyTake = postTestTakes.find(
    ({ surveyId }) => surveyId === survey.id,
  );

  useAsyncEffect(
    async (isCancelled) => {
      if (!currentSurveyTake && postTestUnlocked) {
        const postTest = await api.createPostTestSurveyTake({
          productId,
          surveyId: survey.id,
          orgId,
        });
        if (!isCancelled()) {
          setProductSession((value) =>
            updateProductSessionWithPostTestTake(value, survey, postTest),
          );
        }
      }
    },
    [survey, unitSessions, productId, productId, orgId, postTestUnlocked],
  );

  const onSurveyResponseSaved = useCallback(
    (response: { questionId: number; value: number }) => {
      setProductSession((value) =>
        updateProductSessionWithPostTestResponse(value, survey, response),
      );
    },
    [setProductSession, survey],
  );

  const setSurveyResponse = useSaveSurveyResponse({
    survey,
    surveyTake: currentSurveyTake,
    onSurveyResponseSaved,
    orgId,
    productId,
  });

  const goToPreviousUnitSurvey = useCallback(() => {
    const index = postTestSurveys.findIndex(({ id }) => id === survey.id);
    if (index <= 0) {
      return;
    }
    const previousIndex = Math.max(0, index - 1);
    const previousSurvey = postTestSurveys[previousIndex];
    if (previousSurvey) {
      history.push(
        formatPostTestSurveyQuestionRoute(
          previousSurvey,
          previousSurvey.questions.length ?? 0,
        ),
      );
    }
  }, [survey, postTestSurveys, history]);

  const onUnitSurveyComplete = useCallback(() => {
    const index = postTestSurveys.findIndex(({ id }) => id === survey.id);
    const nextIndex = index + 1;

    let nextSurvey = postTestSurveys[nextIndex];

    // If there is no next, check if all of them are complete
    if (!nextSurvey) {
      nextSurvey = incompletePostTestSurveys[0];
    }

    // If we still don't have a next session, it must mean the post test is complete
    if (!nextSurvey) {
      history.replace(POST_TEST_RESULTS);
    } else {
      history.push(formatPostTestSurveyRoute(nextSurvey));
    }
  }, [survey, postTestSurveys, incompletePostTestSurveys, history]);

  if (!postTestUnlocked) {
    return <Redirect to="/" />;
  }

  // In the process of creating it
  if (!currentSurveyTake) {
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-blue-900">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-blue-900 min-h-screen">
      <div className="relative md:max-w-2xl md:mx-auto">
        <PostTestScreen
          basePath={formatPostTestSurveyRoute(survey)}
          survey={survey}
          postTestTake={currentSurveyTake}
          onResponseChange={setSurveyResponse}
          goToPreviousUnitSurvey={goToPreviousUnitSurvey}
          onUnitSurveyComplete={onUnitSurveyComplete}
          postTestSurveys={postTestSurveys}
        />
      </div>
    </div>
  );
};
