import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { Language } from 'shared/lib/types/Language';

const englishLabels = [
  'I know their favorite game.',
  'I know who they text.',
  'I monitor their social media.',
  'I talk with my kids about what they do online.',
  'I use parental controls.',
];

const spanishLabels = [
  'Conozco su juego favorito.',
  'Sé a quién envian mensajes de texto.',
  'Monitoreo sus redes sociales.',
  'Hablo con mi hijo/a sobre lo que hace en línea.',
  'Uso controles parentales.',
];

const correctMessages = {
  english: `Sounds like you’re really connected to what your 
            child’s doing online, great work!`,
  spanish: `Parece que está realmente conectado con lo que 
            hace su hijo/a en línea, excelente trabajo!`,
};

const incorrectMessages = {
  english: `Sounds like you’re starting to connect to what your child’s doing online and may consider 
            having a conversation with your child to learn more about their online activity.`,
  spanish: `Parece que está comenzando a conectarse con lo que hace su hijo/a en línea y puede 
            considerar tener una conversación con su hijo/a para aprender más sobre sus actividades en línea.`,
};

const getCorrectIncorrectMessages = (lang: Language, correct: boolean) =>
  correct ? correctMessages[lang] : incorrectMessages[lang];

export const HBST_Screens_d: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      question1: false,
      question2: false,
      question3: false,
      question4: false,
      question5: false,
    });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const allCorrect =
    value.question1 &&
    value.question2 &&
    value.question3 &&
    value.question4 &&
    value.question5;

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const labels = isSpanish ? spanishLabels : englishLabels;

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? `Su turno: ¿Cuánto sabe sobre lo que le gusta hacer a su 
              hijo/a en línea? Marque todo lo que aplique.`
            : `Your turn: How much do you know about what your child 
              likes to do online? Check all that apply.`
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center">
          <FloatingCard className="w-full pt-3 px-4">
            {Object.keys(value).map((key, idx) => {
              const valueKey = key as keyof typeof value;
              return (
                <SlideCheckbox
                  key={key}
                  className="mb-3"
                  checked={value[valueKey]}
                  onChange={() =>
                    setValue({ ...value, [key]: !value[valueKey] })
                  }
                  label={labels[idx]}
                />
              );
            })}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 self-center mb-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Entregue' : 'Submit'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        icon={allCorrect ? 'thumbs-up' : 'alert'}
        onNext={next}
        badgeLocation="right"
        bearCharacter="GIRL"
        activityPoints={activity.pointValue}
        activityMessage={getCorrectIncorrectMessages(language, allCorrect)}
      />
    </SlideContainer>
  );
};
