import { FC, useEffect, useState, useCallback } from 'react';
import { ParticipantNavOverlay } from '../ParticipantNavOverlay/ParticipantNavOverlay';
import { useLocation } from 'react-router-dom';

export const ParticipantNavButton: FC<{
  highlightedUnitId?: number;
  highlightedSectionId?: number;
  className?: string;
}> = ({ highlightedSectionId, highlightedUnitId, className = '', ...rest }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(
    () => setMenuOpen((menuOpen) => !menuOpen),
    [],
  );

  /**
   * Automatically close the menu when the page location changes.
   */
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  return (
    <>
      <button
        {...rest}
        className={`w-7 flex flex-col items-end flex-shrink-0 ${className}`}
        aria-label="Open menu"
        onClick={toggleMenu}
      >
        {/* Hamburger menu bars */}
        <div className="not-sr-only rounded-sm bg-blue-400 h-1 w-full" />
        <div className="not-sr-only rounded-sm bg-blue-400 h-1 w-6 mt-1" />
        <div className="not-sr-only rounded-sm bg-blue-400 h-1 w-5 mt-1" />
      </button>
      {menuOpen && (
        <ParticipantNavOverlay
          onClose={toggleMenu}
          highlightedUnitId={highlightedUnitId}
          highlightedSectionId={highlightedSectionId}
        />
      )}
    </>
  );
};
