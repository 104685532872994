import { FC, useMemo } from 'react';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { PillButton } from '../PillButton/PillButton';
import { SurveyTakeWithDraftResponses } from 'shared/lib/types/SurveyTake';
import { calculateSurveyResults } from 'shared/lib/utils/calculateSurveyResults';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { Unit } from 'shared/lib/types/Unit';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { InfoIcon } from '../InfoIcon/InfoIcon';

const resultCategories = {
  red: {
    name: { english: 'red', spanish: 'rojo' },
    sideLabel: { english: 'Focus Area', spanish: 'Área de Enfoque' },
    description: {
      english: `Your answers show this topic is a concern for your family. This 
                program can help you improve your child’s behavior and your 
                family relationships.`,
      spanish: `Sus respuestas enseñan que este tema es de preocupación para 
                su familia. Este programa puede ayudar en mejorar el 
                comportamiento de su hijo/a y las relaciones familiares.`,
    },
    backgroundColor: 'bg-survey-result-bad',
    borderColor: 'border-survey-result-bad',
    fontColor: 'text-white',
  },
  yellow: {
    name: { english: 'yellow', spanish: 'amarillo ' },
    sideLabel: { english: 'Needs Attention', spanish: 'Necesita Atención' },
    description: {
      english: `Your answers show this topic could use some attention in your family. 
                By catching a problem early and using the skills in this program, 
                you can make positive changes for your child and family.`,
      spanish: `Sus repuestas enseñan que este tema puede usar su atención en su familia. 
                Cuando se puede notar un problema temprano y usar las habilidades de este 
                programa, puede hacer cambios positivos para su niño/a y familia. `,
    },
    backgroundColor: 'bg-survey-result-okay',
    borderColor: 'border-survey-result-okay',
    fontColor: 'text-blue',
  },
  green: {
    name: { english: 'green', spanish: 'verde' },
    sideLabel: { english: 'Area of Strength', spanish: 'Área de Fortaleza' },
    description: {
      english: `Your answers show that you use good parenting skills that help you 
                positively shape your child’s behavior. Keep up the good work!`,
      spanish: `Sus respuestas enseñan que Ud. usa buenas habilidades de crianza 
                que positivamente forman los comportamientos de su hijo/a. ¡Siguiente 
                con el buen trabajo!`,
    },
    color: 'green',
    backgroundColor: 'bg-survey-result-good',
    borderColor: 'border-survey-result-good',
    fontColor: 'text-white',
  },
};
const resultCategoriesArray = [
  resultCategories.red,
  resultCategories.yellow,
  resultCategories.green,
];

export const SurveyResultScreen: FC<{
  unit: Pick<Unit, 'name'>;
  survey: SurveyWithQuestions;
  take: SurveyTakeWithDraftResponses;
  onNext(): unknown;
  onBack(): unknown;
  closeButtonText?: string;
  className?: string;
}> = ({
  className = '',
  survey,
  take,
  closeButtonText,
  onNext,
  onBack,
  unit,
  ...rest
}) => {
  const [showInfoOverlay, toggleInfoOverlay] = useToggleState(false);
  const { isSpanish, language } = useProductLanguage();

  const resultGroups = useMemo(() => {
    const surveyResults = calculateSurveyResults(survey, take, language);

    return [
      {
        ...resultCategories.red,
        items: surveyResults?.red ?? [],
      },
      {
        ...resultCategories.yellow,
        items: surveyResults?.yellow ?? [],
      },
      {
        ...resultCategories.green,
        items: surveyResults
          ? surveyResults.green.length === 0
            ? [
                {
                  label: isSpanish
                    ? 'Participando en este programa'
                    : 'Participating in this program',
                  questionIds: [],
                },
              ]
            : surveyResults.green
          : [],
      },
    ];
  }, [survey, take, language, isSpanish]);

  return (
    <div
      {...rest}
      className={`flex flex-col min-h-screen bg-white ${className}`}
    >
      {/* hamburger menu */}
      <div className="flex flex-row justify-end px-4 pt-5">
        <ParticipantNavButton />
      </div>
      <img
        src={getPublicFilePath(survey.icon)}
        alt=""
        className="w-20 h-20 self-center flex-shrink-0 -mt-2 bg-blue rounded-full"
      />

      <h1 className="text-blue-400 text-2xl text-center font-light mt-4 px-4">
        {survey.name}
      </h1>

      <h2 className="bg-blue-400 text-white font-bold text-lg text-center p-6 mt-4">
        {isSpanish ? 'Resultados de la encuesta' : 'Survey Results'}
      </h2>

      {/* Result category explanation overlay */}
      <div className="relative">
        {showInfoOverlay && (
          <div className="absolute -top-14 left-4 right-4 flex flex-col px-12 py-10 bg-white rounded-lg z-10">
            <h3 className="font-bold text-lg text-center">
              {isSpanish ? 'Categorias de Resultado' : 'Results Categories'}
            </h3>
            <ul>
              {resultCategoriesArray.map((resultCategory) => (
                <li
                  key={resultCategory.name[language]}
                  className={`border-3 rounded-lg mt-5 ${resultCategory.borderColor}`}
                >
                  <div
                    className={`text-center text-lg font-bold p-1 ${resultCategory.backgroundColor} ${resultCategory.fontColor}`}
                  >
                    {resultCategory.name[language]}
                  </div>
                  <p className="p-4 leading-tight">
                    {resultCategory.description[language]}
                  </p>
                </li>
              ))}
            </ul>
            <PillButton
              onClick={toggleInfoOverlay}
              className="w-48 self-center mt-8"
            >
              {isSpanish ? 'Hecho' : 'Done'}
            </PillButton>
          </div>
        )}
      </div>

      <div className="bg-gray-100 flex-1 flex flex-col pt-2 pb-8 text-blue-800">
        <div className="flex-1 flex flex-col">
          <p className="px-10 mt-5">
            {isSpanish
              ? `Recomendamos que repase todo el contenido, pero basado en sus resultados,
                 le recomendamos que ponga más atención en los temas que están en la Área
                 de Enfoque. Toque *La imagen de Vea los resultados* para aprender más del
                 significado de la categoría de cada color.`
              : `We suggest you go through all the content, but based on your results,
                 we recommend you pay close attention to the topics in the Focus Area.`}
          </p>

          <p className="px-10 mb-5 mt-4">
            {isSpanish ? (
              <>
                Toque <InfoIcon /> para aprender más sobre el significado de la
                categoría de cada color.
              </>
            ) : (
              <>
                Tap the <InfoIcon /> to learn more about the color categories
              </>
            )}
          </p>

          <button
            onClick={toggleInfoOverlay}
            aria-label="More info"
            className="
              w-7 h-7 flex-shrink-0 self-center mb-4
              rounded-full bg-yellow can-hover:hover:bg-yellow-200 transition-colors shadow-xl
              text-xl font-bold"
          >
            i {/* Open the result info overlay */}
          </button>
          {/* Result groups */}
          {resultGroups.map(
            (
              { name, sideLabel, borderColor, backgroundColor, items },
              groupIndex,
            ) => {
              return (
                <div
                  className={`relative min-h-36 flex-shrink-0 flex flex-col justify-center font-medium ${
                    groupIndex > 0 ? 'mt-2' : ''
                  }`}
                  key={name[language]}
                >
                  {/* Sideways label */}
                  <div
                    className={`${backgroundColor} absolute left-0 top-0 h-full w-3 flex flex-col justify-center items-center`}
                  >
                    <div className="transform rotate-90 translate-x-4 whitespace-nowrap text-sm font-medium">
                      {sideLabel[language]}
                    </div>
                  </div>

                  {/* Result list for a single color */}
                  <ul key={name[language]} className="px-16">
                    {items.map((item, itemIndex) => (
                      <li
                        className={`border-5 py-4 px-2 text-center rounded-lg uppercase font-medium ${borderColor} ${
                          itemIndex > 0 ? 'mt-2' : ''
                        }`}
                        key={item.label}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            },
          )}
        </div>
        {!showInfoOverlay && (
          <PillButton
            onClick={onNext}
            className="w-60 self-center flex-shrink-0 mt-12"
          >
            {closeButtonText ?? (isSpanish ? 'Empiece' : 'Get started')}
          </PillButton>
        )}
      </div>
    </div>
  );
};
