import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import starRibbonSrc from '../../../images/star_ribbon.png';

export const PositiveParenting19Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Using incentives and rewards'
          : 'Uso de incentivos y recompensas'
      }
      className="flex-col items-center text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            A helpful way to get your child to follow your directions is to use
            incentives and rewards for their good behavior. While very similar,
            incentives and rewards can be used differently.
          </>
        ) : (
          <>
            Una forma útil de lograr que su niño/a siga sus instrucciones es
            usar incentivos y recompensas por su buen comportamiento. Los
            incentivos y las recompensas son muy similares y se pueden usar de
            maneras diferentes.
          </>
        )}
      </p>
      <img src={starRibbonSrc} alt="ribbon" className="w-30 mt-5" />
    </SlideContainer>
  );
};
