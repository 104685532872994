import { FC } from 'react';
import { ReactComponent as ThumbsUp } from '../../images/feather/thumbs-up.svg';

type ThumbsUpBackgroundColor = 'blue' | 'red';

const BACKGROUND_COLOR_MAPPING: Record<ThumbsUpBackgroundColor, string> = {
  blue: 'bg-blue-400',
  red: 'bg-red-600',
};

const STROKE_COLOR_MAPPING: Record<ThumbsUpBackgroundColor, string> = {
  blue: '#4DACBE',
  red: '#FF6762',
};

export interface ThumbsUpIconProps {
  className?: string;
  thumbsUpClassName?: string;
  backgroundColor?: ThumbsUpBackgroundColor;
}

export const ThumbsUpIcon: FC<ThumbsUpIconProps> = ({
  className = '',
  thumbsUpClassName = '',
  backgroundColor = 'blue',
  ...rest
}) => (
  <div
    className={`w-9 h-9 pb-0.5 flex justify-center items-center 
      ${BACKGROUND_COLOR_MAPPING[backgroundColor]} rounded-full ${className}`}
    {...rest}
  >
    <ThumbsUp
      fill="white"
      stroke={STROKE_COLOR_MAPPING[backgroundColor]}
      className={thumbsUpClassName}
    />
  </div>
);
