import { FC, ReactNode } from 'react';
import { SlideHeader } from '../SlideHeader/SlideHeader';
import { PillButton, ButtonTheme } from '../PillButton/PillButton';
import { useSlideContext } from '../../contexts/slideContext';
import { useProductLanguage } from '../../hooks/useProductLanguage';

/**
 * @param {string} [className=""] - Affects immediate container around chidlren. Not the parent container.
 * @param {string} [buttonText="Next"] - Defaults to "Next".
 * @param {function} [onButtonClick=next] - Defaults to next function located on SlideContext
 */
export const SlideContainer: FC<{
  lightBg?: boolean;
  lightProgressBar?: boolean;
  slideTitle?: string;
  hideButton?: boolean;
  buttonText?: string | false;
  buttonTheme?: ButtonTheme;
  onButtonClick?(): unknown;
  className?: string;
  headerContent?: ReactNode;
}> = ({
  lightBg,
  lightProgressBar,
  slideTitle,
  hideButton,
  buttonText,
  buttonTheme,
  onButtonClick,
  className = '',
  headerContent,
  children,
  ...rest
}) => {
  const { next, minimalSlideWrapper, viewOnly } = useSlideContext();
  const { isSpanish } = useProductLanguage();

  if (minimalSlideWrapper) {
    // Used for the My Plan page
    return <div className={`flex flex-1 mb-4 ${className}`}>{children}</div>;
  }

  const showButton = !hideButton && !viewOnly;

  return (
    <div
      {...rest}
      className={`min-h-screen flex flex-col ${
        lightBg ? 'bg-white' : 'bg-blue-900'
      }`}
    >
      <SlideHeader headerContent={headerContent} light={lightProgressBar} />
      {slideTitle && (
        <h2 className="font-extrabold text-sm uppercase mb-3 px-14 text-white">
          {slideTitle}
        </h2>
      )}
      <div className={`flex flex-1 ${className} slide-content`}>{children}</div>
      {showButton && (
        <PillButton
          className="w-52 self-center my-12"
          disableXPadding
          theme={buttonTheme}
          onClick={onButtonClick ?? next}
        >
          {buttonText || (isSpanish ? 'Siguiente' : 'Next')}
        </PillButton>
      )}
    </div>
  );
};
