import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse9Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Coping without alcohol and drugs'
          : 'Afrontar sin alcohol ni drogas'
      }
      className="flex-col text-white px-14 font-light"
    >
      {isEnglish ? (
        <>
          When you don’t give in to a craving because you are parenting your
          child, give yourself credit! Your resistance takes srength and
          deserves to be recognized.
        </>
      ) : (
        <>
          Cuando no cede ante un antojo porque está criando a su hijo, ¡dése
          crédito a sí mismo! Su resistencia toma fuerza y ​​merece ser
          reconocida.
        </>
      )}
    </SlideContainer>
  );
};
