import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import thumbsUpIconSrc from '../../../images/thumbs_up.png';
import thumbsDownIconSrc from '../../../images/thumbs_down.png';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting16Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      buttonText={isEnglish ? 'Start Sorting' : 'Empiece a sortear'}
      buttonTheme={ButtonTheme.YELLOW}
      className="flex-col items-center text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Click the Start Sorting button to test if you can tell the
            difference between helpful and unhelpful ways of giving directions
            to your child.
          </>
        ) : (
          <>
            Haga clic en el botón "Empiece a sortear" para ver si puede
            diferenciar entre formas útiles y no útiles de dar instrucciones a
            su niño/a.
          </>
        )}
      </p>
      <div className="flex justify-center mt-16 mb-10">
        <div
          className="flex flex-col items-center border-2 font-bold
            border-blue-400 text-blue-400 border-dashed mr-2 
            px-4 py-2.5 flex-shrink-0"
        >
          <h3 className="text-lg">{isEnglish ? 'HELPFUL' : 'ÚTIL'}</h3>
          <img className="h-32 w-32" src={thumbsUpIconSrc} alt="thumbs up" />
        </div>
        <div
          className="flex flex-col items-center border-2 font-bold
            border-blue-400 text-blue-400 border-dashed ml-2 
            px-4 py-2.5 flex-shrink-0"
        >
          <h3 className="text-lg">{isEnglish ? 'UNHELPFUL' : 'NO ÚTIL'}</h3>
          <img
            className="h-32 w-32"
            src={thumbsDownIconSrc}
            alt="thumbs down"
          />
        </div>
      </div>
    </SlideContainer>
  );
};
