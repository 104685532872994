import { FC } from 'react';
import {
  SurveyQuestionKind,
  SurveyQuestionOptions,
  SurveyQuestionValue,
} from 'shared/lib/types/SurveyQuestion';

export const MultipleChoiceQuestionInput: FC<{
  options: SurveyQuestionOptions<SurveyQuestionKind.MULTIPLE_CHOICE>;
  value: SurveyQuestionValue<SurveyQuestionKind.MULTIPLE_CHOICE> | null;
  onChange(
    value: SurveyQuestionValue<SurveyQuestionKind.MULTIPLE_CHOICE>,
  ): unknown;
}> = ({ options, value, onChange, ...rest }) => {
  return (
    <ul {...rest}>
      {options.choices.map((choice, i) => (
        <li key={choice.value} className={i > 0 ? 'mt-5' : undefined}>
          <label
            className={`block rounded-full cursor-pointer w-full p-3 border-blue-450 transition-colors border-2 font-medium text-center text-blue-800 text-lg ${
              value === choice.value ? 'bg-blue-200' : 'bg-white'
            }`}
          >
            {choice.label}
            <input
              className="sr-only"
              name="multiple_choice"
              type="radio"
              checked={value === choice.value}
              onChange={(event) => onChange(choice.value)}
            />
          </label>
        </li>
      ))}
    </ul>
  );
};
