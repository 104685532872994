import { FC } from 'react';
import { wellnessAndSelfCareSlides } from './wellnessAndSelfCare';
import { parentingAndSubstanceUseSlides } from './parentingAndSubstanceUse';
import { positiveParentingSlides } from './positiveParenting';
import { proactiveParentingSlides } from './proactiveParenting';
import { rulesAndConsequencesSlides } from './rulesAndConsequences';

export function getEarlyChildhoodSlideComponents(
  unitName: string,
): Record<string, FC> {
  switch (unitName) {
    case 'Bienestar y Cuidado Personal':
    case 'Wellness & Self-Care':
      return wellnessAndSelfCareSlides;
    case 'La Crianza de Los Hijos y el Uso de Sustancias':
    case 'Parenting & Substance Use':
      return parentingAndSubstanceUseSlides;
    case 'Crianza Positiva':
    case 'Positive Parenting':
      return positiveParentingSlides;
    case 'Crianza Proactiva':
    case 'Proactive Parenting':
      return proactiveParentingSlides;
    case 'Reglas y Consecuencias':
    case 'Rules & Consequences':
      return rulesAndConsequencesSlides;
    default:
      console.warn(`Unknown or unimplemented middleschool unit ${unitName}`);
      return {};
  }
}
