import { FC } from 'react';

/**
 *
 * @param {string} [inputLabel="Assignment"] - Default to "Assignment"
 */

export const LabeledInput: FC<{
  className?: string;
  onInputChange(value: string): void;
  inputLabel?: string;
  inputValue?: string;
}> = ({
  className = '',
  onInputChange,
  inputLabel = 'Assignment',
  inputValue = '',
  ...rest
}) => (
  <div
    {...rest}
    className={`flex flex-col-reverse text-blue-800 text-xs box-border ${className}`}
  >
    <label className="font-bold mt-1 block box-border" htmlFor="input">
      {inputLabel}
    </label>
    <input
      type="text"
      value={inputValue}
      className="border-0 border-b border-blue-800 max-w-full box-border block"
      onChange={(e) => onInputChange(e.target.value)}
      style={{ outline: 'none', background: 'transparent' }}
    />
  </div>
);
