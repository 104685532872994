import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import tipBagSrc from '../../../images/proactive_parenting_tip_bag.png';
import tipBagSpanishSrc from '../../../images/proactive_parenting_tip_bag_spanish.png';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting6Slide: FC = () => {
  const { value, setValue, isReview } = useActivityState();
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const proactiveParentingTips = isEnglish
    ? englishProactiveParentingTips
    : spanishProactiveParentingTips;
  const { title, example } = proactiveParentingTips[currentTipIndex];

  const endOfTips = currentTipIndex + 1 === proactiveParentingTips.length;

  const canProgress = isReview || value;

  return (
    <SlideContainer hideButton>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1"
        icon="pointer"
        instructions={
          isEnglish
            ? `Your turn: Each time you tap on the bag you will 
          get a tip to help you parent in a proactive way.`
            : `Su turno: Cada vez que toque la bolsa, obtendrá 
          un consejo para ayudarle a ser padre de manera proactiva.`
        }
      >
        <FloatingCard
          className="w-full pt-6 pb-2.5 px-2.5 flex flex-col 
          items-center text-center min-h-36 mb-2 font-light text-blue-800"
        >
          <h3 className="text-blue-400 mb-2.5 text-sm font-semibold">
            {title}
          </h3>
          {example}
        </FloatingCard>
        <button
          className="w-full mb-10"
          onClick={() => {
            setCurrentTipIndex((curIndex) =>
              endOfTips ? curIndex : curIndex + 1,
            );
            setValue({});
          }}
        >
          <img
            src={isEnglish ? tipBagSrc : tipBagSpanishSrc}
            alt={isEnglish ? 'paper bag' : 'bolsa de papel'}
            className="w-full"
          />
        </button>
        {canProgress && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={next}
          >
            {isEnglish ? 'Next' : 'Siguiente'}
          </PillButton>
        )}
      </ActivityBox>
    </SlideContainer>
  );
};

const englishProactiveParentingTips = [
  {
    title: 'Distract child with alternative activity or toy.',
    example: (
      <p>
        (<i className="font-extralight">example</i> Let's race to see who can
        get their shoes on first so we can leave!)
      </p>
    ),
  },
  {
    title: 'Explain what will happen next.',
    example: (
      <p>
        (<i className="font-extralight">example</i> After we finish reading this
        book, it will be time for bed.)
      </p>
    ),
  },
  {
    title: 'Eliminate “trigger” that may create negative emotional responses.',
    example: (
      <p>
        (<i className="font-extralight">example</i> Avoid the candy aisle when
        you shop.)
      </p>
    ),
  },
  {
    title: 'Give some choices.',
    example: (
      <p>
        (<i className="font-extralight">example</i> Would you like to brush your
        teeth before we read a story or after?)
      </p>
    ),
  },
  {
    title: 'Teach or suggest a way for child to cope.',
    example: (
      <p>
        (<i className="font-extralight">example</i> When I feel that way, I like
        to cuddle my favorite stuffed animal.)
      </p>
    ),
  },
];

const spanishProactiveParentingTips = [
  {
    title: 'Distraiga al niño/a con una actividad alternativa o un juguete.',
    example: (
      <p>
        (ejemplo ¡Hagamos una carrera para ver quién puede ponerse los zapatos
        primero para poder irnos!)
      </p>
    ),
  },
  {
    title: 'Explique lo que sucederá a continuación.',
    example: (
      <p>
        (ejemplo Después de que terminemos de leer este libro, será hora de ir a
        la cama).
      </p>
    ),
  },
  {
    title:
      'Elimine el "desencadenante" que puede crear respuestas emocionales negativas. ',
    example: (
      <p>(Ejemplo Evite el pasillo de dulces cuando vaya de compras).</p>
    ),
  },
  {
    title: 'De algunas opciones.',
    example: (
      <p>
        (ejemplo ¿Te gustaría cepillarte los dientes antes de que leamos una
        historia o después?)
      </p>
    ),
  },
  {
    title:
      'Enseñe o sugiera una manera para que los niños puedan afrontar la situacion ',
    example: (
      <p>
        (ejemplo Cuando me siento así, me gusta abrazar a mi animal de peluche
        favorito).
      </p>
    ),
  },
];
