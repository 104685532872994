import { FC } from 'react';

export const FloatingCard: FC<{
  className?: string;
}> = ({ className = '', children, ...rest }) => (
  <div
    {...rest}
    className={`bg-white rounded-lg shadow-lg min-w-6
      ${className}`}
  >
    {children}
  </div>
);
