import { FC, ComponentPropsWithoutRef } from 'react';
import './Spinner.css';

export const Spinner: FC<ComponentPropsWithoutRef<'div'>> = ({
  className = '',
  ...rest
}) => (
  <div {...rest} className={`Spinner ${className}`}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);
