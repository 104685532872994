import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { IconTextArea } from '../../../components/IconTextArea/IconTextArea';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Relation_c: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      strength: '',
      connect: '',
    });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer hideButton>
      <ActivityBox
        icon="chat"
        className="items-center pt-10 pb-6 px-4 text-blue-600 flex-1"
        instructions={
          isSpanish
            ? `Su turno: Escriba una de las fortalezas de su hijo/a, 
              y cómo va a conectarse conél/ella.`
            : `Your turn: Write in one of your child's strengths, 
              and how you will connect with them.`
        }
      >
        <div className="flex flex-col justify-between items-center w-full m-0 p-0 flex-1">
          <div className="w-full">
            <FloatingCard className="w-full py-4 pr-5 mb-3">
              <IconTextArea
                icon="dumbbell"
                label={isSpanish ? 'FORTALEZA' : 'STRENGTH'}
                placeholder={
                  isSpanish
                    ? 'Ingrese la fuerza de su hijo'
                    : "Enter your child's strength."
                }
                value={value.strength}
                onChange={(val) => setValue({ ...value, strength: val })}
              />
            </FloatingCard>
            <FloatingCard className="w-full py-4 pr-5">
              <IconTextArea
                icon="connect"
                label={isSpanish ? 'CÓMO CONECTAR' : 'HOW TO CONNECT'}
                placeholder={
                  isSpanish
                    ? 'Ingrese lo que haría para conectarse con ellos'
                    : 'Enter what you would do to connect with them.'
                }
                value={value.connect}
                onChange={(val) => setValue({ ...value, connect: val })}
              />
            </FloatingCard>
          </div>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="mt-5 mb-10 w-52"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Entregar' : 'Submit'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="GIRL_WINKING"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
