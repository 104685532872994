import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishOptions = [
  'Exercise',
  'Take a bath',
  'Journal',
  'Take a nap',
  'List what you’re grateful for',
  'Watch a movie',
  'Practice relaxation',
  'Talk to a friend',
];

const spanishOptions = [
  'Ejercicio',
  'Tomar un baño',
  'Escribir en un diario',
  'Tomar una siesta',
  'Lista de cosas por cual está agradecido/a',
  'Ver una película',
  'Practicar relajación',
  'Hablar con un amigo/a',
];

export const WellnessAndSelfCare4Slide: FC = () => {
  const { next, myPlanActivity, viewOnly } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const { isEnglish } = useProductLanguage();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState<Record<string, boolean>>({});

  const checkboxOptions = isEnglish ? englishOptions : spanishOptions;

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    // or activity is located on my plan page
    if (isReview || !hasInteracted || myPlanActivity) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer
      hideButton
      lightBg
      lightProgressBar
      buttonTheme={ButtonTheme.YELLOW}
    >
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isEnglish
            ? 'Your turn: Start small. Think of one fun thing you could do this week.'
            : 'Su turno: Comience poco a poco. Piense en algo divertido que podría hacer esta semana.'
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="w-full p-4 mb-10">
            {checkboxOptions.map((option, i) => (
              <SlideCheckbox
                key={i.toString()}
                label={option}
                checked={!!value[i.toString()]}
                onChange={(event) =>
                  setValue({
                    ...value,
                    [i.toString()]: event.currentTarget.checked,
                  })
                }
                className={i > 0 ? 'mt-4' : ''}
              />
            ))}
          </FloatingCard>
          {!viewOnly && (
            <PillButton
              onClick={handleSubmit}
              theme={ButtonTheme.YELLOW}
              className="w-52 self-center mt-auto"
            >
              {isEnglish ? 'Submit' : 'Entregue'}
            </PillButton>
          )}
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        icon={'thumbs-up'}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
