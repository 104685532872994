import { FC, FormEvent, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import { api } from '../../api';
import { ReactComponent as ChevronLeftIcon } from '../../images/feather/chevron-left.svg';
import { PillButton, PillLink } from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useSearchParams } from '../../hooks/useSearchParams';
import { Logo } from '../../components/Logo/Logo';

export const AcceptPasswordResetPage: FC = () => {
  const resetCode = useSearchParams().get('code') ?? '';
  const resetExpiration = useSearchParams().get('expiresAt');
  const resetEmail = useSearchParams().get('email');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const resetExpired = useMemo(
    () =>
      !!(resetExpiration && new Date(resetExpiration).getTime() < Date.now()),
    [resetExpiration],
  );
  useDocumentTitle('Update Password');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (isBlank(password)) {
        throw new Error('Password is required.');
      }
      if (password !== passwordConfirm) {
        throw new Error(`Passwords don't match.`);
      }
      await api.acceptPasswordReset({ code: resetCode, password });
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error);
    }
  }

  if (!resetCode) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-blue-700 px-4 pb-24 pt-36 text-white">
      <Logo className="flex-shrink-0 self-center" />
      {submitSuccess ? (
        <div className="container max-w-4xl w-full mx-auto flex flex-col px-4 mt-20 text-center">
          <h1 className="text-xl font-bold">Password updated successfully</h1>
          <p className="mt-8">Please login to start using Family Check-Up!</p>
          <PillLink to="/" className="mt-8 flex flex-row mx-auto">
            <ChevronLeftIcon className="mr-2 mt-0.5" /> Back to Sign In
          </PillLink>
        </div>
      ) : resetExpired ? (
        <div className="container max-w-4xl w-full mx-auto flex flex-col px-4 mt-20 text-center">
          <h1 className="text-xl font-bold">Password reset expired.</h1>
          <p className="mt-8">Please resend the password reset.</p>
          <PillLink to="/" className="mt-8 flex flex-row mx-auto">
            <ChevronLeftIcon className="mr-2 mt-0.5" /> Back to Sign In
          </PillLink>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="container max-w-4xl w-96 mx-auto flex flex-col px-4 mt-20"
        >
          <h1 className="text-xl font-bold">Update password</h1>
          <p className="mt-8">Enter your new password.</p>
          {submitError && (
            <p className="text-rose-600 py-2">{submitError.message}</p>
          )}
          <TextInput
            readOnly
            value={resetEmail ?? ''}
            className="h-12 pl-5 mt-8 bg-gray-300"
          />
          <TextInput
            required
            value={password}
            onValueChange={setPassword}
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            className="h-12 pl-5 mt-4"
          />
          <TextInput
            required
            value={passwordConfirm}
            onValueChange={setPasswordConfirm}
            type="password"
            placeholder="Password confirmed"
            autoComplete="new-password"
            className="h-12 pl-5 mt-4"
          />
          <PillButton className="mt-8 w-48 mx-auto">Submit</PillButton>
        </form>
      )}
    </div>
  );
};
