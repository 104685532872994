import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Eating_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={isSpanish ? 'Comiendo' : 'Eating'}
      className="font-light text-white"
      bearCharacter="BOY_WINKING"
      videoKey={isSpanish ? 'ani_18_spanish' : 'ani_18'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Mire a esta mamá hablar con su hija sobre la alimentación saludable en la escuela y con sus amigos.`
            : `Watch this mom talk with her child about eating healthy 
              at school and with friends.`}
        </p>
      }
    />
  );
};
