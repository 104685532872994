import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_ProblemSolving_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Resolución de Problemas Familiares'
          : 'Family Problem Solving'
      }
      className="px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `A veces, las familias afrontan los problemas que son difíciles para 
            resolver. Cuando se le invita a cada miembro de la familia a platicar 
            del problema y proponer soluciones, los problemas difíciles son más 
            fáciles de resolver. Para usar esta estrategia pueden tener una junta 
            familiar enfocándose en resolución de problemas.`
          : `Sometimes families face problems that are difficult to solve. When each
            family member is invited to talk about the problem and propose
            solutions, difficult problems are easier to resolve. To use this
            strategy you can have a family meeting focused on problem solving.`}
      </p>
    </SlideContainer>
  );
};
