import { FC, FormEventHandler, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { OrgWithoutAuth } from 'shared/lib/types/Org';
import { getErrorMessage, getErrorStatus } from 'shared/lib/utils/errorUtil';
import {
  formatPhoneNumber,
  formatPhoneNumberPretty,
} from 'shared/lib/utils/formatPhoneNumber';
import { isString } from 'shared/lib/utils/validate';
import { api } from '../../api';
import { PillButton } from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { ReactComponent as AlertOctagon } from '../../images/feather/alert-octagon.svg';
import { isBlank } from 'shared/lib/utils/isBlank';
import { isPhoneNumber } from 'shared/lib/utils/isPhoneNumber';
import { Language } from 'shared/lib/types/Language';
import { Spinner } from '../../components/Spinner/Spinner';
import { getRegistrationTranslations } from '../../constants/registration/registrationConstants';
import { Logo } from '../../components/Logo/Logo';

export const RegisterPage: FC = (props) => {
  const params = useParams<{ orgId: string; productId: string }>();
  const [org, setOrg] = useState<OrgWithoutAuth | null>(null);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const [hasUnrecoverableError, setHasUnrecoverableError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUnapprovedEmail, setIsUnapprovedEmail] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [childFirstName, setChildFirstName] = useState('');
  const [childLastName, setChildLastName] = useState('');
  const [language, setLanguage] = useState<Language | null>(null);
  const [loading, setLoading] = useState(true);

  const isEnglish = language === 'english';
  const { translatedFields, errorBundle, generalTranslations } =
    getRegistrationTranslations(language ?? 'english');

  const orgId = Number(params.orgId);
  const productId = Number(params.productId);

  // Fetch org and language
  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setOrg(null);
        setLanguage(null);
      }

      try {
        const fetchedOrg = await api.getOrgByIdNoAuth(orgId);
        let productLanguage: Language | null = null;
        if (fetchedOrg.productIds.includes(productId)) {
          productLanguage = await api
            .getProductLanguage(productId)
            .catch((e): Language => {
              // This probably means there are other more serious errors.
              console.error(e);
              return 'english';
            });
        }
        if (!isCancelled()) {
          setOrg(fetchedOrg);
          setLanguage(productLanguage);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled()) {
          if (getErrorStatus(error) === 404) {
            // Cannot translate reliably.
            setErrorMessage(
              "Sorry, we can't not find your affiliated organization. Please check the invite URL you received.",
            );
          } else {
            setErrorMessage(getErrorMessage(error));
          }
          setLoading(false);
        }
      }
    },
    [orgId, productId],
  );

  // Validate org and productId
  useEffect(() => {
    if (org) {
      setIsRegistrationComplete(false);
      setHasUnrecoverableError(false);

      if (!org.selfEnrollEnabled) {
        setErrorMessage(errorBundle.notEnabled);
        setHasUnrecoverableError(true);
      } else if (!org.productIds.includes(productId)) {
        setErrorMessage(errorBundle.productNotFound);
        setHasUnrecoverableError(true);
      }
    }
  }, [org, productId, errorBundle]);

  const orgContactMessage = useMemo(() => {
    if (org) {
      const contactPhrases = [
        org.selfEnrollContactPhone &&
          `${errorBundle.callContact} ${formatPhoneNumberPretty(
            org.selfEnrollContactPhone,
          )}`,
        org.selfEnrollContactEmail &&
          `${errorBundle.emailContact} ${org.selfEnrollContactEmail}`,
        org.selfEnrollContactUrl &&
          `${errorBundle.visitSite} ${org.selfEnrollContactUrl}`,
      ].filter(isString);

      if (contactPhrases.length === 0) {
        return errorBundle.emailOnFile;
      }

      const or = isEnglish ? 'or' : 'o';
      if (contactPhrases.length === 2) {
        contactPhrases[0] += ` ${or}`;
      } else if (contactPhrases.length === 3) {
        contactPhrases[0] += ',';
        contactPhrases[1] += `, ${or}`;
      }
      return `${errorBundle.contactAssistance} ${contactPhrases.join(' ')}.`;
    }
    return '';
  }, [org, errorBundle, isEnglish]);

  const isSubmitDisabled = org === null || hasUnrecoverableError || submitting;

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setErrorMessage('');
    setIsUnapprovedEmail(false);

    try {
      const submitValue = {
        orgId,
        productId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phone,
        childFirstName: childFirstName.trim(),
        childLastName: childLastName.trim(),
      };

      const requiredFields: [value: string, label: string][] = [
        [submitValue.firstName, translatedFields.firstName],
        [submitValue.lastName, translatedFields.lastName],
        [submitValue.email, translatedFields.email],
        [submitValue.phone, translatedFields.phoneNumber],
        [submitValue.childFirstName, translatedFields.childFirstName],
        [submitValue.childLastName, translatedFields.childLastName],
      ];
      requiredFields.forEach(([value, label]) => {
        if (isBlank(value)) {
          throw new Error(`${label} ${errorBundle.isRequired}.`);
        }
      });

      if (!isPhoneNumber(submitValue.phone)) {
        throw new Error(errorBundle.invalidPhoneNumber);
      }

      submitValue.phone = formatPhoneNumber(phone) ?? '';

      await api.createOrgParticipantSelfEnrolled(submitValue);
      setIsRegistrationComplete(true);
    } catch (error) {
      if (getErrorStatus(error) === 403) {
        // "403 Forbidden" is only returned for unapproved emails
        setIsUnapprovedEmail(true);
      } else {
        setErrorMessage(getErrorMessage(error));
      }
    }

    setSubmitting(false);
  };

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-blue-900">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      {...props}
      className="min-h-screen flex flex-col bg-blue-700 px-4 pt-12 pb-6"
    >
      <div className="max-w-lg mx-auto text-white">
        <Logo className="mx-auto" />
        <h1 className="mt-16 text-center text-3.5xl font-bold">
          {generalTranslations.mainHeading}
        </h1>
        <p className="text-center text-15px">{org?.name}</p>

        {errorMessage && (
          <p className="text-rose-500 text-center pb-4">{errorMessage}</p>
        )}

        {isRegistrationComplete ? (
          <div className="my-16">
            <h2 className="text-center text-2xl font-bold">
              {generalTranslations.successHeading}
            </h2>
            <p className="my-4">{generalTranslations.successMessage}</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            autoCorrect="off"
            spellCheck="false"
            className="flex flex-col mt-16"
          >
            <div
              className={`grid gap-4 md:grid-cols-2 max-w-lg mx-auto font-semibold ${
                // need to play with the font size to fit reasonably
                isEnglish ? 'text-base' : 'text-15px'
              }`}
            >
              <label>
                {translatedFields.firstName}
                <TextInput
                  autoCapitalize="words"
                  value={firstName}
                  onValueChange={setFirstName}
                  className="w-full mt-1"
                />
              </label>
              <label>
                {translatedFields.lastName}
                <TextInput
                  autoCapitalize="words"
                  value={lastName}
                  onValueChange={setLastName}
                  className="w-full mt-1"
                />
              </label>
              <label>
                {translatedFields.email}
                <TextInput
                  type="email"
                  value={email}
                  onValueChange={setEmail}
                  className="w-full mt-1"
                />
              </label>
              {isUnapprovedEmail && (
                <div className="flex md:col-span-2 md:row-start-3">
                  <AlertOctagon className="flex-shrink-0 text-red w-6 p-px mr-1" />
                  <p className="font-normal text-sm">
                    {errorBundle.emailNotInRecords} {orgContactMessage}
                  </p>
                </div>
              )}
              <label>
                {translatedFields.phoneNumber}
                <TextInput
                  type="tel"
                  value={phone}
                  onValueChange={setPhone}
                  className="w-full mt-1"
                />
              </label>
              <label>
                {translatedFields.childFirstName}
                <TextInput
                  autoCapitalize="words"
                  value={childFirstName}
                  onValueChange={setChildFirstName}
                  className="w-full mt-1"
                />
              </label>
              <label>
                {translatedFields.childLastName}
                <TextInput
                  autoCapitalize="words"
                  value={childLastName}
                  onValueChange={setChildLastName}
                  className="w-full mt-1"
                />
              </label>
            </div>

            <PillButton
              disabled={isSubmitDisabled}
              className="w-60 mt-10 px-0 self-center"
            >
              {generalTranslations.registerButton}
            </PillButton>
          </form>
        )}

        <div className="text-white text-center text-xs mt-12 w-72 max-w-full mx-auto">
          {generalTranslations.trademark}
        </div>
        <div className="text-center text-blue-400 text-xs mt-1">
          <Link to={{ pathname: '/', search: '?privacy-policy' }}>
            {generalTranslations.privacyPolicy}
          </Link>
        </div>
      </div>
    </div>
  );
};
