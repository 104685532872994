import { FC } from 'react';
import { HBST_Stress_a } from './HBST_Stress_a';
import { HBST_Stress_b } from './HBST_Stress_b';
import { HBST_Stress_c } from './HBST_Stress_c';
import { HBST_Stress_d } from './HBST_Stress_d';
import { HBST_Connect_a } from './HBST_Connect_a';
import { HBST_Connect_b } from './HBST_Connect_b';
import { HBST_Connect_c } from './HBST_Connect_c';
import { HBST_Connect_d } from './HBST_Connect_d';
import { HBST_Eating_a } from './HBST_Eating_a';
import { HBST_Eating_b } from './HBST_Eating_b';
import { HBST_Eating_c } from './HBST_Eating_c';
import { HBST_Eating_d } from './HBST_Eating_d';
import { HBST_Eating_e } from './HBST_Eating_e';
import { HBST_Bodyposi_a } from './HBST_Bodyposi_a';
import { HBST_Bodyposi_b } from './HBST_Bodyposi_b';
import { HBST_Bodyposi_c } from './HBST_Bodyposi_c';
import { HBST_Bodyposi_d } from './HBST_Bodyposi_d';
import { HBST_Physicalactivity_a } from './HBST_Physicalactivity_a';
import { HBST_Physicalactivity_b } from './HBST_Physicalactivity_b';
import { HBST_Physicalactivity_c } from './HBST_Physicalactivity_c';
import { HBST_Screens_a } from './HBST_Screens_a';
import { HBST_Screens_b } from './HBST_Screens_b';
import { HBST_Screens_c } from './HBST_Screens_c';
import { HBST_Screens_d } from './HBST_Screens_d';
import { HBST_Screens_e } from './HBST_Screens_e';
import { HBST_Screens_f } from './HBST_Screens_f';
import { HBST_Screens_g } from './HBST_Screens_g';
import { HBST_Screens_h } from './HBST_Screens_h';
import { HBST_Screens_i } from './HBST_Screens_i';
import { HBST_Screens_j } from './HBST_Screens_j';
import { HBST_Screens_k } from './HBST_Screens_k';
import { HBST_Screens_l } from './HBST_Screens_l';

export const healthyBehaviorSlides: Record<string, FC> = {
  'HBST Stress_a': HBST_Stress_a,
  'HBST Stress_b': HBST_Stress_b,
  'HBST Stress_c': HBST_Stress_c,
  'HBST Stress_d': HBST_Stress_d,
  'HBST Connect_a': HBST_Connect_a,
  'HBST Connect_b': HBST_Connect_b,
  'HBST Connect_c': HBST_Connect_c,
  'HBST Connect_d': HBST_Connect_d,
  'HBST Eating_a': HBST_Eating_a,
  'HBST Eating_b': HBST_Eating_b,
  'HBST Eating_c': HBST_Eating_c,
  'HBST Eating_d': HBST_Eating_d,
  'HBST Eating_e': HBST_Eating_e,
  'HBST Bodyposi_a': HBST_Bodyposi_a,
  'HBST Bodyposi_b': HBST_Bodyposi_b,
  'HBST Bodyposi_c': HBST_Bodyposi_c,
  'HBST Bodyposi_d': HBST_Bodyposi_d,
  'HBST Physicalactivity_a': HBST_Physicalactivity_a,
  'HBST Physicalactivity_b': HBST_Physicalactivity_b,
  'HBST Physicalactivity_c': HBST_Physicalactivity_c,
  'HBST Screens_a': HBST_Screens_a,
  'HBST Screens_b': HBST_Screens_b,
  'HBST Screens_c': HBST_Screens_c,
  'HBST Screens_d': HBST_Screens_d,
  'HBST Screens_e': HBST_Screens_e,
  'HBST Screens_f': HBST_Screens_f,
  'HBST Screens_g': HBST_Screens_g,
  'HBST Screens_h': HBST_Screens_h,
  'HBST Screens_i': HBST_Screens_i,
  'HBST Screens_j': HBST_Screens_j,
  'HBST Screens_k': HBST_Screens_k,
  'HBST Screens_l': HBST_Screens_l,
};
