import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting14Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      className="flex-col text-white"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_05'
          : 'early_childhood_spanish_Ani_05'
      }
      slideTitle={isEnglish ? 'Giving directions' : 'Dar instrucciones'}
      videoTitle={
        <h3 className="text-lg text-blue-800">
          {isEnglish ? 'Give directions' : 'Dar instrucciones'}
        </h3>
      }
      upperSlideContent={
        <p className="px-14 font-light">
          {isEnglish ? (
            <>
              When you give directions to your child in a simple and clear way
              you can increase cooperation, prevent arguments, and improve
              family relationships. Watch this video to see how to do it.
            </>
          ) : (
            <>
              Cuando le da instrucciones a su niño/a de una manera simple y
              clara. Ud. puede aumentar la cooperación, evitar discusiones y
              mejorar las relaciones familiares. Mire este vídeo para ver cómo
              hacerlo.
            </>
          )}
        </p>
      }
      bearCharacter="BABY_BEAR"
    />
  );
};
