import { Language } from 'shared/lib/types/Language';

const messages: Record<string, string[]> = {
  english: ['Awesome!', 'Great!', 'Sweet!', 'Fantastic!', 'Super Job!'],
  spanish: [
    '¡Impresionante!',
    '¡Excelente!',
    '¡Que bueno!',
    '¡Fantástico!',
    '¡Super trabajo!',
  ],
};
export default function getPositiveBlurbText(language: Language): string {
  const langMessages = messages[language];
  return langMessages[Math.floor(langMessages.length * Math.random())];
}
