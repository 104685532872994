import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { SuggestionTextArea } from '../../../components/SuggestionTextArea/SuggestionTextArea';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSuggestions = [
  'Increase physical activity/exercise',
  'Improve bed time routine',
  'Eat healthier meals',
  'Play with my child',
  'Find more time for relaxation',
  'Reduce screen time',
  'Improve morning routine',
  'Plan fun activities',
];

const spanishSuggestions = [
  'Aumentar la actividad física/ejercicio.',
  'Mejorar la rutina a la hora de acostarse.',
  'Comer comidas más saludables.',
  'Jugar con mi hijo/a.',
  'Encontrar más tiempo para relajarse.',
  'Reducir el tiempo de pantalla.',
  'Mejorar la rutina de la mañana.',
  'Juegue una actividad divertida.',
];

export const WellnessAndSelfCare19Slide: FC = () => {
  const { isEnglish, isSpanish } = useProductLanguage();
  const { next, myPlanActivity } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const {
    value: answers,
    setValue: setAnswers,
    isReview,
    hasInteracted,
    activity,
  } = useActivityState<string[]>([]);
  const [openPortal, setOpenPortal] = useState<null | number>(null);

  const maxSelections = 3;

  const handleAnswer =
    (key: number) => (type: 'text' | 'select') => (value: string) => {
      let newAnswers = [...answers];

      if (type === 'text') {
        newAnswers[key] = value;
      } else if (type === 'select') {
        if (newAnswers.includes(value)) {
          // if value already in array, get rid of it
          newAnswers = newAnswers.filter((item) => item !== value);
        }
        // if max selections reached, replace current field with new selection
        else if (newAnswers.filter(Boolean).length === maxSelections) {
          newAnswers[key] = value;
        }
        // if current index has no value, fill it
        else if (!newAnswers[key]) {
          newAnswers[key] = value;
        }
        // find any empty indexes and fill selection
        else if (newAnswers.indexOf('') !== -1) {
          newAnswers[newAnswers.indexOf('')] = value;
        } else {
          newAnswers = [...newAnswers, value];
        }
      }
      setAnswers(newAnswers);
    };

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    // or activity is located on my plan page
    if (isReview || !hasInteracted || myPlanActivity) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const handlePortal = (key: number) => (value: boolean) => {
    setOpenPortal(value ? key : null);
  };

  const allAnswers = answers.filter(Boolean);

  const suggestions = isEnglish ? englishSuggestions : spanishSuggestions;

  const suggestionAreas = Array.from({ length: maxSelections }).map(() => ({
    title: isEnglish
      ? 'Healthy Routines Suggestions'
      : 'Sugerencias de Rutinas Saludables',
    placeholder: isEnglish
      ? 'Type your own or choose from the list'
      : 'Escriba sus propias ideas o use el botón para elegir de una lista de opciones.',
  }));

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pencil"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isEnglish
            ? 'Your turn: Healthy routines'
            : 'Su turno: Rutinas saludables'
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="pt-4 px-5">
            {suggestionAreas.map((area, idx) => (
              <SuggestionTextArea
                key={idx}
                placeholder={area.placeholder}
                value={answers[idx]}
                suggestions={suggestions}
                suggestionsTitle={area.title}
                pickedSuggestions={allAnswers}
                onValueChange={handleAnswer(idx)}
                showSuggestions={openPortal === idx}
                openPortal={handlePortal(idx)}
                maxSelections={maxSelections}
                // Increase height in order fit Spanish placeholder text
                className={isSpanish ? 'h-32' : undefined}
              />
            ))}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className={`${isEnglish ? 'w-52' : 'w-54'} my-12 self-center`}
            onClick={handleSubmit}
          >
            {isEnglish ? 'Finish Plan' : 'Termine el Plan'}
          </PillButton>
          <QuizCompletionOverlay
            open={showOverlay}
            onNext={next}
            activityPoints={activity.pointValue}
            bearCharacter="GIRL_WINKING"
          />
        </div>
      </ActivityBox>
    </SlideContainer>
  );
};
