import { FC, ReactNode, ChangeEventHandler } from 'react';
import { FakeCheckbox } from '../FakeCheckbox/FakeCheckbox';

export const SlideCheckbox: FC<{
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({
  className = '',
  disabled,
  label,
  checked = false,
  onChange,
  ...rest
}) => (
  <label
    className={`flex flex-row items-center pl-6 min-w-6 min-h-6 relative text-base ${
      disabled ? 'cursor-default' : 'cursor-pointer'
    }
    ${className}`}
  >
    <input
      {...rest}
      onChange={onChange}
      type="checkbox"
      className="hidden"
      disabled={disabled}
      checked={checked}
    />
    <FakeCheckbox checked={checked} disabled={disabled} />
    {label && (
      <span className="ml-4 text-blue-950 font-medium leading-5">{label}</span>
    )}
  </label>
);
