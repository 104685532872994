import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { OverheadBlurb } from '../../../components/OverheadBlurb/OverheadBlurb';
import { useSlideContext } from '../../../contexts/slideContext';
import bearWoman from '../../../images/bear_woman.png';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishBlurbs = [
  'Does your child know your expectations for healthy eating when they’re not  with you?',
  'What do you think your child eats when they’re with friends?',
];

const spanishBlurbs = [
  '¿Su hijo/a conoce sus expectativas de una alimentación saludable cuando no está con usted?`',
  '¿Qué cree que come su hijo/a cuando está con amigos?',
];

export const HBST_Eating_d: FC = () => {
  const { next } = useSlideContext();
  const [currentBlurbIndex, setCurrentBlurbIndex] = useState(0);
  const { isSpanish } = useProductLanguage();

  const blurbs = isSpanish ? spanishBlurbs : englishBlurbs;

  const handleClick = () => {
    if (currentBlurbIndex + 1 < blurbs.length) {
      setCurrentBlurbIndex(currentBlurbIndex + 1);
    } else {
      next();
    }
  };

  return (
    <SlideContainer
      buttonText="Next"
      className="flex-col justify-end items-center"
    >
      <OverheadBlurb>{blurbs[currentBlurbIndex]}</OverheadBlurb>
      <img
        src={bearWoman}
        className="w-64 -mr-16 mt-5 -mb-8"
        alt={isSpanish ? 'mujer oso preocupada' : 'concerned bear woman'}
      />
      <BottomToast
        onButtonClick={handleClick}
        buttonLabel={isSpanish ? 'Siguiente' : 'Next'}
        animate={false}
      />
    </SlideContainer>
  );
};
