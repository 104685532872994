import { FC } from 'react';
import { WellnessAndSelfCare1Slide } from './wellness_and_self_care_1';
import { WellnessAndSelfCare2Slide } from './wellness_and_self_care_2';
import { WellnessAndSelfCare3Slide } from './wellness_and_self_care_3';
import { WellnessAndSelfCare4Slide } from './wellness_and_self_care_4';
import { WellnessAndSelfCare5Slide } from './wellness_and_self_care_5';
import { WellnessAndSelfCare6Slide } from './wellness_and_self_care_6';
import { WellnessAndSelfCare7Slide } from './wellness_and_self_care_7';
import { WellnessAndSelfCare8Slide } from './wellness_and_self_care_8';
import { WellnessAndSelfCare9Slide } from './wellness_and_self_care_9';
import { WellnessAndSelfCare10Slide } from './wellness_and_self_care_10';
import { WellnessAndSelfCare11Slide } from './wellness_and_self_care_11';
import { WellnessAndSelfCare12Slide } from './wellness_and_self_care_12';
import { WellnessAndSelfCare13Slide } from './wellness_and_self_care_13';
import { WellnessAndSelfCare14Slide } from './wellness_and_self_care_14';
import { WellnessAndSelfCare15Slide } from './wellness_and_self_care_15';
import { WellnessAndSelfCare16Slide } from './wellness_and_self_care_16';
import { WellnessAndSelfCare17Slide } from './wellness_and_self_care_17';
import { WellnessAndSelfCare18Slide } from './wellness_and_self_care_18';
import { WellnessAndSelfCare19Slide } from './wellness_and_self_care_19';

export const wellnessAndSelfCareSlides: Record<string, FC> = {
  // Self-Care
  wellness_and_self_care_1: WellnessAndSelfCare1Slide,
  wellness_and_self_care_2: WellnessAndSelfCare2Slide,
  wellness_and_self_care_3: WellnessAndSelfCare3Slide,
  wellness_and_self_care_4: WellnessAndSelfCare4Slide,

  // Managing Depression
  wellness_and_self_care_5: WellnessAndSelfCare5Slide,
  wellness_and_self_care_6: WellnessAndSelfCare6Slide,
  wellness_and_self_care_7: WellnessAndSelfCare7Slide,
  wellness_and_self_care_8: WellnessAndSelfCare8Slide,
  wellness_and_self_care_9: WellnessAndSelfCare9Slide,

  // Managing Stress
  wellness_and_self_care_10: WellnessAndSelfCare10Slide,
  wellness_and_self_care_11: WellnessAndSelfCare11Slide,
  wellness_and_self_care_12: WellnessAndSelfCare12Slide,
  wellness_and_self_care_13: WellnessAndSelfCare13Slide,

  // Sleep
  wellness_and_self_care_14: WellnessAndSelfCare14Slide,
  wellness_and_self_care_15: WellnessAndSelfCare15Slide,

  // Healthy Routines
  wellness_and_self_care_16: WellnessAndSelfCare16Slide,
  wellness_and_self_care_17: WellnessAndSelfCare17Slide,
  wellness_and_self_care_18: WellnessAndSelfCare18Slide,
  wellness_and_self_care_19: WellnessAndSelfCare19Slide,
};
