import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '../../images/feather/chevron-right.svg';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const ParticipantPlanLink: FC<{ className?: string }> = ({
  className = '',
}) => {
  const { isSpanish } = useProductLanguage();
  return (
    <Link
      to="/plan"
      className={`flex flex-row items-center font-bold text-blue-800 ${className}`}
    >
      {`M${isSpanish ? 'i' : 'y'} Plan`}
      <ChevronRightIcon className="text-red ml-2" strokeWidth={3} />
    </Link>
  );
};
