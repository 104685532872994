import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse12Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      className="font-light text-white"
      slideTitle={isEnglish ? 'Communication' : 'Comunicación'}
      upperSlideContent={
        <p className="px-14">
          <b className="font-bold">
            {isEnglish
              ? 'What does effective communication look like?'
              : '¿Cómo es la comunicación efectiva?'}
          </b>
          <br />
          {isEnglish ? 'Watch this video to see.' : 'Vea este vídeo para ver'}
        </p>
      }
      videoTitle={
        <h3 className="text-lg text-blue-800">
          {isEnglish ? 'Effective communication' : 'Comunicación efectiva'}
        </h3>
      }
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_06'
          : 'early_childhood_spanish_Ani_06'
      }
      bearCharacter="YOUNG_GIRL"
    />
  );
};
