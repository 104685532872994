import { FC } from 'react';

export const IdleTimeoutOverlay: FC = (props) => (
  <div
    {...props}
    className="fixed z-50 inset-0 bg-black bg-opacity-30 flex items-center justify-center"
  >
    <div className="bg-white p-16 text-center text-blue-700 max-w-lg w-11/12">
      <h1 className="font-bold text-3xl">Inactive</h1>
      <p className="text-xl mt-8">
        You are about to be logged out due to inactivity.
      </p>
    </div>
  </div>
);
