import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishReasons = [
  'Reduce anxiety',
  'Be social with friends',
  'Take a break from children',
  'Reduce physical pain',
  'Recreational purpose / for fun',
  'Help sleeping',
];

const spanishReasons = [
  'Reducir ansiedad',
  'Ser social con amigos',
  'Relajarse',
  'Tomar un descanso de los niños',
  'Reducir el dolor físico',
  'Propósito de recreación/por diversión',
  'Ayuda para dormir',
];

export const ParentingAndSubstanceUse4Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { value, setValue, isReview, activity, hasInteracted } =
    useActivityState<Record<string, boolean>>({});

  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const reasons = isEnglish ? englishReasons : spanishReasons;

  const handleSubmit = () => {
    isReview || !hasInteracted ? next() : setShowOverlay(true);
  };

  return (
    <SlideContainer lightBg lightProgressBar hideButton className="flex-col">
      <ActivityBox
        className="items-center mt-14 pt-10 pb-6 flex-1"
        icon="checkbox"
        instructions={
          isEnglish
            ? 'Your turn: Reasons I have used substances. Check all that apply.'
            : 'Su turno: Razones por las que he consumido sustancias. Marque todo lo que correspondan'
        }
      >
        <FloatingCard className="py-4 px-5 w-full mb-10">
          {reasons.map((reason, index) => {
            const indexKey = index.toString(10);
            return (
              <SlideCheckbox
                key={reason}
                label={reason}
                checked={!!value[indexKey]}
                onChange={(event) =>
                  setValue({
                    ...value,
                    [indexKey]: event.currentTarget.checked,
                  })
                }
                className={index + 1 !== reasons.length ? 'mb-4' : ''}
              />
            );
          })}
        </FloatingCard>
        <PillButton
          className="mt-auto w-52"
          theme={ButtonTheme.YELLOW}
          onClick={handleSubmit}
        >
          {isEnglish ? 'Submit' : 'Entregar'}
        </PillButton>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="YOUNG_GIRL"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
