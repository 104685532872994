import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Encouragement_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Animar & Elogiar' : 'Encouragement & praise'}
      className="flex-col px-14 text-white font-light"
    >
      {isSpanish
        ? `Cuando usa el animo y el elogio Ud. promueve un sentimiento fuerte de si 
           mismo en su hijo/a por mandarle mensajes como estos: `
        : `When you use encouragement and praise you promote a strong sense of self in
          your child by sending them these messages:`}

      <YellowCircleList className="mt-6 font-bold">
        <li>{isSpanish ? '¡Tú lo puedes hacer!' : 'You can do it!'}</li>
        <li>
          {isSpanish ? '¡Tú tienes buenas ideas!' : 'You have good ideas!'}
        </li>
        <li>{isSpanish ? '¡Tú eres importante!' : 'You are important!'}</li>
        <li>
          {isSpanish
            ? 'Me gusta lo que estás haciendo ahora.'
            : 'I like what you are doing right now.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
