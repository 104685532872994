import { useCallback } from 'react';
import { api } from '../../api';
import { Survey } from 'shared/lib/types/Survey';
import { SurveyTake } from 'shared/lib/types/SurveyTake';

interface UseSaveSurveyResponseParams {
  survey: Survey | null | undefined;
  surveyTake: SurveyTake | null | undefined;
  productId: number;
  orgId: number;
  onSurveyResponseSaved(response: { questionId: number; value: number }): void;
}

export function useSaveSurveyResponse({
  survey,
  surveyTake,
  productId,
  orgId,
  onSurveyResponseSaved,
}: UseSaveSurveyResponseParams) {
  return useCallback(
    (response: { questionId: number; value: number }) => {
      if (!survey || !surveyTake) {
        return;
      }

      api
        .saveSurveyResponse({
          orgId,
          productId,
          surveyId: survey.id,
          takeId: surveyTake.id,
          questionId: response.questionId,
          value: response.value,
        })
        .catch((error) =>
          console.error(`Failed to save survey response`, error),
        );

      onSurveyResponseSaved(response);
    },
    [orgId, productId, onSurveyResponseSaved, survey, surveyTake],
  );
}
