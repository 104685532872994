import { FC } from 'react';
import suggestionIconSrc from '../../images/list_icon.png';
import { SelectOverlay } from '../SelectOverlay/SelectOverlay';
import { useProductLanguage } from '../../hooks/useProductLanguage';

/**
 * @param {string} [suggestionTitle="Suggestions"] - Defaults to Suggestions
 * @param {string} [placeholder="Type your own or choose from the list"] - English - Defaults to "Type your own or choose from the list"/ Spanish defaults - Escriba el suyo o elija de la lista.
 */

export const SuggestionTextArea: FC<{
  className?: string;
  placeholder?: string;
  value?: string;
  onValueChange(type: 'text' | 'select'): (value: string) => void;
  suggestions?: string[];
  suggestionsTitle?: string;
  pickedSuggestions?: string[];
  showSuggestions?: boolean;
  openPortal(value: boolean): void;
  maxSelections: number;
}> = ({
  className = '',
  placeholder,
  value = '',
  onValueChange,
  suggestions = [],
  suggestionsTitle = 'Suggestions',
  pickedSuggestions = [],
  showSuggestions,
  openPortal,
  maxSelections,
  ...rest
}) => {
  const { isSpanish } = useProductLanguage();

  return (
    <div className={`flex mb-5 ${className}`} {...rest}>
      <textarea
        value={value}
        className="min-h-15 flex-1 min-w-0 border-2 border-r-0 border-gray-200
        resize-none overflow-auto rounded-l-lg bg-white m-0 py-3 px-6
        text-blue-900"
        placeholder={
          placeholder ||
          (isSpanish
            ? 'Escriba el suyo o elija de la lista'
            : 'Type your own or choose from the list')
        }
        onChange={(e) => onValueChange('text')(e.currentTarget.value)}
      ></textarea>
      <button
        className="flex justify-center items-center w-15 rounded-r-lg
      flex-shrink-0 bg-blue-700 p-0"
        onClick={() => openPortal(true)}
      >
        <img src={suggestionIconSrc} alt="Suggestion" className="w-5" />
      </button>
      {showSuggestions && (
        <SelectOverlay
          title={suggestionsTitle}
          options={suggestions.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }))}
          initValues={pickedSuggestions}
          onValueChange={onValueChange('select')}
          openPortal={openPortal}
          selectionsDisabled={pickedSuggestions.length === maxSelections}
          value={value}
        />
      )}
    </div>
  );
};
