import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting3Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'What is positive parenting?'
          : '¿Qué es la crianza positiva?'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            It’s easy to focus on negative behaviors. It is hard to notice when
            things are going well. But if you regularly respond to negative
            behaviors and ignore positive behaviors, you can end up increasing
            unwanted behaviors.
          </>
        ) : (
          <>
            Es fácil enfocarse en los comportamientos negativos. Es difícil
            notar cuando las cosas van bien. Pero si regularmente responde a los
            comportamientos negativos y ignora los comportamientos positivos,
            puede que vea un aumento en los comportamientos que no quiera.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
