import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { Blurb } from '../../../components/Blurb/Blurb';
import livingRoomGirlSrc from '../../../images/LivingRoomGirl.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer className="flex-col text-white">
      <p className="mb-5 px-10">
        {isSpanish
          ? `Recuerde, es más probable que su hijo/a sigue sus reglas si lo nota 
            cuando lo hace.`
          : `Remember, your child will be more likely to follow your rules if you
            notice it when they do.`}
      </p>
      <div className="flex relative">
        <img
          src={livingRoomGirlSrc}
          alt={
            isSpanish
              ? 'oso niña sentada en la sala de estar'
              : 'bear girl sitting in living room'
          }
          className="w-full"
        />
        <div className="absolute top-1/4 mt-5 left-1/4 -ml-20 z-10 w-1/2 transform rotate-180 sm:text-lg sm:w-5/12 sm:top-1/3 sm:mt-10">
          <Blurb className="flex justify-center p-2 sm:p-4">
            <p className="transform rotate-180">
              {isSpanish
                ? `Gracias por informarme sobre tu cambio de planes hoy.
                  Eres muy responsable.`
                : `Thanks for letting me know about your change in plans today.
                  You’re very responsible.`}
            </p>
          </Blurb>
        </div>
      </div>
    </SlideContainer>
  );
};
