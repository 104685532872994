export const englishScenarios = [
  {
    id: 1,
    question: 'You finish your vegetables',
    choices: {
      A: { text: 'You can pick out a shirt for school.', correct: false },
      B: { text: 'You can have a cookie.', correct: true },
      C: { text: 'You can pick out a book.', correct: false },
      D: { text: 'You can get out the play dough.', correct: false },
    },
  },
  {
    id: 2,
    question: 'You brush your teeth',
    choices: {
      A: { text: 'You can pick out a shirt for school.', correct: false },
      B: { text: 'You can have a cookie.', correct: false },
      C: { text: 'You can pick out a book.', correct: true },
      D: { text: 'You can get out the play dough.', correct: false },
    },
  },
  {
    id: 3,
    question: 'You put your blocks away',
    choices: {
      A: { text: 'You can pick out a shirt for school.', correct: false },
      B: { text: 'You can have a cookie.', correct: false },
      C: { text: 'You can pick out a book.', correct: false },
      D: { text: 'You can get out the play dough.', correct: true },
    },
  },
];

export const spanishScenarios = [
  {
    id: 1,
    question: 'Termines tus verduras',
    choices: {
      A: { text: 'Puedes escoger tu camisa para la escuela.', correct: false },
      B: { text: 'Puedes comerte una galleta. ', correct: true },
      C: { text: 'Puedes escoger un libro.', correct: false },
      D: { text: 'Puedes sacar la plastilina.', correct: false },
    },
  },
  {
    id: 2,
    question: 'Te cepillas los dientes.',
    choices: {
      A: { text: 'Puedes escoger tu camisa para la escuela.', correct: false },
      B: { text: 'Puedes comerte una galleta. ', correct: false },
      C: { text: 'Puedes escoger un libro.', correct: true },
      D: { text: 'Puedes sacar la plastilina.', correct: false },
    },
  },
  {
    id: 3,
    question: 'Guardes tus bloques.',
    choices: {
      A: { text: 'Puedes escoger tu camisa para la escuela.', correct: false },
      B: { text: 'Puedes comerte una galleta.', correct: false },
      C: { text: 'Puedes escoger un libro.', correct: false },
      D: { text: 'Puedes sacar la plastilina.', correct: true },
    },
  },
];
