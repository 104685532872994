import { FC, useState } from 'react';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { doOrDontQuiz } from '../../../constants/early_childhood/parenting_and_substance_use_19_quiz';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type QuizKey = keyof typeof doOrDontQuiz;

type ActivityState = Partial<Record<QuizKey, boolean>>;

export const ParentingAndSubstanceUse19Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview } =
    useActivityState<ActivityState>({});

  const moveForward = Object.keys(value).length > 2;

  return (
    <SlideContainer className={`flex-col items-center`} hideButton>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-900"
        instructions={
          isEnglish ? "Your turn: Do or Don't?" : 'Su turno: ¿Hacer o no hacer?'
        }
        icon="pointer"
      >
        <p className="px-10 mb-10">
          {isEnglish ? (
            <>
              Do you know the do's and don'ts when it comes to substance use
              when you have children in your home.
            </>
          ) : (
            <>
              ¿Sabe que hacer y no hacer cuando hay uso de substancias en su
              casa cuando hay niños?
            </>
          )}
        </p>
        <HelpfulUnhelpfulQuiz
          title={
            isEnglish
              ? 'Decide if each statement is a DO or a DON’T to keep children safe when substances are in your home.'
              : 'Decida si cada declaración es un SÍ o NO para mantener seguros a los niños cuando hay sustancias en su hogar.'
          }
          iconPair="do/dont"
          helpfulWord={isEnglish ? 'Do' : 'Hacer'}
          unhelpfulWord={isEnglish ? "Don't" : 'No Hacer'}
        >
          {Object.entries(doOrDontQuiz).map(
            ([
              key,
              { answer, question, spanishQuestion, feedback, spanishFeedback },
            ]) => (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                isHelpfulCorrect={answer}
                value={value[key as QuizKey] ?? null}
                questionText={isEnglish ? question : spanishQuestion}
                feedback={isEnglish ? feedback : spanishFeedback}
                helpfulWord={isEnglish ? 'Do' : 'Hacer'}
                unhelpfulWord={isEnglish ? "Don't" : 'No Hacer'}
                onChange={(bool) => {
                  setValue({
                    ...value,
                    [key]: bool,
                  });
                }}
              />
            ),
          )}
        </HelpfulUnhelpfulQuiz>
        {moveForward && (
          <PillButton
            className="mt-10"
            theme={ButtonTheme.YELLOW}
            onClick={() => (isReview ? next() : setShowOverlay(true))}
          >
            {isEnglish ? 'Finish Activity' : 'Termine la Actividad'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
