import { FC, ReactNode } from 'react';
import dumbbellIconSrc from '../../images/dumbbell.png';
import connectIconSrc from '../../images/connect.png';
import magnifyingGlassInfoSrc from '../../images/magnifying_glass_info.png';
import pulsingHeartSrc from '../../images/pulsing_heart.png';
import shoeSrc from '../../images/shoe_dark.png';
import { noop } from 'shared/lib/utils/noop';

const icons = {
  dumbbell: {
    img: dumbbellIconSrc,
    alt: 'dumbbell',
  },
  connect: {
    img: connectIconSrc,
    alt: 'connect',
  },
  magnifyingGlass: {
    img: magnifyingGlassInfoSrc,
    alt: 'magnifying glass',
  },
  pulsingHeart: {
    img: pulsingHeartSrc,
    alt: 'pulsing heart',
  },
  leftShoe: {
    img: shoeSrc,
    alt: 'left shoe',
  },
  rightShoe: {
    img: shoeSrc,
    alt: 'right shoe',
  },
};

/**
 * @param {ReactNode} elementOverlay - element you would want to place over icon image.
 */

export interface IconTextAreaProps {
  label: string;
  className?: string;
  disabled?: boolean;
  icon?: keyof typeof icons;
  placeholder?: string;
  value: string;
  onChange?(arg: string): void;
  elementOverlay?: ReactNode;
}

export const IconTextArea: FC<IconTextAreaProps> = ({
  className = '',
  disabled = false,
  icon,
  label,
  placeholder,
  value,
  onChange = noop,
  elementOverlay,
  ...rest
}) => {
  const image = icon && icons[icon];

  return (
    <div
      {...rest}
      className={`relative flex flex-col box-border ${
        image ? '' : 'pl-5'
      } ${className}`}
    >
      {/* Title  */}
      <h4
        className={`text-base font-bold mb-1 text-blue-800 ${
          image ? 'pl-14' : ''
        }`}
      >
        {label}
      </h4>
      {/* Main Row */}
      <div className="relative flex items-center border-box">
        {image && (
          <div className="relative">
            {elementOverlay}
            <img
              className={`mx-2 ${
                icon === 'rightShoe'
                  ? 'transform scale-x-flip h-14'
                  : icon === 'leftShoe'
                  ? 'h-14'
                  : 'w-10'
              }`}
              src={image.img}
              alt={image.alt}
            />
          </div>
        )}
        <textarea
          className="py-2 px-3 flex-1 max-w-full overflow-auto rounded-lg bg-white border-warmGray-200 border-2 min-h-24"
          disabled={disabled}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
