import { FC } from 'react';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { PillButton } from '../PillButton/PillButton';
import bearMan from '../../images/bears/BearMan@3x.png';
import bearWoman from '../../images/bear_woman.png';
import { Language } from 'shared/lib/types/Language';
import { appName } from '../../constants/appName';
import { tradeMarkText } from '../../utils/tradeMark';

const contactEmail = 'fcu@uoregon.edu';

export const PrivacyPolicyModal: FC<{
  onClose: () => void;
  language: Language;
}> = ({ onClose, language }) => {
  const isSpanish = language === 'spanish';
  useBodyOverflowHidden();

  return (
    <section className="fixed top-0 z-10 overflow-y-auto w-screen h-screen flex flex-col items-center bg-blue-700">
      <div className="mt-14 mx-auto w-full max-w-xl bg-blue-200 rounded-t-lg">
        <div className="p-10 space-y-4">
          <h1 className="mb-8 text-center text-2xl">
            {isSpanish ? 'Política de privacidad' : 'Privacy Policy'}
          </h1>
          <p>
            {isSpanish ? (
              <>
                {appName} no comparte, vende, alquila ni intercambia ninguna
                información de identificación personal con terceros por ningún
                nam a. Mantenemos nam arca de recopilación, almacenamiento y
                procesamiento de información, incluidas medidas de seguridad
                física, para evitar el acceso no autorizado a nuestros sistemas.
                Todo el personal del nam arc está especialmente capacitado en
                cómo proteger la privacidad y la confidencialidad.
              </>
            ) : (
              <>
                {appName} does not share, sell, rent, or trade any personally
                identifying information with any third parties for any reason
                whatsoever. We maintain information collection, storage, and
                processing practices, including physical security measures, to
                prevent unauthorized access to our systems. All project staff
                are specially trained in how to protect privacy and
                confidentiality.
              </>
            )}
          </p>
          <p>
            {isSpanish ? (
              <>
                Utilizamos el estándar cifrado de capa de sockets seguros (SSL)
                de la industria para proteger todos los datos recopilados
                durante el uso del programa. También capturamos y guardamos el
                tipo de información que recopilan muchos sitios web, como el
                tipo de navegador, la fecha, la hora del día y la duración de
                cada visita al programa, así como el contenido del programa que
                se ve.
              </>
            ) : (
              <>
                We use industry-standard secure sockets layer (SSL) encryption
                to protect all data collected during program use. We also
                capture and save the type of information that many websites
                collect, such as browser type, the date, time of day, and
                duration of each program visit as well as which program content
                is viewed.
              </>
            )}
          </p>
          <p>
            {isSpanish ? (
              <>
                El programa envía periódicamente correos electrónicos y mensajes
                de texto a sus usuarios para fomentar su uso.
              </>
            ) : (
              <>
                The program periodically sends e-mail and text messages to its
                users to encourage use.
              </>
            )}
          </p>
          <p>
            {isSpanish ? (
              <>
                {appName} utiliza cookies o pequeños archivos de texto
                almacenados en la computadora de cada usuario para realizar un
                seguimiento de su ubicación en el programa y sus preferencias.
                Estas cookies duran solo el período de tiempo que los usuarios
                están conectados al programa.
              </>
            ) : (
              <>
                {appName} uses cookies or small text files stored on each user’s
                computer in order to keep track of their location in the program
                and their preferences. These cookies last only for the period of
                time users are logged into the program.
              </>
            )}
          </p>
          <p>
            {isSpanish ? (
              <>
                ¿Qué pasa si esta política de privacidad cambia? No se anticipan
                cambios a esta política de privacidad.
              </>
            ) : (
              <>
                What if this privacy policy changes? No changes to this privacy
                policy are anticipated.
              </>
            )}
          </p>
          <p>
            {isSpanish ? (
              <>Si tiene preguntas, contáctanos a </>
            ) : (
              <>If you have questions, contact us at </>
            )}
            <a
              href={`mailto:${contactEmail}`}
              className="font-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactEmail}
            </a>
          </p>
        </div>
        <div className="flex items-end gap-x-1 overflow-hidden">
          <img
            src={bearMan}
            alt={isSpanish ? 'hombre oso' : 'Bear Man'}
            className="w-7/12 h-auto -ml-0.5"
          />
          <img
            src={bearWoman}
            alt={isSpanish ? 'mujer oso' : 'Bear Woman'}
            className="w-5/12 h-auto"
          />
        </div>
      </div>
      <PillButton onClick={onClose} className="mt-4 mb-8">
        {isSpanish ? 'Continuar' : 'Continue'}
      </PillButton>
      <div className="text-white text-center text-xs mt-8 mb-8 w-72 max-w-full mx-auto">
        {tradeMarkText(isSpanish)}
      </div>
    </section>
  );
};
