import { FC, useRef, useCallback, useContext } from 'react';
import { ViewVideoContext } from '../../contexts/viewVideoContext';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';

export const Video: FC<{
  videoKey?: string;
  disablePreloading?: boolean;
  onEnded?(playedToCompletion: boolean): void;
  onStarted?(): any;
}> = ({
  videoKey = 'placeholder',
  disablePreloading,
  onEnded,
  onStarted,
  ...rest
}) => {
  const { viewVideo } = useContext(ViewVideoContext);
  const { language } = useProductLanguage();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleStart = useCallback(() => {
    if (videoKey) {
      viewVideo(videoKey).catch((error) =>
        console.error('Failed to record video view', error),
      );
    }
    return onStarted?.();
  }, [onStarted, viewVideo, videoKey]);

  const handleEnd = useCallback(() => {
    const { current } = videoRef;
    const { duration, currentTime } = current || {
      duration: 0,
      currentTime: 0,
    };
    if (onEnded) {
      onEnded(currentTime >= duration);
    }
  }, [onEnded]);

  return (
    <video
      className="flex-shrink-0 rounded-lg shadow-lg overflow-hidden max-h-42"
      {...rest}
      controls
      onEnded={handleEnd}
      onPlay={handleStart}
      width="305px"
      preload={disablePreloading ? 'none' : 'auto'}
      ref={videoRef}
      poster={`/api/video/${videoKey}/thumbnail`}
      crossOrigin="anonymous"
      controlsList="nodownload"
    >
      <source src={`/api/video/${videoKey}.mp4`} type="video/mp4" />
      <source src={`/api/video/${videoKey}.ogv`} type="video/ogv" />
      <source src={`/api/video/${videoKey}.webm`} type="video/webm" />
      <track
        src={`/api/video/${videoKey}/caption`}
        kind="subtitles"
        label={capitalizeFirst(language)}
        srcLang={language === 'spanish' ? 'es' : 'en'}
      />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
};
