import { FC } from 'react';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import { getMiddleschoolSlideComponents } from './middleschool';
import { getEarlyChildhoodSlideComponents } from './early-childhood';

export function getUnitSlideComponents(
  productKey: string,
  unitName: string,
): Record<string, FC> {
  switch (productKey) {
    case ProductKey.SCHOOL_AGE:
      return getMiddleschoolSlideComponents(unitName);
    case ProductKey.EARLY_CHILDHOOD:
      return getEarlyChildhoodSlideComponents(unitName);
    default:
      console.warn(`Unknown or unimplemented product ${productKey}`);
      return {};
  }
}
