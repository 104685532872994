import React, { FC, useState, useEffect } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import childAskingForCookie from '../../../images/rules_and_consequences_ignoring_1.png';
import childAskingForCookieSpanish from '../../../images/spanish_rules_and_consequences_ignoring_1.png';
import bearMomGlancingOver from '../../../images/rules_and_consequences_ignoring_2.png';
import bearMomThankingChild from '../../../images/rules_and_consequences_ignoring_3.png';
import bearMomThankingChildSpanish from '../../../images/spanish_rules_and_consequences_ignoring_3.png';
import bearMomReading from '../../../images/rules_and_consequences_ignoring_4.png';

export const RulesAndConsequences18Slide: FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isEnglish } = useProductLanguage();
  const images = isEnglish ? englishImages : spanishImages;
  const { img, alt } = images[currentImageIndex];

  const endOfContent = currentImageIndex + 1 === images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((curIndex) => {
        if (curIndex + 1 !== images.length) {
          return curIndex + 1;
        } else {
          clearInterval(interval);
          return curIndex;
        }
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <SlideContainer
      className="text-white flex-col font-light px"
      hideButton={!endOfContent}
    >
      <div className="px-14 slide-content">
        {!endOfContent ? (
          <>
            {isEnglish ? (
              <>
                <p>Here’s what intentional ignoring looks like:</p>
                <p>
                  Jackson is whining because he didn't get a cookie. Mom ignores
                  the whining at first, but when he sits down to play quietly,
                  Mom immediately praises him.
                </p>
              </>
            ) : (
              <>
                <p>
                  Esto es un ejemplo de como ignorar intencionalmente se mira:
                </p>
                <p>
                  Jackson esta gimiendo porque no recibió una galleta. Mamá
                  ignora el gimoteo al principio, pero cuando él se sienta a
                  jugar en silencio, mamá inmediatamente le da un elogio.
                </p>
              </>
            )}
          </>
        ) : (
          <>
            {isEnglish ? (
              <p>
                Notice that though it seems like Mom is ignoring Jackson, she's
                actually aware of him because she immediately praises his
                positive behavior.
              </p>
            ) : (
              <p>
                Note que aunque parece que mamá está ignorando a Jackson, en
                realidad está consciente de él porque inmediatamente elogio su
                comportamiento positivo.
              </p>
            )}
          </>
        )}
      </div>
      <img src={img} alt={alt} className="w-full mt-16 mb-5" />
    </SlideContainer>
  );
};

const englishImages = [
  {
    img: childAskingForCookie,
    alt: 'bear mom sitting on couch while child asks for cookie',
  },
  {
    img: bearMomGlancingOver,
    alt: 'bear mom glancing over at child',
  },
  {
    img: bearMomThankingChild,
    alt: 'bear mom thanking child for playing quietly.',
  },
  {
    img: bearMomReading,
    alt: 'bear mom reading',
  },
];

const spanishImages = [
  {
    img: childAskingForCookieSpanish,
    alt: 'mama oso sentada en el sofá',
  },
  {
    img: bearMomGlancingOver,
    alt: 'mama oso mirando a su hijo',
  },
  {
    img: bearMomThankingChildSpanish,
    alt: 'mama oso agradeciendo a su hijo por jugar tranquilamente',
  },
  {
    img: bearMomReading,
    alt: 'mama oso leyendo',
  },
];
