import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import {
  formatPostTestSurveyRoute,
  POST_TEST_RESULTS,
} from '../../constants/routes/postTestRoutes';
import { usePostTestSurveys } from './usePostTestSurveys';

export function usePostTestRedirect(
  productSession: Pick<
    DraftParticipantProductSession,
    'unitSessions' | 'postTestUnlocked' | 'exitSurveyAndTake'
  > | null,
) {
  const history = useHistory();
  const match = useRouteMatch('/post-test');

  const postTestUnlocked = productSession?.postTestUnlocked ?? false;
  const { incompletePostTestSurveys } = usePostTestSurveys(productSession);

  const needsToDoPostTest = incompletePostTestSurveys.length > 0;

  const postTestLink = useMemo(() => {
    if (postTestUnlocked && !match) {
      // If there are incomplete post tests, redirect to the first incomplete
      if (incompletePostTestSurveys.length) {
        const [survey] = incompletePostTestSurveys;
        return formatPostTestSurveyRoute(survey);
      }
      // Otherwise, route to the results page.
      return POST_TEST_RESULTS;
    }
    return null;
  }, [postTestUnlocked, match, incompletePostTestSurveys]);

  const goToPostTest = useCallback(() => {
    if (postTestLink) {
      history.replace(postTestLink);
    }
  }, [postTestLink, history]);

  return {
    postTestUnlocked,
    needsToDoPostTest,
    isDoingPostTest: postTestUnlocked && !!match,
    goToPostTest,
    postTestLink,
  };
}
