import { FC } from 'react';
import { comunicationSlides } from './communication';
import { healthyBehaviorSlides } from './healthyBehavior';
import { positiveParentingSlides } from './positiveParenting';
import { rulesAndConsequencesSlides } from './rulesAndConsequences';
import { supportSchoolSuccessSlides } from './supportSchoolSuccess';

const middleschoolSlides = {
  comunicationSlides,
  healthyBehaviorSlides,
  positiveParentingSlides,
  rulesAndConsequencesSlides,
  supportSchoolSuccessSlides,
};

export function getMiddleschoolSlideComponents(
  unitName: string,
): Record<string, FC> {
  switch (unitName) {
    case 'Healthy Behaviors for Stressful Times':
    case 'Comportamientos Saludables Para Tiempos Estresantes':
      return middleschoolSlides.healthyBehaviorSlides;
    case 'Positive Parenting':
    case 'Crianza Positiva':
      return middleschoolSlides.positiveParentingSlides;
    case 'Rules & Consequences':
    case 'Reglas & Consecuencias':
      return middleschoolSlides.rulesAndConsequencesSlides;
    case 'Support School Success':
    case 'Apoye el Éxito Escolar':
      return middleschoolSlides.supportSchoolSuccessSlides;
    case 'Communication':
    case 'Comunicación':
      return middleschoolSlides.comunicationSlides;
    default:
      console.warn(`Unknown or unimplemented middleschool unit ${unitName}`);
      return {};
  }
}
