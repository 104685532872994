import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? '¿Por qué usar un premio?' : 'Why use a reward?'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `A veces los padres creen que los niños deben saber cómo se deben de portar, 
          pero algunos comportamientos son difíciles y el cambio toma tiempo. 
          Premios motivan a los niños a aprender nuevas habilidades o comportamientos.`
        : `Sometimes parents believe that children should just know how to behave, but
          some behaviors are hard and changing can take time. Rewards motivate
          children to learn new skills and behaviors.`}
      <h2 className="font-extrabold text-sm uppercase mb-3 mt-6">
        {isSpanish ? '¿Cuándo usar un premio?' : 'When to use a reward?'}
      </h2>
      {isSpanish
        ? `Premios se pueden usar cuando el elogio y la afección no son lo suficiente 
          para motivar a un niño/a para cambiar un comportamiento o aprender una nueva 
          habilidad. `
        : `Rewards can be used when praise and affection are not quite enough to
          motivate a child to change behaviors or learn a new skill.`}
      <ExampleSection
        className="mt-6"
        title={
          <h3 className="uppercase font-bold ml-2">
            {isSpanish ? 'Ejemplo:' : 'Example:'}
          </h3>
        }
      >
        <p className="pl-8 mt-2">
          {isSpanish
            ? `Si haces toda la tarea antes de la cena, te puedo dar 30 minutos extra de tiempo en la pantalla.`
            : `If you do all your homework before dinner, you can have 30 minutes
               extra screen time.`}
        </p>
      </ExampleSection>
    </SlideContainer>
  );
};
