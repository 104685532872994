import { FC } from 'react';

export const SelectUnderscoreElement: FC<{
  className?: string;
  selected?: boolean;
  element: number;
  onSelect(value: number): void;
}> = ({ className = '', selected = false, element, onSelect }) => (
  <div
    className={`flex flex-col h-12 ${className}`}
    onClick={() => onSelect(element)}
  >
    <div className="flex justify-center w-11 h-11 p-1 items-center rounded-full bg-blue-300 mb-2 text-blue-800 text-3xl font-bold cursor-pointer">
      {element}
    </div>
    {selected && <div className="border-3 border-yellow" />}
  </div>
);
