import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Pospar_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? '¿Qué es la crianza positiva?'
          : 'What is positive parenting?'
      }
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Es fácil enfocarse en los comportamientos negativos. Es difícil observar cuando las cosas van bien. Pero si Ud. regularmente responde a lo negativo mientras ignora lo positivo, puede que aumenten los comportamientos que no quiere.`
        : `It is easy to focus on negative behaviors. It is hard to notice when things
          are going well. But if you regularly respond to the negative, and ignore the
          positive, you can end up increasing unwanted behaviors.`}
    </SlideContainer>
  );
};
