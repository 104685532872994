import { OrgDocument } from 'shared/lib/types/OrgDocument';
import { createContext, useContext } from 'react';

export interface OrgDocumentContextValues {
  orgDocument: OrgDocument | null;
}

export const OrgDocumentContext = createContext<OrgDocumentContextValues>({
  orgDocument: null,
});

export function useOrgDocumentContext(): OrgDocumentContextValues {
  return useContext(OrgDocumentContext);
}
