import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting13Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      className="text-white font-light px-14"
      slideTitle={
        isEnglish ? 'Relationship building' : 'Construyendo la relación'
      }
    >
      <p>
        {isEnglish ? (
          <>
            When you are sensitive and responsive to your child time after time,
            you let your child know they can count on you. You make your child
            feel loved, safe, and confident. From your actions they will learn
            to manage their own feelings and behaviors, and be prepared to
            explore, learn, and relate to other people.
          </>
        ) : (
          <>
            Cuando usted es sensible y receptivo con su niño/a una y otra vez,
            le hace saber a su niño/a que puede contar con usted. Haga que su
            niño/a se sienta amado/a, seguro/a y confiado/a. De sus acciones su
            niño/a aprenderá a manejar sus propios sentimientos y
            comportamientos, y estará preparado/a para explorar, aprender y
            relacionarse con otras personas.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
