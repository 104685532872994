import Compress from 'compress.js';

export async function compressImage(
  file: File,
  { maxWidth, maxHeight }: { maxWidth: number; maxHeight: number },
): Promise<File> {
  const compressResult = await new Compress().compress([file], {
    maxHeight,
    maxWidth,
    resize: true,
  });

  return Compress.convertBase64ToFile(
    compressResult[0].data,
    compressResult[0].ext,
  );
}
