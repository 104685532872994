import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Assignment names.',
  'The date assignments are due.',
  'Check to make sure your child understands the assignment.',
  'Review the assignment to make sure it is complete.',
];

const spanishList = [
  'Nombre de trabajo/asignación',
  'La fecha de entrega',
  'Revise que su hijo/a entiende el trabajo',
  'Revise la tarea para estar seguro que la ha completado',
];

export const SSS_Schoolwork_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      slideTitle={
        isSpanish ? 'Organice el trabajo Escolar' : 'Organize Schoolwork'
      }
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Una idea para ayudar a su hijo/a a organizarse es hacer una hoja de 
            trabajo para realizar un seguimiento de las tareas. Considere usar 
            las siguientes categorías:`
          : `One idea to help your child get organized is to make a worksheet to keep
            track of assignments. Consider using the categories below:`}
      </p>
      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
