import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { ShoeList, ShoeListItem } from '../../../components/ShoeList/ShoeList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_b: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer className="flex-col px-14 font-light text-white">
      <p className="text-red-600 font-bold">
        {isSpanish
          ? `Hay dos pasos para mejorar la forma en que supervisa lo que hace 
            su hijo/a durante el tiempo sin supervisión.`
          : `There are two steps to improve how you monitor what your child is doing
            during unsupervised time:`}
      </p>
      <ShoeList className="mt-7 mb-10">
        <ShoeListItem className="mt-7">
          <b className="font-bold">
            {isSpanish ? 'Establezca reglas claras ' : 'SET CLEAR RULES '}
          </b>
          {isSpanish
            ? `sobre el tiempo sin supervisión para quesu hijo/a sepa 
            lo que espera.`
            : `about unsupervised time so your child knows what 
            you expect.`}
        </ShoeListItem>
        <ShoeListItem className="mt-7">
          <b className="font-bold">
            {isSpanish ? 'Asegurarse ' : 'FOLLOW UP '}
          </b>
          {isSpanish
            ? 'de que le sigan sus reglas.'
            : 'to make sure that your rules are being followed.'}
        </ShoeListItem>
      </ShoeList>
      <ExampleSection
        title={
          <h3 className="uppercase font-bold ml-2">
            {isSpanish ? 'Ejemplos' : 'Examples'}:
          </h3>
        }
      />
      <p className="text-red-600 font-bold my-4">
        {isSpanish
          ? 'Toque el signo más para aprender más.'
          : 'Tap on the plus below to learn how to follow up.'}
      </p>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? 'Por favor déjame saber cuando llegues a casa de la escuela.'
              : 'Please check in with me when you come home from school.'}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? `si su hijo/a no le llama ni le envía un mensaje de texto a la 
              hora establecida, comuníquese con él/ella.`
            : `If your child does not call or text you at the set time, reach 
              out to them.`}
        </p>
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? `Debe haber un adulto presente cuando está en la casa de un amigo/a.`
              : `There must be an adult present when you’re at a friend’s house.`}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? `Llame al adulto para confirmar.`
            : 'Call the adult to confirm.'}
        </p>
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? 'Debe haber un adulto presente cuando invitas a amigos a la casa.'
              : 'There must be an adult present when you have friends over.'}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? `Esté usted mismo en casa o confirme con su pareja que estará allí.`
            : 'Either be home yourself or confirm with your partner that they will be there.'}
        </p>
      </PlusExpandable>
      <PlusExpandable
        open={open === 3}
        onExpand={() => handleOpen(3)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? 'Debes dejarme saber dónde estás cuando no estás en casa.'
              : 'You must let me know where you are when you’re not at home.'}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? 'Si su hijo/a no le deja saber dónde está, lláme o envíele un mensaje de texto.'
            : 'If your child doesn’t let you know where they are, call or text them.'}
        </p>
      </PlusExpandable>
      <PlusExpandable
        open={open === 4}
        onExpand={() => handleOpen(4)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? 'Necesito conocer a tus amigo/as y a sus padres antes de que salgas con ellos.'
              : 'I need to meet your friends and their parents before you hang out with them.'}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? 'Haga un esfuerzo por conocer a los amigo/as de su hijo/a y a sus padres.'
            : 'Make an effort to get to know your child’s friends and their parents.'}
        </p>
      </PlusExpandable>
      <PlusExpandable
        open={open === 5}
        onExpand={() => handleOpen(5)}
        headerText={
          <p>
            <b className="font-bold">{isSpanish ? 'Regla' : 'Rule'}: </b>
            {isSpanish
              ? 'Necesito saber qué estás haciendo cuando estás en línea.'
              : 'I need to know what you’re doing when you’re online.'}
          </p>
        }
      >
        <p>
          <b className="font-bold">
            {isSpanish ? 'Seguimiento' : 'Follow Up'}:
          </b>{' '}
          {isSpanish
            ? `Supervise con quién juega su hijo/a, con quién chatea en video, 
              con quién envía mensajes. Mantenga un registro de los nombres de 
              usuario y las contraseñas de su hijo/a para sus actividades en línea.`
            : `Monitor who your child plays games with, video chats with, texts, and
              messages with. Keep a record of your child’s usernames and passwords
              for their online activities.`}
        </p>
      </PlusExpandable>
    </SlideContainer>
  );
};
