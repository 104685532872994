import { FC, ReactNode, useCallback } from 'react';
import { CorrectedRadio } from '../CorrectedRadio/CorrectedRadio';
import { ThumbsUpIcon } from '../ThumbsUpIcon/ThumbsUpIcon';
import alertCircleIconSrc from '../../images/alert_circle_icon.png';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const HelpfulUnhelpfulQuizQuestion: FC<{
  questionText: string;
  className?: string;
  onChange(value: boolean): void;
  isHelpfulCorrect: boolean;
  helpfulWord?: string | false;
  unhelpfulWord?: string | false;
  value: boolean | null;
  feedback?: ReactNode;
}> = ({
  questionText,
  className = '',
  onChange,
  isHelpfulCorrect,
  helpfulWord,
  unhelpfulWord,
  feedback,
  value,
  ...rest
}) => {
  const { isSpanish } = useProductLanguage();

  const handleHelpfulClick = useCallback(() => onChange(true), [onChange]);
  const handleUnhelpfulClick = useCallback(() => onChange(false), [onChange]);

  const valueIsHelpful = value === isHelpfulCorrect;

  return (
    <div
      className={`flex flex-col py-3 border-t border-warmGray-200 ${className}`}
      {...rest}
    >
      {/* Question Row */}
      <div className="flex flex-shrink-0">
        {/* Question Text  */}
        <div
          className="text-blue-800 text-sm font-medium 
          leading-4 pt-1 flex-1"
        >
          {questionText}
        </div>
        {/* Quiz Row */}
        <div
          className="flex flex-shrink-0 text-blue-800 text-xs 
          font-semibold text-center ml-3"
        >
          {/* Quiz Column */}
          <div
            className="flex flex-col w-15 items-center"
            onClick={handleHelpfulClick}
          >
            {/* Quiz Label */}
            <div className="flex flex-col mb-2 justify-center flex-1">
              {helpfulWord || (isSpanish ? 'Ayuda' : 'Helpful')}
            </div>
            <CorrectedRadio
              checked={value === true}
              checkedCorrect={isHelpfulCorrect}
              onRadioClick={handleHelpfulClick}
              inputType="checkbox"
            />
          </div>
        </div>
        {/* Quiz Row */}
        <div
          className="flex flex-shrink-0 text-blue-800 text-xs 
          font-semibold text-center ml-3"
        >
          {/* Quiz Column */}
          <div
            className="flex flex-col w-15 items-center"
            onClick={handleUnhelpfulClick}
          >
            {/* Quiz Label */}
            <div className="flex flex-col mb-2 justify-center flex-1">
              {unhelpfulWord || (isSpanish ? 'No Ayuda' : 'Not Helpful')}
            </div>
            <CorrectedRadio
              checked={value === false}
              checkedCorrect={!isHelpfulCorrect}
              onRadioClick={handleUnhelpfulClick}
              inputType="checkbox"
            />
          </div>
        </div>
      </div>
      {/* Feedback Box */}
      {value !== null && feedback && (
        <div
          className="flex rounded-lg bg-blue-350 p-2 text-xs text-blue-800 
          font-semibold leading-4 mt-3 flex-shrink-0 items-center"
        >
          <div
            className={`h-5 w-5 rounded-full flex-shrink-0 mr-2 overflow-hidden
            ${valueIsHelpful ? 'bg-blue-400' : 'bg-red'}`}
          >
            {valueIsHelpful ? (
              <ThumbsUpIcon
                className="w-full h-full"
                thumbsUpClassName="w-3.5 h-3.5"
              />
            ) : (
              <img
                src={alertCircleIconSrc}
                alt="alert"
                className="w-full h-full"
              />
            )}
          </div>
          {feedback}
        </div>
      )}
    </div>
  );
};
