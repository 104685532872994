import { FC } from 'react';
import ActivityBox from '../ActivityBox/ActivityBox';
import { Video } from '../Video/Video';
import bearManSrc from '../../images/bears/BearMan@3x.png';
import { Blurb } from '../Blurb/Blurb';
import { BottomToast } from '../BottomToast/BottomToast';
import bearGirlSrc from '../../images/bears/BearGirl.png';
import PointsBadge from '../PointsBadge/PointsBadge';
import { WELCOME_POINT_VALUE } from 'shared/lib/constants/points';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useProductKey } from '../../hooks/product/useProductKey';
import { Language } from 'shared/lib/types/Language';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

const SCHOOL_AGE_INTROS: Record<Language, string> = {
  english: 'flyover',
  spanish: 'flyover_spanish',
};

const EARLY_CHILDHOOD_INTROS: Record<Language, string> = {
  english: 'early_childhood_en_Ani_00',
  spanish: 'early_childhood_spanish_Ani_00',
};

function getVideosForProduct(productKey: ProductKey): Record<Language, string> {
  switch (productKey) {
    case ProductKey.SCHOOL_AGE:
      return SCHOOL_AGE_INTROS;
    case ProductKey.EARLY_CHILDHOOD:
      return EARLY_CHILDHOOD_INTROS;
    default:
      console.error(
        `No videos set up for ${productKey}. Gracefully defaulting.`,
      );
      return SCHOOL_AGE_INTROS;
  }
}

export const WelcomeOverlay: FC<{
  onVideoEnd?(): unknown;
  onClose(): unknown;
  showCloseButton?: boolean;
}> = ({ onVideoEnd, onClose, showCloseButton }) => {
  const { isSpanish } = useProductLanguage();
  const productKey = useProductKey();
  const videoSet = getVideosForProduct(productKey);

  return (
    <div className="absolute overflow-y-auto w-screen min-h-screen z-40 inset-0 pt-12 bg-blue-700 wave-bg flex flex-col justify-between">
      <h1 className="text-center text-blue-200 text-2xl">
        {isSpanish ? 'Bienvenido' : 'Welcome'}
      </h1>
      <ActivityBox
        icon="film"
        instructions={
          isSpanish ? 'Cómo usar Family Check Up' : 'How to use Family Check-Up'
        }
        className="items-center py-8"
      >
        <Video
          videoKey={isSpanish ? videoSet['spanish'] : videoSet['english']}
          onEnded={onVideoEnd}
        />
      </ActivityBox>
      <div className="flex flex-row justify-center">
        <div className="w-72 flex flex-col justify-end">
          <img src={bearManSrc} alt="Bear man" className="w-full h-auto" />
        </div>
        <Blurb className="p-4">
          {isSpanish
            ? 'Estoy tan contento de que estés aquí.'
            : "I'm so glad you're here."}
        </Blurb>
      </div>
      {showCloseButton && (
        <BottomToast onButtonClick={onClose}>
          <PointsBadge
            points={WELCOME_POINT_VALUE}
            label="PTS"
            className="self-center mt-2"
          />
          <p className="pt-8 text-3xl text-center">
            You’ve earned {WELCOME_POINT_VALUE} points for
            <br />
            completing an activity!
          </p>
          <div className="flex flex-row justify-center pt-8">
            <img src={bearGirlSrc} alt="Bear girl" className="w-48" />
            <div>
              <Blurb className="p-4">Great!</Blurb>
            </div>
          </div>
        </BottomToast>
      )}
    </div>
  );
};
