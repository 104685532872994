import { FC, ReactNode } from 'react';
import { FloatingCard } from '../FloatingCard/FloatingCard';
import { QuizIcon, QUIZ_ICONS } from '../QuizIcon/QuizIcon';

const ICON_PAIRS: Record<string, Array<keyof typeof QUIZ_ICONS>> = {
  'helpful/unhelpful': ['thumbsUp', 'thumbsDown'],
  'clear/unclear': ['sunny', 'cloudy'],
  'logical/illogical': ['logical', 'illogical'],
  'do/dont': ['do', 'dont'],
};

/**
 * @param {string} [iconPair="helpful/unhelpful"] = Defaults to helpful/unhelpful
 */

export const HelpfulUnhelpfulQuiz: FC<{
  title: ReactNode;
  helpfulWord: string;
  unhelpfulWord: string;
  iconPair?: keyof typeof ICON_PAIRS;
  className?: string;
}> = ({
  title,
  helpfulWord,
  unhelpfulWord,
  iconPair = 'helpful/unhelpful',
  className = '',
  children,
  ...rest
}) => {
  const [icon1, icon2] = ICON_PAIRS[iconPair];
  return (
    <FloatingCard className={`p-5 pt-6 ${className}`} {...rest}>
      {/* Header */}
      <div className="flex justify-between pb-3">
        {/* Title */}
        <h4 className="text-base leading-5 font-bold text-blue-400">{title}</h4>
        {/* Quiz Row */}
        <div className="flex">
          <div className="flex flex-shrink-0 text-xs font-semibold text-center ml-3 text-blue-800">
            {/* Quiz Column */}
            <div className="flex flex-col items-center w-15">
              {/* Quiz Label */}
              <div className="flex flex-col mb-2 justify-center flex-1">
                {helpfulWord}
              </div>
              {/* Icon Container */}
              <div className="flex flex-col justify-end items-center">
                <QuizIcon icon={icon1} />
              </div>
            </div>
          </div>
          {/* Quiz Row */}
          <div className="flex flex-shrink-0 text-xs font-semibold text-center ml-3 text-blue-800">
            {/* Quiz Column */}
            <div className="flex flex-col items-center w-15">
              {/* Quiz Label */}
              <div className="flex flex-col mb-2 justify-center flex-1">
                {unhelpfulWord}
              </div>
              {/* Icon Container */}
              <div className="flex flex-col justify-end items-center">
                <QuizIcon icon={icon2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </FloatingCard>
  );
};
