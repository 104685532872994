import { FC } from 'react';
import './PlusMinusIcon.css';

/**
 *
 * @param {string} [theme="light"] - Defaults to light theme
 */

export const PlusMinusIcon: FC<{
  expanded: boolean;
  className?: string;
  theme?: 'dark' | 'light';
}> = ({ className = '', expanded, theme = 'light', ...rest }) => {
  const darkTheme = theme === 'dark';

  return (
    <div
      {...rest}
      className={`PlusMinusIcon bg-blue-200 ${className} ${
        expanded ? 'expanded' : ''
      } ${darkTheme ? 'dark bg-blue-800' : ''}`}
    />
  );
};
