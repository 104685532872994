import { useMemo } from 'react';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import { notEmpty } from 'shared/lib/utils/notEmpty';
import { isSurveyTakeComplete } from 'shared/lib/utils/surveyUtils';
import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { sort, sortByIndex } from 'shared/lib/utils/sort';
import { SurveyTakeWithDraftResponses } from 'shared/lib/types/SurveyTake';

export function usePostTestSurveys(
  productSession: Pick<
    DraftParticipantProductSession,
    'unitSessions' | 'exitSurveyAndTake'
  > | null,
) {
  const { unitSessions, exitSurveyAndTake } = productSession ?? {
    exitSurveyAndTake: null,
    unitSessions: [],
  };

  const { postTestSurveys, postTestTakes } = useMemo(() => {
    const sortedUnitSessions = sort(
      unitSessions,
      ({ unit: unitA }, { unit: unitB }) => sortByIndex(unitA, unitB),
    ).filter(notEmpty);
    const surveys: SurveyWithQuestions[] = sortedUnitSessions
      .map(({ survey }) => survey)
      .filter(notEmpty);
    const surveyTakes: SurveyTakeWithDraftResponses[] = sortedUnitSessions
      .map(({ postTest }) => postTest)
      .filter(notEmpty);
    if (exitSurveyAndTake) {
      const { survey, surveyTake } = exitSurveyAndTake;
      surveys.push(survey);
      if (surveyTake) {
        surveyTakes.push(surveyTake);
      }
    }
    return {
      postTestSurveys: surveys,
      postTestTakes: surveyTakes,
    };
  }, [unitSessions, exitSurveyAndTake]);

  const incompletePostTestSurveys = useMemo(
    () =>
      postTestSurveys.filter((postTestSurvey) => {
        const postTestTake = postTestTakes.find(
          ({ surveyId }) => surveyId === postTestSurvey.id,
        );
        return (
          !postTestTake || !isSurveyTakeComplete(postTestSurvey, postTestTake)
        );
      }),
    [postTestSurveys, postTestTakes],
  );

  return {
    postTestSurveys,
    incompletePostTestSurveys,
    postTestTakes,
  };
}
