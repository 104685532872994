import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { WhenThenList } from '../../../components/WhenThenList/WhenThenList';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { QuadTuple } from 'shared/lib/types/QuadTuple';

export const HBST_Screens_h: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<
    { str: string } | undefined
  >();
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const options = isSpanish ? spanishOptions : englishOptions;

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pointer"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          <>
            <h3 className="text-xl">
              {isSpanish ? 'Su turno:' : 'Your turn:'}
            </h3>
            <p className="text-sm px-3 my-2">
              {isSpanish
                ? `Haga coincidir cada declaración "Cuando" con una declaración "Luego" que mejor se adapte a su familia.`
                : `Match the "When" statement to the "Then" statement that best fits
                  your family.`}
            </p>
          </>
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center">
          <WhenThenList
            whenStatement={
              isSpanish
                ? 'Cuando hayas sacado la basura'
                : "When you've taken out the garbage"
            }
            options={options}
            correctOptions={options as string[]}
            onChange={(str) => setValue({ str })}
            selected={value?.str}
            language={language}
          />
          {value && (
            <PillButton
              theme={ButtonTheme.YELLOW}
              className="w-52 my-12"
              onClick={() => (isReview ? next() : setShowOverlay(true))}
            >
              {isSpanish ? 'Siguiente' : 'Continue'}
            </PillButton>
          )}
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        icon="thumbs-up"
        badgeLocation="right"
        bearCharacter="GIRL_WINKING"
        activityMessage={
          isSpanish
            ? `Correcto. Todas estas son formas de utilizar el tiempo frente a la pantalla para 
              promover comportamientos positivos.`
            : 'Correct. All of these are ways to use screen time to promote positive behaviors.'
        }
      />
    </SlideContainer>
  );
};

const englishOptions: QuadTuple<string> = [
  'you can play your videogames',
  'you can watch your show',
  'you can video chat with friends',
  'you can use your computer',
];

const spanishOptions: QuadTuple<string> = [
  'puedes jugar tus videojuegos',
  'puedes ver tu programa',
  'puedes chatear en video con amigo/as',
  'puedes usar tu computadora',
];
