import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { IconTextArea } from '../../../components/IconTextArea/IconTextArea';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { ResponseCompare } from '../../../components/ResponseCompare/ResponseCompare';
import { ShoeList, ShoeListItem } from '../../../components/ShoeList/ShoeList';
import { Response } from './positive_parenting_11';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSituation = {
  text: `You’ve just finished putting your son to bed and closed the door
      behind you, but now he’s out of bed and throwing a tantrum.`,
  recShouldSay: "Go to his room to find out why he's upset.",
  recShouldDo: `You discover that he’s feeling lonely. You put him back in bed, 
      and give him a stuffed animal for company. Then, instead of closing the door, 
      you leave it cracked, and reassure him that you are nearby.`,
};

const spanishSituation = {
  text: `Acaba de terminar de poner a su hijo en la cama y cierra la puerta detrás de Ud., 
      pero ahora está fuera de la cama y haciendo un berrinche.`,
  recShouldSay: `Vaya a su cuarto a ver porque está enojado.`,
  recShouldDo: `Ud. descubre que se siente solo. Ud. lo pone de regreso en la cama y 
      le da un peluche para que le haga compañía. Y después, en vez de cerrar la puerta, 
      Ud. la deja abierta un poco, y le asegura que Ud. va a estar cerca.`,
};

export const PositiveParenting12Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [comparingResponse, setComparingResponse] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish, isSpanish } = useProductLanguage();

  const { value, setValue, isReview, activity } = useActivityState<Response>({
    userWouldSay: '',
    userWouldDo: '',
  });

  const onTextAreaChange = (key: keyof Response) => (text: string) => {
    setValue({
      ...value,
      [key]: text,
    });
  };

  const { userWouldSay, userWouldDo } = value;

  const { text, recShouldSay, recShouldDo } = isEnglish
    ? englishSituation
    : spanishSituation;

  const compareResponseText = isEnglish
    ? 'How do your responses compare?'
    : 'Cómo se comparan sus respuestas?';

  const yourTurnText = isEnglish
    ? 'Your turn: Practice Situation 2'
    : 'Su turno: Práctica 2';

  const continueText = isEnglish ? 'Continue' : 'Siga';
  const nextText = isEnglish ? 'Next' : 'Entregar';
  const beSensitiveText = isEnglish ? 'BE SENSITIVE' : 'SEA SENSIBLE';
  const beResponsiveText = isEnglish ? 'BE RESPONSIVE' : 'SEA RECEPTIVO/A';

  const handleNext = () => {
    if (!comparingResponse) {
      setComparingResponse(true);
      return;
    }
    if (isReview) {
      next();
      return;
    }
    setShowOverlay(true);
  };

  return (
    <SlideContainer
      className="flex-col text-blue-800"
      hideButton
      lightBg
      lightProgressBar
    >
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1"
        instructions={comparingResponse ? compareResponseText : yourTurnText}
        icon="chat"
      >
        {!comparingResponse ? (
          <>
            <p className="flex flex-col px-8 font-light">
              <span className="font-semibold self-start mb-1">
                {isEnglish ? 'Situation' : 'Situación'}
              </span>
              {text}
            </p>
            <FloatingCard className="pt-3.5 pb-5 px-4 mt-5 w-full">
              <IconTextArea
                label={beSensitiveText}
                icon="leftShoe"
                placeholder={
                  isEnglish
                    ? 'Enter what you would say.'
                    : 'Escriba lo que Ud. diría.'
                }
                value={value.userWouldSay}
                onChange={(value) => onTextAreaChange('userWouldSay')(value)}
                elementOverlay={
                  <span className="absolute z-10 top-1 right-3.5 font-bold text-xl">
                    1
                  </span>
                }
              />
            </FloatingCard>
            <FloatingCard className="pt-2.5 pb-5 px-4 mt-2.5 mb-10 w-full">
              <IconTextArea
                label={beResponsiveText}
                icon="rightShoe"
                placeholder={
                  isEnglish
                    ? 'Enter what you would do.'
                    : 'Escribe lo que harías.'
                }
                value={value.userWouldDo}
                onChange={(value) => onTextAreaChange('userWouldDo')(value)}
                elementOverlay={
                  <span className="absolute z-10 top-1 right-3.5 font-bold text-xl">
                    2
                  </span>
                }
              />
            </FloatingCard>
          </>
        ) : (
          <ResponseCompare
            isSpanish={isSpanish}
            className="w-full"
            usersResponse={
              <ShoeList className="my-5 ml-5 pr-12 font-light">
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold">{beSensitiveText}</h3>
                  {userWouldSay}
                </ShoeListItem>
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold mt-10">
                    {beResponsiveText}
                  </h3>
                  {userWouldDo}
                </ShoeListItem>
              </ShoeList>
            }
            recommendedResponse={
              <ShoeList className="my-5 ml-5 pr-12 font-light">
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold">{beSensitiveText}</h3>
                  {recShouldSay}
                </ShoeListItem>
                <ShoeListItem dark className="text-blue-800">
                  <h3 className="uppercase font-bold mt-10">
                    {beResponsiveText}
                  </h3>
                  {recShouldDo}
                </ShoeListItem>
              </ShoeList>
            }
          />
        )}
        {userWouldSay && userWouldDo && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={handleNext}
          >
            {comparingResponse ? continueText : nextText}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
        onNext={next}
      />
    </SlideContainer>
  );
};
