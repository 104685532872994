import { FC } from 'react';
import { SSS_Healthy_a } from './SSS_Healthy_a';
import { SSS_Healthy_b } from './SSS_Healthy_b';
import { SSS_Healthy_c } from './SSS_Healthy_c';
import { SSS_Morning_a } from './SSS_Morning_a';
import { SSS_Morning_b } from './SSS_Morning_b';
import { SSS_Morning_c } from './SSS_Morning_c';
import { SSS_Sleep_a } from './SSS_Sleep_a';
import { SSS_Sleep_b } from './SSS_Sleep_b';
import { SSS_Schoolwork_a } from './SSS_Schoolwork_a';
import { SSS_Schoolwork_b } from './SSS_Schoolwork_b';
import { SSS_Schoolwork_c } from './SSS_Schoolwork_c';
import { SSS_Schoolwork_d } from './SSS_Schoolwork_d';
import { SSS_DuringAfter_a } from './SSS_DuringAfter_a';
import { SSS_DuringAfter_b } from './SSS_DuringAfter_b';
import { SSS_DuringAfter_c } from './SSS_DuringAfter_c';
import { SSS_DuringAfter_d } from './SSS_DuringAfter_d';
import { SSS_Choose_a } from './SSS_Choose_a';
import { SSS_Choose_b } from './SSS_Choose_b';
import { SSS_Choose_c } from './SSS_Choose_c';

export const supportSchoolSuccessSlides: Record<string, FC> = {
  'SupportSchool Healthy_a': SSS_Healthy_a,
  'SupportSchool Healthy_b': SSS_Healthy_b,
  'SupportSchool Healthy_c': SSS_Healthy_c,
  'SupportSchool Morning_a': SSS_Morning_a,
  'SupportSchool Morning_b': SSS_Morning_b,
  'SupportSchool Morning_c': SSS_Morning_c,
  'SupportSchool Sleep_a': SSS_Sleep_a,
  'SupportSchool Sleep_b': SSS_Sleep_b,
  'SupportSchool Schoolwork_a': SSS_Schoolwork_a,
  'SupportSchool Schoolwork_b': SSS_Schoolwork_b,
  'SupportSchool Schoolwork_c': SSS_Schoolwork_c,
  'SupportSchool Schoolwork_d': SSS_Schoolwork_d,
  'SupportSchool Duringafter_a': SSS_DuringAfter_a,
  'SupportSchool Duringafter_b': SSS_DuringAfter_b,
  'SupportSchool Duringafter_c': SSS_DuringAfter_c,
  'SupportSchool Duringafter_d': SSS_DuringAfter_d,
  'SupportSchool Choose_a': SSS_Choose_a,
  'SupportSchool Choose_b': SSS_Choose_b,
  'SupportSchool Choose_c': SSS_Choose_c,
};
