import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse10Slide: FC = () => {
  const { isEnglish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      slideTitle={
        isEnglish
          ? 'Coping without alcohol and drugs'
          : 'Afrontar sin alcohol ni drogas'
      }
      upperSlideContent={
        <p className="px-14">
          {isEnglish ? (
            <>
              Breathing can help reduce stress and anxiety that may cause
              cravings, give it a try using this video:
            </>
          ) : (
            <>
              Respirar puede ayudar a reducir el estrés y la ansiedad que pueden
              causar antojos, pruébalo con este video.
            </>
          )}
        </p>
      }
      videoTitle={
        <h3 className="text-lg text-blue-800">
          {isEnglish ? 'Breathing Exercise' : 'Ejercicio de respiración'}
        </h3>
      }
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_02'
          : 'early_childhood_spanish_Ani_02'
      }
      bearCharacter="BABY_BEAR"
    />
  );
};
