import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare9Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Managing Stress' : 'Manejar El Estrés'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish ? (
          <>
            Let’s face it, stress is part of life. But when you get too
            stressed, it becomes hard to cope with your everyday jobs.
          </>
        ) : (
          <>
            Seamos realistas, el estrés es parte de la vida. Pero cuando se
            estresa demasiado, se vuelve difícil afrontar sus trabajos diarios.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
