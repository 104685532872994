import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Ignoring_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Ignorar' : 'Ignoring'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `Puede pensar que ignorar no es una habilidad de los padres. 
            Ignorar es una de las habilidades de crianza más efectivas que 
            puede usar porque funciona rápidamente para disminuir muchos 
            comportamientos problemáticos.`
          : `You may think ignoring is not a parenting skill. Ignoring is one of the
            most effective parenting skills that you can use because it works
            quickly to decrease many problem behaviors.`}
      </p>
      <p>
        {isSpanish
          ? `¿Qué es ignorar? Ignorar es una habilidad de crianza intencional donde 
            usted no le presta atención a los comportamientos que usted quiere que 
            su hijo/a deje de hacer. Ignorar siempre se combina con brindar atención 
            positiva por el buen comportamiento.`
          : `What is ignoring? Ignoring is an intentional parenting skill where you
            withhold attention to behaviors that you want your child to stop doing.
            Ignoring is always paired with providing positive attention for good
            behavior.`}
      </p>
    </SlideContainer>
  );
};
