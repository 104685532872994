import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import bearBedroomSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom.png';
import bearBedroomShadesSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom_shades.png';
import bearBedroomElectronicsSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom_electronics.png';
import bearBedroomMealsSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom_meals.png';
import bearBedroomDogSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom_dog.png';
import bearBedroomAsleepSrc from '../../../images/early-childhood/bear-bedroom/bear_bedroom_asleep.png';
import { InfoTooltip } from '../../../components/InfoTooltip/InfoTooltip';
import { InfoIcon } from '../../../components/InfoIcon/InfoIcon';
import { useActivityState } from '../../../hooks/useActivityState';
import { useSlideContext } from '../../../contexts/slideContext';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare14Slide: FC = () => {
  const { next } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showInfoButton, setShowInfoButton] = useState(true);
  const [sceneBackground, setSceneBackground] = useState(bearBedroomSrc);
  const step = sceneSteps[stepIndex];

  const { isEnglish } = useProductLanguage();

  const {
    setValue: setCompleted,
    isReview,
    activity,
  } = useActivityState({ bool: false });

  const handleSubmit = () => {
    setCompleted({ bool: true });
    if (isReview) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer
      hideButton={!isReview && stepIndex === 0}
      onButtonClick={handleSubmit}
      className="flex-col"
    >
      <p className="mx-14">
        {isEnglish ? (
          <>
            Tap on a <InfoIcon /> in the room to see some habits that can
            improve how you sleep:
          </>
        ) : (
          <>
            Toque un <InfoIcon /> en la habitación para ver algunos hábitos que
            pueden mejorar su forma de dormir:
          </>
        )}
      </p>
      <div className="relative flex-shrink-0 mt-8">
        <img src={sceneBackground} alt="" className="w-full flex-shrink-0" />
        <div className="absolute" style={step.scenePosition}>
          {showInfoButton && (
            <InfoIcon
              className="w-8 h-8"
              onClick={() => {
                // Reveal the tooltip and transition to the current step's background.
                setShowTooltip(true);
                setSceneBackground(step.sceneImage);
              }}
            />
          )}
          {showTooltip && (
            <div className={`absolute ${step.tooltipClassName}`}>
              <InfoTooltip
                visible
                onClose={() => {
                  if (stepIndex < sceneSteps.length - 1) {
                    // Move to the next step when the tooltip closes.
                    setStepIndex(stepIndex + 1);
                  } else {
                    // No more steps. Hide the info button and show the final scene background.
                    setShowInfoButton(false);
                    setSceneBackground(bearBedroomAsleepSrc);
                  }
                  setShowTooltip(false);
                }}
                text={isEnglish ? step.englishMessage : step.spanishMessage}
                position={step.tooltipArrowDirection}
                className="w-66"
              />
            </div>
          )}
        </div>
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR_THUMBS_UP_WINKING"
      />
    </SlideContainer>
  );
};

const sceneSteps = [
  {
    scenePosition: { left: '7%', bottom: '27%' },
    englishMessage:
      'Get some exercise. Being physically active during the day can help you fall asleep more easily at night.',
    spanishMessage:
      'Haga ejercicio. Ser físicamente activo/a durante el día puede ayudarlo/a a dormir más fácilmente por la noche.',
    sceneImage: bearBedroomSrc,
    tooltipArrowDirection: 'left',
    tooltipClassName:
      'absolute left-full top-0 transform -translate-y-1/2 ml-4',
  },
  {
    scenePosition: { left: '15%', bottom: '44%' },
    englishMessage:
      'Be consistent. Go to bed at the same time each night and get up at the same time each morning, including on the weekends.',
    spanishMessage:
      'Sea consistente. Acuéstese a la misma hora todas las noches y levántese a la misma hora todas las mañanas, incluidos los fines de semana.',
    sceneImage: bearBedroomSrc,
    tooltipArrowDirection: 'left',
    tooltipClassName:
      'absolute left-full top-0 transform -translate-y-1/2 ml-4',
  },

  {
    scenePosition: { left: '15%', bottom: '85%' },
    englishMessage:
      'Make sure your bedroom is quiet, dark, relaxing, and at a comfortable temperature.',
    spanishMessage:
      'Asegúrese de que su dormitorio sea tranquilo, oscuro, relajante y a una temperatura agradable.',
    sceneImage: bearBedroomShadesSrc,
    tooltipArrowDirection: 'left',
    tooltipClassName:
      'absolute left-full top-0 transform -translate-y-1/2 ml-4',
  },
  {
    scenePosition: { left: '28%', bottom: '40%' },
    englishMessage:
      'Remove electronic devices, such as TVs, computers, and smart phones, from the bedroom.',
    spanishMessage:
      'Retire los dispositivos electrónicos, como televisores, computadoras y teléfonos inteligentes, del dormitorio.',
    sceneImage: bearBedroomElectronicsSrc,
    tooltipArrowDirection: 'left',
    tooltipClassName:
      'absolute left-full top-0 transform -translate-y-1/2 ml-4',
  },
  {
    scenePosition: { left: '90%', bottom: '43%' },
    englishMessage: 'Avoid large meals, caffeine, and alcohol before bedtime.',
    spanishMessage:
      'Evite las comidas grandes, la cafeína, y el alcohol antes de acostarse.',
    sceneImage: bearBedroomMealsSrc,
    tooltipArrowDirection: 'right',
    tooltipClassName:
      'absolute right-full top-0 transform -translate-y-1/2 mr-4 mt-3',
  },
  {
    scenePosition: { left: '55%', bottom: '40%' },
    englishMessage:
      'Sleeping next to a partner, kids or pets can keep you from getting the sleep you need because of increased noise and movement, excessive body heat or crowding. ',
    spanishMessage:
      'Dormir junto con su pareja, niños o mascotas puede impedirle dormir lo que necesita debido al aumento del ruido y el movimiento, el calor corporal excesivo o el hacinamiento.',
    sceneImage: bearBedroomDogSrc,
    tooltipArrowDirection: 'bottom',
    tooltipClassName:
      'absolute bottom-full transform -translate-x-1/2 mb-4 ml-3',
  },
] as const;
