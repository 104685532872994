import { FC } from 'react';
import useSort from '@emberex/react-utils/lib/useSort';
import { sortByIndex } from 'shared/lib/utils/sort';
import { PostTestProgress } from 'shared/lib/types/PostTestProgress';

interface PostTestProgressBarProps {
  /**
   * The progress of the post test survey.
   * Will be sorted by index.
   * It will not hide "future" progress.
   */
  postTestProgress: PostTestProgress[];
  className?: string;
}

export const PostTestProgressBar: FC<PostTestProgressBarProps> = ({
  postTestProgress,
  className = '',
  ...rest
}) => {
  const unitSortedProgress = useSort(postTestProgress, sortByIndex);
  return (
    <div
      className={`w-full flex flex-row space-x-1 items-center flex-shrink-0 ${className}`}
      {...rest}
    >
      {unitSortedProgress.map(({ index, currentStep, totalSteps }) => {
        const percentProgress =
          totalSteps === 0 ? 0 : (currentStep / totalSteps) * 100;
        return (
          <div
            key={index}
            className="w-full self-center h-4 rounded-full overflow-hidden bg-white border-3 border-blue-450 border-opacity-20"
            title={`${percentProgress}%`}
          >
            <div
              className="h-full rounded-full blue-gradient-bg transition-all flex-shrink-0"
              style={{
                width: `${percentProgress}%`,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
