import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishPraises = [
  'Great job!',
  'Thanks for putting the legos away',
  'Thank you!',
  'I like the way you shared with your brother',
];

const spanishPraises = [
  '¡Buen trabajo!',
  'Gracias por guardar los legos/bloques.',
  '¡Gracias!',
  'Me gusta la manera en que compartiste con tu hermano. ',
];

export const PositiveParenting8Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview, hasInteracted } =
    useActivityState<Record<string, boolean>>({});

  const handleNext = () =>
    isReview || !hasInteracted ? next() : setShowOverlay(true);

  const praises = isEnglish ? englishPraises : spanishPraises;

  return (
    <SlideContainer lightBg lightProgressBar hideButton className="flex-col">
      <ActivityBox
        className="items-center mt-14 pt-10 pb-6 flex-1"
        icon="pointer"
        instructions={
          isEnglish
            ? 'Your turn: Check all the boxes that are examples of specific praise'
            : 'Su turno: Marque todas las cajas que son ejemplos del elogio específico'
        }
      >
        <FloatingCard className="py-4 px-5 w-full">
          {praises.map((praise, index) => {
            const indexKey = index.toString();
            return (
              <SlideCheckbox
                key={praise}
                label={praise}
                checked={value[indexKey]}
                className={index + 1 !== praises.length ? 'mb-4' : ''}
                onChange={(event) =>
                  setValue({
                    ...value,
                    [indexKey]: event.currentTarget.checked,
                  })
                }
              />
            );
          })}
        </FloatingCard>
        <PillButton
          className="mt-auto w-52"
          theme={ButtonTheme.YELLOW}
          onClick={handleNext}
        >
          {isEnglish ? 'Submit' : 'Entregar'}
        </PillButton>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="BABY_BEAR"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
