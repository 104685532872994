import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  'Listening to your child.',
  'Restating your child’s comments with feeling.',
  'Asking questions.',
];

const spanishList = [
  'Escuchar a su hijo/a',
  'Reafirmar los comentarios de su hijo/a con sentimiento',
  'Hacer preguntas',
];

export const Com_Com_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;
  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Comunicación Abierta' : 'Open Communication'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `La comunicación es la clave para tener una relación positiva 
            con su hijo/a.`
          : `Communication is the key to having a positive relationship 
            with your child.`}
      </p>
      <p>
        {isSpanish
          ? 'La comunicación abierta se establece y se mantiene por:'
          : 'Open communication is established and maintained by:'}
      </p>
      <YellowCircleList className="py-6 font-bold">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
