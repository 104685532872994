import { FC, useState } from 'react';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting9Slide: FC = () => {
  const [activeSectionID, setActiveSectionID] = useState<number | undefined>(1);
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Routines' : 'Rutinas'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            There are three times of day when routines can be most helpful.
            Think about your days. How could you use proactive parenting skills
            to set you and your child up for success?
          </>
        ) : (
          <>
            Hay tres momentos del día en que las rutinas pueden ser más útiles.
            Piense en sus días. ¿Cómo podría usar las habilidades de crianza
            proactiva para prepararlo a usted y a su hijo/a para el éxito?
          </>
        )}
      </p>

      <span className="font-semibold my-5 text-red-600">
        {isEnglish
          ? 'Tap on the plus below to learn more.'
          : 'Toque el signo de sumar a continuación para obtener más información.'}
      </span>
      <PlusExpandable
        headerText={
          <b>
            {isEnglish ? 'Bedtime routines' : 'Rutinas a la hora de acostarse'}
          </b>
        }
        open={activeSectionID === 1}
        onExpand={() =>
          setActiveSectionID((activeId) => (activeId !== 1 ? 1 : undefined))
        }
      >
        <p className="mt-2">
          <b className="font-bold">
            {isEnglish
              ? 'Plan to give time to transition to bedtime.'
              : 'Planee dar tiempo para la transición a la hora de acostarse.'}
          </b>{' '}
          {isEnglish ? (
            <>
              Doing small steps ahead of time, like getting pajamas on, brushing
              teeth, and shutting down the house can help prepare kids for the
              idea that bedtiime is coming.
            </>
          ) : (
            <>
              Hacer pequeños pasos con anticipación, como ponerse la pijama,
              cepillarse los dientes y apagar la casa puede ayudar a preparar a
              los niños para la idea de que se acerca la hora de acostarse.
            </>
          )}
        </p>
        <YellowCircleList className="my-5">
          <li>
            {isEnglish ? (
              <>
                Prompt your child that you are about to transition to bedtime:
                “In three minutes it will be time to put toys away for bedtime.”
              </>
            ) : (
              <>
                Dígale a su hijo/a que está a punto de pasar a la hora de
                acostarse: "En tres minutos será hora de guardar los juguetes
                para la hora de acostarse".
              </>
            )}
          </li>
        </YellowCircleList>
        <p>
          <b className="font-bold">
            {isEnglish
              ? 'Consider an appropriate bedtime for your child.'
              : 'Considere una hora adecuada de acostarse para su hijo/a.'}
          </b>{' '}
          {isEnglish ? (
            <>
              Children need a lot more sleep than adults and sometimes they are
              harder to get to bed the more tired they are.
            </>
          ) : (
            <>
              Los niños necesitan dormir mucho más que los adultos y, a veces,
              es más difícil acostarlos cuanto esten más cansados.
            </>
          )}
        </p>
        <p className="my-5">
          <b className="font-bold">
            {isEnglish ? 'Give options.' : 'De opciones'}
          </b>{' '}
          {isEnglish ? (
            <>
              Allow your child to choose their jammies, which book they read or
              song they sing. When you’re doing activities like teeth brushing,
              offer choices like, “would you like me to put the toothpaste on
              the toothbrush or do you want to do it?”
            </>
          ) : (
            <>
              Permita que su hijo/a elija sus pijamas, qué libro lee o qué
              canción cantar. Cuando este haciendo actividades como cepillarse
              los dientes, ofrezca opciones como "¿quieres que ponga la pasta de
              dientes en el cepillo de dientes o quieres hacerlo tú?"
            </>
          )}
        </p>
      </PlusExpandable>
      <PlusExpandable
        headerText={
          <b>{isEnglish ? 'Morning routines' : 'Rutinas de le mañana'}</b>
        }
        open={activeSectionID === 2}
        onExpand={() =>
          setActiveSectionID((activeId) => (activeId !== 2 ? 2 : undefined))
        }
      >
        <p className="mt-2">
          {isEnglish ? (
            <>
              <b className="font-bold">
                You might need to help your child with transitions in the
                morning.
              </b>{' '}
              It’s helpful to model the behavior you want to see in your
              children.
            </>
          ) : (
            <>
              <b className="font-bold">
                Es posible que deba ayudar a su hijo/a con las transiciones por
                la mañana.
              </b>{' '}
              Es útil modelar el comportamiento que desea ver en sus hijos.
            </>
          )}
        </p>
        <p className="mt-5">
          {isEnglish ? (
            <>
              <b className="font-bold">
                Plan to get up before your child to prepare for the day.
              </b>{' '}
              If you are up and ready to go, it will be easier to help to your
              child get ready.
            </>
          ) : (
            <>
              <b className="font-bold">
                Planee levantarse antes que su hijo/a para prepararse para el
                día.
              </b>{' '}
              Si está despierto/a y listo para ir, será más fácil ayudar a su
              hijo/a a prepararse.
            </>
          )}
        </p>
        <p className="mt-5">
          {isEnglish ? (
            <>
              <b className="font-bold">Consider tidying the house.</b> If your
              child sees that all the shoes, toys and clothes are put away then
              they are more likely to do that themselves.
            </>
          ) : (
            <>
              <b className="font-bold">Considere ordenar la casa. </b> Si su
              hijo/a ve que todos los zapatos, juguetes y ropa están guardados,
              es más probable que lo haga él/ella mismo/a.
            </>
          )}
        </p>
        <p className="mt-5">
          {isEnglish ? (
            <>
              <b className="font-bold">Give options.</b> If you let your child
              choose which shirt to wear, they may be more likely to get dressed
              for you. that themselves.
            </>
          ) : (
            <>
              <b className="font-bold">De opciones.</b> Si deja que su hijo/a
              elija qué camisa usar, es más probable que se vista para usted.
            </>
          )}
        </p>
      </PlusExpandable>
      <PlusExpandable
        headerText={
          <b>
            {isEnglish ? 'Mealtime routines' : 'Rutinas a la hora de comer'}
          </b>
        }
        open={activeSectionID === 3}
        onExpand={() =>
          setActiveSectionID((activeId) => (activeId !== 3 ? 3 : undefined))
        }
      >
        <p className="mt-2">
          {isEnglish ? (
            <>
              <b className="font-bold">
                Having a routine can help children know what to expect when it
                is time for meals or snacks.
              </b>{' '}
              Sitting down together for mealtime can teach your child how to eat
              and helps your child develop social skills.
            </>
          ) : (
            <>
              <b className="font-bold">
                Tener una rutina puede ayudar a los niños saber qué esperar
                cuando sea la hora de las comidas o los bocadillos.
              </b>{' '}
              Sentarse juntos a la hora de comer puede enseñarle a su hijo/a a
              comer y le ayuda a desarrollar habilidades sociales.
            </>
          )}
        </p>
        <p className="mt-5">
          <b className="font-bold">
            {isEnglish
              ? 'Have clear rules for mealtimes like'
              : 'Tenga reglas claras para las comidas como'}
          </b>{' '}
        </p>

        <YellowCircleList className="my-5">
          {isEnglish ? (
            <>
              <li>Wash hands before sitting down.</li>
              <li>Try at least one bite of each item on the plate.</li>
              <li>Ask to be excused before you leave the table.</li>
            </>
          ) : (
            <>
              <li>Lávarse las manos antes de sentarse.</li>
              <li>Probar al menos un bocado de cada elemento en el plato.</li>
              <li>Pedir permiso antes de abandonar la mesa.</li>
            </>
          )}
        </YellowCircleList>
        <p className="mt-5">
          <b className="font-bold">
            {isEnglish ? (
              <>
                Plan simple healthy meals that your child is willing and able to
                eat.
              </>
            ) : (
              <>
                Planear comidas sencillas y saludables que su hijo/a quiera y
                pueda comer.
              </>
            )}
          </b>
        </p>
        <YellowCircleList className="mt-5">
          {isEnglish ? (
            <>
              <li>
                Keeping meals simple will make preparation easier for you.
              </li>
              <li>
                Choosing foods your child is likely to eat can help you avoid
                power struggles.
              </li>
              <li>
                Consider slowly introducing new foods to expand your child’s
                diet. Offering new foods at the same time that you offer foods
                your child likes can ease mealtime conflicts. Remember, it’s
                common for children not to like new foods when they are younger.
              </li>
            </>
          ) : (
            <>
              <li>
                Mantener las comidas simples hará que la preparación sea más
                fácil para usted.
              </li>
              <li>
                Elegir alimentos que su hijo/a probablemente coma puede ayudarlo
                a evitar luchas de poder.
              </li>
              <li>
                Considere la posibilidad de introducir lentamente nuevos
                alimentos para ampliar la dieta de su hijo/a. Ofrecer nuevos
                alimentos al mismo tiempo que ofrece alimentos que le gustan a
                su hijo/a puede aliviar los conflictos a la hora de comer.
                Recuerde, es común que a los niños no les gusten los alimentos
                nuevos cuando son más pequeños.
              </li>
            </>
          )}
        </YellowCircleList>
      </PlusExpandable>
    </SlideContainer>
  );
};
