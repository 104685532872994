import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Clear_Rules_f: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_4_spanish' : 'ani_4'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Las reglas claras le ayudarán a usted y a su 
              hijo/a a saber qué esperar, observe.`
            : `Clear rules help both you and your child know what 
              to expect, watch.`}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
