export const englishHouseholdRules = [
  {
    id: 1,
    title: 'ARE CLEAR',
    text: 'Be specific about what you want, so your child knows exactly what you expect them to do.',
    example: '“Gentle touches with your baby sister”, or “Use walking feet."',
  },
  {
    id: 2,
    title: 'ARE REASONABLE',
    text: 'Be thoughtful about the number and size of tasks you give your child.',
    example: '"Put your toys in the box.” instead of, "Keep your room clean!"',
  },
  {
    id: 3,
    title: 'SAY WHAT YOU WANT',
    text: 'Not what you don’t want.',
    example: `"Close doors quietly, please" describes exactly what you expect instead of, "Don't slam the door!"`,
  },
  {
    id: 4,
    title: 'ARE SIMPLE',
    text: 'Giving one command at a time is more effective than giving multiple commands.',
    example:
      '“Brush your teeth please” is more effective than “brush your teeth, put on your pajamas, pick out a book, and get in bed.”',
  },
  {
    id: 5,
    title:
      'ARE REALISTIC (for your child’s age) AND EASY TO FOLLOW (and enforce)',
    example:
      '“If you eat your dinner, you can have dessert.” or “Help me brush your teeth.” vs. “Go get ready for bed.”',
  },
  {
    id: 6,
    title: 'DON’T USE QUESTIONS',
    text: 'Your child might say “no.” Be clear that your request is not optional.',
    example:
      '“Do you want to pick up the toys?” is less effective than “Pick up the toys!”',
  },
];

export const spanishHouseholdRules = [
  {
    id: 1,
    title: 'SON CLARAS',
    text: 'Sea específico sobre lo que quiere, para que su hijo/a sepa exactamente lo que espera que hagan.',
    example: '"Toques suaves con tu hermanita" o "Usa tus pies de caminar."',
  },
  {
    id: 2,
    title: 'SON RAZONABLES',
    text: 'Sea cuidadoso con la cantidad y el tamaño de las tareas que le asigna a su hijo/a.',
    example:
      '"Pon tus juguetes en la caja". En lugar de "¡Mantén tu habitación limpia!',
  },
  {
    id: 3,
    title: 'DIGA LO QUE QUIERE',
    text: 'No lo que no quiere.',
    example: `"Cierra la puerta con cuidado, por favor" describa exactamente 
    lo que espera en vez de, "¡No tires la puerta!"`,
  },
  {
    id: 4,
    title: 'SON SIMPLES',
    text: 'Dar una orden a la vez es más efectivo que dar múltiples órdenes a la vez.',
    example: `"Cepíllate los dientes, por favor" es más efectivo que 
      "Cepíllate los dientes, ponte la pijama, escoge un 
      libro y vete a la cama".`,
  },
  {
    id: 5,
    title:
      'SON REALISTAS (para la edad de su hijo/a) Y FÁCILES DE SEGUIR (y cumplir)',
    example: `"Si comes tu cena, puedes comer postre". o "Ayúdame a cepillarte los dientes". 
      en vez de "Ve a prepararte para ir a la cama".`,
  },
  {
    id: 6,
    title: 'NO USE PREGUNTAS',
    text: 'Su hijo/a puede decir "no". Sea claro/a que su pedido no es opcional.',
    example: `"¿Quieres recoger los juguetes?" es menos efectivo que "¡Recoge los juguetes!"`,
  },
];
