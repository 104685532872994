import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_DuringAfter_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Apoyo y Monitoreo del Trabajo Escolar'
          : 'Support and Monitor Schoolwork'
      }
      className="px-14 text-white font-light"
    >
      <p>
        {isSpanish
          ? `Ahora que ha ayudado a su hijo/a a hacer un plan para organizar 
            su trabajo escolar, aprenda a crear un ambiente de apoyo que le 
            facilite hacer su tarea en casa.`
          : `Now that you’ve helped your child make a plan for organizing their
            schoolwork, learn how to set up a supportive environment that makes it
            easier for them to get their work done at home.`}
      </p>
    </SlideContainer>
  );
};
