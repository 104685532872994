import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { IconTextArea } from '../../../components/IconTextArea/IconTextArea';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Monitoring_e: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      ruleText: '',
      monitorText: '',
    });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  return (
    <SlideContainer className="text-white" hideButton>
      <ActivityBox
        icon="pencil"
        className="items-center pt-10 pb-6 px-4 text-blue-600 flex-1"
        instructions={
          isSpanish
            ? `Su turno: Escriba una regla que le gustaría que su hijo/a siguiera cuando no pueda 
               estar allí. Luego, describa cómo supervisará su regla. `
            : `Your turn: Write in a rule you would like your child to follow when you can’t be 
              there. Then describe how you will monitor your rule.`
        }
      >
        <div className="flex flex-col justify-between items-center w-full m-0 p-0 flex-1">
          <div className="w-full">
            <FloatingCard className="w-full py-4 pr-5 mb-3">
              <IconTextArea
                label={
                  isSpanish
                    ? 'Regla para cuando no esta ahí:'
                    : "Rule for when you're not there:"
                }
                placeholder={isSpanish ? 'Escriba su idea' : 'Type your idea.'}
                value={value.ruleText}
                onChange={(val) => setValue({ ...value, ruleText: val })}
              />
              <IconTextArea
                className="mt-5"
                label={
                  isSpanish
                    ? '¿Cómo va a supervisar su regla?'
                    : 'How will you monitor that rule:'
                }
                placeholder={isSpanish ? 'Escriba su idea' : 'Type your idea.'}
                value={value.monitorText}
                onChange={(val) => setValue({ ...value, monitorText: val })}
              />
            </FloatingCard>
          </div>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="mt-5 mb-10 w-52"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Siguente' : 'Next'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        bearCharacter="GIRL_WINKING"
        activityPoints={activity.pointValue}
      />
    </SlideContainer>
  );
};
