import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = {
  ignore: ['Whining', 'Emotional outbursts', 'Complaining', 'Pouting'],
  dontIgnore: ['Aggresive behaviors', 'Hitting', 'Being unsafe'],
};

const spanishList = {
  ignore: ['Quejidos', 'Berinches', 'Quejas', 'Pucheros'],
  dontIgnore: ['Comportamiento agresivo', 'Golpeando', 'Siendo inseguros'],
};

export const RC_Ignoring_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      className="flex-col px-14 font-light"
    >
      <h3 className="text-lg text-red-600 font-bold uppercase mb-3">
        {isSpanish ? 'Ignorar' : 'Do Ignore'}
      </h3>
      <YellowCircleList>
        {list.ignore.map((item) => (
          <li>{item}</li>
        ))}
      </YellowCircleList>
      <h3 className="text-lg text-red-600 font-bold uppercase mb-3 mt-9">
        {isSpanish ? 'No Ignorar' : "Don't Ignore"}
      </h3>
      <YellowCircleList>
        {list.dontIgnore.map((item) => (
          <li>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
