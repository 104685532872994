import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Schoolwork_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish ? 'Organice el trabajo Escolar' : 'Organize Schoolwork'
      }
      className="px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `El trabajo escolar es la responsabilidad de su hijo/a, pero Ud. 
            le puede apoyar. Los padres que ayudan organizar el trabajo escolar 
            de su hijos tienen hijos que sobresalen en la escuela.`
          : `Schoolwork is your child’s responsibility, but you can support them.
            Parents who help to organize their children’s schoolwork have children
            who are successful at school.`}
      </p>
    </SlideContainer>
  );
};
