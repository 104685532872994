import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Physicalactivity_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={isSpanish ? 'Actividad Física' : 'Physical Activity'}
      className="font-light text-white"
      bearCharacter="BOY_WINKING"
      videoKey={isSpanish ? 'ani_19_spanish' : 'ani_19'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Observe cómo esta familia descubre cómo agregar 
              más ejercicio a su vida diaria.`
            : `Watch this family figure out how to add more 
              exercise into their daily lives.`}
        </p>
      }
    />
  );
};
