import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Logical_b: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer className="flex-col px-14 font-light text-white">
      <p className="mb-5">
        {isSpanish ? (
          `Hay cuatro puntos claves para considerar y pensar 
          en hacer consecuencias lógicas, y son:`
        ) : (
          <>
            A good guideline for coming up with logical consequences is
            <b className="font-bold"> SANE:</b>
          </>
        )}
      </p>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Pequeño es mejor' : 'Small is better'}
          </b>
        }
      >
        {isSpanish
          ? `Es mejor dar una consecuencia pequeña y sencilla consistentemente 
            en vez de una consecuencia grande de manera inconsistente. Si son 
            demasiado graves o duran demasiado, no son útiles.`
          : `It’s better to give a small consequence consistently than a large
            consequence inconsistently. If they are too severe or last too long,
            then they are not helpful.`}

        <ExampleSection
          className="mt-4"
          title={
            <h3 className="uppercase font-bold ml-2">
              {isSpanish ? 'Ejemplo' : 'Example'}:
            </h3>
          }
        >
          <p className="pl-8 mt-2">
            {isSpanish
              ? `Castigar a su hijo/a durante un mes puede ser demasiado grave. 
                En cambio, perder el acceso a un privilegio por un día suele 
                ser suficiente.`
              : `Grounding your child for one month may be too severe. Instead,
                losing access to a privilege for a day is often enough.`}
          </p>
        </ExampleSection>
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? 'Evite castigarse a sí mismo'
              : 'Avoid punishing yourself'}
          </b>
        }
      >
        {isSpanish
          ? 'Si una consequencia lo castiga a si mismo, probablemente no la lleve a cabo.'
          : 'If a consequence punishes you then you probably won’t carry it out.'}

        <ExampleSection
          className="mt-4"
          title={
            <h3 className="uppercase font-bold ml-2">
              {isSpanish ? 'Ejemplo' : 'Example'}:
            </h3>
          }
        >
          <p className="pl-8 mt-2">
            {isSpanish
              ? `No querrá cancelar planes para una actividad familiar divertida ni querrá asignar una consecuencia que requiera su supervisión constante.`
              : `You wouldn’t want to cancel plans for a fun family activity nor
                would you want to assign a consequence that requires your constant
                supervision.`}
          </p>
        </ExampleSection>
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <b className="font-bold">
            {isSpanish ? 'Nunca abuse' : 'Never abuse'}
          </b>
        }
      >
        {isSpanish
          ? `Siempre deben evitarse las acciones duras o físicamente abusivas. 
            Pueden causar un daño duradero a su relación, lastimar a su hijo/a 
            y dar un mensaje equivocado sobre cómo tratar a los demás.`
          : `Harsh or physically abusive actions should always be avoided. They can
            cause long lasting damage to your relationship, hurt your child, and
            give the wrong message about how to treat others.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 3}
        onExpand={() => handleOpen(3)}
        headerText={
          <b className="font-bold">{isSpanish ? 'Cada vez' : 'Every time'}</b>
        }
      >
        {isSpanish
          ? `Asegúrese de cumplir con las consecuencias cada vez para 
             que su hijo/a se tome las reglas en serio.`
          : `Be sure to follow-through with consequences every time so 
            your child takes your rules seriously.`}

        <ExampleSection
          className="mt-4"
          title={
            <h3 className="uppercase font-bold ml-2">
              {isSpanish ? 'Ejemplo' : 'Example'}:
            </h3>
          }
        >
          <p className="pl-8 mt-2">
            {isSpanish
              ? `Si está trabajando para que su hijo/a limpie después de sí 
                mismo, cuando haga un desastre, pídale que lo limpie cada vez.`
              : `If you're working on having your child clean up after themselves,
                when they make a mess, have them clean it up every time.`}
          </p>
        </ExampleSection>
      </PlusExpandable>
    </SlideContainer>
  );
};
