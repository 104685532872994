import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import shuffle from 'lodash.shuffle';
import random from 'lodash.random';
import bearBoyLoookingUp from '../../images/bear_boy_looking_up.png';
import babyBearLookinUp from '../../images/baby_bear_looking_up.png';
import './ruleCloud.css';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

const englishWordDropsSA = [
  'STOP',
  'DO YOUR HOMEWORK',
  'NO ELECTRONICS',
  'GO TO BED',
  'NO',
  'CLEAN YOUR ROOM',
  'DO YOUR CHORES',
  'TURN THAT DOWN',
  'GET DRESSED',
  'BRUSH YOUR TEETH',
  'BE HOME ON TIME',
  'STOP FIGHTING WITH YOUR SIBLINGS',
];

const englishWordDropsYC = [
  'GO TO BED',
  'NOT YET',
  'STOP',
  'INSIDE VOICE',
  'NO',
  'WAIT',
  'BE QUIET',
];

const spanishWordDrops = [
  'VETE A LA CAMA',
  'TODAVÍA NO',
  'PARA',
  'VOZ INTERIOR',
  'NO',
  'ESPERA',
  'CÁLLATE',
];

interface RainDropData {
  id: number;
  text: string;
  rotationAngle: number; // degrees
  top: number; // percent
  left: number; // percent
  delay: number; // second
  duration: number; // second
}

const bearImageOptions = {
  BABY_BOY: { img: babyBearLookinUp, alt: 'baby bear being rained on' },
  BOY: { img: bearBoyLoookingUp, alt: 'boy bear being rained on' },
};

export interface RuleCloudProps extends ComponentPropsWithoutRef<'div'> {
  bearImageOption?: keyof typeof bearImageOptions;
  className?: string;
  cloudStatement?: string;
  productKey: ProductKey;
}

export const RuleCloud: FC<RuleCloudProps> = ({
  bearImageOption = 'BOY',
  className = '',
  cloudStatement,
  productKey,
  ...rest
}) => {
  const { isSpanish } = useProductLanguage();

  const { img, alt } = bearImageOptions[bearImageOption];

  const englishWordDrops =
    productKey === ProductKey.SCHOOL_AGE
      ? englishWordDropsSA
      : englishWordDropsYC;

  // Create 3 copies of this list of words
  const rainDrops = new Array(3)
    .fill(isSpanish ? spanishWordDrops : englishWordDrops)
    .flat();

  const shuffledRainDrops = useMemo<RainDropData[]>(() => {
    const shuffledRaindrops = shuffle(rainDrops);
    return shuffledRaindrops.map((text, idx) => ({
      id: idx,
      text,
      // Randomly rotate the text
      rotationAngle: random(-320, 345),
      // Randomly adjust where the "rain" starts so that some end sooner than others
      top: random(-5, 0),
      // Randomly distribute among the x-axis
      left: 10 + random(80),
      // Start the animations at different times and make them last differing amounts of time
      delay: random(0, 10),
      duration: random(10, 20),
    }));
  }, [rainDrops]);

  return (
    <div
      {...rest}
      className={`relative flex flex-col bg-blue-200 w-full overflow-hidden items-center ${className}`}
    >
      <div className="flex w-full pt-1 px-12 h-1/4 bg-cloud bg-bottom bg-no-repeat bg-cover z-20 font-light text-blue-800 sm:justify-center">
        {cloudStatement}
      </div>
      <div className="flex-1 w-full">
        {shuffledRainDrops.map((raindrop) => (
          <span
            key={raindrop.id}
            className="text-blue-800 text-lg leading-6 text-center font-black absolute"
            style={{
              left: `${raindrop.left}%`,
              top: `${raindrop.top}%`,
              transform: `rotate(${raindrop.rotationAngle}deg)`,
              animationName: 'raindrop_fall_animation',
              animationDuration: `${raindrop.duration}s`,
              animationDelay: `${raindrop.delay}s`,
              animationIterationCount: 'infinite',
            }}
          >
            {raindrop.text}
          </span>
        ))}
      </div>
      <img src={img} alt={alt} className="z-10 h-60" />
    </div>
  );
};
