import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse20Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Home Safety' : 'Seguridad del hogar'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Many parents worry that their children might be impacted by their
            substance use. There are many resources available to support
            parents. Find out what is available in your community or consider
            reaching out to your child’s primary care provider for further
            information.
          </>
        ) : (
          <>
            Muchos padres se preocupan de que sus hijos puedan verse afectados
            por el consumo de sustancias. Hay muchos recursos disponibles para
            apoyar a los padres. Averigüe lo que está disponible en su comunidad
            o considere comunicarse con el doctor primario de su hijo/a para
            obtener más información.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
