export const spanishSituations = [
  {
    id: 1,
    situation: `Va a preparar la cena tarde y su niño/a tiene hambre. Ud. 
      quiere que el/ella coma cena con Ud. ¿Cómo puede manejar esta 
      situación de una manera proactiva? `,
    questions: {
      P: {
        feedback: `Se está haciendo tarde y su hijo/a ya tiene hambre. 
          Ofrézcale un pequeño bocadillo para que pueda completar 
          su rutina de cocinar a la hora de comer.`,
        choices: [
          {
            id: 'P-1',
            label: 'El problema es que su hijo/a tiene hambre.',
            correct: true,
          },
          {
            id: 'P-2',
            label: 'El problema es que está preparando la cena tarde.',
            correct: true,
          },
          {
            id: 'P-3',
            label: 'El problema es que su hijo/a es demasiado impaciente.',
            correct: false,
          },
        ],
      },
      L: {
        feedback:
          'Los niños pequeños no pueden esperar mucho por las cosas, especialmente cuando tienen hambre.',
        choices: [
          {
            id: 'L-1',
            label:
              'Mire de manera realista cuánto tiempo puede esperar su hijo/a y prepare una comida más rápida.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'No le hará daño a su hijo/a pasar hambre, y ¿qué importa si le hace un berrinche?',
            correct: false,
          },
          {
            id: 'L-3',
            label:
              'Siga con su plan original, los niños necesitan aprender a esperar.',
            correct: false,
          },
        ],
      },
      A: {
        feedback:
          'Es probable que sea mejor preparar una comida más sencilla para que puedan comer juntos',
        choices: [
          {
            id: 'A-1',
            label:
              'Ajuste, dando un pequeño bocadillo mientras prepara una cena más rápida. ',
            correct: true,
          },
          {
            id: 'A-2',
            label:
              'Ajuste actividades. Este es un buen momento para permitir que su hijo/a vea su programa favorito antes de la cena, les ayuda a esperar más tiempo.',
            correct: true,
          },
          {
            id: 'A-3',
            label:
              'Dígale a su hijo/a que espere la cena o habrá consecuencias.',
            correct: false,
          },
        ],
      },
      N: {
        feedback:
          'La habilidad que quiere enseñar es la paciencia; observe, elogie y, a veces, motívelos cuando pueda.',
        choices: [
          {
            id: 'N-1',
            label: `Observe la paciencia de su hijo/a y elógielo/a.`,
            correct: true,
          },
          {
            id: 'N-2',
            label:
              'Observe  cuando su hijo/a se pone inquieto y dígale que deje de gimoteos.',
            correct: false,
          },
          {
            id: 'N-3',
            label:
              'Observe  y refuerce la espera  de su hijo/a diciéndole que se ganó un pequeño postre después de la cena.',
            correct: true,
          },
        ],
      },
    },
  },
  {
    id: 2,
    situation: `Acaba de salir del trabajo, recogio a su hijo/a de la 
    escuela y tiene que ir al supermercado. Su hijo/a está cansado y 
    le preocupa que pueda hacer un berrinche. 
    ¿Cómo podría manejar proactivamente esta situación?`,
    questions: {
      P: {
        feedback: `A veces tiene que hacer mandados cuando no es un 
        buen momento para sus hijos/as. Decirle a los niños/as 
        lo que espera justo antes de ir a la tienda aumenta las 
        posibilidades de un buen viaje de compras.`,
        choices: [
          {
            id: 'P-1',
            label:
              'El problema es que su hijo/a va a tener dificultades durante este viaje de compras.',
            correct: true,
          },
          {
            id: 'P-2',
            label:
              'El problema es que necesita conseguir comida para la cena y no tiene tiempo para lidiar proactivamente con el comportamiento potencial de su hijo/a.',
            correct: false,
          },
          {
            id: 'P-3',
            label:
              'El problema es que debe detenerse en la tienda y su hijo/a está con usted.',
            correct: true,
          },
        ],
      },
      L: {
        feedback: `A veces es difícil para los niños ir con sus padres a hacer mandados de adultos. 
          Cuando los niños están con usted, hacer que estos viajes sean cortos y 
          agradables puede disminuir el comportamiento problemático.`,
        choices: [
          {
            id: 'L-1',
            label:
              'Mire de manera realista cuánto tiempo su hijo/a puede permanecer con buen comportamiento en la tienda.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'Mire de manera realista el hecho de que su hijo/a siempre es difícil para usted y que tiene que conseguir comida de todos modos.',
            correct: false,
          },
          {
            id: 'L-3',
            label:
              'Mire de manera realista lo que realmente necesita para que el viaje de compras sea rápido.',
            correct: true,
          },
        ],
      },
      A: {
        feedback:
          'Decirles a los niños lo que espera y ofrecerles pequeños incentivos es un buen ajuste en estas situaciones.',
        choices: [
          {
            id: 'A-1',
            label: `Ajusta su tiempo en la tienda, haga que el viaje de compras sea breve. 
              Dígale a su hijo/a que este es un "viaje de ida y vuelta, solo 
              compraremos cosas para la cena" y puede ganar un incentivo 
              por ayudarle a hacerlo rápidamente.`,
            correct: true,
          },
          {
            id: 'A-2',
            label: `Ajuste preparando a su hijo/a antes de ir a la tienda. 
              Dígales cómo espera que se comporten: “Manos y pies bajo control” 
              “Caminen juntos o permanezcan en el carro” “Voces internas” `,
            correct: true,
          },
          {
            id: 'A-3',
            label: `Si no aprenden a manejar un largo viaje de compras ahora, 
              ¿cuándo lo harán? Amenaze con un castigo si no se comportan.`,
            correct: false,
          },
        ],
      },
      N: {
        feedback: `Es importante prestar atención a los comportamientos que queremos ver más. 
          Felicitar a su hijo/a cuando está cooperando en la tienda significa que 
          es más probable que continúe cooperando.`,
        choices: [
          {
            id: 'N-1',
            label:
              'Note y elogie el buen comportamiento de su hijo/a, y déle un incentivo cuando lo gane.',
            correct: true,
          },
          {
            id: 'N-2',
            label:
              'Observe  al principio del viaje cómo están cooperando y elógielos a medida que avanza.',
            correct: true,
          },
          {
            id: 'N-3',
            label:
              'Esté atento y observe  su comportamientos problemáticos, y señálelos.',
            correct: false,
          },
        ],
      },
    },
  },
  {
    id: 3,
    situation:
      'Su hijo/a está jugando con juguetes y sabe que no se detendrá cuando le diga que es hora de acostarse.',
    questions: {
      P: {
        feedback:
          'Es normal que los niños no quieran terminar de jugar e irse a la cama.',
        choices: [
          {
            id: 'P-1',
            label:
              'El problema es que su hijo/a disfruta jugando con sus juguetes y no querrá parar.',
            correct: true,
          },
          {
            id: 'P-2',
            label:
              'El problema es que los niños no suelen disfrutar de la hora de acostarse, incluso cuando están cansados.',
            correct: true,
          },
          {
            id: 'P-3',
            label:
              'El problema es que su hijo/a siempre está tratando de manipularlo/a para que se quede despierto/a hasta tarde.',
            correct: false,
          },
        ],
      },
      L: {
        feedback:
          'Es importante ayudar a los niños durante estas transiciones y pensar en su capacidad de desarrollo.',
        choices: [
          {
            id: 'L-1',
            label:
              'Mire de manera realista cuánta ayuda necesitará su hijo/a en su transición a la hora de acostarse.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'Mire de manera realista cuánto tiene que hacer esta noche y siéntase frustrado con su hijo/a por hacer que esto sea difícil.',
            correct: false,
          },
          {
            id: 'L-3',
            label:
              'Mire de manera realista cuánto esfuerzo va a tomar esto y simplemente deje que su hijo/a se quede despierto/a.',
            correct: false,
          },
        ],
      },
      A: {
        feedback: `Indicar antes de las transiciones les permite a los niños saber qué esperar. 
          El uso de declaraciones de "cuándo/entonces" puede ayudar a los niños con las transiciones. 
          Ofrecer opciones puede ayudar a los padres a mantenerse al margen de las luchas de poder.`,
        choices: [
          {
            id: 'A-1',
            label: `Ajúste para ayudar a su hijo con la transición: 
            "Cuando recojamos tus juguetes, podrás elegir qué cuento leer".`,
            correct: true,
          },
          {
            id: 'A-2',
            label: `Ajúste para preparar a su hijo para las transiciones porque son 
            difíciles para los niños pequeños. Dar una advertencia es útil: 
            "Tienes 3 minutos hasta que sea hora de limpiar para irte a la cama".`,
            correct: true,
          },
          {
            id: 'A-3',
            label: `Ajústate a la realidad de que esto va a ser una pelea, siempre lo es. 
              Deje que su hijo/a se quede despierto hasta tarde viendo televisión y agotándose, y déjelo ir.`,
            correct: false,
          },
        ],
      },
      N: {
        feedback: `Es importante prestar atención a los comportamientos que desea ver más. 
        Felicitar a su hijo/a cuando es útil durante su rutina a la hora de acostarse puede 
        facilitar las transiciones en el futuro. Observe  también sus propios éxitos, 
        la crianza de los hijos es difícil.`,
        choices: [
          {
            id: 'N-1',
            label:
              'Observe los esfuerzos de su hijo/a, elógielo/a e incentivelo/a.',
            correct: true,
          },
          {
            id: 'N-2',
            label: 'Note que dolor de cabeza es su niño/a y pongase mal genio.',
            correct: false,
          },
          {
            id: 'N-3',
            label: `Observe todos los pequeños éxitos tanto para usted como para 
              su hijo/a trabajando juntos durante las transiciones.`,
            correct: true,
          },
        ],
      },
    },
  },
];
