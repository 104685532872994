import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_AskQuestions_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="text-white font-light"
      videoKey={isSpanish ? 'ani_7_spanish' : 'ani_7'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Puede mejorar la comunicación con su hijo/a cuando demuestra que 
              lo está escuchando por repitir sus sentimientos y hacer preguntas 
              durante una conversación. ¡Mire cómo lo hace esta mamá!`
            : `You can improve communication with your child when you show you’re
              listening by restating with feeling and asking questions during a
              conversation. Watch how this mom does it!`}
        </p>
      }
      bearCharacter="BOY"
    />
  );
};
