import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { PlanLetter } from '../../../components/PlanLetter/PlanLetter';
import {
  PlanLetterOption,
  LETTER_OPTIONS,
} from 'shared/lib/types/PlanLetterOption';
import { SituationCard } from '../../../components/SituationCard/SituationCard';
import stock9Src from '../../../images/stock_9.png';
import stock10Src from '../../../images/stock_10.png';
import stock11Src from '../../../images/stock_11.png';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { RoundedCheckbox } from '../../../components/RoundedCheckbox/RoundedCheckbox';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { Feedback } from '../../../components/Feedback/Feedback';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { situations as englishSituations } from '../../../constants/early_childhood/proactive_parenting_4_situations';
import { spanishSituations } from '../../../constants/early_childhood/proactive_parenting_4_spanish_situations';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const cardImages = [
  { img: stock9Src, alt: 'child munching on table' },
  { img: stock10Src, alt: 'suprised child' },
  { img: stock11Src, alt: 'child playing with toy cars' },
];

function getVisitedAndUnvisitedSituations(
  answeredSituationsMap: Record<string, number>,
  isReview: boolean,
  isEnglish: boolean,
) {
  const situations = isEnglish ? englishSituations : spanishSituations;
  if (isReview) {
    return { availableSituations: situations, visitedSituations: situations };
  }
  const availableSituations = situations.filter((situation) => {
    const totalQuestions = Object.keys(situation.questions).length;
    const answeredSituationsKey = situation.id.toString();
    const previouslyAnswered = answeredSituationsMap[answeredSituationsKey];
    return previouslyAnswered !== totalQuestions;
  });
  // Visited all the situations
  if (!availableSituations.length) {
    return { availableSituations: situations, visitedSituations: situations };
  }
  return {
    availableSituations,
    visitedSituations: situations.filter(
      (situation) =>
        !availableSituations.some(
          (availableSituation) => availableSituation.id === situation.id,
        ),
    ),
  };
}

export const ProactiveParenting4Slide: FC = () => {
  const [currentSituationId, setCurrentSituationId] = useState<number>();
  const [letterOptionIndex, setLetterOptionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview } = useActivityState<
    Record<string, string>
  >({});

  const currentLetterOption = LETTER_OPTIONS[letterOptionIndex];
  const situations = isEnglish ? englishSituations : spanishSituations;
  const planStepContent = isEnglish
    ? englishPlanStepContent
    : spanishPlanStepContent;

  const { id, situation, questions } =
    situations.find((situation) => situation.id === currentSituationId) ||
    situations[0];

  const answerKey = `${id}-${currentLetterOption}`;

  const answeredSituationsMap = Object.keys(value).reduce((acc, val) => {
    const situationId = val[0];
    if (!acc[situationId]) {
      acc[situationId] = 1;
    } else {
      acc[situationId] += 1;
    }
    return acc;
  }, {} as Record<string, number>);

  const { availableSituations, visitedSituations } =
    getVisitedAndUnvisitedSituations(
      answeredSituationsMap,
      isReview,
      isEnglish,
    );

  const endOfPlanning = currentLetterOption === PlanLetterOption.N;

  const usersFirstTimeThrough =
    availableSituations.length === situations.length - 1;

  const handleToastButtonClick = () => {
    if (!endOfPlanning) {
      setLetterOptionIndex((curIndex) => curIndex + 1);
    } else if (usersFirstTimeThrough && !isReview) {
      setShowOverlay(true);
    } else {
      setCurrentSituationId(undefined);
      setLetterOptionIndex(0);
    }
    setShowFeedback(false);
  };

  const { cardDescription, planDescription } =
    planStepContent[currentLetterOption];

  const handleAnswer = (answerId: string) => {
    setValue({
      ...value,
      [answerKey]: answerId,
    });
    setShowFeedback(true);
  };

  const usersChoice = value[answerKey];

  const isUsersChoiceCorrect = questions[currentLetterOption].choices.find(
    (choice) => choice.id === usersChoice,
  )?.correct;

  return (
    <SlideContainer className="flex-col" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pointer"
        instructions={
          <h2 className="font-bold text-lg mt-7">
            {isEnglish ? 'Your turn:' : 'Su turno:'}
          </h2>
        }
        className="items-center px-8 text-blue-800 flex-1 pb-10"
      >
        <div className="flex">
          {LETTER_OPTIONS.map((option, index) => (
            <PlanLetter
              key={option}
              letterOption={option}
              active={!currentSituationId || currentLetterOption === option}
              className={`${index > 0 ? 'ml-2.5' : ''}`}
            />
          ))}
        </div>
        {!currentSituationId ? (
          <>
            {availableSituations.map(({ id, situation }, index) => {
              const { img, alt } = cardImages[index];
              const lastCard = index + 1 === availableSituations.length;
              return (
                <SituationCard
                  key={id}
                  className={`w-full mt-5 ${lastCard ? 'mb-10' : ''}`}
                  cardImage={{ src: img, alt }}
                  onCardButtonClick={() => setCurrentSituationId(id)}
                >
                  <b className="mt-1">
                    {`${
                      isEnglish ? 'Problem Situation' : 'Situación problemática'
                    } ${id}`}
                  </b>
                  <br />
                  {situation}
                </SituationCard>
              );
            })}
          </>
        ) : (
          <>
            <div className="mt-5 flex flex-col px-1">
              <p className="font-light">{planDescription}</p>
              <span className="font-semibold mt-8">
                {isEnglish ? 'Situation' : 'Situación'}
              </span>
              {situation}
            </div>
            <FloatingCard className="mt-5 py-3.5 px-5">
              <h3 className="font-bold text-lg text-center px-4">
                {cardDescription}
              </h3>
              {questions[currentLetterOption].choices.map((choice) => (
                <div className="flex items-center mt-5" key={choice.id}>
                  <RoundedCheckbox
                    className="mr-2.5"
                    checked={usersChoice === choice.id}
                    onClick={() => handleAnswer(choice.id)}
                  />
                  {choice.label}
                </div>
              ))}
            </FloatingCard>
          </>
        )}
        {showFeedback && (
          <BottomToast
            onButtonClick={handleToastButtonClick}
            buttonLabel={isEnglish ? 'Next' : 'Siga'}
          >
            <Feedback
              className="mt-12 mb-7 mx-5"
              icon={isUsersChoiceCorrect ? 'THUMBS_UP' : 'EXCLAMATION'}
            >
              {questions[currentLetterOption].feedback}
            </Feedback>
          </BottomToast>
        )}
        {visitedSituations.length > 0 && !currentSituationId && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={next}
          >
            {isEnglish ? 'Next' : 'Siguente'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR"
        onNext={() => {
          setShowOverlay(false);
          setCurrentSituationId(undefined);
          setLetterOptionIndex(0);
        }}
      />
    </SlideContainer>
  );
};

type StepContent = {
  planDescription: string;
  cardDescription: string;
};

type PlanStepContent = Record<PlanLetterOption, StepContent>;

const englishPlanStepContent: PlanStepContent = {
  P: {
    planDescription:
      'P = Pay attention and identify the problem situations for your child.',
    cardDescription:
      'Choose the answer that best describes the problem situation for the child.',
  },
  L: {
    planDescription:
      "L = Look realistically at your child's abilities in that situation.",
    cardDescription:
      "Choose the answer that looks realistically at the child's abilities in this situation",
  },
  A: {
    planDescription:
      'A = Adjust difficult activities to maximize success and minimize negative emotions.',
    cardDescription:
      'Choose the answer that is the best example of adjusting this situation for a successful result.',
  },
  N: {
    planDescription:
      'N = Notice and remember to prompt, suggest, and reard success!',
    cardDescription:
      'Choose the answer that is the best example of noticing and rewarding success.',
  },
};

const spanishPlanStepContent: PlanStepContent = {
  P: {
    planDescription:
      'Preste atención e identifique las situaciones problemáticas para su hijo/a.',
    cardDescription:
      'Elija la respuesta que mejor describa la situación problemática para el niño/a.',
  },
  L: {
    planDescription:
      'Mirando de manera realistica las habilidades de su niño/a en esa situación.',
    cardDescription:
      'Elija la respuesta que considere de manera realista las habilidades del niño en la situación.',
  },
  A: {
    planDescription:
      'Ajuste las actividades difíciles para maximizar el éxito y minimizar las emociones negativas.',
    cardDescription:
      'Elija la respuesta que sea el mejor ejemplo de cómo ajustar esta situación para obtener un resultado exitoso.',
  },
  N: {
    planDescription:
      'Observe y recuerde impulsar, sugerir y recompensar el éxito.',
    cardDescription:
      'Elija la respuesta que sea el mejor ejemplo de notar y recompensar el éxito.',
  },
};
