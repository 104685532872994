import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import waveSrc from '../../../images/wave.png';
import cravingWaveSrc from '../../../images/craving_wave.png';
import cravingWaveSpanishSrc from '../../../images/craving_wave_spanish.png';
import rampUpWaveSrc from '../../../images/ramp_up_wave.png';
import rampUpWaveSpanishSrc from '../../../images/ramp_up_wave_spanish.png';
import peakWaveSrc from '../../../images/peak_wave.png';
import peakWaveSpanishSrc from '../../../images/peak_wave_spanish.png';
import successWaveSrc from '../../../images/success_wave.png';
import successWaveSpanishSrc from '../../../images/success_wave_spanish.png';
import { useSlideContext } from '../../../contexts/slideContext';
import { ChevronCircleButton } from '../../../components/ChevronCircleButton/ChevronCircleButton';
import { PillButton } from '../../../components/PillButton/PillButton';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSurfImages = [
  { src: waveSrc, alt: 'ocean wave' },
  { src: cravingWaveSrc, alt: 'surfer paddling towards wave' },
  { src: rampUpWaveSrc, alt: 'surfer riding wave' },
  { src: peakWaveSrc, alt: 'surfer cresting wave' },
  { src: successWaveSrc, alt: 'surfer relaxing after successful surf' },
];

const spanishSurfImages = [
  { src: waveSrc, alt: 'ocean wave' },
  { src: cravingWaveSpanishSrc, alt: 'surfista remando hacia la ola' },
  { src: rampUpWaveSpanishSrc, alt: 'surfista montando la ola' },
  { src: peakWaveSpanishSrc, alt: 'surfista encima de la ola' },
  {
    src: successWaveSpanishSrc,
    alt: 'surfista relajándose después de surfear con éxito',
  },
];

export const ParentingAndSubstanceUse6Slide: FC = () => {
  const [currentSurfImageIndex, setCurrentSurfImageIndex] = useState(0);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const surfImages = isEnglish ? englishSurfImages : spanishSurfImages;
  const endOfSlides = currentSurfImageIndex + 1 === surfImages.length;

  const { src, alt } = surfImages[currentSurfImageIndex];

  return (
    <SlideContainer
      hideButton
      className="flex-col justify-between text-white font-light"
      slideTitle={isEnglish ? 'Managing Cravings' : 'Manejar los antojos'}
    >
      <p className="px-14">
        {isEnglish ? (
          <>
            When this happens, imagine you are a surfer who is riding the wave
            of cravings. If you're able to surf the craving waves, the feeling
            will subside. And over time, as they are resisted, your cravings
            will grow weaker and happen less often.
          </>
        ) : (
          <>
            Cuando sucedan los antojos, imagine que es un surfista que está
            montando la ola de los antojos. Si es capaz de surfear las olas
            ansiosas, la sensación disminuirá. Y con el tiempo, a medida que se
            resisten, sus antojos se debilitarán y ocurrirán con menos
            frecuencia.
          </>
        )}
      </p>

      <div className="relative w-full">
        <img src={src} alt={alt} className="w-full h-auto" />
        {!endOfSlides ? (
          <>
            {currentSurfImageIndex === 0 ? (
              <div
                className={`${
                  isEnglish ? 'w-48' : 'w-64'
                } absolute bottom-12 right-0 left-0 mx-auto bg-blue-1000 pl-7 py-3.5 rounded-full text-white self-end`}
              >
                {isEnglish ? 'tap to begin' : 'toque para empezar'}
                <ChevronCircleButton
                  onClick={() => setCurrentSurfImageIndex((index) => index + 1)}
                  className="absolute w-12 h-12 bottom-0.5 -right-0"
                />
              </div>
            ) : (
              <ChevronCircleButton
                onClick={() => setCurrentSurfImageIndex((index) => index + 1)}
                className="absolute w-12 h-12 bottom-12 right-0 left-0 mx-auto"
              />
            )}
          </>
        ) : (
          <PillButton
            className="absolute w-52 bottom-7 right-0 left-0 mx-auto"
            onClick={next}
          >
            {isEnglish ? 'Next' : 'Siguente'}
          </PillButton>
        )}
      </div>
    </SlideContainer>
  );
};
