import { FC } from 'react';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse3Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      buttonTheme={ButtonTheme.YELLOW}
      slideTitle={
        isEnglish
          ? 'Coping without alcohol and drugs'
          : 'Afrontar sin alcohol ni drogas'
      }
      className="flex-col text-white px-14"
    >
      <p>
        {isEnglish ? (
          <>
            You may find that your use of substances doesn’t just affect you. It
            can cause stress to other people in your life too, especially your
            children and partner.
          </>
        ) : (
          <>
            El uso de sustancias no solo afecta a la persona que las usa.
            También puede causar estrés a otras personas en su vida,
            especialmente a los niños o a la pareja.
          </>
        )}
      </p>
      <p>
        {isEnglish ? (
          <>
            Most people who use substances do so to cope with body aches, pains,
            and emotional distress. When reducing or stopping use of substances,
            people may experience both physical and psychological problems, such
            as increased muscle tensions, anxiety, depression, runny nose,
            watery eyes, yawning, and frequent mood swings.
          </>
        ) : (
          <>
            La mayoría de las personas que consumen sustancias lo hacen para
            sobrellevar dolores corporales, dolores y angustia emocional. Al
            reducir o suspender el uso de sustancias, las personas pueden
            experimentar problemas tanto físicos como psicológicos, como aumento
            de la tensión muscular, ansiedad, depresión, goteo nasal, ojos
            llorosos, bostezos y cambios de humor.
          </>
        )}
      </p>
      <span className="font-semibold mt-6">
        {isEnglish
          ? 'Next, think about some reasons you have used substances.'
          : 'A continuación, piense en algunas razones por las que ha consumido sustancias.'}
      </span>
    </SlideContainer>
  );
};
