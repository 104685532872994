export const englishClearUnclearRulesQuiz = {
  seatBeltsNeedToBeOn: {
    answer: true,
    question: 'Seat belts need to be on in the car before we can go.',
    feedback: 'This is a clear rule.',
  },
  dontRunInHouse: {
    answer: false,
    question: "Don't run in the house!",
    feedback: `“Walk when you are in the house” would be a clear rule 
            because it tells your child what you want them to do.`,
  },
  useQuietVoice: {
    answer: true,
    question: 'Use quiet voice when your sister napping.',
    feedback: 'This is a clear rule.',
  },
  bedtimeIsAt8: {
    answer: true,
    question: 'Bedtime is at 8:00.',
    feedback: 'This is a clear rule.',
  },
  noYelling: {
    answer: false,
    question: 'No yelling in the house.',
    feedback: `“Use a quiet voice when inside” would be a clear 
              rule because it tells your child what you want them to do.`,
  },
  wantToGoToBed: {
    answer: false,
    question: 'Do you want to go to bed now?',
    feedback: 'This is a question, not a clear rule.',
  },
};

export const spanishClearUnclearRulesQuiz = {
  seatBeltsNeedToBeOn: {
    answer: true,
    question:
      'Los cinturones de seguridad deben estar puestos en el auto antes de que podamos irnos.',
    feedback: 'Esta es una regla clara.',
  },
  dontRunInHouse: {
    answer: false,
    question: '¡No corras en la casa!',
    feedback: `"Camina cuando estas dentro de la casa" es una 
    regla más clara porque le dice a su niño/a que es lo que Ud. 
    quiere que el/ella haga.`,
  },
  useQuietVoice: {
    answer: true,
    question: 'Usa una voz baja cuando tu hermana esté durmiendo.',
    feedback: 'Esta es una regla clara.',
  },
  bedtimeIsAt8: {
    answer: true,
    question: 'La hora de dormir es las 8:00.',
    feedback: 'Esta es una regla clara.',
  },
  noYelling: {
    answer: false,
    question: 'No grites en la casa.',
    feedback: `"Usa una voz baja cuando estés adentro" 
    sería una regla clara porque le dice a su hijo/a 
    lo que quiere que haga.`,
  },
  wantToGoToBed: {
    answer: false,
    question: '¿Te quieres ir a la cama ahora?',
    feedback: 'Esta es una pregunta, no una regla clara.',
  },
};
