import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { noop } from 'shared/lib/utils/noop';

export interface SlideTextareaProps extends ComponentPropsWithoutRef<'div'> {
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onTextAreaChange?(arg: string): void;
}

export const SlideTextarea: FC<SlideTextareaProps> = ({
  className = '',
  disabled = false,
  label,
  placeholder,
  value,
  onTextAreaChange = noop,
  ...rest
}) => {
  const uniqueTextAreaId = useMemo(() => uniqueId('text-area'), []);
  return (
    <div className={`w-full flex flex-col ${className}`} {...rest}>
      {label && (
        <label htmlFor={uniqueTextAreaId} className="font-bold uppercase">
          {label}
        </label>
      )}
      <textarea
        id={uniqueTextAreaId}
        disabled={disabled}
        className="w-full resize-none p-3 border-2 border-gray-100 rounded-lg text-black min-h-24"
        value={value}
        onChange={(e) => onTextAreaChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};
