import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import infoIconSrc from '../../../images/info_icon.png';
import kitchenSrc from '../../../images/rules_and_consequences_kitchen.png';
import { Concern } from 'shared/lib/types/Concern';
import { useActivityState } from '../../../hooks/useActivityState';
import { InfoPopoverButton } from '../../../components/InfoPopoverButton/InfoPopoverButton';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences3Slide: FC = () => {
  const { value, setValue, isReview } = useActivityState();
  const [activeConcern, setActiveConcern] = useState<string | null>(null);
  const { isEnglish } = useProductLanguage();

  const canProgress = isReview || value;
  const safetyConcerns = isEnglish
    ? englishSafetyConcerns
    : spanishSafetyConcerns;
  return (
    <SlideContainer
      hideButton={!canProgress}
      className="flex-col text-white font-light"
    >
      <p className="px-14">
        {isEnglish ? (
          <>
            Tap on a
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            in the kitchen below to learn more about everyday safety concerns in
            your home.
          </>
        ) : (
          <>
            Oprima una
            <img src={infoIconSrc} alt="info" className="inline w-5 h-5 mx-1" />
            en la cocina a continuación para obtener más información sobre las
            preocupaciones diarias de seguridad en su hogar.
          </>
        )}
      </p>
      <div className="relative flex flex-col items-center max-w-full min-w-93 w-93 sm:w-151 self-center">
        <img src={kitchenSrc} alt="kitchen" className="mt-24" />
        {Object.entries(safetyConcerns).map(([key, info]) => (
          <InfoPopoverButton
            key={key}
            onInfoClicked={() => {
              setActiveConcern((curConcernKey) =>
                curConcernKey !== key ? key : null,
              );
              setValue({});
            }}
            onInfoPopoverClosed={() => setActiveConcern(null)}
            popoverText={info.concern}
            className={info.iconClassName}
            popoverClassName={info.tooltipClassName}
            popoverArrowPosition={info.caretPosition}
            showInfoPopover={activeConcern === key}
          />
        ))}
      </div>
    </SlideContainer>
  );
};

const englishSafetyConcerns: Record<string, Concern> = {
  '1': {
    concern: 'Store knives out of reach.',
    iconClassName: 'right-1/2 -mr-14 bottom-36 sm:bottom-56',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '2': {
    concern:
      'Latch cabinets with cleansers, solvents, bleaches, dishwasher detergent and other poisons.',
    iconClassName: 'right-1/2 -mr-10 bottom-20 sm:bottom-28 sm:-mr-12',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '3': {
    concern: 'Store plastic bags and plastic grocery bags out of reach.',
    iconClassName: 'right-1/2 mr-1 bottom-14 sm:bottom-16',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '4': {
    concern:
      "Shorten the cords on blinds to get them up out of children's reach.",
    iconClassName: 'right-8 bottom-28 sm:bottom-44 sm:right-24',
    tooltipClassName: 'w-64 -right-1',
    caretPosition: 'bottomRight',
  },
  '5': {
    concern: 'Use safety gate at the top and bottom of the stairs.',
    iconClassName: 'bottom-10 right-6',
    tooltipClassName: 'w-64 -right-1',
    caretPosition: 'bottomRight',
  },
};

const spanishSafetyConcerns: Record<string, Concern> = {
  '1': {
    concern: 'Guarde los cuchillos fuera del alcance de los niños.',
    iconClassName: 'right-1/2 -mr-14 bottom-36 sm:bottom-56',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '2': {
    concern:
      'Cierre los gabinetes que tienen limpiadores, solventes, blanqueadores, detergente para platos y otros venenos.',
    iconClassName: 'right-1/2 -mr-10 bottom-20 sm:bottom-28 sm:-mr-12',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '3': {
    concern: 'Guarde las bolsas de plástico fuera del alcance de los niños.',
    iconClassName: 'right-1/2 mr-1 bottom-14 sm:bottom-16',
    tooltipClassName: 'w-64',
    caretPosition: 'bottom',
  },
  '4': {
    concern:
      'Corte los cordones de las cortinas para que queden fuera del alcance de los niños.',
    iconClassName: 'right-8 bottom-28 sm:bottom-44 sm:right-24',
    tooltipClassName: 'w-64 -right-1',
    caretPosition: 'bottomRight',
  },
  '5': {
    concern:
      'Use una puerta de seguridad en la parte alta y baja de las gradas.',
    iconClassName: 'bottom-10 right-6',
    tooltipClassName: 'w-64 -right-1',
    caretPosition: 'bottomRight',
  },
};
