import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Schoolwork_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={
        isSpanish ? 'Organice el trabajo Escolar' : 'Organize Schoolwork'
      }
      className="font-light text-white"
      bearCharacter="GIRL_WINKING"
      videoKey={isSpanish ? 'ani_9_spanish' : 'ani_9'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? 'Vea como esta mamá revisa el trabajo escolar.'
            : 'Watch this Mom check in about schoolwork.'}
        </p>
      }
    />
  );
};
