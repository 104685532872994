import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_g: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `A veces, las pantallas se pueden utilizar para fomentar un comportamiento 
          positivo. Si las pantallas son un motivador para su hijo/a, utilícelas a su favor. 
          Considere comenzar con una actividad que le gustaría que su hijo/a hiciera más, 
          luego use una declaración de cuándo / entonces para respaldar este comportamiento.`
        : `Sometimes screens can be used to encourage positive behavior. If screens are
          a motivator for your child, use them to your advantage. Consider starting
          with one activity you would like your child to do more of, then use a
          when/then statement to support this behavior.`}

      <ExampleSection
        title={
          <h3 className="uppercase font-bold text-lg ml-2">
            {isSpanish ? 'Ejemplo:' : 'Example:'}
          </h3>
        }
        className="my-4"
      >
        <p className="pt-2 pl-8">
          {isSpanish
            ? `“Cuando haya leído durante 30 minutos, podrá pasar tiempo en su tableta.”`
            : `“When you’ve read for 30 minutes, then you can spend time on your tablet.”`}
        </p>
      </ExampleSection>
    </SlideContainer>
  );
};
