import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences12Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Logical Consequences' : 'Consecuencias lógicas'}
      className="px-14 text-white font-light flex-col"
    >
      <p>
        {isEnglish ? (
          <>
            When children have trouble following directions, they may need
            consequences to their behavior. The most effective way to support
            your clear rules is to use logical consequences.
          </>
        ) : (
          <>
            Cuando los niños/as tienen problemas para seguir instrucciones, es
            posible que necesiten consecuencias por su comportamiento. La forma
            más efectiva de respaldar sus reglas claras es usar consecuencias
            lógicas.
          </>
        )}
      </p>
      <p>
        {isEnglish ? (
          <>
            Logical consequences make sense because they are linked to the
            behavior you are trying to change.
          </>
        ) : (
          <>
            Consecuencias lógicas tienen sentido porque están relacionadas con
            el comportamiento que está tratando de cambiar.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
