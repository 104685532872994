import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting4Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const slideTitle = isEnglish ? 'Encouragement & praise' : 'Ánimo y elogio';
  return (
    <SlideVideo
      className="font-light text-white"
      slideTitle={slideTitle}
      bearCharacter="BABY_BEAR"
      upperSlideContent={
        <p className="px-14 mb-2">
          {isEnglish ? (
            <>
              Try to strengthen positive behaviors by noticing when your child
              does something right. To do this, get in the habit of giving
              praise and encouragement for the little things you like or
              appreciate that your child does each day.
            </>
          ) : (
            <>
              Trate de fortalecer los comportamientos positivos al notar cuando
              su niño/a hace algo bien. Para hacer esto, adquiera el hábito de
              elogiar y alentar las pequeñas cosas que le gustan o aprecia que
              su hijo hace cada día.
            </>
          )}
        </p>
      }
      videoTitle={<h3 className="text-lg text-blue-800">{slideTitle}</h3>}
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_04'
          : 'early_childhood_spanish_Ani_04'
      }
    />
  );
};
