import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { UnitSlideWithActivities } from 'shared/lib/types/UnitSlide';
import {
  getUnitSlides,
  addLocalSlideView,
  setLocalActivityResponse,
} from 'shared/lib/utils/unitUtils';
import { UnitVisit } from 'shared/lib/types/UnitVisit';
import {
  isSurveyTakeComplete,
  assignSurveyTakeResponse,
} from 'shared/lib/utils/surveyUtils';
import { api } from '../../api';
import SectionCompletionOverlay from '../../components/SectionCompletionOverlay/SectionCompletionOverlay';
import { SlideHeader } from '../../components/SlideHeader/SlideHeader';
import { useOrg } from '../../contexts/orgContext';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { SlideContext, useSlideContext } from '../../contexts/slideContext';
import { getUnitSlideComponents } from '../../slides';
import { SurveyTakeScreen } from '../../components/Survey/SurveyTakeScreen';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { Spinner } from '../../components/Spinner/Spinner';
import { SHOW_SLIDE_NAMES } from '../../env';
import { ModuleCompleteSlide } from '../../components/ModuleCompleteSlide/ModuleCompleteSlide';
import { DraftUnitActivityResponse } from 'shared/lib/types/UnitActivityResponse';
import { useActivitySaveInterval } from '../../hooks/useActivitySaveInterval';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useSaveSurveyResponse } from '../../hooks/product/useSaveSurveyResponse';

export const UnitPage: FC<{ unit: UnitWithSections }> = ({ unit }) => {
  const unitNumber = unit.index + 1;
  const history = useHistory();
  const { org } = useOrg();
  const { productSession, setProductSession } = useParticipantProductContext();

  const unitSession = productSession.unitSessions.find(
    (unitSession) => unitSession.unit.id === unit.id,
  );
  const slideSessions = unitSession?.slideSessions;
  const surveyTake = unitSession?.surveyTake;
  const survey = unitSession?.survey;
  const slides = useMemo(() => getUnitSlides(unit), [unit]);
  const completedSlideIds = useMemo<number[]>(() => {
    if (!slideSessions) {
      return [];
    }
    return slideSessions
      ? slides
          .filter((slide) =>
            slideSessions.some(
              ({ slideId, completedAt }) =>
                slideId === slide.id && completedAt !== null,
            ),
          )
          .map((slide) => slide.id)
      : [];
  }, [slides, slideSessions]);
  const orgId = org.id;
  const unitId = unit.id;
  const productId = productSession.product.id;
  const queueActivityResponse = useActivitySaveInterval({
    orgId,
    unitId,
    productId,
  });

  /**
   * Keep track of how long the participant spends in the unit.
   */
  useUnitVisitInterval({
    orgId: org.id,
    productId: productSession.product.id,
    unitId: unit.id,
  });

  /**
   * Create a survey take if none exists.
   */
  useAsyncEffect(
    async (isCancelled) => {
      if (survey && !surveyTake) {
        const newSurveyTake = await api.createSurveyTake({
          orgId,
          productId,
          surveyId: survey.id,
        });
        if (!isCancelled()) {
          setProductSession((value) => ({
            ...value,
            unitSessions: value.unitSessions.map((unitSession) => {
              if (unitSession.unit.id !== unitId) {
                return unitSession;
              }
              return { ...unitSession, surveyTake: newSurveyTake };
            }),
          }));
        }
      }
    },
    [survey, surveyTake],
  );

  /**
   * Sets the unit id the participant should receive a text reminder about in a day.
   * Pass `null` to clear the reminder.
   */
  const setUnitTextReminder = useCallback(
    async (unitId: number | null) => {
      try {
        await api.setUnitTextReminder({ unitId, orgId, productId });
        setProductSession((value) => ({
          ...value,
          participantProduct: {
            ...value.participantProduct,
            remindUnitId: unitId,
          },
        }));
      } catch (error) {
        console.error(`Failed to update unit text reminder`, error);
      }
    },
    [orgId, productId, setProductSession],
  );

  /**
   * If the participant has a reminder unit set and they visit that unit's page, clear the reminder.
   */
  useAsyncEffect(async () => {
    if (productSession.participantProduct.remindUnitId === unitId) {
      setUnitTextReminder(null);
    }

    // This dependency array intentionally omits `productSession` to avoid an infinite loop.
    // This check only needs to happen when the page first loads or switches between products/units.
  }, [setUnitTextReminder, unitId, productId]);

  const onSurveyResponseSaved = useCallback(
    (response: { questionId: number; value: number }) => {
      setProductSession((value) => ({
        ...value,
        unitSessions: value.unitSessions.map((unitSession) => {
          if (unitSession.unit.id !== unitId) {
            return unitSession;
          }

          return {
            ...unitSession,
            surveyTake:
              unitSession.surveyTake &&
              assignSurveyTakeResponse(unitSession.surveyTake, response),
          };
        }),
      }));
    },
    [setProductSession, unitId],
  );

  const setSurveyResponse = useSaveSurveyResponse({
    survey,
    surveyTake,
    onSurveyResponseSaved,
    orgId,
    productId,
  });

  const setActivityResponse = useCallback(
    ({ activityId, value }: { activityId: number; value: unknown }): void => {
      queueActivityResponse({ activityId, value });

      setProductSession((productSession) => ({
        ...productSession,
        unitSessions: productSession.unitSessions.map((unitSession) =>
          unitSession.unit.id === unitId
            ? setLocalActivityResponse(unitSession, {
                activityId,
                value,
                participantProductId: productSession.participantProduct.id,
              })
            : unitSession,
        ),
      }));
    },
    [setProductSession, queueActivityResponse, unitId],
  );

  return (
    <div className="bg-blue-900 min-h-screen">
      <div className="relative md:max-w-2xl md:mx-auto">
        <Switch>
          {/* Unit survey */}
          <Route path={`/unit/${unitNumber}/survey`}>
            {({ match }) => {
              if (!match || !unitSession) {
                return null;
              }

              // No survey, redirect to the default slide.
              if (!unitSession.survey) {
                return <Redirect to={`/unit/${unitNumber}/`} />;
              }

              if (!unitSession.surveyTake) {
                // Creating survey take
                return (
                  <div className="h-screen flex flex-col justify-center items-center bg-blue-900">
                    <Spinner />
                  </div>
                );
              }

              return (
                <SurveyTakeScreen
                  basePath={`/unit/${unitNumber}/survey`}
                  unit={unitSession.unit}
                  survey={unitSession.survey}
                  take={unitSession.surveyTake}
                  onResponseChange={setSurveyResponse}
                  onBack={() => history.push(`/`)}
                  onClose={() => history.push(`/unit/${unitNumber}/`)}
                />
              );
            }}
          </Route>

          {/* Unit slide */}
          <Route path={`/unit/${unitNumber}/:slideNumber`}>
            {({ match }) => {
              if (!match || !match.params.slideNumber) {
                return null;
              }

              if (
                survey &&
                (!surveyTake || !isSurveyTakeComplete(survey, surveyTake))
              ) {
                return <Redirect to={`/unit/${unitNumber}/survey`} />;
              }

              const slideNumber = +match.params.slideNumber;
              const slide = slides[slideNumber - 1];

              if (!slide) {
                return <Redirect to={`/unit/${unitNumber}/`} />;
              }

              return (
                <UnitSlidePage
                  unit={unit}
                  slide={slide}
                  slideNumber={slideNumber}
                  setUnitTextReminder={setUnitTextReminder}
                  setActivityResponse={setActivityResponse}
                  activityResponses={
                    unitSession?.activityResponses.filter((response) =>
                      slide.activities.some(
                        (activity) => activity.id === response.activityId,
                      ),
                    ) ?? []
                  }
                />
              );
            }}
          </Route>

          {/* Default route */}
          <Route path={`/unit/${unitNumber}/`}>
            {({ match }) => {
              if (!match) {
                return null;
              }

              if (
                survey &&
                (!surveyTake || !isSurveyTakeComplete(survey, surveyTake))
              ) {
                return <Redirect to={`/unit/${unitNumber}/survey`} />;
              }

              /**
               * Slide to display when navigating directly to the unit without specifying the specific
               * part of the unit (survey or slide number).
               * First incomplete slide or the first slide if all slides are complete.
               */
              const initialSlide =
                slides.find((slide) => !completedSlideIds.includes(slide.id)) ??
                slides[0];

              const initialSlideIndex = slides.findIndex(
                (slide) => initialSlide.id === slide.id,
              );

              return (
                <Redirect to={`/unit/${unitNumber}/${initialSlideIndex + 1}`} />
              );
            }}
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const UnitSlidePage: FC<{
  unit: UnitWithSections;
  slide: UnitSlideWithActivities;
  activityResponses: DraftUnitActivityResponse[];
  slideNumber: number;
  setUnitTextReminder(unitId: number | null): void;
  setActivityResponse(response: { activityId: number; value: unknown }): void;
}> = ({
  unit,
  slide,
  slideNumber,
  activityResponses,
  setUnitTextReminder,
  setActivityResponse,
}) => {
  const [showSectionCompleteOverlay, setShowSectionCompleteOverlay] =
    useState(false);
  const [showUnitCompleteOverlay, setShowUnitCompleteOverlay] = useState(false);
  const history = useHistory();
  const { org } = useOrg();
  const { productSession, setProductSession } = useParticipantProductContext();
  const product = productSession.product;
  const slideComponents = getUnitSlideComponents(product.key, unit.name);
  const SlideComponent = slide ? slideComponents[slide.name] : null;
  const orgId = org.id;
  const unitId = unit.id;
  const productId = product.id;
  const slideId = slide?.id;
  const participantProductId = productSession.participantProduct.id;
  const unitIndex = unit.index;
  const unitNumber = unitIndex + 1;
  const section = useMemo(
    () =>
      unit.sections.find((section) =>
        section.slides.some((slide) => slide.id === slideId),
      ),
    [unit, slideId],
  );
  const isLastSlideInSection = useMemo(
    () =>
      !!(
        section &&
        slideId &&
        section.slides[section.slides.length - 1].id === slideId
      ),
    [section, slideId],
  );
  const isLastSectionInUnit = useMemo(
    () =>
      !!(section && section.id === unit.sections[unit.sections.length - 1].id),
    [unit, section],
  );
  useDocumentTitle(`${unit.name}, Slide ${slideId}`);

  const viewSlide = useCallback(
    (complete: boolean) => {
      if (!slideId) {
        return;
      }

      api
        .viewSlide({
          orgId,
          productId,
          unitId,
          slideId,
          complete,
        })
        .catch((error) => console.error(`Failed to save slide view.`, error));

      /**
       * Update the local unit session to account for the updated slide session.
       */
      setProductSession((productSession) => ({
        ...productSession,
        unitSessions: productSession.unitSessions.map((unitSession) => {
          if (unitSession.unit.id !== unitId) {
            return unitSession;
          }
          return addLocalSlideView(unitSession, {
            slideId,
            participantProductId,
            complete,
          });
        }),
      }));
    },
    [
      orgId,
      participantProductId,
      productId,
      setProductSession,
      slideId,
      unitId,
    ],
  );

  /**
   * Whenever a new slide is opened, record the slide view.
   */
  useEffect(() => {
    viewSlide(false);
  }, [viewSlide]);

  const next = useCallback(() => {
    if (slideId) {
      viewSlide(true);
    }

    if (isLastSlideInSection) {
      setShowSectionCompleteOverlay(true);
    } else {
      history.push(`/unit/${unitNumber}/${slideNumber + 1}`);
    }
  }, [
    slideId,
    isLastSlideInSection,
    viewSlide,
    history,
    unitNumber,
    slideNumber,
  ]);

  const back = useCallback(() => {
    if (slideNumber <= 1) {
      history.push('/');
    } else {
      history.push(`/unit/${unitNumber}/${slideNumber - 1}`);
    }
  }, [history, unitNumber, slideNumber]);

  if (!slide || !section) {
    return <div>Invalid slide index {slide.index}.</div>;
  }

  const handleOverlayNext = () => {
    if (isLastSectionInUnit) {
      setShowUnitCompleteOverlay(true);
    } else {
      history.push(`/unit/${unitNumber}/${slideNumber + 1}`);
    }
    setShowSectionCompleteOverlay(false);
  };

  const handleReminderEnabledChange = async (enabled: boolean) => {
    await setUnitTextReminder(enabled ? unitId : null);
  };

  return (
    <SlideContext.Provider
      value={{
        unit,
        section,
        slide,
        activityResponses,
        setActivityResponse,
        next,
        back,
      }}
    >
      <div className="text-white">
        {SlideComponent ? <SlideComponent /> : <UnimplementedSlideComponent />}
      </div>
      {showSectionCompleteOverlay && (
        <SectionCompletionOverlay
          onNext={handleOverlayNext}
          onReminderEnabledChange={handleReminderEnabledChange}
          currentSegment={section.index + 1}
          segmentCount={unit.sections.length}
          moduleName={unit.name}
        />
      )}
      {showUnitCompleteOverlay && <ModuleCompleteSlide />}
      {SHOW_SLIDE_NAMES && (
        /* Slide name label centered at the top of the page for debugging. */
        <div className="fixed left-1/2 top-0 -translate-x-1/2 transform bg-blue-700 text-white px-4">
          {slide.name}
        </div>
      )}
    </SlideContext.Provider>
  );
};

const UnimplementedSlideComponent: FC = () => {
  const { slide, next } = useSlideContext();

  return (
    <div className="h-screen bg-blue-900 text-white">
      <SlideHeader />
      <h1>Unimplemented slide component {slide.name}.</h1>
      <button onClick={next}>Next</button>
    </div>
  );
};

/**
 * Creates a unit visit and update it every 10 seconds while the component is mounted.
 */
function useUnitVisitInterval({
  orgId,
  productId,
  unitId,
}: {
  orgId: number;
  productId: number;
  unitId: number;
}): void {
  useEffect(() => {
    let unitVisit: UnitVisit | null = null;
    let lastTick = Date.now();

    const interval = setInterval(async () => {
      if (unitVisit !== null) {
        const now = Date.now();
        try {
          const elapsed = now - lastTick;
          lastTick = now;
          await api.tickUnitVisit({
            visitId: unitVisit.id,
            orgId,
            unitId,
            productId,
            elapsed,
          });
        } catch (error) {
          console.error(`Failed to tick unit visit`, error);
          if (error.status === 401) {
            clearInterval(interval);
          }
        }
      }
    }, 10000);

    api
      .visitUnit({ orgId, productId, unitId })
      .then((newUnitVisit) => {
        unitVisit = newUnitVisit;
      })
      .catch((error) => {
        console.error(`Failed to create unit visit`, error);
        if (error.status === 401) {
          clearInterval(interval);
        }
      });

    return () => clearInterval(interval);
  }, [orgId, productId, unitId]);
}
