import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences7Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Clear Rules' : 'Reglas Claras'}
      className="text-white px-14 font-light"
    >
      {isEnglish ? (
        <>
          Setting rules in your household can help to reduce problem behaviors
          and misunderstandings with your child, and improve family
          relationships.
        </>
      ) : (
        <>
          Establecer reglas en su hogar puede reducir los comportamientos
          problemáticos y mal entendimientos con su niño, y mejorar las
          relaciones familiares.
        </>
      )}
    </SlideContainer>
  );
};
