import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_a: FC = () => {
  const [open, setOpen] = useState<null | number>(0);
  const { isSpanish } = useProductLanguage();

  const handleOpen = (id: number) =>
    setOpen((state) => (state === id ? null : id));

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Las pantallas se han convertido en una parte necesaria de la vida. 
          Muchos padres se sienten confundidos cuando se trata de administrar el 
          tiempo frente a una pantalla. Lea las preguntas comunes que los padres 
          hacen a continuación y piense en lo que tiene más sentido para usted y su hijo/a. `
        : `Screens have become a necessary part of life. Many parents feel confused
          when it comes to managing screen time. Read the common questions parents
          ask below, and think about what makes the most sense for you and your
          child.`}

      <h3 className="text-red-600 font-bold my-5">
        {isSpanish
          ? 'Toque el signo más para aprender más.'
          : 'Tap on the plus below to learn more'}
      </h3>
      <PlusExpandable
        open={open === 0}
        onExpand={() => handleOpen(0)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? `¿Qué edad debe tener mi hijo/a para obtener un teléfono y usar 
                las redes sociales?`
              : 'How old should my child be to get a phone and use social media?'}
          </b>
        }
      >
        {isSpanish
          ? `Depende de su circunstancia. Es posible que sea necesario dar a su hijo/a 
            un teléfono a una edad temprana por seguridad, pero puede esperar para 
            permitir que su hijo/a interactúe con las redes sociales hasta que sea lo 
            suficientemente maduro para manejar estas interacciones, generalmente no 
            hasta finales de la escuela secundaria o preparatoria.`
          : `It depends on your circumstance. You may need to give your child a phone
            at a young age for safety, but you can wait to let your child interact
            with social media until they are mature enough to handle these
            interactions, usually not until late middle or high school.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 1}
        onExpand={() => handleOpen(1)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? `¡Mi hijo/a me está volviendo loco/a! Todo lo que hace es enviar 
                mensajes de texto y usar las redes sociales. ¿Debería quitarle 
                el teléfono por completo?`
              : `My child is driving me crazy! All they do is text and use social
                media. Should I take their phone away completely?`}
          </b>
        }
      >
        {isSpanish
          ? `No, usar un teléfono para comunicarse con amigos es la nueva normalidad 
            para todos nosotros, por lo cual es importante que su hijo/a aprenda a 
            manejar este aspecto de la vida ahora durante la escuela secundaria y más 
            allá. No es realista quitarlo o limitar severamente su uso. Le resultará 
            difícil cumplirlas si crea reglas demasiadas estrictas. Sin embargo, puede 
            seguir algunos consejos sencillos para reducir el tiempo de pantalla y 
            gestionar este comportamiento. Por ejemplo, puede restringir el uso de teléfonos 
            inteligentes, tabletas y mensajes de texto entre las 4 y las 7 pm para que su 
            hijo pueda hacer sus deberes, quehaceres domésticos y participar en la conversación 
            a la hora de la cena sin la competencia de la interacción constante con sus amigos.`
          : `No, using a phone to communicate with friends is the new normal for all
            of us, so it is important for your child to learn how to manage this
            aspect of modern life now during middle school and beyond. It is not
            realistic to take it away or severely limit use. It will be hard for you
            to follow through if you create rules that are too strict. You can,
            however, follow some simple tips to reduce screen time and manage this
            behavior. For example, you could restrict smart phone, tablet and
            texting use between 4-7pm so your child can complete their homework,
            chores, and take part in dinner time conversation without the
            competition of constant peer interaction.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 2}
        onExpand={() => handleOpen(2)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? `A veces es más fácil entretener a mi hijo/a con mi teléfono o mi computadora. 
                ¿Eso me convierte en un mal padre?`
              : `Sometimes it’s easier to entertain my child with my phone or
                computer. Does that make me a bad parent?`}
          </b>
        }
      >
        {isSpanish
          ? `No, todos los padres usan videos, videojuegos y el tiempo del teléfono 
            para entretener a sus hijos, a veces. Es importante establecer límites en 
            su uso y tener reglas claras sobre el tiempo de pantalla.`
          : `No, every parent uses videos, video games, and phone time to entertain
            their children, sometimes. It’s important to set limits on their usage,
            and have clear rules about screen time.`}
      </PlusExpandable>
      <PlusExpandable
        open={open === 3}
        onExpand={() => handleOpen(3)}
        headerText={
          <b className="font-bold">
            {isSpanish
              ? `Mi hijo/a pasa demasiado tiempo jugando videojuegos, ¿cómo 
                puedo lidiar con eso?`
              : `My child spends too much time playing video games, how can I deal
                with that?`}
          </b>
        }
      >
        {isSpanish
          ? `Establezca límites en los videojuegos como lo haría con cualquier 
            otra actividad. Para hacer esto:`
          : `Set limits on video games just as you would any other activity you want
            to manage. To do this:`}

        <YellowCircleList className="mt-4">
          <li>{isSpanish ? 'Establecer reglas claras' : 'Set clear rules.'}</li>
          <li>
            {isSpanish
              ? `Utilice incentivos y rutinas para apoyar otros comportamientos saludables`
              : `Use incentives and routines to support other, healthy behaviors.`}
          </li>
          <li>
            {isSpanish
              ? 'Cumpla con las consecuencias que estableció'
              : 'Follow through with the consequences you set.'}
          </li>
          <li>
            {isSpanish
              ? `Cuando quita un privilegio de pantalla o teléfono, asegúrese de 
                informarle a su hijo/a cuándo y cómo recuperarlo. De esta manera su 
                hijo/a tiene esperanza para el futuro inmediato y sea más probable 
                que mejore su comportamiento.`
              : `When you do remove a screen or phone privilege be sure to let your
                child know when and how to get it back—this way your child has hope
                for the immediate future and will be more likely to improve their
                behavior.`}
          </li>
        </YellowCircleList>
      </PlusExpandable>
    </SlideContainer>
  );
};
