import { useHistory, useRouteMatch } from 'react-router-dom';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import { useDocumentTitle } from '../useDocumentTitle';
import { useCallback, useMemo } from 'react';
import { formatPostTestSurveyQuestionRoute } from '../../constants/routes/postTestRoutes';
import { isPostTestComplete } from 'shared/lib/utils/postTestUtils';
import { useParticipantProductContext } from '../../contexts/participantProductContext';

interface UsePostTestScreenParams {
  basePath: string;
  survey: SurveyWithQuestions;
  onUnitSurveyComplete(): void;
  goToPreviousUnitSurvey(): void;
}

export function usePostTestScreen({
  basePath,
  goToPreviousUnitSurvey,
  onUnitSurveyComplete,
  survey,
}: UsePostTestScreenParams) {
  const history = useHistory();
  const { productSession } = useParticipantProductContext();
  const { unitSessions, exitSurveyAndTake } = productSession;

  let documentTitle = `${survey.name} Post Test`;
  const questionMatch = useRouteMatch<{ questionNumber: string }>(
    `${basePath}/question/:questionNumber`,
  );

  if (questionMatch) {
    const { questionNumber } = questionMatch.params;
    documentTitle = `${survey.name}, Survey Question ${questionNumber}`;
  }
  useDocumentTitle(documentTitle);

  const onNext = useCallback(
    (currentQuestionIndex: number) => {
      if (currentQuestionIndex === survey.questions.length - 1) {
        onUnitSurveyComplete();
      } else {
        // Navigate to the next question
        const questionNumber = currentQuestionIndex + 1;
        history.push(
          formatPostTestSurveyQuestionRoute(survey, questionNumber + 1),
        );
      }
    },
    [survey, onUnitSurveyComplete, history],
  );

  const onBack = useCallback(
    (questionIndex: number) => {
      if (questionIndex === 0) {
        goToPreviousUnitSurvey();
      } else {
        // Navigate to the previous question;
        const questionNumber = questionIndex + 1;
        history.push(
          formatPostTestSurveyQuestionRoute(survey, questionNumber - 1),
        );
      }
    },
    [history, goToPreviousUnitSurvey, survey],
  );

  const postTestComplete = useMemo(
    () => isPostTestComplete(unitSessions, exitSurveyAndTake),
    [unitSessions, exitSurveyAndTake],
  );

  return {
    onNext,
    onBack,
    postTestComplete,
  };
}
