import { FC } from 'react';
import { Pospar_Pospar_a } from './Pospar_Pospar_a';
import { Pospar_Pospar_b } from './Pospar_Pospar_b';
import { Pospar_Pospar_c } from './Pospar_Pospar_c';
import { Pospar_Encouragement_a } from './Pospar_Encouragement_a';
import { Pospar_Encouragement_b } from './Pospar_Encouragement_b';
import { Pospar_Encouragement_c } from './Pospar_Encouragement_c';
import { Pospar_Encouragement_d } from './Pospar_Encouragement_d';
import { Pospar_Encouragement_e } from './Pospar_Encouragement_e';
import { Pospar_Relation_a } from './Pospar_Relation_a';
import { Pospar_Relation_b } from './Pospar_Relation_b';
import { Pospar_Relation_c } from './Pospar_Relation_c';
import { Pospar_Directions_a } from './Pospar_Directions_a';
import { Pospar_Directions_b } from './Pospar_Directions_b';
import { Pospar_Directions_c } from './Pospar_Directions_c';
import { Pospar_Directions_d } from './Pospar_Directions_d';
import { Pospar_Directions_e } from './Pospar_Directions_e';
import { Pospar_Rewards_a } from './Pospar_Rewards_a';
import { Pospar_Rewards_b } from './Pospar_Rewards_b';
import { Pospar_Rewards_c } from './Pospar_Rewards_c';
import { Pospar_Rewards_d } from './Pospar_Rewards_d';
import { Pospar_Rewards_e } from './Pospar_Rewards_e';
import { Pospar_Rewards_f } from './Pospar_Rewards_f';
import { Pospar_Rewards_g } from './Pospar_Rewards_g';
import { Pospar_Rewards_h } from './Pospar_Rewards_h';
import { Pospar_Rewards_i } from './Pospar_Rewards_i';

export const positiveParentingSlides: Record<string, FC> = {
  'PositiveParenting A': Pospar_Pospar_a,
  'PositiveParenting B': Pospar_Pospar_b,
  'PositiveParenting C': Pospar_Pospar_c,
  'Encouragement A': Pospar_Encouragement_a,
  'Encouragement B': Pospar_Encouragement_b,
  'Encouragement C': Pospar_Encouragement_c,
  'Encouragement D': Pospar_Encouragement_d,
  'Encouragement E': Pospar_Encouragement_e,
  'RelationshipBuilding A': Pospar_Relation_a,
  'RelationshipBuilding B': Pospar_Relation_b,
  'RelationshipBuilding C': Pospar_Relation_c,
  'GivingDirections A': Pospar_Directions_a,
  'GivingDirections B': Pospar_Directions_b,
  'GivingDirections C': Pospar_Directions_c,
  'GivingDirections D': Pospar_Directions_d,
  'GivingDirections E': Pospar_Directions_e,
  'IncentivesRewards A': Pospar_Rewards_a,
  'IncentivesRewards B': Pospar_Rewards_b,
  'IncentivesRewards C': Pospar_Rewards_c,
  'IncentivesRewards D': Pospar_Rewards_d,
  'IncentivesRewards E': Pospar_Rewards_e,
  'IncentivesRewards F': Pospar_Rewards_f,
  'IncentivesRewards G': Pospar_Rewards_g,
  'IncentivesRewards H': Pospar_Rewards_h,
  'IncentivesRewards I': Pospar_Rewards_i,
};
