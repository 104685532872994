import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting16Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer className="text-white font-light px-14">
      <p>
        {isEnglish ? (
          <>
            Planning ahead is a lot of work at first, but when you can prevent
            problem behavior in your child before it starts, it will be worth
            your efforts.
          </>
        ) : (
          <>
            Planear con anticipación requiere mucho trabajo al principio, pero
            cuando pueda prevenir el comportamiento problemático en su hijo
            antes de que comience, sus esfuerzos valdrán la pena.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
