import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import starRibbonSrc from '../../../images/star_ribbon.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Rewards_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Usando Premios' : 'Using Rewards'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Una manera útil para conseguir que su hijo/a siguiente las direcciones es usar 
          los premios por el buen comportamiento. Piense en las cosas que motiven a 
          su hijo/a. Veamos cómo es que se pueden usar esas cosas como premios. `
        : `A helpful way to get your child to follow your directions is to use rewards
          for their good behavior. Think about the things that motivate your child.
          Let's see how those things can be used as rewards.`}

      <img
        src={starRibbonSrc}
        alt={isSpanish ? 'cinta de recompensa' : 'reward ribbon'}
        className="w-30 self-center mt-5"
      />
    </SlideContainer>
  );
};
