import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_DuringAfter_d: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Apoyo y Monitoreo del Trabajo Escolar'
          : 'Support and Monitor Schoolwork'
      }
      className="px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Participar activamente en el trabajo escolar de su hijo/a le ayuda a 
            desarrollar buenos hábitos de estudio y le demuestra que usted 
            se preocupa por él/ella.`
          : `Being actively involved with your child’s schoolwork helps them develop
            good study habits and shows them that you care about them.`}
      </p>
    </SlideContainer>
  );
};
