import { FC, Fragment } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse11Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const healthyRelationshipsList = isEnglish
    ? englishHealthyRelationshipsList
    : spanishHealthyRelationshipsList;
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Unhealthy vs. Healthy Relationships'
          : 'Relaciones Saludables'
      }
      className="flex-col text-white px-14 font-light"
    >
      <h3 className="font-bold mb-2">
        {isEnglish
          ? 'What do healthy relationships look like?'
          : '¿Cómo son las relaciones saludables?'}
      </h3>
      <p>
        {isEnglish ? (
          <>
            When you’re a parent, your relationships with other people may need
            to change. Your family members may also need to support you by
            making healthy changes.
          </>
        ) : (
          <>
            Cuando es padre, es posible que sus relaciones con otras personas
            deban cambiar. Es posible que los miembros de su familia también
            necesiten apoyarlo/a haciendo cambios saludables.
          </>
        )}
      </p>
      <p>
        {isEnglish
          ? ' Healthy relationships include:'
          : 'Las relaciones saludables incluyen:'}
      </p>

      <YellowCircleList className="mt-5">
        {healthyRelationshipsList.map((healthyRelationshipItem, i) => {
          return (
            <Fragment key={i}>
              <li className="mb-1.5 font-bold">
                {healthyRelationshipItem.title}
              </li>
              {healthyRelationshipItem.text}
            </Fragment>
          );
        })}
      </YellowCircleList>
    </SlideContainer>
  );
};

const englishHealthyRelationshipsList = [
  {
    title: 'Communication',
    text: 'It’s important that you can be honest with your friends and family, as well as be open to listening to what they have to say.',
  },
  {
    title: 'Support',
    text: 'Surround yourself with people that support your healthy decisions.',
  },
  {
    title: 'Boundaries',
    text: 'Good boundaries set limits and expectations for what is okay within your relationships.',
  },
  {
    title: 'Trust',
    text: 'Trust is a key ingredient to help you feel safe in any relationship.',
  },
];

const spanishHealthyRelationshipsList = [
  {
    title: 'Comunicación',
    text: 'Es importante que pueda ser honesto/a con sus amigos/as y familiares, así como estar abierto a escuchar lo que tienen que decir.',
  },
  {
    title: 'Apoyo',
    text: 'Rodéase de personas que apoyen sus decisiones saludables.',
  },
  {
    title: 'Límites',
    text: 'Los buenos límites establecen expectativas de lo que está bien hacer en sus relaciones.',
  },
  {
    title: 'Confianza',
    text: 'La confianza es un ingrediente clave para ayudarle a sentirse seguro/a en cualquier relación',
  },
];
