import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse13Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const effectiveCommunicationList = isEnglish
    ? englishEffectiveCommunicationList
    : spanishEffectiveCommunicationList;
  return (
    <SlideContainer className="flex-col text-white px-14 font-light">
      <h3 className="font-bold mb-2">
        {isEnglish
          ? 'What does effective communication look like?'
          : '¿Cómo es la comunicación efectiva?'}
      </h3>
      <p>
        {isEnglish ? (
          <>
            Communication is essential to any relationship. But sometimes, it
            can be difficult to convey what you want. Here are some tips:
          </>
        ) : (
          <>
            La comunicación es esencial para cualquier relación. Pero a veces
            puede ser difícil transmitir lo que quiere. Aquí hay algunos
            consejos. Las relaciones saludables incluyen:
          </>
        )}
      </p>
      <YellowCircleList className="mt-5">
        {effectiveCommunicationList.map((effectiveCommunicationItem, i) => {
          return (
            <li className="mb-1.5" key={i}>
              {effectiveCommunicationItem}
            </li>
          );
        })}
      </YellowCircleList>
    </SlideContainer>
  );
};

const englishEffectiveCommunicationList = [
  'When talking to your partner, avoid blaming or criticizing them.',
  'Use “I” statements to express what you want or how you feel, rather than “You” statements.',
  'Try to listen without judgment, and take time to understand the other person’s perspective.',
  'Summarize and reflect back what you hear to ensure you understand what the person is trying to say.',
];

const spanishEffectiveCommunicationList = [
  'Cuando habla con su pareja, evite culparlos o criticarlos.',
  'Usa declaraciones de "yo" en lugar de declaraciones de "tú" para expresar lo que quiere o cómo se siente.',
  'Trate de escuchar sin juzgar y tómese el tiempo para comprender la perspectiva de la otra persona.',
  'Resume y refleja lo que escucha para asegurarse de que entiende lo que la persona está tratando de decir.',
];
