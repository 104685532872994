import { FC, ReactNode } from 'react';
import ActivityOverlay from '../ActivityOverlay/ActivityOverlay';
import PointsBadgeMessage from '../PointsBadgeMessage/PointsBadgeMessage';
import IconMessage from '../IconMessage/IconMessage';
import { Blurb } from '../Blurb/Blurb';
import getPositiveBlurbText from '../../utils/getPositiveBlurbText';
import { Confetti } from '../Confetti/Confetti';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { BEAR_IMG_MAP } from '../../constants/bears/bearImageMapping';
import { HEIGHT_MAP } from '../AnimatedOverlay/AnimatedOverlay';

export type QuizCompleteOverlayIconType = 'thumbs-up' | 'alert';

/**
 * @param {number} [activityPoints=5] - Defaults to 5
 * @param {string} [bearCharacter="BOY"] - Defaults to BOY
 * @param {string} [badgeLocation="top"] - Defaults to top
 * @param {boolean} [showConfetti=false] - Defaults to false
 * @param {string} [blurbText=getPositiveBlurbText()] - Defaults to getPositiveBlurbText()
 */

const QuizCompletionOverlay: FC<{
  open: boolean;
  icon?: QuizCompleteOverlayIconType;
  onNext(): void;
  activityPoints?: number;
  activityMessage?: ReactNode;
  bearCharacter: keyof typeof BEAR_IMG_MAP;
  badgeLocation?: 'top' | 'right';
  blurbText?: string;
  showConfetti?: boolean;
  heightOverride?: keyof typeof HEIGHT_MAP;
}> = ({
  activityPoints = 5,
  icon,
  bearCharacter,
  activityMessage,
  badgeLocation = 'top',
  blurbText,
  showConfetti = false,
  heightOverride,
  ...rest
}) => {
  const { language } = useProductLanguage();
  const bearBlurb = blurbText || getPositiveBlurbText(language);

  const badgeTop = badgeLocation === 'top';

  return (
    <ActivityOverlay
      height={heightOverride ?? (!activityPoints ? 'MEDIUM' : 'LARGE')}
      {...rest}
    >
      {/* Container */}
      <div className="relative box-border flex flex-col h-full pt-6">
        {/* Message Row */}
        <div
          className={`relative flex box-border justify-center content-center items-center
            ${!activityPoints ? 'h-full' : 'h-2/4'}`}
        >
          {/* Message */}
          {badgeTop ? (
            <PointsBadgeMessage
              points={activityPoints}
              pointsBadgeLabel="PTS"
              messageWidth="SHRUNK"
            />
          ) : (
            <IconMessage icon={icon} activityMessage={activityMessage} />
          )}
        </div>
        {/* Bear Row */}
        <div className="relative flex flex-shrink-0 box-border h-1/2 pt-2 smY:pt-0">
          {/* Bottom Column */}
          <div className="relative flex justify-end" style={{ flex: 1.5 }}>
            {/* Bear Character */}
            <img
              src={BEAR_IMG_MAP[bearCharacter]}
              alt="bear character"
              className="w-auto h-full"
            />
          </div>
          <div
            className={`flex flex-col flex-1 ${
              badgeTop ? 'justify-center items-start ' : ''
            }`}
          >
            {badgeTop ? (
              <Blurb className="p-4">{bearBlurb}</Blurb>
            ) : (
              <PointsBadgeMessage
                points={activityPoints}
                pointsBadgeLabel="PTS"
              />
            )}
          </div>
        </div>
      </div>
      {showConfetti && <Confetti />}
    </ActivityOverlay>
  );
};

export default QuizCompletionOverlay;
