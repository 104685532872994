import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      className="font-light text-white"
      bearCharacter="BOY_WINKING"
      videoKey={isSpanish ? 'ani_16_spanish' : 'ani_16'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Es importante establecer límites saludables para el uso de la pantalla de su hijo/a. Asegúrese de que sus límites sean realistas para poder mantenerlos. Observe cómo esta mamá sigue adelante con su hijo.`
            : `It’s important to set healthy limits for your child’s screen use. Make
              sure your limits are realistic so you can maintain them. Watch how this
              Mom follows through with her son.`}
        </p>
      }
    />
  );
};
