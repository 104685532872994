import { FC, useState } from 'react';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { useSlideContext } from '../../../contexts/slideContext';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { Language } from 'shared/lib/types/Language';

const englishLabels = [
  'Great job!',
  'Thanks for putting the dishes away.',
  'Thank you!',
  'I like the friendly tone you used with your brother just now.',
];

const spanishLabels = [
  '¡Buen trabajo!',
  'Gracias por guardar los platos.',
  '¡Gracias!',
  'Me gusta el tono amigable que usaste con tu hermano en este momento.',
];

const correctMessages = {
  english: `That’s right! Specific praise includes the behavior your child 
            is doing that you want to increase.`,
  spanish: `¡Eso es correcto! El elogio especifico incluye el comportamiento 
            de su hijo/a que Ud. Quiere que incremente.`,
};

const incorrectMessages = {
  english: (
    <>
      Actually, specific praise includes the behavior your child is doing that
      you want to increase.
      <br />
      <br />
      “Thanks for putting the dishes away” and “I like the friendly tone you
      used with your brother just now” were both examples of specific praise.
    </>
  ),
  spanish: (
    <>
      El elogio especifico incluye el comportamiento que su hijo/a está haciendo
      que Ud. quiere que incremente.
      <br />
      <br />
      "Gracias por guardar los platos" y "me gusta el tono amigable que usaste
      con tu hermano en este momento" ambos son ejemplos de elogio especifico.
    </>
  ),
};

const getCorrectIncorrectMessages = (lang: Language, correct: boolean) =>
  correct ? correctMessages[lang] : incorrectMessages[lang];

export const Pospar_Encouragement_e: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      question1: false,
      question2: false,
      question3: false,
      question4: false,
    });
  const [overlayOpen, setOverlayOpen] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const allCorrect =
    !value.question1 && value.question2 && !value.question3 && value.question4; // Only questions 2 and 4 should be checked

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setOverlayOpen(true);
    }
  };

  const labels = isSpanish ? spanishLabels : englishLabels;

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? 'Su turno: Marque todas las cajas que son ejemplos del elogio especifico.'
            : 'Your turn: Check all the boxes that are examples of specific praise.'
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center px-3">
          <FloatingCard className="w-full py-3 px-4">
            {Object.keys(value).map((key, idx) => {
              const valueKey = key as keyof typeof value;
              return (
                <SlideCheckbox
                  key={key}
                  className="mb-3"
                  checked={value[valueKey]}
                  onChange={() =>
                    setValue({ ...value, [key]: !value[valueKey] })
                  }
                  label={labels[idx]}
                />
              );
            })}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 mb-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Entregar' : 'Submit'}
          </PillButton>
        </div>
      </ActivityBox>
      <QuizCompletionOverlay
        open={overlayOpen}
        icon={allCorrect ? 'thumbs-up' : 'alert'}
        onNext={next}
        badgeLocation="right"
        bearCharacter="BOY_WINKING"
        activityPoints={activity.pointValue}
        showConfetti={false}
        activityMessage={getCorrectIncorrectMessages(language, allCorrect)}
        heightOverride="LARGE_ON_TALLER"
      />
    </SlideContainer>
  );
};
