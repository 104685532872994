import { createContext, useContext } from 'react';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { UnitSectionWithSlides } from 'shared/lib/types/UnitSection';
import { UnitSlideWithActivities } from 'shared/lib/types/UnitSlide';
import { missingProvider } from 'shared/lib/utils/errors';
import { DraftUnitActivityResponse } from 'shared/lib/types/UnitActivityResponse';

export interface SlideContextValue {
  unit: UnitWithSections;
  section: UnitSectionWithSlides;
  slide: UnitSlideWithActivities;
  activityResponses: DraftUnitActivityResponse[];
  setActivityResponse<T>(response: { activityId: number; value: T }): void;
  back(): void;
  next(): void;
  /** Hide navigation UI for all slides. Used for the My Plan page. */
  minimalSlideWrapper?: boolean;
  // for identifiable prop when rendering activity in plan page
  myPlanActivity?: boolean;
  // For provider view only mode
  viewOnly?: boolean;
}

export const SlideContext = createContext<SlideContextValue | null>(null);

export function useSlideContext(): SlideContextValue {
  return (
    useContext(SlideContext) ??
    missingProvider('useSlideContext', 'SlideContext.Provider')
  );
}

export function useSlideContextOptional(): SlideContextValue | null {
  return useContext(SlideContext);
}
