import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Healthy_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas Saludables' : 'Healthy Routines'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `La crianza de niños es difícil hasta en los mejores de casos. 
            Nuevos retos y estreses en su vida puedan hacer la crianza 
            de niños muy abrumador.`
          : `Parenting is hard in the best of times. New challenges and stressors in
            your life can make parenting overwhelming.`}
      </p>
      <p>
        {isSpanish
          ? `Establecer rutinas saludables consistentes con su hijo/a les 
            ayudará a ambos. Las rutinas hacen sus días predecibles y pone 
            a su hijo/a en posición para sobresalir o tener éxito.`
          : `Establishing consistent healthy routines with your child will help you
            both. Routines make your days predictable and set your child up for
            success.`}
      </p>
    </SlideContainer>
  );
};
