import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import { Mood } from 'shared/lib/types/Mood';
import { MoodQuestionInput } from '../../../components/MoodQuestionInput/MoodQuestionInput';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishQuestions = {
  healthyMeals: 'Eat healthy meals',
  sleep: 'Get at least 7 hours of sleep',
  bedtimeRoutine: 'Have a bedtime routine',
  timeWithChildren: 'Spend quality time with children',
  exercises: 'Get exercise',
};

const spanishQuestions = {
  healthyMeals: 'Come comidas saludables regularmente',
  sleep: 'Dormir al menos 7 horas',
  bedtimeRoutine: 'Tener una rutina para la hora de acostarse',
  timeWithChildren: 'Pasar tiempo de calidad con los niños',
  exercises: 'Hacer Ejercicios',
};

type QuestionKey =
  | keyof typeof englishQuestions
  | keyof typeof spanishQuestions;

export const WellnessAndSelfCare16Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<{
    [key in QuestionKey]: Mood | null;
  }>({
    healthyMeals: null,
    sleep: null,
    bedtimeRoutine: null,
    timeWithChildren: null,
    exercises: null,
  });
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSubmit = () => {
    if (isReview) {
      next();
    } else {
      setValue(value); // Set activity completed even if no values were changed
      setShowOverlay(true);
    }
  };

  // Show the next button during reviews or when more than 2 moods are submitted
  const showNext =
    isReview || Object.values(value).filter((val) => val !== null).length >= 3;

  const moodQuestions = isEnglish ? englishQuestions : spanishQuestions;

  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Healthy Routines' : 'Rutinas Saludables'}
      className="flex-col px-14 font-light text-white"
      hideButton={!showNext}
      onButtonClick={handleSubmit}
    >
      {isEnglish
        ? 'Rate your satisfaction with some of the things you probably do each day.'
        : 'Califique su satisfacción con algunas de las cosas que probablemente hace cada día.'}
      <div className="flex flex-col justify-center items-center mt-5">
        {Object.entries(moodQuestions).map(([key, questionText]) => (
          <MoodQuestionInput
            key={key}
            value={value[key as QuestionKey]}
            onChange={(mood) => setValue({ ...value, [key]: mood })}
            question={questionText}
          />
        ))}
      </div>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR_THUMBS_UP_WINKING"
      />
    </SlideContainer>
  );
};
