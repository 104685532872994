import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishContent = [
  {
    question: 'Please ask permission to leave the table.',
    helpfulCorrect: true,
    feedback: 'This is a clear direction with a specific request.',
  },
  {
    question: 'Do you want to clean your room?',
    helpfulCorrect: false,
    feedback:
      'Questions are not directions because they provide an option to say “no”.',
  },
  {
    question: 'It’s your fault the house is a mess',
    helpfulCorrect: false,
    feedback:
      'Blame statements tend to make children defensive, moodier and less likely to follow directions. ',
  },
  {
    question: 'I will turn the TV off so I am sure you can hear me.',
    helpfulCorrect: true,
    feedback: 'This is a statement that conveys information to your child.',
  },
  {
    question:
      'I can tell you put your clothes in the laundry basket, thank you!',
    helpfulCorrect: true,
    feedback: 'This is a clear praise statement.',
  },
  {
    question: 'Wow, you cleared your plate without me asking, nicely done!',
    helpfulCorrect: true,
    feedback:
      'This praise statement gives your child information about the behavior you want them to continue.',
  },
  {
    question: 'Brush your teeth before getting in bed.',
    helpfulCorrect: true,
    feedback: 'This is a clear direction with a specific request.',
  },
  {
    question:
      'Put away your games, wash your hands, then come to the table, please.',
    helpfulCorrect: false,
    feedback:
      'Giving multiple directions at once is less effective than giving one direction at a time.',
  },
  {
    question: 'You’ve always been a slob.',
    helpfulCorrect: false,
    feedback:
      'Terms such as “always,” “never” and negative words reduce cooperation and self-esteem in children and adults.',
  },
  {
    question: 'Don’t talk with your mouth full.',
    helpfulCorrect: false,
    feedback:
      'Directions that tell your children what to do, instead of what not to do, are more effective.',
  },
  {
    question: 'Don’t get in bed without brushing your teeth',
    helpfulCorrect: false,
    feedback:
      'Directions that tell your children what to do, instead of what not to do, are more effective.',
  },
  {
    question: 'You’re being so loud, it’s annoying!',
    helpfulCorrect: false,
    feedback:
      'This is a statement, not a direction. It is unclear what behavior is being requested.',
  },
];

const spanishContent = [
  {
    question: 'Por favor pide permiso para dejar la mesa.',
    helpfulCorrect: true,
    feedback: 'Esta es una dirección que tiene un pedido específico.',
  },
  {
    question: '¿Quieres limpiar tu cuarto?',
    helpfulCorrect: false,
    feedback:
      'Preguntas no son direcciones porque dan una oportunidad para decir "no".',
  },
  {
    question: 'Es tu culpa que la casa es un desorden.',
    helpfulCorrect: false,
    feedback: `Oraciones que culpan tiene la tendencia de poner niños a la defensiva, 
      malhumorado, y menos aptos para seguir direcciones.`,
  },
  {
    question: 'Voy a apagar la TV para que me puedas escuchar mejor.',
    helpfulCorrect: true,
    feedback: `Esta es una oración que le da información a su hijo/a.`,
  },
  {
    question: '¡Yo puedo ver que guardaste tu ropa en la canasta, gracias!',
    helpfulCorrect: true,
    feedback: `Esta es una oración clara. `,
  },
  {
    question: '¡Wow, guardaste tu plato sin que yo te preguntara, bien hecho!',
    helpfulCorrect: true,
    feedback: `Esta oración de elogio le da información sobre el comportamiento que 
    Ud. quiere que continúe.`,
  },
  {
    question: 'Lávate los dientes antes de acostarse.',
    helpfulCorrect: true,
    feedback: `Esta es una dirección que tiene un pedido específico. `,
  },
  {
    question:
      'Guarda tus juegos, lávate las manos, y después ven a la mesa, por favor.',
    helpfulCorrect: false,
    feedback: `Dando múltiples direcciones a la misma vez es menos eficaz que dar 
    una dirección a la vez.`,
  },
  {
    question: 'Siempre has sido desordenado.',
    helpfulCorrect: false,
    feedback: `Palabras como "siempre", "nunca" y palabras negativas reducen la 
    cooperación y la autoestima de niños y adultos.`,
  },
  {
    question: 'No hables con la boca llena.',
    helpfulCorrect: false,
    feedback: `Direcciones que le dicen a su hijo/a que hacer, en vez de lo 
    que no deben hacer, son más efectivas.`,
  },
  {
    question: 'No te vayas a dormir sin lavarte los dientes.',
    helpfulCorrect: false,
    feedback: `Direcciones que le dicen a su hijo/a que hacer, en vez de 
    lo que no deben hacer, son más efectivas.`,
  },
  {
    question: 'Estás haciendo mucho ruido, es muy fastidioso.',
    helpfulCorrect: false,
    feedback: `Esta es un oración, no una dirección. No es muy claro 
    lo que le está pidiendo.`,
  },
];

export const Pospar_Directions_d: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<
    {
      [key in `question${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}`]:
        | boolean
        | null;
    }
  >({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
    question6: null,
    question7: null,
    question8: null,
    question9: null,
    question10: null,
    question11: null,
    question12: null,
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const minReqAnswered =
    Object.values(value).filter((answer) => answer !== null).length >= 3;

  const handleSubmit = () => (isReview ? next() : setShowOverlay(true));

  const content = isSpanish ? spanishContent : englishContent;

  return (
    <SlideContainer
      lightBg
      lightProgressBar
      hideButton
      className="flex-col items-center text-white"
    >
      <ActivityBox
        icon="pointer"
        className="flex flex-col items-center flex-1 mt-8 pt-8 pb-6"
        instructions={
          <div className="flex flex-col items-center">
            <h2 className="text-lg font-bold">
              {isSpanish
                ? 'Su turno: Ayuda o no ayuda?'
                : 'Your turn: Helpful or Unhelpful?'}
            </h2>
            <p className="text-base leading-5 font-light px-9 my-3 text-left">
              {isSpanish
                ? `Esta es una actividad que le va a ayudar determinar entre 
                  algo que ayuda o no ayuda cuando da direcciones a su hijo/a. 
                  Haga clic en el botón dónde cree que pertenece la oración de ejemplo.`
                : `This is an activity to help you tell the difference between
                  helpful and unhelpful ways to give directions to your child.`}
            </p>
          </div>
        }
      >
        <HelpfulUnhelpfulQuiz
          className="mb-10"
          iconPair="helpful/unhelpful"
          helpfulWord={isSpanish ? 'Ayuda' : 'Helpful'}
          unhelpfulWord={isSpanish ? 'No Ayuda' : 'Unhelpful'}
          title={
            isSpanish
              ? 'Decida si estas maneras de dar direcciones ayudan o no ayudan.'
              : 'Tap the button to choose if the example statement is a Helpful or Unhelpful direction.'
          }
        >
          {Object.keys(value).map((key, idx) => {
            const { question, feedback, helpfulCorrect } = content[idx];
            const valueKey = key as keyof typeof value;
            return (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                questionText={question}
                isHelpfulCorrect={helpfulCorrect}
                onChange={(bool) => setValue({ ...value, [valueKey]: bool })}
                value={value[valueKey]}
                feedback={feedback}
              />
            );
          })}
        </HelpfulUnhelpfulQuiz>
        {minReqAnswered && (
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-56 mb-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine la Actividad' : 'Finish Activity'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};
