import { FC } from 'react';
import { ShoeList, ShoeListItem } from '../../../components/ShoeList/ShoeList';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting9Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish ? 'Relationship building' : 'Construyendo la relación'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Parenting is hard work that can also be fun and rewarding. It’s
            important for parents to build a strong relationship with their
            child.
          </>
        ) : (
          <>
            La crianza es trabajo difícil que también puede ser divertido y
            gratificante. Es importante que los padres construían una
            fuerte/sólida relación con su niño/a.
          </>
        )}
      </p>

      <span className="font-semibold text-red-600 my-5">
        {isEnglish
          ? ' There are 2 steps to building your relationship:'
          : 'Hay dos pasos para construir su relación:'}
      </span>
      <ShoeList>
        <ShoeListItem className="flex flex-col mb-8">
          <h3 className="uppercase font-semibold">
            {isEnglish ? 'Be Sensitive' : 'Sea sensible'}
          </h3>
          {isEnglish
            ? 'Recognize what your child needs in the moment.'
            : 'Reconozca lo que su niño/a necesita en el momento.'}
        </ShoeListItem>
        <ShoeListItem className="flex flex-col">
          <h3 className="uppercase font-semibold">
            {isEnglish ? 'Be Responsive' : 'Sea receptivo/a'}
          </h3>
          {isEnglish
            ? "Anticipate and meet your child's needs."
            : 'Anticipe y responda a las necesidades de su niño/a.'}
        </ShoeListItem>
      </ShoeList>
    </SlideContainer>
  );
};
