import { FC } from 'react';
import { ReactComponent as Star } from '../../images/feather/star.svg';

export enum FillOption {
  RED = 'red',
  WHITE = 'white',
}

export interface StarIconProps {
  className?: string;
  fillOption?: FillOption;
}

export const StarIcon: FC<StarIconProps> = ({
  className = '',
  fillOption = FillOption.RED,
  ...rest
}) => {
  const fillOptionRed = fillOption === FillOption.RED;
  return (
    <div
      {...rest}
      className={`flex justify-center items-center h-6 w-6 rounded-full ${className} ${
        fillOptionRed ? 'bg-red-600' : 'bg-white'
      } `}
    >
      <Star
        className="w-3/4 h-3/4 stroke-0"
        style={{
          marginBottom: '1px',
          fill: fillOptionRed ? 'white' : '#FFD84A',
        }}
      />
    </div>
  );
};
