import { FC, ReactNode } from 'react';
import circleStarImageSrc from '../../images/circle_star.png';
import magnifyingGlassSrc from '../../images/magnifying_glass_info.png';
import pulsingHeartSrc from '../../images/pulsing_heart.png';

const ICON_OPTIONS = {
  star: {
    img: circleStarImageSrc,
    alt: 'circle star',
  },
  magnifyingGlass: {
    img: magnifyingGlassSrc,
    alt: 'magnifying glass',
  },
  pulsingHeart: {
    img: pulsingHeartSrc,
    alt: 'pulsing heart',
  },
};

/**
 * @param {string} [icon="star"] - Defaults to star
 */

export const ExampleSection: FC<{
  className?: string;
  title: ReactNode;
  icon?: keyof typeof ICON_OPTIONS;
}> = ({ className = '', title, icon = 'star', children, ...rest }) => {
  const { img, alt } = ICON_OPTIONS[icon];

  return (
    <div className={`${className}`} {...rest}>
      <div className="flex flex-1 items-center">
        <img
          className={`${icon !== 'star' ? 'w-10' : 'w-6'}`}
          src={img}
          alt={alt}
        />
        {title}
      </div>
      {children}
    </div>
  );
};
