import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare13Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Sleep' : 'Dormir'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish ? (
          <>
            You need at least 7 hours of good quality sleep each night to
            function at your best. Being well-rested helps you to parent in the
            most effective way.
          </>
        ) : (
          <>
            Necesita al menos 7 horas de sueño de buena calidad cada noche para
            funcionar de la mejor manera. Descansar bien le ayuda a ser
            padre/madre de la manera más efectiva.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
