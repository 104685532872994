import { FC } from 'react';
import { SlideHeader } from '../SlideHeader/SlideHeader';
import { Blurb } from '../Blurb/Blurb';
import bearBoySrc from '../../images/bear_boy.png';
import babyBearSrc from '../../images/bear_baby_neutral.png';
import { PillButton } from '../PillButton/PillButton';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useProductKey } from '../../hooks/product/useProductKey';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

const BEAR_BY_PRODUCT: Record<ProductKey, string> = {
  [ProductKey.SCHOOL_AGE]: bearBoySrc,
  [ProductKey.EARLY_CHILDHOOD]: babyBearSrc,
};

export const SurveyWelcomeScreen: FC<{
  className?: string;
  unit: UnitWithSections;
  onBack(): unknown;
  onNext(): unknown;
}> = ({ className = '', unit, onNext, onBack, ...rest }) => {
  const { isSpanish } = useProductLanguage();
  const isFirstUnit = unit.index === 0;
  const welcomeText = isSpanish ? 'Bienvendo' : 'Welcome';
  const productKey = useProductKey();
  return (
    <div
      {...rest}
      className={`flex flex-col min-h-screen pb-8 bg-blue-900 text-white ${className}`}
    >
      <SlideHeader
        unit={unit}
        onBack={onBack}
        title={isFirstUnit ? welcomeText : undefined}
      />
      <div className="px-10">
        <h2 className="font-bold">{isSpanish ? 'CUESTIONARIOS' : 'SURVEYS'}</h2>
        <p className="mt-3">
          {isSpanish
            ? 'Para empezar cada tema, responderá a preguntas para obtener una imagen de su familia en este momento. Esto nos va a enseñar las fortalezas de su familia y las áreas que podrían beneficiar de su atención.'
            : `To start each topic, you will answer questions to get a snapshot of how
            your family is doing right now. This will highlight your family’s
            strengths and any areas that might benefit from your focused attention.`}
        </p>
      </div>
      <div className="flex-1">
        <div
          className="flex flex-row justify-center flex-shrink-0
        w-full h-52 mx-auto rounded-t-lg pt-4 px-4 mt-10 bg-blue-200 overflow-hidden"
        >
          <img src={BEAR_BY_PRODUCT[productKey]} alt="" className="h-64" />
          <Blurb className="p-4">
            {isSpanish
              ? '¿Listo para hacer este cuestionario? '
              : 'Ready to take a survey?'}
          </Blurb>
        </div>
      </div>
      <PillButton
        className="w-48 mt-8 self-center flex-shrink-0"
        onClick={onNext}
      >
        {isSpanish ? 'Empiece' : 'Start'}
      </PillButton>
    </div>
  );
};
