import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_Restate_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={
        isSpanish ? 'Repita con  sentimiento' : 'Restate with feeling'
      }
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Hágale saber a su hijo/a que está realmente interesado/a 
            en lo que le está diciendo por:`
          : `Let your child know you are genuinely interested in what 
            they’re telling you by:`}
      </p>
      <YellowCircleList className="font-bold mt-5">
        <li>{isSpanish ? 'Escuchar los hechos' : 'Listening to the facts.'}</li>
        <li>
          {isSpanish ? 'Nombrando sus sentimientos' : 'Naming their feelings.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
