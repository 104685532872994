import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting18Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Giving directions' : 'Dar instrucciones'}
      className="text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Once your child knows what you expect of them, follow-up by making
            sure they did what you asked for. Remember, expressing your
            appreciation can go a long way to help to reinforce their positive
            behavior.
          </>
        ) : (
          <>
            Una vez que su niño/a sepa lo que espera de él/ella, haga un
            seguimiento asegurándose de que hizo lo que le pidió. Recuerde,
            expresar su aprecio puede ser de gran ayuda para ayudar a reforzar
            su comportamiento positivo.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
