export const situations = [
  {
    id: 1,
    situation: `You’re going to be late getting dinner made and your child is hungry.
      You want them to eat dinner with you. How can you proactively handle
      this situation?`,
    questions: {
      P: {
        feedback:
          "It's getting late and your child is already hungry. Offer them a small snack so you can complete your mealtime routine.",
        choices: [
          {
            id: 'P-1',
            label: 'The problem is your child is hungry.',
            correct: true,
          },
          {
            id: 'P-2',
            label: 'The problem is you are late getting dinner made.',
            correct: true,
          },
          {
            id: 'P-3',
            label: 'The problem is your child is just too impatient.',
            correct: false,
          },
        ],
      },
      L: {
        feedback:
          "Young children can't wait very long for things, especially when they are hungry.",
        choices: [
          {
            id: 'L-1',
            label:
              'Look realistically at how long your child can wait, and prepare a quicker meal.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'It won’t hurt your child to go hungry, and so what if they throw a fit?',
            correct: false,
          },
          {
            id: 'L-3',
            label: 'Stay with your original plan, kids need to learn to wait.',
            correct: false,
          },
        ],
      },
      A: {
        feedback:
          "It's likely best to prepare a simpler meal so you can still eat together.",
        choices: [
          {
            id: 'A-1',
            label:
              'Adjust, give them a small snack while you prepare a quicker dinner.',
            correct: true,
          },
          {
            id: 'A-2',
            label:
              'Adjust activities. This is a good time to allow your child to watch their favorite show before dinner, it helps them to wait longer.',
            correct: true,
          },
          {
            id: 'A-3',
            label:
              'Tell your child to just wait for dinner or there will be consequences.',
            correct: false,
          },
        ],
      },
      N: {
        feedback:
          'The skill you want to teach is patience; notice, praise and sometimes incentivize them when you can.',
        choices: [
          {
            id: 'N-1',
            label: `Notice your child's patience and praise them.`,
            correct: true,
          },
          {
            id: 'N-2',
            label:
              'Notice when your child gets fussy and tell them to stop whining.',
            correct: false,
          },
          {
            id: 'N-3',
            label:
              'Notice and reinforce your child’s waiting by telling them they earned a small dessert after dinner.',
            correct: true,
          },
        ],
      },
    },
  },
  {
    id: 2,
    situation: `You just got off work, picked your child up from daycare and you have
      to go to the grocery store. Your child is tired and you’re concerned
      they may have a tantrum. How could you proactively handle this
      situation?`,
    questions: {
      P: {
        feedback:
          'Sometimes you have to run errands when it’s not a good time for your kids. Telling children what you expect right before going into the store increases the chances of a good shopping trip.',
        choices: [
          {
            id: 'P-1',
            label:
              'The problem is your child is going to have a difficult time during this shopping trip.',

            correct: true,
          },
          {
            id: 'P-2',
            label:
              'The problem is you need to get food for dinner and don’t have time to proactively deal with your kid’s potential behavior.',
            correct: false,
          },
          {
            id: 'P-3',
            label:
              'The problem is you need to stop at the store, and your child is with you.',
            correct: true,
          },
        ],
      },
      L: {
        feedback:
          'It is sometimes hard for children to go with their parents for adult errands. When children are with you, making these trips short and sweet can decrease problem behavior.',
        choices: [
          {
            id: 'L-1',
            label:
              'Look realistically at how long your child can stay on good behavior at the store.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'Look realistically at the fact that your child is always difficult for you, and you have to get food anyway.',
            correct: false,
          },
          {
            id: 'L-3',
            label:
              'Look realistically at what you actually need to get so the shopping trip can be quick.',
            correct: true,
          },
        ],
      },
      A: {
        feedback:
          'Telling children what you expect and offering small incentives is a good adjustment in these situations.',
        choices: [
          {
            id: 'A-1',
            label:
              'Adjust your time at the store, make the shopping trip brief. Tell your child this is an “in and out trip, we’re only getting things for dinner” and they can earn an incentive for helping you do it quickly.',
            correct: true,
          },
          {
            id: 'A-2',
            label:
              'Adjust by preparing your child before going into the store. Tell them how you expect them to behave: “Hands and feet to yourself” “Walk together or stay in the cart” “Inside voices”',
            correct: true,
          },
          {
            id: 'A-3',
            label:
              'If they don’t learn to handle a long shopping trip now, when will they? Threaten a punishment if they don’t behave.',
            correct: false,
          },
        ],
      },
      N: {
        feedback:
          'It’s important to pay attention to the behaviors we want to see more of. Praising your child when they’re cooperating in the store means they are more likely to continue to cooperate.',
        choices: [
          {
            id: 'N-1',
            label:
              'Notice and praise your child’s good behavior, and give your child their incentive when they earn it.',
            correct: true,
          },
          {
            id: 'N-2',
            label:
              'Notice early in the trip how they are cooperating, and praise them as you go.',
            correct: true,
          },
          {
            id: 'N-3',
            label:
              'Watch for and notice their problem behavior, and point it out to them.',
            correct: false,
          },
        ],
      },
    },
  },
  {
    id: 3,
    situation:
      "Your child is playing with toys and you know they won't stop when you tell them it's bedtime.",
    questions: {
      P: {
        feedback:
          'It’s normal for children not to want to end play and go to bed.',
        choices: [
          {
            id: 'P-1',
            label:
              'The problem is your child is enjoying playing with their toys and they won’t want to stop.',
            correct: true,
          },
          {
            id: 'P-2',
            label:
              'The problem is children don’t typically enjoy bedtime even when they are tired.',
            correct: true,
          },
          {
            id: 'P-3',
            label:
              'The problem is your child is always trying to manipulate you to stay up late.',
            correct: false,
          },
        ],
      },
      L: {
        feedback:
          'It’s important to help children during these transitions and think about their developmental ability.',
        choices: [
          {
            id: 'L-1',
            label:
              'Look realistically at how much help your child will need in their bedtime transition.',
            correct: true,
          },
          {
            id: 'L-2',
            label:
              'Look realistically at how much you still have to do tonight and get frustrated with your kid for making this hard.',
            correct: false,
          },
          {
            id: 'L-3',
            label:
              'Look realistically at how much effort this is going to take and just let your child stay up.',
            correct: false,
          },
        ],
      },
      A: {
        feedback:
          'Prompting before transitions lets children know what to expect. Using “when/then” statements can help kids with transitions. Offering choices can help parents stay out of power struggles.',
        choices: [
          {
            id: 'A-1',
            label: `Adjust to help your child with the transition, "When we clean up your toys, then you can choose what story to read."`,
            correct: true,
          },
          {
            id: 'A-2',
            label: `Adjust to prepare your child for transitions because they are hard for young children. Giving a warning is helpful: "You have 3 minutes until it's time to clean up for bedtime."`,
            correct: true,
          },
          {
            id: 'A-3',
            label:
              'Adjust to the reality that this is going to be a fight, it always is. Let your child stay up late watching TV and wearing themselves out, and let it go.',
            correct: false,
          },
        ],
      },
      N: {
        feedback:
          'It’s important to pay attention to the behaviors you want to see more of. Praising your child when they’re helpful during their bedtime routine can lead to easier transitions in the future. Notice your own successes as well, parenting is hard.',
        choices: [
          {
            id: 'N-1',
            label: "Notice your child's efforts, praise and incentivize.",
            correct: true,
          },
          {
            id: 'N-2',
            label:
              'Notice what a pain your child is being and lose your temper.',
            correct: false,
          },
          {
            id: 'N-3',
            label:
              'Notice all the small successes for both you and your child working together through transitions.',
            correct: true,
          },
        ],
      },
    },
  },
];
