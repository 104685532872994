import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import kitchenSrc from '../../../images/FCU_Kitchen.png';
import clipboardSrc from '../../../images/clipboard.png';
import spanishClipboardSrc from '../../../images/clipboard_spanish.png';
import clipSrc from '../../../images/Clip.png';
import { PlusExpandable } from '../../../components/PlusExpandable/PlusExpandable';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { CircleXButton } from '../../../components/CircleXButton/CircleXButton';
import {
  englishHouseholdRules,
  spanishHouseholdRules,
} from '../../../constants/early_childhood/rules_and_consequences_9_rules';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RulesAndConsequences9Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const householdRules = isEnglish
    ? englishHouseholdRules
    : spanishHouseholdRules;

  const [showClipboard, setShowClipboard] = useState(false);
  const [activeRuleId, setActiveRuleId] = useState<number | null>(
    householdRules[0].id,
  );
  const [moveForward, setMoveForward] = useState(false);

  const handleClose = () => {
    setShowClipboard(false);
    setMoveForward(true);
  };

  return (
    <SlideContainer
      className={`flex-col text-white ${showClipboard ? 'px-4' : ''}`}
      hideButton={!moveForward}
    >
      {!showClipboard ? (
        <>
          <span className="px-14 mb-9 font-light">
            {isEnglish
              ? 'Tap the clipboard to learn more about successful household rules.'
              : 'Oprima el portapapeles para obtener más información sobre las reglas del hogar exitosas.'}
          </span>
          <div className="relative">
            <img src={kitchenSrc} alt="kitchen" />
            <div className="absolute top-1/4 right-1/3">
              <button
                className="relative"
                onClick={() => setShowClipboard(true)}
              >
                <img
                  src={isEnglish ? clipboardSrc : spanishClipboardSrc}
                  alt="clipboard"
                  className="w-16 sm:w-22 h-auto transform translate-x-1/2"
                />
              </button>
            </div>
          </div>
        </>
      ) : (
        // ClipBoard
        <div className="relative rounded-xl bg-brown p-3 mt-5 self-center w-full font-light max-w-125">
          {/* Clip */}
          <img
            src={clipSrc}
            alt=""
            className="absolute -top-4 right-0 left-0 mx-auto w-44 z-10"
          />
          {/* Paper */}
          <div className="relative bg-white text-blue-800 px-4 pt-5 pb-8">
            <CircleXButton
              className="absolute top-2 right-2 shadow-lg"
              onClick={handleClose}
            />
            <h2 className="font-boris text-blue-400 text-2xl text-center mb-8">
              {isEnglish ? (
                <>
                  Successful
                  <br />
                  Household Rules:
                </>
              ) : (
                <>
                  Reglas del Hogar
                  <br />
                  Exitosas:
                </>
              )}
            </h2>
            {householdRules.map((rule) => (
              <PlusExpandable
                key={rule.id}
                open={rule.id === activeRuleId}
                headerText={<h3 className="font-bold text-lg">{rule.title}</h3>}
                onExpand={() =>
                  setActiveRuleId((curActiveId) =>
                    curActiveId !== rule.id ? rule.id : null,
                  )
                }
                buttonTheme="dark"
              >
                {rule.text && <p>{rule.text}</p>}
                <ExampleSection
                  className="mt-3"
                  title={
                    <h4 className="font-bold uppercase ml-2">
                      {isEnglish ? 'Example:' : 'Ejemplo:'}
                    </h4>
                  }
                >
                  <p className="ml-7 mt-2">{rule.example}</p>
                </ExampleSection>
              </PlusExpandable>
            ))}
          </div>
        </div>
      )}
    </SlideContainer>
  );
};
