import { FC } from 'react';
import { SlideHeader } from '../SlideHeader/SlideHeader';
import { Blurb } from '../Blurb/Blurb';
import bearGirlSrc from '../../images/bear_girl.png';
import { PillButton } from '../PillButton/PillButton';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useProductKey } from '../../hooks/product/useProductKey';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import youngBearGirlSrc from '../../images/bear_girl_neutral.png';

const BEAR_BY_PRODUCT: Record<ProductKey, string> = {
  [ProductKey.SCHOOL_AGE]: bearGirlSrc,
  [ProductKey.EARLY_CHILDHOOD]: youngBearGirlSrc,
};

export const SurveyPreResultScreen: FC<{
  className?: string;
  unit: UnitWithSections;
  onNext(): unknown;
  onBack(): unknown;
}> = ({ className = '', unit, onNext, onBack, ...rest }) => {
  const { isSpanish } = useProductLanguage();
  const productKey = useProductKey();

  return (
    <div
      {...rest}
      className={`flex flex-col min-h-screen pb-8 bg-blue-900 text-white ${className}`}
    >
      <SlideHeader hideBackButton onBack={onBack} unit={unit} />
      <div className="px-10">
        <h2 className="font-bold">{isSpanish ? 'RESULTADOS' : 'RESULTS'}</h2>
        <p className="mt-3">
          {isSpanish
            ? 'Gracias por responder a esas preguntas. Su participación en este programa demuestra cuanto le importa su crianza.'
            : `Thanks for answering those questions. Participating in this program
              shows how much you care. The results use green, yellow and red to show
              your family’s strengths and areas that would benefit from focused
              attention.`}
        </p>
      </div>
      <div className="flex-1">
        <div
          className="flex flex-row justify-center flex-shrink-0
        w-full h-52 mx-auto rounded-t-lg pt-4 px-4 mt-10 bg-blue-200 overflow-hidden"
        >
          <img src={BEAR_BY_PRODUCT[productKey]} alt="" className="h-64" />
          <Blurb className="p-4">
            {isSpanish
              ? 'Vamos a ver los resultados.'
              : 'Let’s look at your results.'}
          </Blurb>
        </div>
      </div>
      <PillButton
        className="w-48 mt-8 self-center flex-shrink-0"
        onClick={onNext}
      >
        {isSpanish ? 'Siguiente' : 'Next'}
      </PillButton>
    </div>
  );
};
