import { FC, ReactNode } from 'react';
import { noop } from 'shared/lib/utils/noop';
import { PlusMinusIcon } from '../PlusMinusIcon/PlusMinusIcon';

export interface ExpandableBlockProps {
  className?: string;
  headerTitle?: string;
  expanded?: boolean;
  onClick?(): void;
  children: ReactNode;
}

export const ExpandableBlock: FC<ExpandableBlockProps> = ({
  className = '',
  headerTitle = 'Header Title',
  expanded = false,
  children,
  onClick = noop,
  ...rest
}) => (
  <div
    className={`flex flex-col text-blue-800 bg-blue-400 
    rounded-lg w-full cursor-pointer ${className}`}
    onClick={onClick}
    {...rest}
  >
    {/* Block Header */}
    <div className="flex justify-between items-center py-2 pl-3.5">
      <h4 className="font-semibold">{headerTitle}</h4>
      <PlusMinusIcon expanded={expanded} />
    </div>
    {expanded && children}
  </div>
);
