import { useContext, createContext, SetStateAction, Dispatch } from 'react';
import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { missingProvider } from 'shared/lib/utils/errors';

export interface ParticipantProductContextValue {
  productSession: DraftParticipantProductSession;
  setProductSession: Dispatch<SetStateAction<DraftParticipantProductSession>>;
}

export const ParticipantProductContext = createContext<ParticipantProductContextValue | null>(
  null,
);

export function useParticipantProductContext(): ParticipantProductContextValue {
  return (
    useContext(ParticipantProductContext) ??
    missingProvider(
      'useParticipantProductContext',
      'ParticipantProductContext.Provider',
    )
  );
}

export function useParticipantProductContextOptional(): ParticipantProductContextValue | null {
  return useContext(ParticipantProductContext);
}
