import { FC } from 'react';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import sortHouseCorrectSrc from '../../../images/sort_house_correct.png';
import sortHouseIncorrectSrc from '../../../images/sort_house_incorrect.png';

export const ParentingAndSubstanceUse18Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      className="flex-col items-center text-white px-14 font-light"
      slideTitle={isEnglish ? 'Home Safety' : 'Seguridad de hogar'}
      buttonText={isEnglish ? 'Start Sorting' : 'Empezar a ordenar'}
      buttonTheme={ButtonTheme.YELLOW}
    >
      <p>
        {isEnglish
          ? 'Do you know what to do to keep children safe when substances are in your home?'
          : '¿Sabe qué hacer para mantener seguros a los niños cuando hay sustancias en su casa?'}
      </p>
      <p>
        {isEnglish ? (
          <>
            Tap the button to test if you know the do’s and don’ts when it comes
            to substance use when you have children in your home.
          </>
        ) : (
          <>
            Toque el botón para ver si sabe qué hacer y qué no hacer cuando se
            trata del uso de sustancias cuando tiene niños en su hogar.
          </>
        )}
      </p>
      <div className="flex justify-center my-10">
        <div
          className="flex flex-col items-center border-2 font-bold
          border-blue-400 text-blue-400 border-dashed mr-2 
          px-4 py-2.5 flex-shrink-0"
        >
          <h3 className="text-lg">{isEnglish ? 'DO' : 'HACER'}</h3>
          <img
            className="h-32 w-32"
            src={sortHouseCorrectSrc}
            alt="thumbs up house"
          />
        </div>
        <div
          className="flex flex-col items-center border-2 font-bold
          border-blue-400 text-blue-400 border-dashed ml-2 
          px-4 py-2.5 flex-shrink-0"
        >
          <h3 className="text-lg">{isEnglish ? "DON'T" : 'NO HACER'}</h3>
          <img
            className="h-32 w-32"
            src={sortHouseIncorrectSrc}
            alt="thumbs down house"
          />
        </div>
      </div>
    </SlideContainer>
  );
};
