import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Bodyposi_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Positivadad Corporal' : 'Body Positivity'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Cuando los niños atraviesan la pubertad, sus cuerpos cambian.  
          Es posible que su hijo necesite su ayuda para formar hábitos saludables 
          que lo alienten a apreciar su cuerpo en lo que maduran.`
        : `As children go through puberty, their bodies change. Your child may need
          your help to form healthy habits that encourage them to appreciate their
          body as it matures.`}
    </SlideContainer>
  );
};
