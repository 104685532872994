import { FC } from 'react';
import { ButtonTheme } from '../../../components/PillButton/PillButton';
import { QuizIcon } from '../../../components/QuizIcon/QuizIcon';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Directions_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      className="flex-col mt-5 px-8 font-light"
      buttonText={isSpanish ? 'Comienza El Sorteo' : 'Start Sorting'}
      buttonTheme={ButtonTheme.YELLOW}
    >
      <p className="px-7 text-white text-base leading-5">
        {isSpanish
          ? `Toque el botón de comenzar el sorteo para probar si puede diferenciar 
            entre algo que ayuda o no ayuda en la manera que da direcciones o 
            instrucciones a su hijo/a.`
          : `Tap the Start Sorting button to test if you can tell the difference
            between helpful and unhelpful ways of giving directions to your child.`}
      </p>
      {/* Helpful/Unhelpful Container */}
      <div className="flex justify-center mt-20">
        {/* Helpful Container */}
        <div className="p-3 flex flex-col items-center border-2 border-blue-400 border-dashed mr-2">
          <h3 className="uppercase text-lg font-bold text-blue-400">
            {isSpanish ? 'Ayuda' : 'Helpful'}
          </h3>
          <QuizIcon />
        </div>
        {/* Unhelpful Container */}
        <div className="p-3 flex flex-col items-center border-2 border-blue-400 border-dashed ml-2">
          <h3 className="uppercase text-lg font-bold text-blue-400">
            {isSpanish ? 'No Ayuda' : 'Unhelpful'}
          </h3>
          <QuizIcon icon="thumbsDown" />
        </div>
      </div>
    </SlideContainer>
  );
};
