import { FC } from 'react';

export const NotebookPage: FC<{
  className?: string;
  titleText: string;
}> = ({ className = '', titleText, children, ...rest }) => (
  <div
    className="w-75 bg-notebook bg-no-repeat
       bg-cover flex flex-col p-3"
    {...rest}
    style={{ maxHeight: 500 }}
  >
    <h1 className="font-boris text-blue-400 text-center mb-3">{titleText}</h1>
    <div className={`flex pl-7 box-border ${className}`}>{children}</div>
  </div>
);
