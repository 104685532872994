import { useCallback, useMemo } from 'react';
import { UnitActivity } from 'shared/lib/types/UnitActivity';
import { useSlideContext } from '../contexts/slideContext';

export function useActivityState<T>(defaultValue?: T): {
  value: T;
  setValue(value: T): void;
  isReview: boolean;
  hasInteracted: boolean;
  activity: UnitActivity;
} {
  const { setActivityResponse, slide, activityResponses } = useSlideContext();
  const activity: UnitActivity | undefined = slide.activities[0];
  const activityId = activity?.id;
  const response = activityResponses.find(
    (response) => response.activityId === activityId,
  );
  // Only refresh initialResponse when the activity changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialResponse = useMemo(() => response, [activityId]);
  const isReview = useMemo(() => !!initialResponse, [initialResponse]);
  const hasInteracted = initialResponse !== response;

  if (!activity) {
    console.warn(`useActivityState called from a slide with no activities.`);
  }

  const setValue = useCallback(
    (value: unknown) => {
      setActivityResponse({ activityId: activity.id, value });
    },
    [activity.id, setActivityResponse],
  );

  return {
    value: (response?.value ?? defaultValue) as T,
    setValue,
    isReview,
    hasInteracted,
    activity,
  };
}

export function useVideoActivityState(): {
  watched: boolean;
  onEnded(): void;
  isReview: boolean;
  activity: UnitActivity;
} {
  const { value, setValue, isReview, activity } = useActivityState();

  return {
    watched: !!value,
    onEnded: () => setValue({}),
    isReview,
    activity,
  };
}
