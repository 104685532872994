import { FC, ComponentPropsWithoutRef } from 'react';

export const Switch: FC<ComponentPropsWithoutRef<'input'>> = ({
  className = '',
  ...rest
}) => (
  <label
    className={`flex flex-row items-center w-12 h-5 border-blue-400 border-2 rounded-full ${
      rest.disabled || rest.readOnly ? '' : 'cursor-pointer'
    } ${className}`}
  >
    <input {...rest} type="checkbox" className="sr-only" />
    <div
      className={`w-6 h-6 not-sr-only border-blue-400 border-2 bg-blue-200 rounded-full -ml-1 transition-transform ${
        rest.checked ? 'translate-x-7' : 'translate-x-0'
      } transform`}
    ></div>
  </label>
);
