import { PostTestResults } from '../../components/PostTestResults/PostTestResults';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useProductKey } from '../../hooks/product/useProductKey';
import { usePostTestResultsPage } from '../../hooks/product/usePostTestResultsPage';
import { Spinner } from '../../components/Spinner/Spinner';

export const PostTestResultsPage = () => {
  const { language } = useProductLanguage();
  const productKey = useProductKey();
  const { postTestFeedback, onContinueClicked, onReturnLaterClicked, loading } =
    usePostTestResultsPage();

  if (loading) {
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-blue-900">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-blue-900 min-h-screen h-full">
      <PostTestResults
        language={language}
        productKey={productKey}
        postTestFeedback={postTestFeedback}
        onContinueClicked={onContinueClicked}
        onReturnLaterClicked={onReturnLaterClicked}
        className="md:max-w-2xl md:mx-auto flex-shrink-0"
      />
    </div>
  );
};
