import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Relation_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Crezca su relación' : 'Build your relationship'}
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Puede ser difícil mantener una relación positiva con su hijo/a durante sus 
          cambios en actitud y su búsqueda de independencia. A veces ayuda pensar en 
          las cosas que su hijo/a hace bien o que le gusta de él/ella la mayoría 
          del tiempo.`
        : `It can be hard to maintain a positive relationship with your child through
          their changing moods and increased desire for independence. Sometimes it
          helps to think about what your child is good at, or what you like the most
          about them.`}
    </SlideContainer>
  );
};
