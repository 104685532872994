import { FC } from 'react';
import { Mood } from 'shared/lib/types/Mood';
import contentSrc from '../../images/mood-icons/content@2x.png';
import happySrc from '../../images/mood-icons/happy@2x.png';
import sadSrc from '../../images/mood-icons/sad@2x.png';

export interface MoodImageProps {
  mood: Mood;
  highlighted?: boolean;
  className?: string;
  transparentBg?: boolean;
}

export const MoodImage: FC<MoodImageProps> = ({
  mood,
  highlighted = false,
  className = '',
  transparentBg = false,
  ...rest
}) => {
  const { src, alt } = getMoodImageProps(mood);

  return (
    <img
      {...rest}
      className={`w-12 h-12 flex-shrink-0 rounded-full ${
        highlighted ? 'bg-blue-300' : transparentBg ? '' : 'bg-white'
      } ${className}`}
      src={src}
      alt={alt}
    />
  );
};

function getMoodImageProps(mood: Mood): { src: string; alt: string } {
  switch (mood) {
    case Mood.HAPPY:
      return { src: happySrc, alt: 'Happy' };
    case Mood.CONTENT:
      return { src: contentSrc, alt: 'Content' };
    case Mood.SAD:
      return { src: sadSrc, alt: 'Sad' };
  }
}
