import { FC, ComponentPropsWithoutRef } from 'react';
import infoIconSrc from '../../images/info_icon.png';

export const InfoIcon: FC<
  ComponentPropsWithoutRef<'img'> & {
    onIconClick?(): unknown;
  }
> = ({ className = '', onIconClick, ...rest }) => (
  <img
    className={`flex-shrink-0 w-5 h-5 inline-block ${
      onIconClick ? 'cursor-pointer' : ''
    } ${className}`}
    onClick={onIconClick}
    src={infoIconSrc}
    alt="info"
    {...rest}
  />
);
