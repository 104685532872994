import { FC } from 'react';

export const OverheadBlurb: FC<{ className?: string }> = ({
  className = '',
  children,
  ...rest
}) => (
  <div
    className={`w-72 h-48 flex justify-center items-center bg-overhead-blurb bg-no-repeat bg-center bg-cover ${className}`}
    {...rest}
  >
    <p className="pb-5 px-7 text-blue-800 font-extrabold text-xl leading-6 text-center">
      {children}
    </p>
  </div>
);
