import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishContent = [
  {
    question:
      'Since you did poorly on your math test, you will need to finish all your math homework before hanging out with your friends.',
    feedback: 'This is a logical consequence.',
    helpfulCorrect: true,
  },
  {
    question:
      'You can’t play video games for 3 days because you didn’t walk the dog.',
    feedback:
      'This is too big so it is not logical. Instead say, “You don’t get to play video games until you walk the dog.”',
    helpfulCorrect: false,
  },
  {
    question:
      'If you don’t turn off your phone while we eat dinner, I will need to take it away for the rest of the night.',
    feedback: 'This is a logical consequence.',
    helpfulCorrect: true,
  },
  {
    question: 'If you don’t eat your peas, you will lose your phone.',
    feedback:
      'These actions are not related. Instead say, “If you don’t eat your peas, you can’t have dessert with the rest of us.”',
    helpfulCorrect: false,
  },
];

const spanishContent = [
  {
    question:
      'Como te fue mal en tu examen de matemáticas, tendrás que terminar toda tu tarea de matemáticas antes de salir con tus amigos.',
    feedback: 'Esta es una consecuencia lógica.',
    helpfulCorrect: true,
  },
  {
    question:
      'No puedes jugar videojuegos durante 3 días porque no sacaste al perro.',
    feedback:
      'Esto es demasiado fuerte entonces no es lógico. En su lugar, diga: "No puedes jugar videojuegos hasta que saques al perro."',
    helpfulCorrect: false,
  },
  {
    question:
      'Si no apagas tu teléfono mientras cenamos, te lo voy a quitar por el resto de la noche.',
    feedback: 'Esta es una consecuencia lógica.',
    helpfulCorrect: true,
  },
  {
    question: 'Si no comes tus guisantes, perderás tu teléfono.',
    feedback:
      'Estas acciones no están relacionadas. En su lugar, diga: "Si no comes tus guisantes, no puedes comer postre con el resto de nosotros.',
    helpfulCorrect: false,
  },
];

export const RC_Logical_d: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, activity } = useActivityState<
    { [key in `question${1 | 2 | 3 | 4}`]: boolean | null }
  >({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const { isSpanish } = useProductLanguage();

  const minReqAnswered =
    Object.values(value).filter((answer) => answer !== null).length >= 3;

  const handleSubmit = () => (isReview ? next() : setShowOverlay(true));

  const content = isSpanish ? spanishContent : englishContent;

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pointer"
        className="flex flex-col items-center flex-1 mt-8 pt-8 pb-6"
        instructions={
          <div className="flex flex-col items-center mb-5">
            <h2 className="text-lg font-bold">
              {isSpanish
                ? 'Su turno: ¿Lógico o ilógico?'
                : 'Your turn: Logical or Not Logical?'}
            </h2>
          </div>
        }
      >
        <HelpfulUnhelpfulQuiz
          className="mb-10"
          iconPair="logical/illogical"
          title={
            isSpanish
              ? '¿Cuáles de estas consecuencias cree que son lógicas?'
              : 'Which of these consequences do you think are logical?'
          }
          helpfulWord={isSpanish ? 'Lógico' : 'Logical'}
          unhelpfulWord={isSpanish ? 'Ilógico' : 'Not Logical'}
        >
          {(Object.keys(value) as (keyof typeof value)[]).map((key, idx) => {
            const { question, feedback, helpfulCorrect } = content[idx];
            return (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                questionText={question}
                isHelpfulCorrect={helpfulCorrect}
                onChange={(bool) => setValue({ ...value, [key]: bool })}
                value={value[key]}
                feedback={feedback}
                helpfulWord={isSpanish ? 'Lógico' : 'Logical'}
                unhelpfulWord={isSpanish ? 'Ilógico' : 'Not Logical'}
              />
            );
          })}
        </HelpfulUnhelpfulQuiz>
        {minReqAnswered && (
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-56 mb-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine la Actividad' : 'Finish Activity'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};
