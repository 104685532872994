import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Directions_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      buttonText={isSpanish && 'Siguiente'}
      slideTitle={isSpanish ? 'Dar Instrucciones' : 'Giving Directions'}
      className="font-light text-white"
      videoKey={isSpanish ? 'ani_2_spanish' : 'ani_2'}
      bearCharacter="GIRL"
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Cuando le de direcciones a su hijo/a en un forma simple y clara puede 
              mejorar la cooperación, prevenir argumentos, y mejorar las relaciones 
              familiares. Vea ahora cómo es que se hace. `
            : `When you give directions to your child in a simple and clear way you can
              increase cooperation, prevent arguments, and improve family
              relationships. Watch now to see how to do it.`}
        </p>
      }
    />
  );
};
