import { FC } from 'react';
import alertCircleIconSrc from '../../images/alert_circle_icon.png';
import { ThumbsUpIcon } from '../ThumbsUpIcon/ThumbsUpIcon';

const IconOptions = {
  THUMBS_UP: ThumbsUpIcon,
  EXCLAMATION: alertCircleIconSrc,
};

export interface FeedbackProps {
  className?: string;
  icon?: keyof typeof IconOptions;
  hideIcon?: boolean;
}

export const Feedback: FC<FeedbackProps> = ({
  className = '',
  icon = 'THUMBS_UP',
  children,
  hideIcon = false,
  ...rest
}) => (
  <div
    className={`relative flex justify-center items-center py-6 
    px-5 rounded-lg bg-blue-350 text-blue-800 font-semibold 
    text-center ${className}`}
    {...rest}
  >
    {!hideIcon && icon === 'THUMBS_UP' && (
      <ThumbsUpIcon className="absolute -top-5 right-0 left-0 mx-auto" />
    )}
    {!hideIcon && icon === 'EXCLAMATION' && (
      <img
        src={IconOptions[icon]}
        alt={icon}
        className="absolute h-9 w-9 -top-5 right-0 left-0 mx-auto"
      />
    )}
    {children}
  </div>
);
