import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting8Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'Benefits of Family Routines'
          : 'Beneficios de las Rutinas Familiares'
      }
      className="flex-col text-white px-14 font-light"
    >
      {isEnglish ? (
        <>
          Research has shown that children who grow up with consistent routines
          have better emotional control and coping skills as adults.
        </>
      ) : (
        <>
          Las investigaciones han demostrado que los niños que crecen con
          rutinas constantes tienen un mejor control emocional y habilidades de
          afrontamiento cuando son adultos.
        </>
      )}

      <span className="font-semibold my-5">
        {isEnglish ? 'Routines also:' : 'Las rutinas también:'}
      </span>
      <YellowCircleList>
        {isEnglish ? (
          <>
            <li>Provide predictability for your child.</li>
            <li>Assist with consistent rules for transitions.</li>
            <li>Help your child cope with transitions.</li>
            <li>
              Support a healthy lifestyles through regular sleep, meals, and
              exercise.
            </li>
          </>
        ) : (
          <>
            <li>Proporcionan previsibilidad para su hijo/a.</li>
            <li>Ayudan con reglas consistentes para su hijo/a.</li>
            <li>Ayudan a su hijo/a afrontar las transiciones.</li>
            <li>
              Apoyan un estilo de vida saludable a través del sueño, las comidas
              y el ejercicio regulares.
            </li>
          </>
        )}
      </YellowCircleList>
    </SlideContainer>
  );
};
