import { FC, useState, useEffect } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import plantSlide1 from '../../../images/slides/middleschool/healthyBehavior/plantSlide1@2x.png';
import plantSlide2 from '../../../images/slides/middleschool/healthyBehavior/plantSlide2@2x.png';
import plantSlide3 from '../../../images/slides/middleschool/healthyBehavior/plantSlide3@2x.png';
import plantSlide4 from '../../../images/slides/middleschool/healthyBehavior/plantSlide4@2x.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const plantSlides = [plantSlide1, plantSlide2, plantSlide3, plantSlide4];

export const RC_Ignoring_e: FC = () => {
  const [curSlide, setCurSlide] = useState(0);
  const { isSpanish } = useProductLanguage();

  useEffect(() => {
    if (curSlide + 1 < plantSlides.length) {
      const timeout = setTimeout(() => setCurSlide(curSlide + 1), 500);
      return () => clearTimeout(timeout);
    }
  }, [curSlide]);

  return (
    <SlideContainer className="flex-col items-center justify-between font-light text-white px-14">
      {isSpanish
        ? `Usted aprendió que cuando se concentra en el comportamiento positivo 
          de su hijo/a y lo elogia, aumenta la probabilidad de que su hijo/a se 
          comporte de la manera que usted desea. Lo mismo ocurre cuando se 
          concentra en el comportamiento no deseado, ¡otra razón para que sea 
          bueno para ignorar!`
        : `You learned that when you focus on your child’s positive behavior, and
          praise them for it, you increase the likelihood that they will behave the
          way you want them to. The same thing is true when you focus on your
          child’s unwanted behavior—another reason for you to get good at ignoring!`}

      <img
        src={plantSlides[curSlide]}
        alt="house plant"
        className="w-56 mt-10 sm:mb-60"
      />
    </SlideContainer>
  );
};
