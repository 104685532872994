import { FC } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { TextInput } from '../../../components/TextInput/TextInput';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting15Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const { next, viewOnly } = useSlideContext();
  const { value, setValue } = useActivityState({
    afterIResponse: '',
    iWillResponse: '',
  });

  const { afterIResponse, iWillResponse } = value;

  const bothResponses = !!afterIResponse && !!iWillResponse;

  return (
    <SlideContainer
      lightBg
      lightProgressBar
      hideButton
      headerContent={
        <div className="text-blue-800 px-10 mt-10 slide-content">
          <p>{isEnglish ? 'Make it simple, like:' : 'Hagalo simple, como: '}</p>
          <p>
            {isEnglish ? (
              <>
                <b>After I</b> clean up from breakfast each day(tie),
              </>
            ) : (
              <>
                <b>Después</b> de que limpie el desayuno todos los días (la
                conexión, es limpiar el desayuno),
              </>
            )}
          </p>
          <p>
            {isEnglish ? (
              <>
                <b>I will</b> play patty cake with my child for one minute
                (small).
              </>
            ) : (
              <>
                <b>Jugaré</b> con mi hijo/a durante un minuto (jugar durante un
                minuto es empezar poco a poco).
              </>
            )}
          </p>
        </div>
      }
    >
      <ActivityBox
        icon="pencil"
        instructions={
          <h2 className="font-bold text-lg mt-7">
            {isEnglish ? 'Your turn' : 'Su turno:'}
          </h2>
        }
        className="items-center px-4 text-blue-800 flex-1 pb-10"
      >
        <FloatingCard className="w-full flex flex-col p-5 mb-10">
          <div className="flex justify-between items-center gap-x-3">
            <b className="w-20 flex-shrink-0">
              {isEnglish ? 'After I' : 'Después de que yo'}
            </b>
            <div className="flex w-full items-end">
              <TextInput
                className="border-2 border-gray-200 w-full mr-1"
                disabled={viewOnly}
                placeholder={isEnglish ? '(tie)' : '(connectar)'}
                value={afterIResponse}
                onValueChange={(val) =>
                  setValue({
                    ...value,
                    afterIResponse: val,
                  })
                }
              />
              ,
            </div>
          </div>
          <div className="flex justify-between items-center mt-2.5 gap-x-3">
            <b className="w-20 flex-shrink-0 ">
              {isEnglish ? 'I will' : 'Yo voy a'}
            </b>
            <div className="flex w-full items-end">
              <TextInput
                className="border-2 border-gray-200 w-full mr-1"
                disabled={viewOnly}
                placeholder={isEnglish ? '(small)' : '(algo pequeño)'}
                value={iWillResponse}
                onValueChange={(val) =>
                  setValue({
                    ...value,
                    iWillResponse: val,
                  })
                }
              />
              .
            </div>
          </div>
        </FloatingCard>
        {bothResponses && !viewOnly && (
          <PillButton
            onClick={next}
            theme={ButtonTheme.YELLOW}
            className="w-52 mt-auto"
          >
            {isEnglish ? 'Submit' : 'Entregar'}
          </PillButton>
        )}
      </ActivityBox>
    </SlideContainer>
  );
};
