import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { RuleCloud } from '../../../components/RuleCloud/RuleCloud';
import { useSlideContext } from '../../../contexts/slideContext';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';

export const RC_Clear_Rules_c: FC = () => {
  const { next } = useSlideContext();
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <RuleCloud
        className="flex-1"
        cloudStatement={
          isSpanish
            ? 'Pero para sus hijos las reglas se sienten como esto.'
            : 'But to your child, the rules might often feel like this.'
        }
        productKey={ProductKey.SCHOOL_AGE}
      />
      <BottomToast
        onButtonClick={next}
        buttonLabel={isSpanish ? 'Siguiente' : 'Next'}
        animate={false}
      />
    </SlideContainer>
  );
};
