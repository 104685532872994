import { FC } from 'react';

const PointsBadge: FC<{
  points?: number;
  label?: string;
  className?: string;
}> = ({ points, label, className = '', children, ...rest }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center w-32 h-32 bg-contain bg-no-repeat bg-center bg-badge text-white ${className}`}
      {...rest}
    >
      {points && <div className="text-5xl font-bold">{points}</div>}
      {label && (
        <div className="text-2xl font-normal leading-none">{label}</div>
      )}
    </div>
  );
};

export default PointsBadge;
