import { FC } from 'react';
import ReactConfetti from 'react-confetti';
import useWindowSize from '../../hooks/useWindowSize';

export const Confetti: FC<{ className?: string }> = ({
  className = '',
  ...rest
}) => {
  const { width, height } = useWindowSize();

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full
      overflow-hidden pointer-events-none z-40 ${className}`}
      {...rest}
    >
      <ReactConfetti width={width} height={height} />
    </div>
  );
};
