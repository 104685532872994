import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const RC_Logical_f: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      className="font-light text-white"
      bearCharacter="GIRL_WINKING"
      videoKey={isSpanish ? 'ani_6_spanish' : 'ani_6'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? 'Observe a estos padres dar una consecuencia cuando sus hijos no obedecen las reglas.'
            : 'Watch these parents give a consequence for a broken rule.'}
        </p>
      }
    />
  );
};
