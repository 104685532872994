export const letterContent = {
  P: {
    spanishStatement: (
      <span>
        <b className="font-bold">Preste atención</b> e identifique las
        situaciones problemáticas para su hijo/a.
      </span>
    ),
    statement: (
      <span>
        <b className="font-bold">Pay attention</b> and identify the problem
        situations for your child.
      </span>
    ),
    listItem: (
      <li>
        Bedtime, grocery store, transitions from one activity to the next.
      </li>
    ),
    spanishListItem: (
      <li>
        La hora de acostarse, el supermercado, transiciones de una actividad a
        la siguiente.
      </li>
    ),
  },
  L: {
    spanishStatement: (
      <span>
        <b className="font-bold">Mirando de manera realistica</b> las
        habilidades de su niño/a en esa situación.
      </span>
    ),
    statement: (
      <span>
        <b className="font-bold">Look realistically</b> at your child's
        abilities in that situation.
      </span>
    ),
    listItem: (
      <li>
        Should you go into the grocery store with your child after a long busy
        day and expect good behavior?
      </li>
    ),
    spanishListItem: (
      <li>
        ¿Debería ir al supermercado con su hijo/a después de un día largo y
        ocupado y esperar un buen comportamiento?
      </li>
    ),
  },
  A: {
    spanishStatement: (
      <span>
        <b className="font-bold">Ajuste</b> las actividades difíciles para
        maximizar el éxito y minimizar las emociones negativas.
      </span>
    ),
    statement: (
      <span>
        <b className="font-bold">Adjust</b> difficult activities to maximize
        success and minimize negative emotions.
      </span>
    ),
    listItem: (
      <li>
        Give clear commands, warnings, and prompts so that your child
        understands what is expected.
      </li>
    ),
    spanishListItem: (
      <li>
        Dé órdenes claras, advertencias e indicaciones para que su hijo/a
        entienda lo que se espera.
      </li>
    ),
  },
  N: {
    spanishStatement: (
      <span>
        <b className="font-bold">Observe</b> y recuerde impulsar, sugerir y
        recompensar el éxito.
      </span>
    ),
    statement: (
      <span>
        <b className="font-bold">Notice</b> and remember to prompt, suggest, and
        reward success.
      </span>
    ),
    listItem: <li>Great job getting you pajamas on for bed!</li>,
    spanishListItem: <li>¡Buen trabajo agarando tus pijamas para la cama!</li>,
  },
};
