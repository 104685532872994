import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { FC, useMemo, useState } from 'react';
import { LibraryVideoWithVideo } from 'shared/lib/types/LibraryVideo';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import './custom-swiper-styles.css';
import { api } from '../../api';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { useParticipantUser } from '../../contexts/userContext';
import { Video } from '../../components/Video/Video';
import { VideoCategory } from 'shared/lib/types/VideoCategory';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { VideoView } from 'shared/lib/types/VideoView';
import { hasViewedLibraryVideo } from 'shared/lib/utils/video/hasViewedLibraryVideo';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { useProductKey } from '../../hooks/product/useProductKey';
import { ProductKey } from 'shared/lib/constants/product/ProductKey';
import { reverse } from 'shared/lib/utils/reverse';

SwiperCore.use([Pagination]);
const caroselItemWidth = 305;
const caroselItemSpacing = 10;

function getCategoryButtonStyle(left: boolean, selected: boolean) {
  return `h-13 w-1/2 border-4 ${
    left ? 'rounded-tl-full rounded-bl-full' : 'rounded-tr-full rounded-br-full'
  } border-white font-15px px-5 ${
    selected ? 'font-bold bg-white' : 'font-normal bg-transparent'
  }`;
}

export const ParticipantLibraryPage: FC = (props) => {
  const {
    orgProduct: { productId, orgId },
  } = useParticipantUser();
  const [videos, setVideos] = useState<LibraryVideoWithVideo[]>([]);
  const [viewedVideos, setViewedVideos] = useState<
    Pick<VideoView, 'videoId' | 'viewCount'>[]
  >([]);
  const { isSpanish } = useProductLanguage();
  const productKey = useProductKey();
  const isEarlyChildhood = productKey === ProductKey.EARLY_CHILDHOOD;
  const [videoCategory, setVideoCategory] = useState(VideoCategory.BEAR);

  const visibleVideos = useMemo(
    () =>
      reverse(
        videos.filter(({ video }) =>
          !isEarlyChildhood
            ? video.category === VideoCategory.BEAR
            : video.category === videoCategory,
        ),
      ),
    [videos, isEarlyChildhood, videoCategory],
  );

  useDocumentTitle('Library');

  useAsyncEffect(
    async (isCancelled) => {
      const [fetchedVideos, { viewedVideos: viewedVideoList }] =
        await Promise.all([
          api.getLibrary({
            orgId,
            productId,
          }),
          api.getViewedVideos(),
        ]);
      if (!isCancelled()) {
        setVideos(fetchedVideos);
        setViewedVideos(viewedVideoList);
      }
    },
    [productId, orgId],
  );

  // centering of video and style issues on video when visibleVideos length drops below 3.
  // Switch container size and slidesPerVew to a single video
  const variableSlideWidth = visibleVideos.length >= 3 ? 3 : 1;

  return (
    <div
      {...props}
      className="h-screen overflow-hidden bg-blue-700 flex flex-col"
    >
      <ParticipantHeader hideCircle />

      <h2 className="text-blue-400 text-center text-2xl">
        {isSpanish ? 'Videoteca' : 'Video Library'}
      </h2>
      <div className="bg-yellow py-6 flex flex-col mt-4">
        {isEarlyChildhood && (
          <div className="flex flex-row justify-center items-center self-center mb-16 px-2 w-full max-w-150">
            <button
              onClick={() => setVideoCategory(VideoCategory.BEAR)}
              className={getCategoryButtonStyle(
                true,
                videoCategory === VideoCategory.BEAR,
              )}
            >
              {isSpanish ? 'Videos de los Osos' : 'Bear Videos'}
            </button>
            <button
              onClick={() => setVideoCategory(VideoCategory.STRESS_REDUCTION)}
              className={getCategoryButtonStyle(
                false,
                videoCategory === VideoCategory.STRESS_REDUCTION,
              )}
            >
              {isSpanish
                ? 'Video de Reducción de estrés'
                : 'Stress Reduction Videos'}
            </button>
          </div>
        )}
        <div
          className="flex flex-row justify-center self-center mt-4"
          style={{
            width: (caroselItemWidth + caroselItemSpacing) * variableSlideWidth,
          }}
        >
          <Swiper
            key={isEarlyChildhood ? videoCategory : VideoCategory.BEAR}
            slidesPerView={variableSlideWidth}
            spaceBetween={caroselItemSpacing}
            centeredSlides
            pagination={{
              clickable: true,
              el: '.custom-swiper-pagination',
            }}
          >
            {visibleVideos.map((libraryVideo) => {
              const hasViewed = hasViewedLibraryVideo(
                libraryVideo,
                viewedVideos,
              );
              return (
                <SwiperSlide key={libraryVideo.id}>
                  <div
                    className="flex flex-col pb-6"
                    style={{ width: caroselItemWidth }}
                  >
                    <Video
                      videoKey={libraryVideo.video.key}
                      onEnded={(completed) => {
                        if (completed) {
                          const videoView = viewedVideos.find(
                            (view) => view.videoId === libraryVideo.videoId,
                          );

                          if (videoView) {
                            // increment the view count
                            const updatedVideoView = {
                              ...videoView,
                              viewCount: videoView.viewCount + 1,
                            };
                            setViewedVideos(
                              viewedVideos.map((view) =>
                                view === videoView ? updatedVideoView : view,
                              ),
                            );
                          } else {
                            // create a new view
                            setViewedVideos([
                              ...viewedVideos,
                              {
                                videoId: libraryVideo.videoId,
                                viewCount: 1,
                              },
                            ]);
                          }
                        }
                      }}
                      disablePreloading
                    />
                    <div className="text-blue-700 text-sm font-bold text-center mt-2">
                      {libraryVideo.video.title}
                    </div>
                    {hasViewed && (
                      <div className="flex flex-row w-20 items-center justify-center text-center self-center mt-4 bg-blue-200 font-semibold text-sm rounded">
                        {isSpanish ? 'visto' : 'viewed'}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className="custom-swiper-pagination self-center">
        {/* Used by swiper to render the carosel pagination dots */}
      </div>
      <div className="flex flex-col text-white text-xs items-center justify-between self-center h-18 w-72 mt-10">
        <p className="text-center">
          {isSpanish
            ? 'Animación realizada por: 33 Media Group'
            : 'Animation by: 33 Media Group'}
          <br />
          (Melissa Lubofsky and Kim Lindquist)
        </p>
        <p className="text-center">
          {isSpanish
            ? 'Audio realizada por: Digital Distillery Media'
            : 'Audio by: Digital Distillery Media'}
          <br />
          (Lou Swing)
        </p>
      </div>
    </div>
  );
};
