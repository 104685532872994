import { FC } from 'react';
import { ParticipantNavButton } from '../ParticipantNavButton/ParticipantNavButton';
import { ParticipantPointCircle } from '../ParticipantPointCircle/ParticipantPointCircle';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo/Logo';

export const ParticipantHeader: FC<{
  className?: string;
  hideCircle?: boolean;
}> = ({ className = '', hideCircle = false, ...rest }) => (
  <header
    {...rest}
    className={`bg-blue-700 h-16 px-4 ${
      hideCircle ? '' : 'mb-12'
    } ${className}`}
  >
    <div className="flex flex-row justify-between items-center h-full md:mx-auto md:max-w-2xl">
      <h1 className="block flex-grow pt-2">
        <Link to="/">
          <Logo compact className="flex-shrink-0" />
        </Link>
      </h1>

      {hideCircle ? null : <ParticipantPointCircle className="self-start" />}

      <div className="flex flex-row justify-end w-0 flex-grow pt-5">
        <ParticipantNavButton />
      </div>
    </div>
  </header>
);
