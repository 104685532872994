import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting14Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Play' : 'Jugar'}
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            How can you add quality play time with your child into your regular
            routine?
          </>
        ) : (
          <>
            ¿Cómo puede agregar tiempo de juego de calidad con su hijo/a a su
            rutina habitual?
          </>
        )}
      </p>

      <YellowCircleList className="my-5 font-semibold">
        <li>
          {isEnglish
            ? 'Tie it to something you already do each day'
            : 'Conectelo a algo que ya hace todos los días.'}
        </li>
        <li>{isEnglish ? 'Start small' : 'Empieze pequeño'}</li>
      </YellowCircleList>
    </SlideContainer>
  );
};
