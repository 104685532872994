import { FC, useCallback, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { SurveyWithQuestions } from 'shared/lib/types/Survey';
import { SurveyTakeWithDraftResponses } from 'shared/lib/types/SurveyTake';
import { DraftSurveyResponse } from 'shared/lib/types/SurveyResponse';
import { UnitWithSections } from 'shared/lib/types/Unit';
import { SurveyPreResultScreen } from './SurveyPreResultScreen';
import { SurveyQuestionScreen } from './SurveyQuestionScreen';
import { SurveyResultScreen } from './SurveyResultScreen';
import { SurveyWelcomeScreen } from './SurveyWelcomeScreen';
import { isSurveyTakeComplete } from 'shared/lib/utils/surveyUtils';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useProductLanguage } from '../../hooks/useProductLanguage';

/**
 * Handles the routing needed for navigating between the various survey screens.
 * Survey screen order:
 *   - Welcome screen
 *   - 1 or more questions
 *   - Result intro screen
 *   - Result screen
 */
export const SurveyTakeScreen: FC<{
  basePath?: string;
  unit: UnitWithSections;
  survey: SurveyWithQuestions;
  take: SurveyTakeWithDraftResponses;
  onResponseChange(response: DraftSurveyResponse): unknown;
  onClose(): unknown;
  onBack(): unknown;
}> = ({
  basePath = '',
  unit,
  survey,
  take,
  onResponseChange,
  onClose,
  onBack,
}) => {
  const history = useHistory();
  const { isSpanish } = useProductLanguage();
  const takeComplete = useMemo(
    () => isSurveyTakeComplete(survey, take),
    [survey, take],
  );
  // Is `true` if the take was complete when the component first mounts
  const takeInitiallyComplete = useMemo(
    () => takeComplete,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let documentTitle = `${unit.name} Survey`; // Default for the welcome screen
  const questionMatch = useRouteMatch<{ questionNumber: string }>(
    `${basePath}/question/:questionNumber`,
  );
  const resultIntroMatch = useRouteMatch(`${basePath}/result-intro`);
  const resultMatch = useRouteMatch(`${basePath}/result`);
  if (questionMatch) {
    const { questionNumber } = questionMatch.params;
    documentTitle = `${unit.name}, Survey Question ${questionNumber}`;
  } else if (resultIntroMatch || resultMatch) {
    documentTitle = `${unit.name}, Survey Results`;
  }
  useDocumentTitle(documentTitle);

  const handleWelcomeNext = useCallback(() => {
    history.push(`${basePath}/question/1`);
  }, [history, basePath]);

  const handleQuestionNext = useCallback(
    (questionIndex: number) => {
      if (questionIndex === survey.questions.length - 1) {
        // Last question, navigate to the pre-result screen.
        history.push(`${basePath}/result-intro`);
      } else {
        // Navigate to the next question
        history.push(`${basePath}/question/${questionIndex + 2}`);
      }
    },
    [history, basePath, survey.questions.length],
  );

  const handleQuestionBack = useCallback(
    (questionIndex: number) => {
      if (questionIndex === 0) {
        // Last question, navigate to the welcome screen.
        history.push(`${basePath}/welcome`);
      } else {
        // Navigate to the previous question
        history.push(`${basePath}/question/${questionIndex}`);
      }
    },
    [history, basePath],
  );

  const handlePreResultNext = useCallback(() => {
    // Navigate to the result screen
    history.push(`${basePath}/result`);
  }, [history, basePath]);

  const handlePreResultBack = useCallback(() => {
    // Navigate to the last question
    if (!takeComplete) {
      history.push(`${basePath}/question/${survey.questions.length}`);
    }
  }, [basePath, history, survey.questions.length, takeComplete]);

  const handleResultBack = useCallback(() => {
    if (!takeComplete) {
      history.push(`${basePath}/result-intro`);
    }
  }, [history, basePath, takeComplete]);

  return (
    <Switch>
      <Route path={`${basePath}/welcome`} exact>
        <SurveyWelcomeScreen
          unit={unit}
          onNext={handleWelcomeNext}
          onBack={onBack}
        />
      </Route>
      <Route path={`${basePath}/question/:questionNumber`}>
        {({ match }) => {
          const questionNumber = match?.params.questionNumber
            ? +match.params.questionNumber
            : null;
          if (!questionNumber) {
            return null;
          }

          const question = survey.questions.find(
            (question) => question.index === questionNumber - 1,
          );

          if (!question) {
            // Invalid question number. Redirect to the welcome screen.
            return <Redirect to={`${basePath}/`} />;
          }

          return (
            <SurveyQuestionScreen
              survey={survey}
              question={question}
              value={
                take.responses.find(
                  ({ questionId }) => questionId === question.id,
                )?.value ?? null
              }
              onChange={(value) =>
                onResponseChange({ questionId: question.id, value })
              }
              onNext={() => handleQuestionNext(question.index)}
              onBack={() => handleQuestionBack(question.index)}
            />
          );
        }}
      </Route>
      <Route path={`${basePath}/result-intro`}>
        {takeComplete ? (
          <SurveyPreResultScreen
            unit={unit}
            onNext={handlePreResultNext}
            onBack={handlePreResultBack}
          />
        ) : (
          <Redirect to={`${basePath}/`} />
        )}
      </Route>
      <Route path={`${basePath}/result`}>
        {takeComplete ? (
          <SurveyResultScreen
            onBack={handleResultBack}
            onNext={onClose}
            survey={survey}
            take={take}
            closeButtonText={
              takeInitiallyComplete ? (isSpanish ? 'Hecho' : 'Done') : undefined
            }
            unit={unit}
          />
        ) : (
          <Redirect to={`${basePath}/`} />
        )}
      </Route>
      <Route path={`${basePath}/`}>
        {({ match }) => {
          if (!match) {
            return;
          }

          if (takeComplete) {
            return <Redirect to={`${basePath}/result`} />;
          } else if (take.responses.length > 0) {
            const firstUnansweredQuestion = survey.questions.find(
              (question) =>
                !take.responses.some(
                  (response) => response.questionId === question.id,
                ),
            );

            return (
              <Redirect
                to={`${basePath}/question/${
                  (firstUnansweredQuestion?.index ?? 0) + 1
                }`}
              />
            );
          } else {
            return <Redirect to={`${basePath}/welcome`} />;
          }
        }}
      </Route>
    </Switch>
  );
};
