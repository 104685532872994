import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { WhenThenList } from '../../../components/WhenThenList/WhenThenList';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { LetterOption } from '../../../components/RadioLetterOption/RadioLetterOption';
import { QuadTuple } from 'shared/lib/types/QuadTuple';
import {
  englishScenarios,
  spanishScenarios,
} from '../../../constants/early_childhood/rules_and_consequences_16_scenarios';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';

export const RulesAndConsequences16Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentSituationIndex, setCurrentSituationIndex] = useState(0);
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();
  const { value, setValue, activity, isReview } = useActivityState<
    Record<string, LetterOption>
  >({});

  const scenarios = isEnglish ? englishScenarios : spanishScenarios;
  const { id, question, choices } = scenarios[currentSituationIndex];

  const correctChoice = Object.values(choices).find((choice) => choice.correct);

  const endOfScenarios = currentSituationIndex + 1 === scenarios.length;

  const handleAnswer = (answer: string) => {
    const answerId = Object.entries(choices).find(
      ([, choice]) => choice.text === answer,
    )?.[0] as LetterOption;

    setValue({
      ...value,
      [id.toString()]: answerId!,
    });
  };

  const onPillButtonClick = () => {
    if (!endOfScenarios) {
      setCurrentSituationIndex((curIndex) => curIndex + 1);
    } else {
      if (isReview) {
        next();
      } else {
        setShowOverlay(true);
      }
    }
  };

  const options = Object.values(choices).map(
    (choice) => choice.text,
  ) as QuadTuple<string>;

  const selectedOption = value[id]
    ? choices[value[id] as LetterOption].text
    : undefined;

  return (
    <SlideContainer lightBg lightProgressBar hideButton>
      <ActivityBox
        className="flex-1 items-center pb-6 text-blue-800"
        icon="pointer"
        instructions={
          <div className="text-center text-base px-8 mt-8 mb-10">
            <h2 className="text-lg mb-2.5">
              {isEnglish ? 'Your turn:' : 'Su turno:'}
            </h2>
            <p>
              {isEnglish ? (
                <>
                  Think about your clear rules, then see if you can match the
                  following When/Then commands correctly.
                </>
              ) : (
                <>
                  Piense en sus reglas claras, luego vea si puede hacer
                  coincidir las siguientes oraciones cuando/entonces
                  correctamente.
                </>
              )}
            </p>
          </div>
        }
      >
        <WhenThenList
          className="mb-10"
          options={options}
          whenStatement={question}
          onChange={handleAnswer}
          selected={selectedOption}
          correctOptions={[correctChoice!.text]}
          language={isEnglish ? 'english' : 'spanish'}
        />
        {!!selectedOption && (
          <PillButton
            className="w-52 mt-auto"
            theme={ButtonTheme.YELLOW}
            onClick={onPillButtonClick}
          >
            {isEnglish ? 'Continue' : 'Siga'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="YOUNG_GIRL"
      />
    </SlideContainer>
  );
};
