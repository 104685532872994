import { FC } from 'react';
import { RC_Monitoring_a } from './RC_Monitoring_a';
import { RC_Monitoring_b } from './RC_Monitoring_b';
import { RC_Monitoring_c } from './RC_Monitoring_c';
import { RC_Monitoring_d } from './RC_Monitoring_d';
import { RC_Monitoring_e } from './RC_Monitoring_e';
import { RC_Monitoring_f } from './RC_Monitoring_f';
import { RC_Clear_Rules_a } from './RC_Clear_Rules_a';
import { RC_Clear_Rules_b } from './RC_Clear_Rules_b';
import { RC_Clear_Rules_c } from './RC_Clear_Rules_c';
import { RC_Clear_Rules_d } from './RC_Clear_Rules_d';
import { RC_Clear_Rules_e } from './RC_Clear_Rules_e';
import { RC_Clear_Rules_f } from './RC_Clear_Rules_f';
import { RC_Clear_Rules_g } from './RC_Clear_Rules_g';
import { RC_Logical_a } from './RC_Logical_a';
import { RC_Logical_b } from './RC_Logical_b';
import { RC_Logical_d } from './RC_Logical_d';
import { RC_Logical_e } from './RC_Logical_e';
import { RC_Logical_f } from './RC_Logical_f';
import { RC_Logical_g } from './RC_Logical_g';
import { RC_Ignoring_a } from './RC_Ignoring_a';
import { RC_Ignoring_b } from './RC_Ignoring_b';
import { RC_Ignoring_d } from './RC_Ignoring_d';
import { RC_Ignoring_e } from './RC_Ignoring_e';

export const rulesAndConsequencesSlides: Record<string, FC> = {
  'R&C Monitoring_a': RC_Monitoring_a,
  'R&C Monitoring_b': RC_Monitoring_b,
  'R&C Monitoring_c': RC_Monitoring_c,
  'R&C Monitoring_d': RC_Monitoring_d,
  'R&C Monitoring_e': RC_Monitoring_e,
  'R&C Monitoring_f': RC_Monitoring_f,
  'R&C ClearRules_a': RC_Clear_Rules_a,
  'R&C ClearRules_b': RC_Clear_Rules_b,
  'R&C ClearRules_c': RC_Clear_Rules_c,
  'R&C ClearRules_d': RC_Clear_Rules_d,
  'R&C ClearRules_e': RC_Clear_Rules_e,
  'R&C ClearRules_f': RC_Clear_Rules_f,
  'R&C ClearRules_g': RC_Clear_Rules_g,
  'R&C LogicalConsequences_a': RC_Logical_a,
  'R&C Logical_b': RC_Logical_b,
  'R&C LogicalConsequences_d': RC_Logical_d,
  'R&C LogicalConsequences_e': RC_Logical_e,
  'R&C LogicalConsequences_f': RC_Logical_f,
  'R&C LogicalConsequences_g': RC_Logical_g,
  'R&C Ignoring_a': RC_Ignoring_a,
  'R&C Ignoring_b': RC_Ignoring_b,
  'R&C Ignoring_d': RC_Ignoring_d,
  'R&C Ignoring_e': RC_Ignoring_e,
};
