import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting1Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={
        isEnglish
          ? 'What is positive parenting?'
          : '¿Qué es la crianza positiva?'
      }
      className="flex-col text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            Positive parenting is a way of interacting with your child that
            helps you focus on the positive behaviors your child is doing,
            instead of the negative.
          </>
        ) : (
          <>
            La crianza positiva es una manera de interactuar con su niño/a que
            ayuda a enfocarse en los comportamientos positivos que está haciendo
            su niño/a, en vez de lo negativo.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
