import { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { formatPhoneNumberPretty } from 'shared/lib/utils/formatPhoneNumber';
import { isBlank } from 'shared/lib/utils/isBlank';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { ParticipantPlanLink } from '../../components/ParticipantPlanLink/ParticipantPlanLink';
import {
  ButtonTheme,
  PillButton,
} from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { useParticipantUser } from '../../contexts/userContext';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { compressImage } from '../../utils/compressImage';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const ParticipantAccountPage: FC = (props) => {
  const { user, updateProfile } = useParticipantUser();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [profilePicture, setProfilePicture] = useState<File | string | null>(
    user.profilePicture,
  );
  const { isSpanish } = useProductLanguage();

  useDocumentTitle(`${formatFirstLast(user)} Account Details`);
  const canSubmit =
    (password.length > 0 && passwordConfirm.length > 0) ||
    profilePicture !== user.profilePicture;
  const profilePictureSrc = useMemo(() => {
    if (typeof profilePicture === 'string') {
      return profilePicture;
    }
    if (profilePicture) {
      return URL.createObjectURL(profilePicture);
    }
    return profilePicture;
  }, [profilePicture]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const fields: Parameters<typeof updateProfile>[0] = {};

      try {
        if (password || passwordConfirm) {
          if (
            (!isBlank(password) || !isBlank(passwordConfirm)) &&
            password !== passwordConfirm
          ) {
            throw new Error("Passwords don't match.");
          }
          fields.password = password;
        }

        if (profilePicture === null) {
          fields.profilePictureFile = profilePicture;
        } else if (profilePicture instanceof File) {
          fields.profilePictureFile = await compressImage(profilePicture, {
            maxHeight: 300,
            maxWidth: 300,
          });
        }

        setSubmitting(true);
        await updateProfile(fields);
        setError(null);
        setSuccess(true);
        setPassword('');
        setPasswordConfirm('');
      } catch (error) {
        setError(error);
        setSuccess(false);
      } finally {
        setSubmitting(false);
      }
    },
    [updateProfile, password, passwordConfirm, profilePicture],
  );

  return (
    <div {...props} className="min-h-screen pb-5">
      <ParticipantHeader />

      <form
        onSubmit={handleSubmit}
        className="flex flex-col px-3 md:mx-auto md:max-w-md flex-shrink-0 mt-16"
      >
        <div className="flex flex-row justify-end items-center">
          <ParticipantPlanLink />
        </div>
        <div className="flex flex-col rounded-lg bg-blue-600 border-blue-700 border text-white p-8 pr-12 mt-2">
          <div className="flex flex-col w-full self-center">
            {!!error && (
              <div className="mb-4 text-center text-rose-500">
                {error.message}
              </div>
            )}
            {success && (
              <div className="mb-4 text-center text-green-500">
                {isSpanish ? 'Guardado exsitosamente.' : 'Saved successfully.'}
              </div>
            )}
            <div className="flex flex-row">
              <div className="flex-1">
                <label className="text-blue-150">
                  {isSpanish ? 'Primer Nombre' : 'First Name'}:
                </label>
                <div>{user.firstName}</div>
              </div>
              <div>
                <label className="text-blue-150">
                  {isSpanish ? 'Apellido' : 'Last Name'}:
                </label>
                <div>{user.lastName}</div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <div>
                <label className="text-blue-150">
                  {isSpanish ? 'Teléfono' : 'Phone'}:
                </label>
                <div>
                  {user.phone ? formatPhoneNumberPretty(user.phone) : 'N/A'}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <div>
                <label className="text-blue-150">Email:</label>
                <div>{user.email}</div>
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-blue-150">
                {isSpanish ? 'Nueva Contraseña' : 'New Password'}:
              </label>
              <TextInput
                value={password}
                onValueChange={setPassword}
                type="password"
                autoComplete="new-password"
                className="mt-1"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-blue-150">
                {isSpanish ? 'Confirmar Contraseña' : 'Confirm Password'}:
              </label>
              <TextInput
                value={passwordConfirm}
                onValueChange={setPasswordConfirm}
                type="password"
                autoComplete="new-password"
                className="mt-1"
              />
            </div>
            {profilePictureSrc && (
              <img
                src={profilePictureSrc}
                alt=""
                className="w-48 h-48 rounded-full flex-shrink-0 object-cover self-center mt-8"
              />
            )}
            <label className="cursor-pointer text-blue-400 mt-4">
              {isSpanish ? 'subir nueva foto' : 'upload new photo'}
              <input
                onChange={(event) => {
                  const file = event.currentTarget?.files?.[0];
                  if (file) {
                    setProfilePicture(file);
                  }
                }}
                type="file"
                className="sr-only"
                accept="image/*"
              />
            </label>
            {!!profilePicture && (
              <button
                onClick={() => setProfilePicture(null)}
                className="text-left w-auto text-blue-400 mt-4"
              >
                {isSpanish ? 'quitar foto' : 'remove photo'}
              </button>
            )}
          </div>
        </div>
        <PillButton
          className="w-48 self-center mt-8"
          theme={ButtonTheme.LIGHT_BLUE}
          disabled={submitting || !canSubmit}
        >
          {isSpanish ? 'Guardar' : 'Save'}
        </PillButton>
      </form>
    </div>
  );
};
