import { FC, useCallback, useEffect } from 'react';
import { UnitList } from '../../components/UnitList/UnitList';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { ParticipantHeader } from '../../components/ParticipantHeader/ParticipantHeader';
import { ParticipantPlanLink } from '../../components/ParticipantPlanLink/ParticipantPlanLink';
import { Mood } from 'shared/lib/types/Mood';
import { MoodInput } from '../../components/MoodInput/MoodInput';
import { api } from '../../api';
import { useParticipantUser } from '../../contexts/userContext';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { Link, useLocation } from 'react-router-dom';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const UnitListPage: FC = () => {
  const {
    participant: { id: participantId },
  } = useParticipantUser();
  const { productSession, setProductSession } = useParticipantProductContext();
  const mood = productSession.currentMood ?? null;
  const setMood = useCallback(
    (newMood: Mood | null) => {
      setProductSession((session) => ({ ...session, currentMood: newMood }));
    },
    [setProductSession],
  );
  useDocumentTitle('Units');
  const location = useLocation();
  const { isSpanish } = useProductLanguage();

  /**
   * Save mood changes.
   */
  useEffect(() => {
    if (mood) {
      api
        .saveParticipantMood({ mood, participantId })
        .catch((error) => console.error(`Failed to save mood`, error));
    }
  }, [mood, participantId]);

  return (
    <div className="min-h-screen pb-5">
      <ParticipantHeader />

      {/* Plan link and unit list */}
      <div className="flex flex-col px-3 md:mx-auto md:max-w-2xl flex-shrink-0">
        <div className="flex flex-row justify-end items-center">
          <ParticipantPlanLink />
        </div>
        <UnitList
          // Remount when switching products
          key={productSession.orgProduct.productId}
          className="mt-4"
          productSession={productSession}
        />

        <div className="font-bold text-center mt-4">
          {isSpanish ? '¿Cómo estás hoy?' : 'How are you doing today?'}
        </div>
        <MoodInput value={mood} onChange={setMood} className="mt-4" />
      </div>

      <p className="text-center text-xs text-blue-600 flex-shrink-0 font-medium mt-8">
        {isSpanish ? (
          <>
            Family Check-up® es una marca registrada
            <br />
            de la Universidad de Oregón
          </>
        ) : (
          <>
            Family Check-up® is a Registered Trademark
            <br />
            of the University of Oregon
          </>
        )}
      </p>
      <p className="text-center">
        <Link
          to={{
            pathname: location.pathname,
            search: '?privacy-policy',
          }}
          className="text-blue-400 text-xs"
        >
          {isSpanish ? 'política de privacidad ' : 'privacy policy'}
        </Link>
      </p>
    </div>
  );
};
