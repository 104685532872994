import { MythOrFactProblem } from 'shared/lib/types/MythOrFactProblem';

export const parentingAndSubstanceAbuseMythOrFactProblems: MythOrFactProblem[] =
  [
    {
      id: 'problem-1',
      englishStatement:
        'Substance use impacts people despite their socioeconomic status or backgrounds.',
      spanishStatement:
        'El uso de sustancias afecta a las personas a pesar de su nivel socioeconómico o antecedentes.',
      correct: 'fact',
      englishFeedback:
        ' This is a fact. Many people believe that substance abuse is only a problem in low-income populations. But actually, substance abuse affects people from every income level.',
      spanishFeedback:
        'Esto es un hecho. Muchas personas creen que el abuso de sustancias es solo un problema en las poblaciones de bajos ingresos. Pero en realidad, el abuso de sustancias afecta a personas de todos los niveles de ingresos.',
    },
    {
      id: 'problem-2',
      englishStatement:
        'The effects of substances can have a long-term impact on people’s brain and body function.',
      spanishStatement:
        'Los efectos de las sustancias pueden tener un impacto a largo plazo en el funcionamiento del cerebro y el cuerpo de las personas.',
      correct: 'fact',
      englishFeedback:
        'This is a fact. The changes caused by substance use can alter people’s brain function and body sensations for a long period of time.',
      spanishFeedback:
        'Esto es un hecho. Los cambios causados ​​por el consumo de sustancias pueden alterar la función cerebral y las sensaciones corporales de las personas durante un largo período de tiempo.',
    },
    {
      id: 'problem-3',
      englishStatement:
        'Prescription opioids are safer than "street drugs" because physicians prescribe them all the time.',
      spanishStatement:
        'Los opioides recetados son más seguros que las "drogas callejeras" porque los médicos los recetan todo el tiempo.',
      correct: 'myth',
      englishFeedback:
        'This is a myth. "Street drugs" and prescription opioids frequently have the exact same addictive properties and some prescription drugs can even be worse.',
      spanishFeedback:
        'Esto es un mito. Las "drogas de la calle" y los opioides recetados con frecuencia tienen exactamente las mismas propiedades adictivas y algunos medicamentos recetados pueden ser incluso peores.',
    },
    {
      id: 'problem-4',
      englishStatement:
        'Binge drinking is defined as more than 10 drinks in one night.',
      spanishStatement:
        'El consumo excesivo de alcohol se define como más de 10 tragos en una noche.',
      correct: 'myth',
      englishFeedback:
        'This is a myth. Actually, binge drinking is defined as consuming a great amount of alcohol in a short period of time. In a two-hour period, if a woman has four or more drinks, or a man has five or more drinks, they are binge drinking.',
      spanishFeedback:
        'Esto es un mito. En realidad, el consumo excesivo de alcohol se define como consumir una gran cantidad de alcohol en un corto período de tiempo. En un período de dos horas, si una mujer toma cuatro o más tragos, o un hombre toma cinco o más tragos, están bebiendo en exceso.',
    },
    {
      id: 'problem-5',
      englishStatement:
        ' Most people use marijuana at least a couple times a week.',
      spanishStatement:
        'La mayoría de la gente usa marihuana al menos un par de veces a la semana.',
      correct: 'myth',
      englishFeedback:
        'This is a myth. The numbers are actually much smaller: About 22% for people aged 18-25 years, and only 9% of people older than 25 years used marijuana in the past month.',
      spanishFeedback:
        ' Esto es un mito. En realidad, las cifras son mucho más pequeñas: alrededor del 22 % de las personas de 18 a 25 años, y solo el 9 % de las personas mayores de 25 años consumieron marihuana en el último mes.',
    },
    {
      id: 'problem-6',
      englishStatement:
        'After people have children, their use of substances tends to go down?',
      spanishStatement:
        'Después de que las personas tienen hijos, ¿su consumo de sustancias tiende a disminuir?',
      correct: 'fact',
      englishFeedback: 'This is a fact.',
      spanishFeedback: 'Esto es un hecho.',
    },
  ];
