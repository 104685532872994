import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import depressedPersonSrc from '../../../images/depressed_person.png';
import scatteredPillsSrc from '../../../images/scattered_pills.png';
import childWithArmsCrossedSrc from '../../../images/child_with_arms_crossed.png';
import childWithSnowmanToySrc from '../../../images/child_with_snowman_toy.png';
import crawlingBabySrc from '../../../images/crawling_baby.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse17Slide: FC = () => {
  const { isEnglish } = useProductLanguage();

  return (
    <SlideContainer
      className="flex-col text-white px-14"
      slideTitle={
        isEnglish ? 'Parenting Responsibilities' : 'Responsabilidad de crianza'
      }
    >
      <p>
        {isEnglish
          ? 'If you said “Yes” to any of the statements listed, you’re not alone.'
          : 'Si dijo "Sí" a cualquiera de las declaraciones enumeradas, no está solo/a.'}
      </p>
      <div className="flex flex-col mt-5 mb-16">
        <div className="flex justify-between">
          <img src={depressedPersonSrc} className="w-15 h-15" alt="" />
          <img src={scatteredPillsSrc} className="w-15 h-15" alt="" />
          <img src={childWithArmsCrossedSrc} className="w-15 h-15" alt="" />
        </div>
        <div className="flex justify-around mt-2">
          <img src={childWithSnowmanToySrc} className="w-15 h-15 ml-5" alt="" />
          <img src={crawlingBabySrc} className="w-15 h-15 mr-5" alt="" />
        </div>
      </div>
      <b>
        {isEnglish
          ? 'To take care of your child in the best way you can, try to:'
          : 'Para cuidar a su hijo de la mejor manera que pueda, trate de:'}
      </b>
      <YellowCircleList className="mt-5">
        <li className="mb-1.5">
          {isEnglish
            ? 'Use substances when a second caregiver is available to care for your children.'
            : 'Solo usar sustancias si hay un segundo cuidador/guardian disponible para cuidar a sus hijos.'}
        </li>
        <li className="mb-1.5">
          {isEnglish
            ? 'Surround yourself with people who support you not using substances while you are parenting.'
            : 'Rodéarse de personas que le ayuden a no consumir sustancias mientras esta cuidando de sus niños.'}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
