import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ParentingAndSubstanceUse5Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      className="flex-col text-white px-14 font-light"
      slideTitle={isEnglish ? 'Managing Cravings' : 'Menejar los antojos'}
    >
      <p>
        {isEnglish
          ? 'A craving is an intense desire to use a substance.'
          : 'Un antojo es un deseo intenso de usar una sustancia.'}
      </p>
      <p>
        {isEnglish ? (
          <>
            Everyone experiences cravings; they are completely normal. Sometimes
            a craving can be so strong that it completely overwhelms you, and
            you feel like you can’t resist.
          </>
        ) : (
          <>
            Todo el mundo experimenta antojos; son completamente normales. A
            veces, un antojo puede ser tan fuerte que le abruma por completo y
            siente que no puede resistirse.
          </>
        )}
      </p>
    </SlideContainer>
  );
};
