import { FC, ReactNode } from 'react';
import { Spinner } from '../Spinner/Spinner';

export interface SpinnerOverlayProps {
  message?: ReactNode;
  fadeIn?: boolean;
  className?: string;
}

export const SpinnerOverlay: FC<SpinnerOverlayProps> = ({
  message,
  fadeIn = true,
  className = '',
  ...rest
}) => (
  <div
    {...rest}
    className={`absolute left-0 top-0 w-full h-full flex flex-col justify-center items-center bg-white z-20 ${
      fadeIn ? 'fade-in animation-delay-1500' : ''
    } ${className}`}
  >
    <div className="flex flex-col justify-center items-center">
      <Spinner />
      {!!message && (
        <p className="mt-4 text-sm text-blue-800 font-light opacity-80">
          {message}
        </p>
      )}
    </div>
  </div>
);
