import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const SSS_Morning_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Rutinas de la mañana' : 'Morning Routines'}
      className="flex-col px-14 font-light text-white"
    >
      <p className="mb-6">
        {isSpanish
          ? `Para que los niños hagan buen trabajo en la escuela ellos necesitan llegar a tiempo y completar su trabajo. Llegar a la escuela a la hora es una manera que Ud. puede ayudar a su hijo/a tener un día exitoso.`
          : `In order for children to do well in school, they need to get there on time
            and complete their work. Getting to school on time is one way you can help
            to set your child up for a successful day.`}
      </p>
      <p>
        {isSpanish
          ? `Las rutinas en la mañana pueden ser difíciles porque pueden involucrar mucho más que solo levantarse de la cama y ir a la escuela.levantarse de la cama e ir a la escuela.`
          : `Morning routines can be hard because they usually involve much more than
            just getting out of bed and going to school.`}
      </p>
    </SlideContainer>
  );
};
