export const englishIsOrNotProactiveQuiz = {
  pinkOrPurpleShirtToday: {
    answer: true,
    question: 'You can pick your pink or purple shirt to wear today.',
    feedback: 'Proactive Parenting: Giving your child choices.',
  },
  leaveIn5Minutes: {
    answer: true,
    question: 'In 5 minutes, we are going to leave and go to the store.',
    feedback: 'Proactive Parenting: Giving your child a time limit.',
  },
  stopHittingSister: {
    answer: false,
    question: 'Stop hitting your sister!',
    feedback: 'This is not an example of proactive parenting.',
  },
  pickUpLegos: {
    answer: true,
    question:
      "I like how you cleaned up the legos, let's see how fast you can pick up the crayons!",
    feedback: 'Proactive Parenting: Giving your child a time limit.',
  },
};

export const spanishIsOrNotProactiveQuiz = {
  pinkOrPurpleShirtToday: {
    answer: true,
    question: 'Puedes elegir tu camisa rosa o morada para usar hoy.',
    feedback: 'Crianza Proactiva: Darle opciones a su hijo/a.',
  },
  leaveIn5Minutes: {
    answer: true,
    question: 'En 5 minutos, nos vamos a ir a la tienda.',
    feedback: 'Crianza Proactiva: Darle a su hijo/a un límite de tiempo.',
  },
  stopHittingSister: {
    answer: false,
    question: '¡Deja de pegarle a tu hermana!',
    feedback: 'Este no es un ejemplo de crianza proactiva.',
  },
  pickUpLegos: {
    answer: true,
    question:
      'Me gusta cómo limpiaste los legos, ¡veamos qué tan rápido puedes recoger los crayones!',
    feedback: 'Crianza proactiva: darle a su hijo/a un límite de tiempo.',
  },
};
