import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishList = [
  `Set a consistent time of day when your child can focus on their schoolwork.`,
  'Ensure their environment is free from distractions.',
  'Check-in with them to provide help and see how they are doing.',
];

const spanishList = [
  `Ponga una hora consistente en el día para que su hijo/a pueda 
   concentrarse en su trabajo de escuela.`,
  'Asegúrese de que el ambiente esté libre de distracciones.',
  'Cheque con él/ella para darle ayuda o ver cómo le va.',
];

export const SSS_DuringAfter_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  const list = isSpanish ? spanishList : englishList;

  return (
    <SlideContainer
      slideTitle={
        isSpanish
          ? 'Apoyo y Monitoreo del Trabajo Escolar'
          : 'Support and Monitor Schoolwork'
      }
      className="flex-col px-14 font-light text-white"
    >
      <p>
        {isSpanish
          ? `Un poco de planificación puede ayudar a su hijo/a a tener 
            éxito al hacer las tareas escolares en casa.`
          : `A little bit of planning can help your child succeed when doing
            schoolwork at home.`}
      </p>
      <YellowCircleList className="py-6">
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </YellowCircleList>
    </SlideContainer>
  );
};
