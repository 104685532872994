import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_l: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      // Slide title is only present in Spanish version
      slideTitle={
        isSpanish ? 'Pantallas como herramientas positivas' : undefined
      }
      className=" flex flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Ha aprendido estrategias para apoyar la salud y el bienestar general de 
          su hijo/a y mejorar su relación familiar. Estas nuevas habilidades pueden 
          ayudar a su familia a navegar  tiempos estresantes con éxito.`
        : `You’ve learned strategies to support the overall health and well-being of
          your child, and improve your family relationship. These new skills can help
          your family successfully navigate stressful times.`}
    </SlideContainer>
  );
};
