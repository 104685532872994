import { createContext, useContext } from 'react';
import { User } from 'shared/lib/types/User';
import { UserRole, UserRoleKind } from 'shared/lib/types/UserRole';
import { throwError } from 'shared/lib/utils/errors';
import { OrgParticipant } from 'shared/lib/types/OrgParticipant';
import { OrgProduct } from 'shared/lib/types/OrgProduct';
import { Product } from 'shared/lib/types/Product';
import { GetUserManual } from 'shared/lib/types/getUserManual';
import { api } from '../api';

export const UserContext = createContext<UserContextValue>({
  user: null,
  roles: [],
  activeRole: null,
  userManual: undefined,
  async getUserManual() {},
  setActiveRole() {},
  async login() {},
  async logout() {},
  async updateProfile() {},
  async refreshCurrentUser() {},
});

export interface UserContextValue {
  user: User | null;
  roles: UserRole[];
  activeRole: UserRole | null;
  userManual?: GetUserManual;
  getUserManual?(): Promise<void>;
  setActiveRole(role: UserRole | null): void;
  login(credential: { username: string; password: string }): Promise<void>;
  logout(): Promise<void>;
  updateProfile(
    fields: Omit<Parameters<typeof api.updateProfile>[0], 'userId'>,
  ): Promise<void>;
  refreshCurrentUser(): Promise<void>;
}

/**
 * Returns the user context value with a non-nullable user.
 * Used when the user must be logged in.
 */
export function useUser(): Omit<UserContextValue, 'user' | 'login'> & {
  user: User;
} {
  const contextValue = useContext(UserContext);

  if (!contextValue.user) {
    throwError(
      'Hook useUser must be called after ensuring a user is signed in and loaded.',
    );
  }

  return contextValue as any;
}

export function useParticipantUser(): ReturnType<typeof useUser> & {
  participant: OrgParticipant;
  orgProduct: OrgProduct;
  product: Product;
} {
  const userContext = useUser();

  if (userContext.activeRole?.kind !== UserRoleKind.PARTICIPANT) {
    throw new Error(
      `useParticipant cannot be called unless the user's active role is participant.`,
    );
  }

  return {
    ...userContext,
    participant: userContext.activeRole.participant,
    orgProduct: userContext.activeRole.orgProduct,
    product: userContext.activeRole.product,
  };
}

/**
 * Returns the user context value with a nullable user.
 * Used when the user might be logged out.
 */
export function useUserOptional(): UserContextValue {
  return useContext(UserContext);
}
