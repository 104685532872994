import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function usePatchSearchParams<
  T extends Record<string, string | number | boolean | null | undefined>
>(): (fields: T, options?: { replace?: boolean }) => void {
  const history = useHistory();
  return useCallback(
    (fields: T, { replace = false } = {}) => {
      const urlSearchParams = new URLSearchParams(history.location.search);
      for (const [key, value] of Object.entries(fields)) {
        if (value === null || value === undefined) {
          urlSearchParams.delete(key);
        } else {
          urlSearchParams.set(key, value.toString());
        }
      }
      if (replace) {
        history.replace({
          pathname: history.location.pathname,
          search: urlSearchParams.toString(),
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: urlSearchParams.toString(),
        });
      }
    },
    [history],
  );
}
