import { FC } from 'react';
import './YellowCircleList.css';

export const YellowCircleList: FC<{
  className?: string;
}> = ({ className = '', children, ...rest }) => (
  <ul {...rest} className={`CircleList ${className}`}>
    {children}
  </ul>
);
