import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import stock5Src from '../../../images/stock/stock_5.png';
import stock6Src from '../../../images/stock/stock_6.png';
import stock7Src from '../../../images/stock/stock_7.png';
import stock8Src from '../../../images/stock/stock_8.png';

export const ProactiveParenting13Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const barriers = isEnglish ? englishBarriers : spanishBarriers;
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Play' : 'Jugar'}
      className="flex-col text-white px-14 font-light"
    >
      {isEnglish
        ? 'Try to add play into your daily routine.'
        : 'Trate de agregar el juego a su rutina diaria.'}

      <span className="font-semibold text-red-600 my-5">
        {isEnglish
          ? 'What keeps you from playing with your child?'
          : '¿Qué le impide jugar con su hijo/a?'}
      </span>
      <div className="flex flex-col mb-5">
        {barriers.map((barrier, index) => (
          <div
            key={barrier.caption}
            className={`flex items-center font-semibold ${
              index > 0 ? 'mt-3.5' : ''
            }`}
          >
            <img
              src={barrier.img.src}
              alt={barrier.img.alt}
              className="w-14 mr-3.5"
            />
            <span>{barrier.caption}</span>
          </div>
        ))}
      </div>
    </SlideContainer>
  );
};

const englishBarriers = [
  {
    caption: "You're too busy",
    img: { src: stock5Src, alt: 'birds eye view of computer screen' },
  },
  {
    caption: 'Your other children demand your attention',
    img: { src: stock6Src, alt: 'siblings coloring' },
  },
  {
    caption: "You're tired",
    img: { src: stock7Src, alt: 'face palm' },
  },
  {
    caption: 'Other?',
    img: { src: stock8Src, alt: 'mess of various items' },
  },
];

const spanishBarriers = [
  {
    caption: 'Esta muy ocupado/a',
    img: { src: stock5Src, alt: 'computadora' },
  },
  {
    caption: 'Sus otros hijo/as exigen su atención.',
    img: { src: stock6Src, alt: 'niños coloreando' },
  },
  {
    caption: 'Esta cansado/a',
    img: { src: stock7Src, alt: 'persona con la mano en la cara' },
  },
  {
    caption: '¿Qué mas?',
    img: { src: stock8Src, alt: 'cosas tiradas' },
  },
];
