import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import cautionAlertIconSrc from '../../../images/CautionAlertIcon.png';

export const PositiveParenting15Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  const instructions = isEnglish ? englishInstructions : spanishInstructions;
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Giving directions' : 'Dar instrucciones'}
      className="flex-col text-white px-14 font-light"
    >
      {isEnglish
        ? 'Your directions will be more effective if you:'
        : 'Sus instrucciones serán más efectivas si usted:'}
      <YellowCircleList className="my-6">
        {instructions.map(({ boldedText, text }, i) => {
          return (
            <li key={i}>
              <b className="font-bold">{boldedText}</b> {text}
            </li>
          );
        })}
      </YellowCircleList>
      <img
        className="w-28 mt-2.5 self-center"
        src={cautionAlertIconSrc}
        alt="caution"
      />
      <p className="text-red-600 font-medium text-center text-lg">
        {isEnglish
          ? 'Caution: When your requests sound like questions you open the door to an argument.'
          : 'Precaución: Cuando sus solicitudes suenan como preguntas, esta abre la puerta a una discusión.'}
      </p>
    </SlideContainer>
  );
};

const englishInstructions = [
  {
    boldedText: 'Have your child’s attention:',
    text: 'Be in the same room, make eye contact',
  },
  {
    boldedText: 'Make only one request at a time',
    text: '',
  },
  {
    boldedText: 'Be specific',
    text: 'about what you want your child to do: Say, "Pick up your blocks, please."',
  },
  {
    boldedText: 'Focus on what you want,',
    text: `rather than what you don't: Say, 
        “Chew with your mouth closed, please.” Instead of, "Don’t chew with your mouth open."`,
  },
  {
    boldedText: 'Be polite & respectful:',
    text: 'Keep your expresseion neutral, use a pleasant tone of voice, say "please" and "thank you"',
  },
];

const spanishInstructions = [
  {
    boldedText: 'Tiene la atención de su niño/a:',
    text: 'Esté en la misma habitación, haga contacto visual.',
  },
  {
    boldedText: 'Hace solo una solicitud a la vez.',
    text: '',
  },
  {
    boldedText: 'Es específico',
    text: 'sobre lo que quiere que haga su niño/a: diga: "Recoge tus bloques, por favor".',
  },
  {
    boldedText: 'Se concentra en lo que quiere,',
    text: 'en lugar de lo que no quiere: diga: "Mastiqa con la boca cerrada, por favor". En lugar de, "No mastiques con la boca abierta".',
  },
  {
    boldedText: 'Es cortés y respetuoso:',
    text: 'mantenga su expresión neutral, use un tono de voz agradable, diga "por favor" y "gracias".',
  },
];
