import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Screens_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText={isSpanish ? 'Siguiente' : 'Next'}
      slideTitle={
        isSpanish
          ? 'Pantallas como herramientas positivas'
          : 'Screens as positive tools'
      }
      className="px-14 font-light text-white"
    >
      {isSpanish
        ? `Tome interés en lo que le gusta hacer a su hijo/a en línea. Haga 
          muchas preguntas y elogie su curiosidad y creatividad.`
        : `Take an interest in what your child likes to do online. Ask lots of
          questions, and praise their curiosity and creativity.`}
    </SlideContainer>
  );
};
