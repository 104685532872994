import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import cautionAlertIconSrc from '../../../images/CautionAlertIcon.png';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Directions_b: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Dar Instrucciones' : 'Giving Directions'}
      className="font-light text-white px-14 flex-col"
    >
      <p>
        {isSpanish
          ? 'Sus direcciones van a ser más eficaces si Ud:'
          : 'Your directions will be more effective if you:'}
      </p>
      <YellowCircleList className="py-6">
        <li>
          <b className="font-bold">
            {isSpanish
              ? 'Tiene la atención de su hijo/a:'
              : 'Have your child’s attention:'}
          </b>
          <br />
          {isSpanish
            ? 'Esté en el mismo cuarto, haga contacto de ojo.'
            : 'Be in the same room, make eye contact.'}
        </li>
        <li>
          <b className="font-bold">
            {isSpanish
              ? 'Haga solo una suplica a la vez.'
              : 'Make only one request at a time.'}
          </b>
        </li>
        <li>
          <b className="font-bold">
            {isSpanish ? 'Sea específico ' : 'Be specific '}
          </b>
          {isSpanish
            ? `sobre lo que quiere que su hijo/a haga: "Haz tu cama antes de que
              te vayas a escuela cada día, por favor."`
            : `about what you want your child to
              do: Say, “Make your bed before you leave for school each day,
              please.”`}
        </li>
        <li>
          <b className="font-bold">
            {isSpanish
              ? 'Enfóquese en lo que quiera'
              : 'Focus on what you want'}
          </b>
          {isSpanish
            ? `, en vez de lo que no quiera: Diga: "Llega a las 4pm" 
              en vez de "No llegues tarde."`
            : `, rather than what you don't: Say, “Be home at 4pm” 
              instead of “Don’t be late.”`}
        </li>
        <li>
          <b className="font-bold">
            {isSpanish
              ? 'Sea cortés y respetuoso: '
              : 'Be polite & respectful: '}
          </b>
          {isSpanish
            ? `Mantenga sus expresiones neutras, use un tono placentero, diga
              "por favor" y "gracias"`
            : `Keep your expression neutral, use a
              pleasant tone of voice, say "please" and "thank you."`}
        </li>
      </YellowCircleList>
      {/* Warning Container */}
      <div className="flex flex-col items-center text-center">
        <img
          src={cautionAlertIconSrc}
          alt={isSpanish ? 'señal de precaución' : 'caution sign'}
          className="w-28"
        />
        <p className="text-red-600 text-lg font-medium leading-5">
          {isSpanish
            ? `Cuidado: Cuando sus pedidos se oyen como una pregunta eso 
              abre la puerta para una pelea.`
            : `Caution: When your requests sound like questions you open 
              the door to an argument.`}
        </p>
      </div>
    </SlideContainer>
  );
};
