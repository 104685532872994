import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const WellnessAndSelfCare15Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'Sleep' : 'Dormir'}
      className="flex-col px-14"
    >
      <p>
        {isEnglish
          ? 'Getting enough good sleep will set you and your child up for a successful day.'
          : 'Dormir lo suficiente lo preparará a usted y a su hijo/a para un día exitoso.'}
      </p>
    </SlideContainer>
  );
};
