import { Survey } from 'shared/lib/types/Survey';

export const POST_TEST_ROOT = '/post-test/:surveyIdOrResults';
export const POST_TEST_RESULTS = '/post-test/results';

export type SurveyIdOrResults = 'results' | number;

/**
 * Determine if the matched route is the post test results or a survey
 */
export function getUnitNumberOrResults(
  match: string,
): SurveyIdOrResults | null {
  if (match === 'results') {
    return 'results';
  }
  const asNumber = +match;
  return Number.isNaN(asNumber) ? null : asNumber;
}

/**
 * Determine if the matched route is specifically the post test results screen
 */
export function isPostTestResults(
  value: SurveyIdOrResults,
): value is 'results' {
  return value === 'results';
}

export function formatPostTestSurveyRoute({ id }: Pick<Survey, 'id'>): string {
  return `/post-test/${id}`;
}

export function formatPostTestSurveyQuestionRoute(
  survey: Pick<Survey, 'id'>,
  questionNumber: number,
): string {
  return `${formatPostTestSurveyRoute(survey)}/question/${questionNumber}`;
}
