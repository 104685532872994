import { FC, ComponentPropsWithoutRef, useMemo } from 'react';
import { calculateProductPoints } from 'shared/lib/utils/unitUtils';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import checkStarIconSrc from '../../images/CheckStar@3x.png';
import shieldIconSrc from '../../images/shield_icon.png';
import { useUser } from '../../contexts/userContext';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';

export const ParticipantPointCircle: FC<ComponentPropsWithoutRef<'div'>> = ({
  className = '',
  ...rest
}) => {
  const { user } = useUser();
  const { productSession } = useParticipantProductContext();
  const {
    welcomePoints,
    slidePoints,
    activityPoints,
    slidePointTotal,
    activityPointTotal,
  } = useMemo(() => calculateProductPoints(productSession), [productSession]);

  return (
    <div {...rest} className={`overflow-hidden h-40 text-center ${className}`}>
      <div className="bg-blue-300 rounded-full w-48 h-48 flex-shrink-0 pt-16 -mt-12">
        <div className="flex flex-row items-center">
          {/* Slide view points */}
          <div className="flex flex-col items-center w-0 flex-grow">
            <img
              src={checkStarIconSrc}
              alt="Slide points"
              className="w-5 h-5 flex-shrink-0"
            />
            <span>{slidePoints + welcomePoints}</span>
          </div>

          <ProfilePicture user={user} />

          {/* Activity points */}
          <div className="flex flex-col items-center w-0 flex-grow">
            <img
              src={shieldIconSrc}
              alt="Activity points"
              className="w-4 h-4 flex-shrink-0"
            />
            <span>{activityPoints}</span>
          </div>
        </div>

        {/* Total points */}
        <div className="mt-1">
          {welcomePoints + slidePoints + activityPoints}/
          {welcomePoints + slidePointTotal + activityPointTotal}{' '}
        </div>
      </div>
      <div className="bg-blue-300 rounded-full w-48 h-48 flex-shrink-0 not-sr-only -mt-2">
        {/* Decorative semi-circle */}
      </div>
    </div>
  );
};
