import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Encouragement_c: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Elogio Específico' : 'Specific Praise'}
      className="px-14 text-white font-light"
    >
      {isSpanish
        ? `El elogio específico es directo y se enfoca en el comportamiento que su 
          hijo/a está haciendo que le gustaría que incremente. El elogio específico 
          ayuda a que su hijo/a aprenda habilidades que le gustara que haga diariamente. 
          Es el elogio más efectivo para usar con su hijo/a.`
        : `Specific praise is direct and focuses on the behavior your child is doing
          that you want to increase. Specific praise helps your child learn the skills
          you want them to do more of on a daily basis. It is the most effective
          praise to use with your child.`}
    </SlideContainer>
  );
};
