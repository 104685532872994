import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const ProactiveParenting12Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideVideo
      className="font-light text-white"
      videoKey={
        isEnglish
          ? 'early_childhood_en_Ani_08'
          : 'early_childhood_spanish_Ani_08'
      }
      slideTitle={isEnglish ? 'Play' : 'Jugar'}
      videoTitle={
        <h3 className="text-lg text-blue-800">
          {isEnglish ? 'Play' : 'Jugar'}
        </h3>
      }
      lowerSlideContent={
        <p className="text-center my-10">
          {isEnglish ? (
            <>Try to add play into your daily routine</>
          ) : (
            <>Trate de agregar el juego a su rutina diaria.</>
          )}
        </p>
      }
      upperSlideContent={
        <p className="px-14">
          {isEnglish ? (
            <>
              Playing with your child is an essential part of your relationship.
              Watch this video for tips on how to get more out of play
              experiences.
            </>
          ) : (
            <>
              Jugar con su hijo/a es una parte esencial de su relación. Mire
              este video para obtener consejos sobre cómo obtener más
              experiencias de juego.
            </>
          )}
        </p>
      }
      bearCharacter="YOUNG_GIRL_WINKING"
    />
  );
};
