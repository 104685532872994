import { useEffect, useState } from 'react';
import { OrgDocument } from 'shared/lib/types/OrgDocument';
import { OrgDocumentCategory } from 'shared/lib/constants/org/OrgDocumentCategory';
import { api } from '../../api';
import { getErrorMessage } from 'shared/lib/utils/errorUtil';

export function useOrgDocument(
  orgId: number | null,
  category: OrgDocumentCategory,
) {
  const [orgDocument, setOrgDocument] = useState<OrgDocument | null>(null);

  useEffect(() => {
    if (orgId) {
      api
        .getOrgDocument(orgId, category)
        .then((orgDoc) => setOrgDocument(orgDoc ?? null))
        .catch((e) => console.error(getErrorMessage(e)));
    }
    return () => {
      setOrgDocument(null);
    };
  }, [orgId, category]);

  return {
    orgDocument,
  };
}
