import { FC } from 'react';
import { SlideVideo } from '../../../components/SlideVideo/SlideVideo';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Pospar_Encouragement_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideVideo
      slideTitle={isSpanish ? 'Animar & Elogiar' : 'Encouragement & praise'}
      className="font-light text-white"
      bearCharacter="GIRL"
      videoKey={isSpanish ? 'ani_1_spanish' : 'ani_1'}
      upperSlideContent={
        <p className="px-14">
          {isSpanish
            ? `Trate de fortalecer los comportamientos positivos cuando observe 
              que su hijo/a haga algo correcto. Para hacer esto, trate de crear 
              el hábito de dar elogios y animo por las cosas pequeñas que le gusten 
              o aprecie lo que hace su hijo/a todos los días. Vea esto para ver 
              cómo es que se hace.`
            : `Try to strengthen positive behaviors by noticing when your child does
              something right. To do this, get in the habit of giving praise and
              encouragement for the little things you like or appreciate that your
              child does each day. Watch now to see how to do it.`}
        </p>
      }
    />
  );
};
