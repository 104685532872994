import { FC, ReactNode } from 'react';
import { PlusMinusIcon } from '../PlusMinusIcon/PlusMinusIcon';

/**
 * @param {string} [buttonTheme="light"] - Defaults to light theme
 * @param {boolean} [open=false] - Defaults to false
 */

export const PlusExpandable: FC<{
  headerText: ReactNode;
  open?: boolean;
  buttonTheme?: 'light' | 'dark';
  onExpand(): unknown;
}> = ({
  headerText,
  open = false,
  buttonTheme = 'light',
  onExpand,
  children,
  ...rest
}) => (
  <div
    {...rest}
    className={`my-2 ${
      buttonTheme === 'dark' ? 'text-blue-700' : 'text-white'
    }`}
  >
    <div className="flex" onClick={onExpand}>
      <PlusMinusIcon theme={buttonTheme} className="mt-1" expanded={open} />
      {headerText}
    </div>
    {open && (
      <div className="relative flex flex-col pt-1 pl-10 pb-3">{children}</div>
    )}
  </div>
);
