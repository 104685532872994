import { FC } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import {
  spanishClearUnclearRulesQuiz,
  englishClearUnclearRulesQuiz,
} from '../../../constants/early_childhood/rules_and_consequences_11_quiz';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type ClearUnclearQuizKey =
  | keyof typeof spanishClearUnclearRulesQuiz
  | keyof typeof englishClearUnclearRulesQuiz;

type ActivityState = Partial<Record<ClearUnclearQuizKey, boolean>>;

export const RulesAndConsequences11Slide: FC = () => {
  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const { value, setValue, isReview } = useActivityState<ActivityState>({});

  const clearUnclearRulesQuiz = isEnglish
    ? englishClearUnclearRulesQuiz
    : spanishClearUnclearRulesQuiz;

  const moveForward = Object.values(value).length > 2 || isReview;
  const helpfulWord = isEnglish ? 'Clear' : 'Clara';
  const unhelpfulWord = isEnglish ? 'Unclear' : 'Poco Clara';

  return (
    <SlideContainer hideButton lightBg lightProgressBar>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-900"
        instructions={
          <h2 className="text-lg">
            {isEnglish
              ? 'Your turn: clear or unclear rules?'
              : 'Su turno: ¿reglas claras o poco claras?'}
          </h2>
        }
        icon="pointer"
      >
        <HelpfulUnhelpfulQuiz
          className="mt-6"
          title={
            isEnglish
              ? 'Which of the following are clear rules that will set you up for success?'
              : '¿Cuáles de las siguientes son reglas claras que lo/a prepararán para el éxito?'
          }
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
          iconPair="clear/unclear"
        >
          {Object.entries(clearUnclearRulesQuiz).map(
            ([key, { answer, question, feedback }]) => (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                isHelpfulCorrect={answer}
                value={value[key as ClearUnclearQuizKey] ?? null}
                questionText={question}
                feedback={feedback}
                helpfulWord={helpfulWord}
                unhelpfulWord={unhelpfulWord}
                onChange={(bool) => {
                  setValue({
                    ...value,
                    [key]: bool,
                  });
                }}
              />
            ),
          )}
        </HelpfulUnhelpfulQuiz>
        {moveForward && (
          <PillButton
            className="mt-10"
            theme={ButtonTheme.YELLOW}
            onClick={next}
          >
            {isEnglish ? 'Finish Activity' : 'Termine la Actividad'}
          </PillButton>
        )}
      </ActivityBox>
    </SlideContainer>
  );
};
