import { FC } from 'react';
import './InfoTooltip.css';
import { useProductLanguage } from '../../hooks/useProductLanguage';
import { noop } from 'shared/lib/utils/noop';
import { ToolTipTriPosition } from 'shared/lib/types/ToolTipTriPosition';

/**
 * @param {string} [textAlign="center"] - Defaults to center
 * @param {string} [text="No Content"] - Defaults to "No Content"
 * @param {string} [position="right"] - Defaults to right
 */

const textAlignment = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
};

export const InfoTooltip: FC<{
  visible: boolean;
  text?: string;
  position?: ToolTipTriPosition;
  textAlign?: keyof typeof textAlignment;
  onClose?(): void;
  className?: string;
  absolutePositioned?: boolean;
}> = ({
  visible,
  text = 'No Content',
  position = 'right',
  textAlign = 'center',
  onClose = noop,
  className = '',
  absolutePositioned = false,
  ...rest
}) => {
  const alignment = textAlignment[textAlign];
  const { isSpanish } = useProductLanguage();

  return (
    <div
      className={`${
        absolutePositioned ? 'absolute' : 'relative'
      } flex z-20 text-blue-800 ${visible ? 'visible' : 'hidden'} ${className}
      `}
      {...rest}
    >
      {/* Close */}
      <button
        className={`absolute z-20 right-0 ${
          isSpanish ? 'w-20' : 'w-16'
        }  h-5 text-center text-xs font-bold mr-4 -mt-4 rounded-t-md 
        bg-yellow-secondary`}
        onClick={onClose}
      >
        {isSpanish ? 'CIERRE' : 'CLOSE'} X
      </button>
      {/* Content */}
      <div
        className={`info-point rounded-lg bg-yellow-secondary shadow p-4 
        text-blue-800 text-sm font-extrabold tracking-normal ${alignment} 
        leading-5 w-full ${position}`}
      >
        {text}
      </div>
    </div>
  );
};
