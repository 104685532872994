import { FC, useState } from 'react';
import { useSlideContext } from '../../../contexts/slideContext';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { IconTextArea } from '../../../components/IconTextArea/IconTextArea';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const Com_Restate_c: FC = () => {
  const { next, minimalSlideWrapper: isMyPlanPage } = useSlideContext();
  const [situation, setSituation] = useState(1);
  const { value, setValue, isReview, activity } = useActivityState({
    facts1: '',
    facts2: '',
    feelings1: '',
    feelings2: '',
  });
  const [overlayOpen, setOverlayOpen] = useState(false);
  const { isSpanish } = useProductLanguage();

  const handleFacts = (factValue: string) =>
    situation === 1
      ? setValue({ ...value, facts1: factValue })
      : setValue({ ...value, facts2: factValue });

  const handleFeelings = (feelingValue: string) =>
    situation === 1
      ? setValue({ ...value, feelings1: feelingValue })
      : setValue({ ...value, feelings2: feelingValue });

  const progressForward =
    (situation === 1 && !!value.facts1 && !!value.feelings1) ||
    (situation === 2 && !!value.facts2 && !!value.feelings2);

  const handleSubmit = () => {
    if (situation === 1) {
      setSituation(2);
    } else if (isMyPlanPage && situation === 2) {
      setSituation(1); // Just toggle between situations on My Plan page
    } else {
      isReview ? next() : setOverlayOpen(true);
    }
  };

  return (
    <SlideContainer buttonText="" hideButton>
      <ActivityBox
        icon="chat"
        className="flex-1 mt-10 text-blue-800"
        instructions={
          <h2 className="mt-7">
            {isSpanish
              ? `Su turno: Lea las siguientes situaciones retadoras, y después 
                responda con los hechos y los sentimientos.`
              : `Your turn: Read the following challenging situations, and then
                respond with the facts and the feelings.`}
          </h2>
        }
      >
        <p className="px-8">
          <b className="mb-1">
            {isSpanish ? 'Situación' : 'Situation'} {situation}
          </b>
          <br />
          <b>{isSpanish ? 'El niño/a dice:' : 'Child says:'}</b>{' '}
          {situation === 1 ? (
            <>
              {isSpanish
                ? `Mark y yo estábamos pasando tiempo en el parque y su hermano mayor 
                  llegó con unos amigos. Ellos estaban fumando marihuana cerca de 
                  la cancha de baloncesto. Ellos nos ofrecieron.`
                : `Mark and I were hanging out at the park and his big brother showed
                  up with some friends. They were smoking pot by the basketball
                  court. They offered us some.`}
            </>
          ) : (
            <>
              {isSpanish
                ? `Mi maestro de matemáticas es tan malo. Él se mete conmigo todo el tiempo, ¡y ahora me voy a perder mi hora del almuerzo mañana! ¡Es muy injusto!`
                : `My math teacher is so mean. He picks on me all the time, and now I
                  have to miss lunch tomorrow! It’s so unfair!`}
            </>
          )}
        </p>
        <FloatingCard className="pr-4 pt-3 pb-5 mt-5 mb-3">
          <IconTextArea
            label={
              isSpanish
                ? 'Su respuesta, los hechos:'
                : 'Your response, the facts:'
            }
            icon="magnifyingGlass"
            placeholder={
              isSpanish ? 'Agregue lo que diría. ' : 'Enter what you would say.'
            }
            value={situation === 1 ? value.facts1 : value.facts2}
            onChange={(value) => handleFacts(value)}
          />
        </FloatingCard>
        <FloatingCard className="pr-4 pt-3 pb-5 mb-3">
          <IconTextArea
            label={
              isSpanish
                ? 'Su respuesta, los sentimientos:'
                : 'Your response, the feelings:'
            }
            icon="pulsingHeart"
            placeholder={
              isSpanish
                ? 'Agregue lo que sentimientos.  '
                : 'Enter what you would say.'
            }
            value={situation === 1 ? value.feelings1 : value.feelings2}
            onChange={(value) => handleFeelings(value)}
          />
        </FloatingCard>
        {progressForward && (
          <PillButton
            className="w-52 self-center my-10"
            theme={ButtonTheme.YELLOW}
            onClick={handleSubmit}
          >
            {
              // prettier-ignore
              situation === 1
              ? (isSpanish ? 'Siguiente' : 'Next')
              : (isSpanish ? 'Enviar' : 'Submit')
            }
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={overlayOpen}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BOY"
      />
    </SlideContainer>
  );
};
