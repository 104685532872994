import { ComponentPropsWithoutRef, FC } from 'react';
import { ReactComponent as CheckIcon } from '../../images/feather/check.svg';

export interface RoundedCheckboxProps
  extends ComponentPropsWithoutRef<'button'> {
  className?: string;
  checked?: boolean;
}

export const RoundedCheckbox: FC<RoundedCheckboxProps> = ({
  className = '',
  checked = false,
  ...rest
}) => (
  <button
    className={`flex justify-center items-center flex-shrink-0 
    border-2 border-blue-450 h-11 w-14 ${
      checked ? 'bg-blue-200' : 'bg-white'
    }  ${className}`}
    style={{ borderRadius: '20px' }}
    {...rest}
  >
    <input type="checkbox" checked={checked} readOnly className="hidden" />
    {checked && (
      <CheckIcon
        className="h-5 w-6 fill-blue-800"
        style={{ strokeWidth: 3, stroke: '#0E3750' }}
      />
    )}
  </button>
);
