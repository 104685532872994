import { FC } from 'react';
import { Language } from 'shared/lib/types/Language';
import { FloatingCard } from '../FloatingCard/FloatingCard';
import { RadioLetterOption } from '../RadioLetterOption/RadioLetterOption';
import {
  letterOptions,
  LetterOption,
} from '../RadioLetterOption/RadioLetterOption';
import { QuadTuple } from 'shared/lib/types/QuadTuple';

export const WhenThenList: FC<{
  className?: string;
  options: QuadTuple<string>;
  whenStatement: string;
  correctOptions?: string[];
  onChange(value: string): void;
  selected: string | undefined;
  language?: Language;
}> = ({
  className = '',
  options,
  whenStatement,
  correctOptions = [],
  onChange,
  selected,
  language = 'english',
  ...rest
}) => (
  <FloatingCard className={`py-4 px-10 text-blue-800 ${className}`} {...rest}>
    <h3 className="font-bold text-lg uppercase mb-3">
      {/* Will default language to english for now */}
      {language === 'spanish' ? 'CUANDO...' : 'When...'}
    </h3>
    <p className="text-sm mb-7">{whenStatement}</p>
    <h3 className="font-bold text-lg uppercase mb-3">
      {language === 'spanish' ? 'ENTONCES...' : 'Then...'}
    </h3>
    {options.map((option, index) => {
      const assignedKey = Object.keys(letterOptions)[index] as LetterOption;

      return (
        <div
          key={assignedKey}
          className="flex items-center mb-3"
          onClick={() => option && onChange(selected === option ? '' : option)}
        >
          <RadioLetterOption
            className="mr-3"
            checked={option === selected}
            letterOption={assignedKey}
            optionCorrect={option ? correctOptions.includes(option) : false}
            onRadioSelect={() =>
              option && onChange(selected === option ? '' : option)
            }
          />
          <span style={{ fontSize: '.9375rem' }}>{option}</span>
        </div>
      );
    })}
  </FloatingCard>
);
