import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { OverheadBlurb } from '../../../components/OverheadBlurb/OverheadBlurb';
import { useSlideContext } from '../../../contexts/slideContext';
import bearWoman from '../../../images/bear_woman.png';
import { BottomToast } from '../../../components/BottomToast/BottomToast';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Connect_d: FC = () => {
  const { next } = useSlideContext();
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      buttonText="Next"
      hideButton
      className="flex-col justify-end items-center"
    >
      <OverheadBlurb>
        {isSpanish
          ? `Tómese un momento para pensar, ¿ha tenido su hijo/a algún 
            problema en las redes sociales?`
          : `Take a moment to think, has your child had any trouble 
            with social media?`}
      </OverheadBlurb>
      <img
        src={bearWoman}
        className="w-64 -mr-16 mt-5 mb-32"
        alt={isSpanish ? 'mujer oso preocupada' : 'concerned bear woman'}
      />
      <BottomToast
        onButtonClick={next}
        buttonLabel={isSpanish ? 'Siguiente' : 'Next'}
        animate={false}
      />
    </SlideContainer>
  );
};
