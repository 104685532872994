import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { YellowCircleList } from '../../../components/YellowCircleList/YellowCircleList';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const HBST_Connect_a: FC = () => {
  const { isSpanish } = useProductLanguage();

  return (
    <SlideContainer
      slideTitle={isSpanish ? 'Conexiones Sociales' : 'Social Connections'}
      className="flex-col px-14 font-light text-white"
    >
      {isSpanish
        ? `Durante momentos de estrés, su hijo/a necesita oportunidades para
          expresarse y conectarse con sus amigos/as.`
        : `During stressful times, your child needs opportunities to express
          themselves and connect with peers.`}
      <YellowCircleList className="mt-6">
        <li>
          {isSpanish ? (
            <>
              <b className="font-semibold">Permita que</b> su hijo/a tenga
              espacio para expresar sus frustaciones y enojo.
            </>
          ) : (
            'Allow space for your child to express frustrations and anger.'
          )}
        </li>
        <li>
          {isSpanish ? (
            <>
              <b className="font-semibold">Comparta su voluntad</b> de responder
              a preguntas (esta bien no tener todas las respuestas).
            </>
          ) : (
            `Share your willingness to answer questions (it’s okay not to have all
            the answers).`
          )}
        </li>
        <li>
          {isSpanish ? (
            <>
              <b className="font-semibold">Consuélele y conéctese</b> con su
              hijo/a usando abrazos, sonrisas y palabras amables.
            </>
          ) : (
            `Console and connect with your child using hugs, smiles, and kind
            words.`
          )}
        </li>
        <li>
          {isSpanish ? (
            <>
              <b className="font-semibold">Busque oportunidades</b> para que su
              hijo/a conecte con otros, en persona o a través de las redes
              sociales.
            </>
          ) : (
            `Look for opportunities for your child to connect with others, in
            person or through social media.`
          )}
        </li>
      </YellowCircleList>
    </SlideContainer>
  );
};
