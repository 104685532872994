import { FC, useState } from 'react';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { SuggestionTextArea } from '../../../components/SuggestionTextArea/SuggestionTextArea';
import { useSlideContext } from '../../../contexts/slideContext';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

const englishSuggestions = [
  'Reduce eating out.',
  'Reduce eating fast food.',
  'Limit snacks between meals unless they are healthy.',
  'Keep sliced fruits and vegetables ready for easy to grab snacks.',
  'Include your child in meal planning so you know they’ll like what you serve.',
  'Make sure meals have plenty of healthy foods. A good family goal is to fill half your plates with fruits and vegetables.',
];

const spanishSuggestions = [
  'Reducir las veces que comen afuera de la casa.',
  'Reducir el consumo de comida rápida.',
  'Limite los bocadillos entre comidas a menos que sean saludables.',
  'Mantenga las frutas y verduras en rodajas listas para bocadillos fáciles de tomar.',
  'Incluya a su hijo/a en la planificación de comidas para que sepa que le gustará lo que le sirve.',
  'Asegúrese de que las comidas tengan suficientes alimentos saludables. Una buena meta familiar es llenar la mitad de sus platos con frutas y verduras.',
];

export const HBST_Eating_e: FC = () => {
  const { next } = useSlideContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const {
    value: answers,
    setValue: setAnswers,
    isReview,
    hasInteracted,
    activity,
  } = useActivityState<string[]>([]);
  const [openPortal, setOpenPortal] = useState<null | number>(null);
  const { isSpanish } = useProductLanguage();

  const maxSelections = 3;

  const handleAnswer =
    (key: number) => (type: 'text' | 'select') => (value: string) => {
      let newAnswers = [...answers];

      if (type === 'text') {
        newAnswers[key] = value;
      } else if (type === 'select') {
        if (newAnswers.includes(value)) {
          // if value already in array, get rid of it
          newAnswers = newAnswers.filter((item) => item !== value);
        }
        // if max selections reached, replace current field with new selection
        else if (newAnswers.filter(Boolean).length === maxSelections) {
          newAnswers[key] = value;
        }
        // if current index has no value, fill it
        else if (!newAnswers[key]) {
          newAnswers[key] = value;
        }
        // find any empty indexes and fill selection
        else if (newAnswers.indexOf('') !== -1) {
          newAnswers[newAnswers.indexOf('')] = value;
        } else {
          newAnswers = [...newAnswers, value];
        }
      }
      setAnswers(newAnswers);
    };

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowOverlay(true);
    }
  };

  const handlePortal = (key: number) => (value: boolean) => {
    setOpenPortal(value ? key : null);
  };

  const allAnswers = answers.filter(Boolean);

  const suggestions = isSpanish ? spanishSuggestions : englishSuggestions;

  const suggestionAreas = Array.from({ length: maxSelections }).map(() => ({
    title: isSpanish
      ? 'Sugerencias de alimentación saludable'
      : 'Healthy Eating Suggestions',
    placeholder: isSpanish
      ? 'Escriba sus propias estrategias o escoja de la lista.'
      : 'Type your own or choose from the list',
  }));

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="pencil"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? 'Su turno: eliga hasta 3 estrategias que probará para ayudar a su familia a comer mejor.'
            : 'Your turn: Choose up to 3 strategies you will try out to help your family eat better.'
        }
      >
        <div className="flex flex-col flex-1 justify-between">
          <FloatingCard className="pt-4 px-5">
            {suggestionAreas.map((area, idx) => (
              <SuggestionTextArea
                key={idx}
                placeholder={area.placeholder}
                value={answers[idx]}
                suggestions={suggestions}
                suggestionsTitle={area.title}
                pickedSuggestions={allAnswers}
                onValueChange={handleAnswer(idx)}
                showSuggestions={openPortal === idx}
                openPortal={handlePortal(idx)}
                maxSelections={maxSelections}
              />
            ))}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 my-12 self-center"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Termine el Plan' : 'Finish Plan'}
          </PillButton>
          <QuizCompletionOverlay
            open={showOverlay}
            onNext={next}
            activityPoints={activity.pointValue}
            bearCharacter="GIRL_WINKING"
          />
        </div>
      </ActivityBox>
    </SlideContainer>
  );
};
