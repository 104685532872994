import { FC, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import {
  getMostRecentUnitLocation,
  getSectionFirstSlideIndex,
  getUnitProgress,
  isUnitComplete,
  isUnitSectionComplete,
  isUnitUnlocked,
} from 'shared/lib/utils/unitUtils';
import { DraftParticipantUnitSession } from 'shared/lib/types/ParticipantUnitSession';
import lockIconSrc from '../../images/lock_icon.png';
import { useParticipantProductContext } from '../../contexts/participantProductContext';
import { ReactComponent as ChevronDownIcon } from '../../images/feather/chevron-down.svg';
import checkStarIconSrc from '../../images/check_star_small.png';
import { MaybeLink } from '../MaybeLink/MaybeLink';
import { getPublicFilePath } from '../../utils/getPublicFilePath';
import { DraftParticipantProductSession } from 'shared/lib/types/ParticipantProductSession';
import { useUser } from '../../contexts/userContext';
import { useProductLanguage } from '../../hooks/useProductLanguage';

export const UnitList: FC<{
  productSession: DraftParticipantProductSession;
  hideSurveyLinks?: boolean;
  hideProgressBars?: boolean;
  backgroundColor?: 'blue' | 'transparent';
  sectionListPadding?: 'large' | 'small';
  sectionListTextColor?: 'dark' | 'light';
  highlightedUnitId?: number;
  highlightedSectionId?: number;
  className?: string;
}> = ({
  productSession,
  hideSurveyLinks = false,
  hideProgressBars = false,
  backgroundColor = 'blue',
  sectionListPadding = 'large',
  sectionListTextColor = 'dark',
  highlightedUnitId: forcedHighlightedUnitId,
  highlightedSectionId: forcedHighlightedSectionId,
  className = '',
  ...rest
}) => {
  const highlightedLocation = useMemo(() => {
    if (forcedHighlightedUnitId) {
      return {
        unitId: forcedHighlightedUnitId,
        sectionId: forcedHighlightedSectionId ?? null,
      };
    }

    const lastVisited = getMostRecentUnitLocation(productSession);

    // No history for this product: Highlight the first unit and section
    if (!lastVisited) {
      const firstUnit = productSession.unitSessions[0].unit;
      return {
        unitId: firstUnit.id,
        sectionId: firstUnit.sections.length ? firstUnit.sections[0].id : null,
      };
    }

    const unitSession = productSession.unitSessions.find(
      ({ unit }) => unit.id === lastVisited.unitId,
    );
    if (!unitSession) {
      throw new Error(`Unit id ${lastVisited.unitId} not in in productSession`);
    }

    // Section is incomplete: Highlight it
    if (!isUnitSectionComplete(unitSession, lastVisited.sectionId)) {
      return {
        unitId: lastVisited.unitId,
        sectionId: lastVisited.sectionId,
      };
    }

    const incompleteSection = unitSession.unit.sections.find(
      (section) => !isUnitSectionComplete(unitSession, section.id),
    );

    // Section is complete: Highlight the first incomplete section in the unit
    if (incompleteSection) {
      return {
        unitId: lastVisited.unitId,
        sectionId: incompleteSection.id,
      };
    }

    // All previous units are complete: Highlight next unit
    const incompleteUnit = productSession.unitSessions.find(
      (session) => !isUnitComplete(session),
    );
    if (incompleteUnit) {
      return {
        unitId: incompleteUnit.unit.id,
        sectionId: null,
      };
    }

    // All units and unit sections are complete: Highlight nothing
    return { unitId: null, sectionId: null };
  }, [forcedHighlightedUnitId, forcedHighlightedSectionId, productSession]);

  const [expandedUnitId, setExpandedUnitId] = useState<number | null>(
    highlightedLocation.unitId,
  );

  return (
    <ul {...rest} className={className}>
      {productSession.unitSessions.map((unitSession) => (
        <UnitListItem
          key={unitSession.unit.id}
          unitSession={unitSession}
          highlighted={unitSession.unit.id === highlightedLocation.unitId}
          expanded={unitSession.unit.id === expandedUnitId}
          onToggleExpanded={() =>
            setExpandedUnitId(
              expandedUnitId === unitSession.unit.id
                ? null
                : unitSession.unit.id,
            )
          }
          hideProgressBar={hideProgressBars}
          hideSurveyLink={hideSurveyLinks}
          sectionListPadding={sectionListPadding}
          sectionListTextColor={sectionListTextColor}
          backgroundColor={backgroundColor}
          highlightedSectionId={highlightedLocation.sectionId}
        />
      ))}
    </ul>
  );
};

const UnitListItem: FC<{
  highlighted: boolean;
  expanded: boolean;
  unitSession: DraftParticipantUnitSession;
  onToggleExpanded(): void;
  hideSurveyLink: boolean;
  hideProgressBar: boolean;
  backgroundColor: 'blue' | 'transparent';
  sectionListPadding: 'large' | 'small';
  sectionListTextColor: 'dark' | 'light';
  highlightedSectionId: number | null;
}> = ({
  unitSession,
  highlighted,
  expanded,
  hideSurveyLink,
  hideProgressBar,
  backgroundColor,
  sectionListPadding,
  sectionListTextColor,
  highlightedSectionId,
  onToggleExpanded,
  ...rest
}) => {
  const { user } = useUser();
  const { productSession } = useParticipantProductContext();
  const { unit } = unitSession;
  const progress = getUnitProgress(unitSession);
  const unitComplete = progress === 1;
  const unitUnlocked = isUnitUnlocked(user, productSession, unit.id);
  const unitLinkPath = `/unit/${unit.index + 1}`;
  const { isSpanish } = useProductLanguage();

  return (
    <li {...rest} className={unit.index > 0 ? 'pt-2.5' : ''}>
      {/* Header box */}
      <div
        className={`w-full relative flex flex-row flex-shrink-0 px-2.5 py-2 font-medium text-left rounded-lg ${
          highlighted ? 'bg-blue-300 text-blue-600' : 'bg-blue-600 text-white'
        } `}
      >
        {/* Icon */}
        <div className="flex flex-col justify-center">
          <div className="w-8 h-8 rounded-full flex-shrink-0 bg-blue-700">
            {unit.icon ? (
              <img
                className={`w-full h-full ${
                  unitUnlocked && unitComplete ? 'opacity-100' : 'opacity-30'
                }`}
                src={getPublicFilePath(unit.icon)}
                alt={unitUnlocked ? 'unlocked' : 'locked'}
              />
            ) : null}
          </div>
        </div>

        <div className="flex flex-col w-full ml-2">
          {/* Name and number */}
          <MaybeLink
            to={unitLinkPath}
            disabled={!unitUnlocked}
            className="flex flex-row items-center"
          >
            <div
              className={`font-boris text-xl ${
                highlighted ? 'text-blue-600' : 'text-blue-200'
              }`}
            >
              {unit.index + 1}
            </div>
            <h2 className="ml-3 pt-1">{unit.name}</h2>
          </MaybeLink>

          {/* Progress bar */}
          {!hideProgressBar && (
            <div className="w-full rounded-lg overflow-hidden bg-blue-700 mt-2">
              <div
                className="h-4 rounded-lg border-blue-700 border-4 blue-gradient-bg"
                style={{ width: `${progress * 100}%` }}
              />
            </div>
          )}
        </div>

        {/* Expand button */}
        <button
          onClick={onToggleExpanded}
          disabled={!unitUnlocked}
          className="justify-start h-8"
          aria-label="Toggle expanded"
        >
          <ChevronDownIcon
            className={`text-yellow transition-transform transform ${
              expanded ? 'rotate-180' : ''
            } ${unitUnlocked ? '' : 'opacity-30'}`}
            strokeWidth={3}
          />
        </button>

        {/* Lock overlay */}
        {!unitUnlocked && (
          <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center flex-shrink-0 rounded-lg bg-dark-blue bg-opacity-60">
            <img src={lockIconSrc} alt="Locked" className="w-5 h-6" />
          </div>
        )}
      </div>

      {/* Expandable content */}
      {unitUnlocked && (
        <AnimateHeight
          className={`${
            sectionListTextColor === 'light' ? 'text-white' : 'text-blue-800'
          }`}
          height={expanded ? 'auto' : 0}
        >
          {/* Section list */}
          <ul className={sectionListPadding === 'large' ? 'pt-4 px-7' : 'pt-1'}>
            {unit.sections.map((section, i) => {
              const sectionComplete = isUnitSectionComplete(
                unitSession,
                section.id,
              );

              return (
                <li
                  key={section.id}
                  className={`px-3 mt-1 py-1 ${
                    highlightedSectionId === section.id
                      ? 'bg-blue-200 text-blue-800 rounded-lg'
                      : ''
                  }`}
                >
                  <Link
                    className="flex flex-row font-medium"
                    to={`${unitLinkPath}/${
                      getSectionFirstSlideIndex(unit, section.id) + 1
                    }`}
                  >
                    <img
                      src={checkStarIconSrc}
                      alt={sectionComplete ? 'complete' : 'incomplete'}
                      className={`w-5 h-5 flex-shrink-0 mr-2 mt-0.5 ${
                        sectionComplete ? 'opacity-100' : 'opacity-30'
                      }`}
                    />
                    {section.name}
                  </Link>
                </li>
              );
            })}
          </ul>

          {/* Survey link */}
          {!hideSurveyLink && unitSession.survey && (
            <Link
              className="flex flex-row justify-between items-center ml-4 mt-5 mb-2 font-bold"
              to={`${unitLinkPath}/survey`}
            >
              <span>{`${isSpanish ? 'Ver tu' : 'View your'} ${unit.name} ${
                isSpanish ? 'resultados de la encuesta' : 'survey results'
              }`}</span>
              <ChevronDownIcon
                className="text-red transform -rotate-90"
                strokeWidth={3}
              />
            </Link>
          )}
        </AnimateHeight>
      )}
    </li>
  );
};
