import { FC, ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';

type MaybeLinkProps = Omit<ComponentPropsWithoutRef<typeof Link>, 'to'> & {
  to: ComponentPropsWithoutRef<typeof Link>['to'] | null;
  disabled?: boolean;
};

/**
 * A link that can be disabled by either passing `disabled={true}` or `to={null}`.
 */
export const MaybeLink: FC<MaybeLinkProps & { external?: boolean }> = ({
  to,
  disabled,
  external = false,
  ...rest
}) => {
  if (to === null || disabled) {
    return <span {...rest} />;
  }
  /**
   * Use a regular anchor tag for external links. Open them in a new tab.
   */
  if (external) {
    // eslint-disable-next-line
    return <a {...rest} target="_blank" rel="noreferrer" href={to as string} />;
  }
  return <Link {...rest} to={to} />;
};
