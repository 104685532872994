import { FC, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Spinner } from '../Spinner/Spinner';
import { times } from 'shared/lib/utils/times';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import useWindowSize from '../../hooks/useWindowSize';
import { twMerge } from 'tailwind-merge';

const MAX_PAGE_WIDTH_LARGE_SCREEN = 560;
const MIN_PAGE_WITH = 210;

interface PdfViewerProps {
  className?: string;
  pageClassName?: string;
  gutterSize?: number;
  maxWidth?: number;
  /**
   * The PDF File (from an import pdf from './path/to/pdf.pdf) or url to the pdf.
   */
  file: string | { url: string };
}

export const PdfViewer: FC<PdfViewerProps> = ({
  file,
  className,
  pageClassName,
  gutterSize = 8,
  maxWidth = MAX_PAGE_WIDTH_LARGE_SCREEN,
  ...rest
}) => {
  const [pages, setPages] = useState(0);
  const [loadError, setLoadError] = useState(false);
  const isLargeScreen = useMatchMedia('(min-width:768px)');
  const { width } = useWindowSize();

  // This is a workaround for the inability to set a width%/max-width in react-pdf
  const pageWidth = useMemo(() => {
    if (isLargeScreen) {
      return maxWidth;
    }
    // - 16 to account for horizontal padding (8px left, 8px right, i.e. p-2)
    return Math.max(width - gutterSize * 2, MIN_PAGE_WITH);
  }, [isLargeScreen, width, gutterSize, maxWidth]);

  // Create a "virtual page" for each page.
  const virtualPages = useMemo(
    () => times(pages, (idx) => ({ pageNumber: idx + 1 })),
    [pages],
  );

  const key = typeof file === 'string' ? file : file.url;

  return (
    <Document
      key={key}
      file={file}
      className={twMerge(
        'flex flex-col space-y-4 p-2 w-full sm:w-full  md:max-w-xl',
        className,
      )}
      onLoadSuccess={(pdf) => {
        setPages(pdf.numPages);
      }}
      error={
        loadError
          ? 'Document has not been uploaded!'
          : 'Failed to load PDF file.'
      }
      onLoadError={(error) => {
        console.error(error);
        setLoadError(true);
      }}
      loading={
        <div className="flex flex-col w-full h-full justify-center mt-10 items-center">
          <Spinner />
          <h2>Loading PDF...</h2>
        </div>
      }
      {...rest}
    >
      {/* Render all pages, one after another */}
      {virtualPages.map(({ pageNumber }) => (
        <Page
          key={pageNumber}
          pageNumber={pageNumber}
          className={twMerge(
            'bg-white w-full self-center h-full flex-1 rounded-lg max-w-full animate-fadeIn',
            pageClassName,
          )}
          width={pageWidth}
        />
      ))}
    </Document>
  );
};
