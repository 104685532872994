import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { FloatingCard } from '../../../components/FloatingCard/FloatingCard';
import { SlideCheckbox } from '../../../components/SlideCheckbox/SlideCheckbox';
import {
  ButtonTheme,
  PillButton,
} from '../../../components/PillButton/PillButton';
import { useSlideContext } from '../../../contexts/slideContext';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useActivityState } from '../../../hooks/useActivityState';
import { useProductLanguage } from '../../../hooks/useProductLanguage';
import { Language } from 'shared/lib/types/Language';

const englishLabels = [
  'Listens with understanding.',
  'Focuses on how her daughter’s body feels.',
  'Asks her daughter questions to learn more.',
  "Provides praise and encourages her daughter's healthy habits.",
];

const spanishLabels = [
  'Escuchó con comprensión.',
  'Se enfocó en cómo se sintió el cuerpo de su hija.',
  'Le hizo preguntas a su hija para aprender más.',
  'Elogió y formentó los hábitos saludables de su hija.',
];

const correctMessages = {
  english: `That’s right! The mom used all of these great skills to 
            promote her daughter’s positive body image.`,
  spanish: `¡Así es! La madre usó todas estas excelentes habilidades 
            para promover una imagen positiva del cuerpo de su hija.`,
};

const incorrectMessages = {
  english: `Actually, the mom used all of these great skills to promote 
            her daughter’s positive body image.`,
  spanish: `En realidad, la madre usó todas estas excelentes habilidades 
            para promover la imagen corporal positiva de su hija.`,
};
const getCorrectIncorrectMessages = (lang: Language, correct: boolean) =>
  correct ? correctMessages[lang] : incorrectMessages[lang];

export const HBST_Bodyposi_d: FC = () => {
  const { next } = useSlideContext();
  const { value, setValue, isReview, hasInteracted, activity } =
    useActivityState({
      question1: false,
      question2: false,
      question3: false,
      question4: false,
    });
  const [showPointOverlay, setShowPointOverlay] = useState(false);
  const { isSpanish, language } = useProductLanguage();

  const allCorrect =
    value.question1 && value.question2 && value.question3 && value.question4;

  const handleSubmit = () => {
    // Activity is optional, but don't show points if the user didn't interact
    if (isReview || !hasInteracted) {
      next();
    } else {
      setShowPointOverlay(true);
    }
  };

  const labels = isSpanish ? spanishLabels : englishLabels;

  return (
    <SlideContainer buttonText="Next" lightBg lightProgressBar hideButton>
      <ActivityBox
        icon="checkbox"
        className="flex-1 mt-8 pt-8 pb-6"
        instructions={
          isSpanish
            ? `Su turno: ¿Qué habilidades notó que usó esta mamá cuando 
              habló con su hija sobre su imagen corporal?`
            : `Your turn: What skills did you notice this mom using when 
              she talked to her daughter about her body image?`
        }
      >
        <div className="flex flex-col flex-1 justify-between items-center px-3">
          <FloatingCard className="w-full pt-3 px-4">
            {Object.keys(value).map((key, idx) => {
              const valueKey = key as keyof typeof value;
              return (
                <SlideCheckbox
                  key={key}
                  className="mb-3"
                  checked={value[valueKey]}
                  onChange={() =>
                    setValue({ ...value, [key]: !value[valueKey] })
                  }
                  label={labels[idx]}
                />
              );
            })}
          </FloatingCard>
          <PillButton
            theme={ButtonTheme.YELLOW}
            className="w-52 my-12"
            onClick={handleSubmit}
          >
            {isSpanish ? 'Entregue' : 'Submit'}
          </PillButton>
        </div>
        <QuizCompletionOverlay
          open={showPointOverlay}
          icon={allCorrect ? 'thumbs-up' : 'alert'}
          onNext={next}
          activityPoints={activity.pointValue}
          badgeLocation="right"
          activityMessage={getCorrectIncorrectMessages(language, allCorrect)}
          bearCharacter="BOY"
        />
      </ActivityBox>
    </SlideContainer>
  );
};
