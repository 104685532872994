import { FC, FormEvent, useMemo, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import { api } from '../../api';
import { ReactComponent as ChevronLeftIcon } from '../../images/feather/chevron-left.svg';
import { PillButton, PillLink } from '../../components/PillButton/PillButton';
import { TextInput } from '../../components/TextInput/TextInput';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useSearchParams } from '../../hooks/useSearchParams';
import { Logo } from '../../components/Logo/Logo';

export const AcceptInvitePage: FC = () => {
  const searchParams = useSearchParams();
  const inviteCode = searchParams.get('code') ?? '';
  const isSpanish = searchParams.get('language') === 'spanish';
  const inviteExpiration = searchParams.get('expiresAt');
  const inviteEmail = searchParams.get('email');
  const location = useLocation();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const inviteExpired = useMemo(
    () =>
      !!(inviteExpiration && new Date(inviteExpiration).getTime() < Date.now()),
    [inviteExpiration],
  );
  useDocumentTitle(isSpanish ? 'Registrarse' : 'Register');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (isBlank(password)) {
        throw new Error(
          isSpanish ? 'Se requiere contraseña.' : 'Password is required.',
        );
      }
      if (password !== passwordConfirm) {
        throw new Error(
          isSpanish
            ? 'Las contraseñas no coinciden.'
            : `Passwords don't match.`,
        );
      }
      await api.acceptUserInvite({ code: inviteCode, password });
      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(error as Error);
    }
  }

  if (!inviteCode) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-blue-700 px-4 pb-24 pt-36 text-white">
      <Logo className="flex-shrink-0 self-center" />
      {submitSuccess ? (
        <div className="container w-96 max-w-full mx-auto flex flex-col px-4 mt-20 text-center">
          <h1 className="text-xl font-bold">
            {isSpanish ? 'Exitosamente registrado' : 'Registered successfully'}
          </h1>
          <p className="mt-8">
            {isSpanish
              ? 'Gracias, por favor inicie sesión para comenzar a usar Family Check-Up'
              : 'Thank you, please login to start using Family Check-Up!'}
          </p>
          <PillLink to="/" className="mt-8 flex flex-row mx-auto">
            <ChevronLeftIcon className="mr-2 mt-0.5" />{' '}
            {isSpanish ? 'Regresar a Iniciar Sesión' : 'Back to Sign In'}
          </PillLink>
        </div>
      ) : inviteExpired ? (
        <div className="container max-w-4xl w-full mx-auto flex flex-col px-4 mt-20 text-center">
          <h1 className="text-xl font-bold">
            {isSpanish
              ? 'La invitación ha caducado.'
              : 'Invitation has expired.'}
          </h1>
          <p className="mt-8">
            {isSpanish
              ? 'Pídele al administrador de tu organización que vuelva a enviar la invitación.'
              : 'Ask your organization admin to resend the invite.'}
          </p>
          <PillLink to="/" className="mt-8 flex flex-row mx-auto">
            <ChevronLeftIcon className="mr-2 mt-0.5" />{' '}
            {isSpanish ? 'Regresar a Iniciar Sesión' : 'Back to Sign In'}
          </PillLink>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="container w-96 max-w-full mx-auto flex flex-col px-4 mt-20"
        >
          <h1 className="text-xl font-bold text-center">
            {isSpanish ? 'Registrarse' : 'Register'}
          </h1>
          <p className="mt-8 text-center">
            {isSpanish
              ? 'Ingrese su nueva contranseña para terminar de registrarse.'
              : 'Enter your new password to finish registering.'}
          </p>
          {submitError && (
            <p className="text-rose-600 py-2">{submitError.message}</p>
          )}
          <TextInput
            readOnly
            value={inviteEmail ?? ''}
            className="h-12 pl-5 mt-8 bg-gray-300"
          />
          <TextInput
            required
            value={password}
            onValueChange={setPassword}
            type="password"
            placeholder={isSpanish ? 'Contraseña' : 'Password'}
            autoComplete="new-password"
            className="h-12 pl-5 mt-4"
          />
          <TextInput
            required
            value={passwordConfirm}
            onValueChange={setPasswordConfirm}
            type="password"
            placeholder={
              isSpanish ? 'Contraseña confirmada' : 'Password confirmed'
            }
            autoComplete="new-password"
            className="h-12 pl-5 mt-4"
          />
          <PillButton className="mt-8 w-48 mx-auto">
            {isSpanish ? 'Registrarse' : 'Register'}
          </PillButton>
        </form>
      )}
      <div className="text-white text-center text-xs mt-12 w-72 max-w-full mx-auto">
        {isSpanish
          ? 'Es una marca registrada de la Universidad de Oregón'
          : 'Family Check-up® is a Registered Trademark of the University of Oregon'}
      </div>
      <Link
        to={{
          pathname: location.pathname,
          search: `${location.search}&privacy-policy`,
        }}
        className="text-blue-400 text-xs self-center mt-1"
      >
        {isSpanish ? 'política de privacidad' : 'privacy policy'}
      </Link>
    </div>
  );
};
