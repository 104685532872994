import { FC, useState } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import ActivityBox from '../../../components/ActivityBox/ActivityBox';
import { HelpfulUnhelpfulQuiz } from '../../../components/HelpfulUnhelpfulQuiz/HelpfulUnhelpfulQuiz';
import { HelpfulUnhelpfulQuizQuestion } from '../../../components/HelpfulUnhelpfulQuizQuestion/HelpfulUnhelpfulQuizQuestion';
import { useSlideContext } from '../../../contexts/slideContext';
import {
  PillButton,
  ButtonTheme,
} from '../../../components/PillButton/PillButton';
import { useActivityState } from '../../../hooks/useActivityState';
import {
  englishLogicalNotLogicalQuiz,
  spanishLogicalNotLogicalQuiz,
} from '../../../constants/early_childhood/rules_and_consequences_14_quiz';
import QuizCompletionOverlay from '../../../components/QuizCompletionOverlay/QuizCompletionOverlay';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

type LogicalNotLogicalQuizKey =
  | keyof typeof englishLogicalNotLogicalQuiz
  | keyof typeof spanishLogicalNotLogicalQuiz;

type ActivityState = Partial<Record<LogicalNotLogicalQuizKey, boolean>>;

export const RulesAndConsequences14Slide: FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const { next } = useSlideContext();
  const { isEnglish } = useProductLanguage();

  const { value, setValue, activity, isReview } =
    useActivityState<ActivityState>({});

  const moveForward = Object.values(value).length > 2 || isReview;
  const helpfulWord = isEnglish ? 'Logical' : 'Lógica';
  const unhelpfulWord = isEnglish ? 'Not Logical' : 'No Lógica';
  const logicalNotLogicalQuiz = isEnglish
    ? englishLogicalNotLogicalQuiz
    : spanishLogicalNotLogicalQuiz;

  return (
    <SlideContainer hideButton lightBg lightProgressBar>
      <ActivityBox
        className="items-center pt-10 pb-6 flex-1 text-blue-900"
        instructions={
          <h2 className="text-lg">
            {isEnglish
              ? 'Your turn: Logical or Not Logical?'
              : 'Su turno: ¿Lógico o No Lógico?'}
          </h2>
        }
        icon="pointer"
      >
        <HelpfulUnhelpfulQuiz
          className="mt-6"
          title={
            isEnglish
              ? 'Which of these consequences do you think are logical?'
              : ' ¿Cuál de estas consecuencias para Ud. es lógica?'
          }
          helpfulWord={helpfulWord}
          unhelpfulWord={unhelpfulWord}
          iconPair="logical/illogical"
        >
          {Object.entries(logicalNotLogicalQuiz).map(
            ([key, { answer, question, feedback }]) => (
              <HelpfulUnhelpfulQuizQuestion
                key={key}
                isHelpfulCorrect={answer}
                value={value[key as LogicalNotLogicalQuizKey] ?? null}
                questionText={question}
                feedback={feedback}
                helpfulWord={helpfulWord}
                unhelpfulWord={unhelpfulWord}
                onChange={(bool) => {
                  setValue({
                    ...value,
                    [key]: bool,
                  });
                }}
              />
            ),
          )}
        </HelpfulUnhelpfulQuiz>
        {moveForward && (
          <PillButton
            className="mt-10"
            theme={ButtonTheme.YELLOW}
            onClick={() => (!isReview ? setShowOverlay(true) : next())}
          >
            {isEnglish ? 'Finish Activity' : 'Temine la Actividad'}
          </PillButton>
        )}
      </ActivityBox>
      <QuizCompletionOverlay
        open={showOverlay}
        onNext={next}
        activityPoints={activity.pointValue}
        bearCharacter="BABY_BEAR"
      />
    </SlideContainer>
  );
};
