import { FC } from 'react';
import { SlideContainer } from '../../../components/SlideContainer/SlideContainer';
import { ExampleSection } from '../../../components/ExampleSection/ExampleSection';
import { useProductLanguage } from '../../../hooks/useProductLanguage';

export const PositiveParenting20Slide: FC = () => {
  const { isEnglish } = useProductLanguage();
  return (
    <SlideContainer
      slideTitle={isEnglish ? 'What is an incentive?' : '¿Qué es un incentivo?'}
      className="flex-col items-center text-white px-14 font-light"
    >
      <p>
        {isEnglish ? (
          <>
            An incentive is something that you promise to give your child that
            will motivate or encourage them to do something in the future.
          </>
        ) : (
          <>
            Un incentivo es algo que usted promete darle a su niño/a que lo/la
            motivará o alentará a hacer algo en el futuro.
          </>
        )}
      </p>
      <ExampleSection
        icon="star"
        className="my-5"
        title={
          <h2 className="font-semibold uppercase ml-2.5 text-lg">
            {isEnglish ? 'Example:' : 'Ejemplo:'}
          </h2>
        }
      >
        <p className="ml-8 mt-2.5">
          {isEnglish
            ? 'If you put your toys away, you can have extra time reading with mom.'
            : 'Si guardas tus juguetes, puedes tener más tiempo leyendo con mamá.'}
        </p>
      </ExampleSection>
    </SlideContainer>
  );
};
